import React, { Component } from 'react';
import { Container, FormGroup, Row, Col, Button } from 'reactstrap';
import { FormLabel, FormControl, Modal } from 'react-bootstrap';
import { request } from '../../constants/constants';
import Loader from "react-loader-spinner";

class UserEdit extends Component {
    constructor(props){
        super(props);
        this.state = {
            id: this.props.match.params.id,
            user: [],
            showSuccessModal: false,
            isLoading: true
        }
    }

    getUser(){
        return request('GET', `/users/${this.state.id}`, {}, true);
    }

    init(){
        this.getUser().then(res => {
            this.setState({
                title: res.data.data.title,
                first_name: res.data.data.first_name,
                last_name: res.data.data.last_name, 
                email: res.data.data.email,
                office_telephone: res.data.data.office_telephone,
                mobile_number: res.data.data.mobile_number,
                permission: res.data.data.permission,
                status: res.data.data.status,
                isLoading: false
            })
        })
    }

    componentDidMount() {
       this.init()
    }
    
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleStatus = e =>{
        this.setState({ status: e.target.value })
    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })

        const data = {
            title: this.state.title,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            password: this.state.password,
            office_telephone: this.state.office_telephone,
            mobile_number: this.state.mobile_number,
            permission: this.state.permission,
            status: this.state.status
        }      
           
        request('PUT', `/users/${this.state.id}`, data, true).then(res => {
            console.log(res)
            this.setState({
                isLoading: false,
                showSuccessModal: true,
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data
            })
        })
    } 

    /*Modal Functions */
    hideModal = e => {
        this.setState({ showSuccessModal: false })
        window.location.href = `/userview/${this.state.id}`;
    }

    render() {
        return (
            <div class="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">User Edit Form</h6>
                    </div>
                    <div className="card-body">
                        {/* Modal for Successful Added */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>User Successfully Updated!</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <div className="table-responsive">
                            {
                                this.state.isLoading ? (
                                    <Loader
                                        type="TailSpin"
                                        color="#2850C2"
                                        height={80}
                                        width={80}
                                        timeout={10000} 
                                    />
                                ) : (
                                    <form onSubmit={this.handleFormSubmit}>
                                        <Container>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col lg={2} md={12} sm={12}><FormLabel>Title: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col lg={2} md={12} sm={12}>
                                                        <FormControl
                                                            as="select"
                                                            type="text"
                                                            name="title"
                                                            className="center"
                                                            value={this.state.title}
                                                            onChange={this.handleChange}
                                                        >
                                                            <option>Dr</option>
                                                            <option>Mrs</option>
                                                            <option>Ms</option>
                                                            <option>Mr</option>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col lg={2} md={12} sm={12}><FormLabel>First Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col lg={4} md={12} sm={12}>
                                                        <FormControl
                                                            type="text"
                                                            name="first_name"
                                                            className="center"
                                                            value={this.state.first_name}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col lg={2} md={12} sm={12}><FormLabel className="mt-2 mt-lg-0">Last Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col lg={4} md={12} sm={12}>
                                                        <FormControl
                                                            type="text"
                                                            name="last_name"
                                                            className="center"
                                                            value={this.state.last_name}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col lg={2} md={12} sm={12}><FormLabel>Email: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col lg={4} md={12} sm={12}>
                                                        <FormControl
                                                            type="text"
                                                            name="email"
                                                            className="center"
                                                            value={this.state.email}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col lg={2} md={12} sm={12}><FormLabel className="mt-2 mt-lg-0">Mobile Number: </FormLabel></Col>
                                                    <Col lg={4} md={12} sm={12}>
                                                        <FormControl
                                                            type="text"
                                                            name="mobile_number"
                                                            className="center"
                                                            value={this.state.mobile_number}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col lg={2} md={12} sm={12}><FormLabel>Office Telephone: </FormLabel></Col>
                                                    <Col lg={4} md={12} sm={12}>
                                                        <FormControl
                                                            type="text"
                                                            name="office_telephone"
                                                            className="center"
                                                            value={this.state.office_telephone}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col lg={2} md={12} sm={12}><FormLabel className="mt-2 mt-lg-0">Permission: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col lg={4} md={12} sm={12}>
                                                        <FormControl
                                                            as="select"
                                                            type="text"
                                                            name="permission"
                                                            className="center"
                                                            value={this.state.permission}
                                                            onChange={this.handleChange}
                                                            >
                                                            <option value="super_administrator">super_administrator</option>
                                                            <option value="administrator">administrator</option>
                                                            <option value="staff">staff</option>
                                                            <option value="secretary">secretary</option>                                                    
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">    
                                                    <Col lg={2} md={12} sm={12}><FormLabel>Status: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col lg={4} md={12} sm={12}>
                                                        <FormControl
                                                            as="select"
                                                            type="text"
                                                            name="status"
                                                            className="center"
                                                            value={this.state.status}
                                                            onChange={this.handleStatus}
                                                        >
                                                            <option value="1">Active</option>
                                                            <option value="0">Inactive</option>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <Row className="center">
                                                <Col sm={12} md={12} lg={3}><button style={{width: '100%'}} className="btn btn-primary center">Update</button></Col>
                                            </Row> 
                                        </Container>
                                    </form>
                                )
                            }
                            
                        </div>
                    </div>
                </div>                
            </div>
        );
    }
}

export default UserEdit;