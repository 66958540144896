import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { request } from '../../constants/constants';
import { FormLabel, FormControl, Modal } from 'react-bootstrap';
import Loader from "react-loader-spinner";

class DoctorView extends Component {
    constructor (props){
        super(props);
        this.state = {
            id: this.props.match.params.id,
            doctors: [],
            hospitals: [],
            secretaries: [],
            names: [],
            currentNames: [],
            hospitalNames: [],
            showAppointmentModal: false,
            showAppointmentSecModal: false,
            showDeleteModal: false,
            showDeleteHospModal: false,
            isLoading: true
        }
    }

    getDoctor() {
        console.log(this.state.id);
        return request ('GET', `/doctors/${this.state.id}`, {}, true);
    }

    init (){
        this.getDoctor().then(res => {
            this.setState({
                name: res.data.data.title + '. ' + res.data.data.first_name + ' ' + res.data.data.last_name,
                user_id: res.data.data.user_id,
                status: res.data.data.status,
                mobile: res.data.data.mobile,
                office_telephone: res.data.data.office_telephone,
                office_telephone_2: res.data.data.office_telephone_2,
                office_facsmile: res.data.data.office_facsmile,
                office_email: res.data.data.office_email,
                office_address: res.data.data.office_address,
                office_address_2: res.data.data.office_address_2,
                office_suburb: res.data.data.office_suburb,
                office_state: res.data.data.office_state,
                office_postcode: res.data.data.office_postcode,
                postal_address: res.data.data.postal_address,
                postal_address_2: res.data.data.postal_address_2,
                postal_suburb: res.data.data.postal_suburb,
                postal_state: res.data.data.postal_state,
                postal_post_code: res.data.data.postal_post_code,
                isLoading: false
            })
        })
    }

    componentDidMount() {
        this.init()
        /*Get all the Active Hospitals*/
        request('GET', '/hospitals/Admin/adminHospitalListOnlyActive', {}, true).then(result => { 
            const hospitals = result.data.data.map(obj => ({ 
                id: obj.id,
                name: obj.name
            }));
            this.setState({ hospitals });
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });

        /*Get all the Active Secretaries*/
        request('GET', '/users/secretaries', {}, true).then(result => {
            const secretaries = result.data.data.map(obj => ({
              id: obj.id,
              name: obj.title + '. ' + obj.first_name + ' ' + obj.last_name
            }));
            this.setState({ secretaries });
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });

        /*Get all the Secretaries for this Doctor*/
        request('GET', `/user_doctor/byDoctorId/${this.state.id}`, {}, true).then(result => { 
            console.log('byDoctorId', result);
            const names = result.data.data.map(obj => ({
                id: obj.id,
                doctor: obj.doctor,
                secretary: obj.secretary
            }));
            let datas = names.sort((a, b) => (a.secretary.first_name + ' ' + a.secretary.last_name > b.secretary.first_name + ' ' + a.secretary.last_name) ? 1 : -1)
            this.setState({
                currentNames: names              
            });             
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        })

        /*Get all the Hospital for this Doctor*/
        request('GET', `/doctor_hospitals/byDoctorId/${this.state.id}`, {}, true).then(result => {
            // console.log(result)
            const hospitalNames = result.data.data.map(obj => ({ 
                id: obj.id,
                doctor: obj.doctor,
                hospital: obj.hospital
            }));

            let datas = hospitalNames.sort((a, b) => (a.doctor.first_name + ' ' + a.doctor.last_name > b.doctor.first_name + ' ' + b.doctor.last_name) ? 1 : -1)

            this.setState({ 
                hospitalNames: hospitalNames 
            })

        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });
    }

    /* Handle functions */
    handleDoctor = e => {
        this.setState({
            doctor_id: e.target.value
        })
    }
    
    handleHospital = e => {
        this.setState({
            hospital_id: e.target.value
        })
    }

    handleSecretary = e => {
        this.setState({
            secretary_id: e.target.value
        })
    }

    handleDelete = e => {
        // console.log(e)
        this.setState({ showDeleteModal: true, currentId: e})
    }

    handleDeleteHosp = e => {
        // console.log(e)
        this.setState({ showDeleteHospModal: true, currentHospId: e})
    }

    handleAppoint = e => {
        const data = {
            hospital_id: this.state.hospital_id,
            doctor_id: this.state.id
        }
        
        request('POST', '/doctor_hospitals/addRelationship', data, true).then(result => { 
            this.setState({
                isLoading: false,
                showAppointmentModal: false,
                showSuccessModal: true
            })    
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data.message,
                responseCode: err.response.status
            })
        })
    }

    handleAppointSec = e => {
        const data = {
            user_id: this.state.secretary_id,
            doctor_id: this.state.id
        }
        request('POST', '/user_doctor/addRelationship', data, true).then(result => { 
            this.setState({
                isLoading: false,
                showAppointmentSecModal: false,
                showSuccessModal: true
            })         
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data.message,
                responseCode2: err.response.status
            })
        })
    }

    handleRemove = e => {
        // console.log(e.target.value);
        const data = {
             id: e.target.value
        }
        request('DELETE', `/user_doctor/${e.target.value}`, data, true).then(result => { 
            this.setState({
                isLoading: false,
            })    
            window.location.href = `/doctorview/${this.state.id}`;
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data.message,
                responseCode: err.response.status
            })
        })
    }

    handleRemoveHosp = e => {
        // console.log(e.target.value);
        const data = {
             id: e.target.value
        }
        request('DELETE', `/doctor_hospitals/${e.target.value}`, data, true).then(result => { 
            this.setState({
                isLoading: false,
            })    
            window.location.href = `/doctorview/${this.state.id}`;
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data.message,
                responseCode: err.response.status
            })
        })
    }

    /* Modal Functions*/
    handleAppointmentModal = () => {
        this.setState({ 
          showAppointmentModal: true 
        })
    }

    hideAppointmentModal = () => {
        this.setState({ 
            showAppointmentModal: false 
        })
    }

      /* Modal Functions For Secretary Appointment*/
    handleAppointmentSecretaryModal = () => {
        this.setState({ 
            showAppointmentSecModal: true 
        })
    }
  
    hideAppointmentSecModal = () => {
        this.setState({ 
            showAppointmentSecModal: false 
        })
    }

    hideSuccessModal = () => {
        this.setState({ 
            showSuccessModal: false 
        })
        window.location.href = `/doctorview/${this.state.id}`;  
    }

    /*Delete Secretary Modal */
    hideDeleteModal = () => {
        this.setState({ 
            showDeleteModal: false 
        })
    }

    /* For Delete Hospital Modal */

    hideDeleteHospModal = () => {
        this.setState({ showDeleteHospModal: false })
    }

    render() {

        let current_name = null;

        return (
            <div class="container-fluid" id="center">
                <div className="card shadow mb-4">
                  <div className="card-header py-3">
                      <Container>
                        <Row>
                            <Col sm={12} md={12} lg={3}><h6 className="m-0 font-weight-bold text-primary">Doctor Information</h6></Col>
                            <Col sm={12} md={12} lg={3}>
                                <button style={{width: '100%'}} type="submit" className="btn btn-primary center mt-2 mt-lg-0" onClick={this.handleAppointmentModal}><i class="far fa-calendar-check"></i> Appoint Hospital</button>
                            </Col>
                            <Col sm={12} md={12} lg={3}>
                                <button style={{width: '100%'}} type="submit" className="btn btn-primary center mt-2 mt-lg-0" onClick={this.handleAppointmentSecretaryModal}><i class="far fa-calendar-check"></i> Appoint Secretary</button>
                            </Col>
                            <Col sm={12} md={12} lg={3}>
                                <Link to={`/doctoredit/${this.state.id}`}><button style={{width: '100%'}} type="submit" className="btn btn-primary center mt-2 mt-lg-0" variant="danger"><i class="far fa-edit" /> Edit Doctor</button></Link>
                            </Col>
                        </Row>
                    </Container>
                  </div>
                  <div className="card-body">

                    {/* Modal for appointment of Secretary */}
                    <Modal show={this.state.showAppointmentModal} backdrop="static">
                        <Modal.Header onClick={this.hideAppointmentModal} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger"> Tag Hospital to Doctor</h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className='center m-2'>
                                <Col sm={2}><FormLabel>Hospital: </FormLabel></Col>
                                <Col sm={10}>
                                    <FormControl
                                    as = "select"
                                    type="text"
                                    name="doctor"
                                    className="center"
                                    onChange={this.handleHospital}
                                    >
                                    <option value="-Select-">-Select-</option>
                                    {this.state.hospitals.map(hospital => (
                                        <option value={hospital.id}>{hospital.name}</option>
                                    ))}
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row className='center m-2'>
                                <p style={{color: '#FF0000', display: this.state.responseCode == 403 || 422? 'block' : 'none' }}>{this.state.error}</p>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary center" onClick={this.hideAppointmentModal}>
                                Cancel
                            </button>
                            <button className="btn btn-primary center" onClick={this.handleAppoint}>
                                Appoint
                            </button>
                        </Modal.Footer>
                    </Modal>

                    {/* Modal for appointment of Secretary */}
                    <Modal show={this.state.showAppointmentSecModal}>
                        <Modal.Header onClick={this.hideAppointmentSecModal} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Secretary Appointment</h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className='center m-2'>
                                <Col sm={2}><FormLabel>Secretary: </FormLabel></Col>
                                <Col sm={10}>
                                    <FormControl
                                        as = "select"
                                        type="text"
                                        name="secretary"
                                        className="center"
                                        onChange={this.handleSecretary}
                                    >
                                    <option value="-Select-">-Select-</option>
                                    {this.state.secretaries.map(secretary => (
                                        <option value={secretary.id}>{secretary.name}</option>
                                    ))}
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row className='center m-2'>
                                <p className="center" style={{color: '#FF0000', display: this.state.responseCode2 === 403 || 422 ? 'block' : 'none' }}>{this.state.error}</p>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary center" onClick={this.hideAppointmentSecModal}>
                                Cancel
                            </button>
                            <button className="btn btn-primary center" onClick={this.handleAppointSec}>
                                Appoint
                            </button>
                        </Modal.Footer>
                    </Modal>

                    {/* Modal for Successfully Appointed */}
                    <Modal show={this.state.showSuccessModal}>
                        <Modal.Header onClick={this.hideSuccessModal} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="center" style={{textAlign: "center"}} className="center">Successfully Appointed!</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary center" onClick={this.hideSuccessModal}>
                                OK
                            </button>
                        </Modal.Footer>
                    </Modal>

                    {/* Modal for Delete  */}
                    <Modal show={this.state.showDeleteModal} backdrop="static">
                        <Modal.Header onClick={this.hideDeleteModal} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="center" style={{textAlign: "center"}}>Are you sure you want to remove this secretary?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary center" onClick={this.hideDeleteModal}>
                                Cancel
                            </button>
                            <button 
                                className="btn btn-danger center" 
                                value={this.state.currentId} 
                                onClick={this.handleRemove}
                            >Delete
                            </button>
                        </Modal.Footer>
                    </Modal>

                    {/* Modal for Delete  */}
                    <Modal show={this.state.showDeleteHospModal} backdrop="static">
                        <Modal.Header onClick={this.hideDeleteHospModal} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="center" style={{textAlign: "center"}}>Are you sure you want to remove this Hospital?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary center" onClick={this.hideDeleteHospModal}>
                                Cancel
                            </button>
                            <button 
                                className="btn btn-danger center" 
                                value={this.state.currentHospId} 
                                onClick={this.handleRemoveHosp}
                            >Delete
                            </button>
                        </Modal.Footer>
                    </Modal>

                    {
                        this.state.isLoading ? (
                            <Loader
                                type="TailSpin"
                                color="#2850C2"
                                height={80}
                                width={80}
                                timeout={10000} 
                            />
                        ) : (
                            <Container>
                                {/* General Details */}
                                <Row className="center">
                                    <Col lg={6} md={6} sm={12}><FormLabel><b>General Details:</b> </FormLabel></Col>   
                                </Row>
                                <Row className="center">
                                    <Col lg={2} md={4}><FormLabel>Name: </FormLabel></Col>
                                    <Col lg={10} md={8}>
                                        <FormLabel>
                                            {this.state.name}
                                        </FormLabel>
                                    </Col> 
                                </Row>
                                <Row className="center">
                                    <Col md={4} lg={2}><FormLabel>Mobile: </FormLabel></Col>
                                    <Col md={8} lg={10}>
                                        <FormLabel>
                                        {this.state.mobile}
                                        </FormLabel>
                                    </Col>               
                                </Row>
                                <Row className="center">
                                    <Col lg={2} md={4}><FormLabel>User ID: </FormLabel></Col>
                                    <Col lg={10} md={8}>
                                        <FormLabel>
                                        {this.state.user_id}
                                        </FormLabel>
                                    </Col>
                                </Row>
                                <Row className="center">
                                    <Col lg={2} sm={2} md={4}><FormLabel>Status: </FormLabel></Col>
                                    <Col lg={10} sm={4} md={8}>
                                        <FormLabel>
                                        {this.state.status === 1 ? 'Active' : 'Inactive'}
                                        </FormLabel>
                                    </Col>
                                </Row>
                                <hr/>

                                {/* Telephone / Fax */}
                                <Row className="center">
                                    <Col lg={12} md={12} sm={12}><FormLabel><b>Telephone / Fax:</b> </FormLabel></Col>
                                </Row>
                                <Row className="center">
                                    <Col lg={2} md={4} sm={2}><FormLabel>Office Telephone: </FormLabel></Col>
                                    <Col lg={10} md={8} sm={4}>
                                        <FormLabel>
                                        {this.state.office_telephone}
                                        </FormLabel>
                                    </Col>                
                                </Row>
                                <Row className="center">
                                    <Col lg={2} sm={2} md={4}><FormLabel>Office Telephone 2: </FormLabel></Col>
                                    <Col lg={10} sm={4} md={8}>
                                        <FormLabel>
                                        {this.state.office_telephone_2}
                                        </FormLabel>
                                    </Col>
                                </Row>
                                <Row className="center">
                                    <Col lg={2} sm={2} md={4}><FormLabel>Office Email: </FormLabel></Col>
                                    <Col lg={10} sm={4} md={8}>
                                        <FormLabel>
                                        {this.state.office_email}
                                        </FormLabel>
                                    </Col>
                                </Row>
                                <Row className="center">
                                    <Col lg={2} sm={2} md={4}><FormLabel>Office Facsmile: </FormLabel></Col>
                                    <Col lg={10} sm={4} md={8}>
                                        <FormLabel>
                                        {this.state.office_facsmile}
                                        </FormLabel>
                                    </Col>
                                </Row>                    
                                <hr/>

                                {/* Office Address: */}
                                <Row className="center">
                                    <Col><FormLabel><b>Office Address:</b> </FormLabel></Col>
                                </Row>
                                <Row className="center">
                                    <Col lg={2} sm={2} md={4}><FormLabel>Address 1: </FormLabel></Col>
                                    <Col lg={10} sm={4} md={8}>
                                        <FormLabel>
                                        {this.state.office_address}
                                        </FormLabel>
                                    </Col>
                                </Row>
                                <Row className="center">
                                    <Col lg={2} sm={2} md={4}><FormLabel>Address 2: </FormLabel></Col>
                                    <Col lg={10} sm={4} md={8}>
                                        <FormLabel>
                                        {this.state.office_address_2}
                                        </FormLabel>
                                    </Col>
                                </Row>
                                <Row className="center">
                                    <Col lg={2} sm={2} md={4}><FormLabel>Suburb: </FormLabel></Col>
                                    <Col lg={10} sm={4} md={8}>
                                        <FormLabel>
                                        {this.state.office_suburb}
                                        </FormLabel>
                                    </Col>
                                </Row>
                                <Row className="center">
                                    <Col lg={2} sm={2} md={4}><FormLabel>State: </FormLabel></Col>
                                    <Col lg={10} sm={4} md={8}>
                                        <FormLabel>
                                        {this.state.office_state}
                                        </FormLabel>
                                    </Col>
                                </Row>
                                <Row className="center">
                                    <Col lg={2} sm={2} md={4}><FormLabel>Postcode: </FormLabel></Col>
                                    <Col lg={10} sm={4} md={8}>
                                        <FormLabel>
                                        {this.state.office_postcode}
                                        </FormLabel>
                                    </Col>
                                </Row>
                                <hr/>

                                {/* Postal Address: */}
                                <Row className="center">
                                    <Col><FormLabel><b>Postal Address:</b> </FormLabel></Col>
                                </Row>
                                <Row className="center">
                                    <Col lg={2} sm={2} md={4}><FormLabel>Address 1: </FormLabel></Col>
                                    <Col lg={10} sm={4} md={8}>
                                        <FormLabel>
                                        {this.state.postal_address}
                                        </FormLabel>
                                    </Col>
                                </Row>
                                <Row className="center">
                                    <Col lg={2} sm={2} md={4}><FormLabel>Address 2: </FormLabel></Col>
                                    <Col lg={2} sm={4} md={8}>
                                        <FormLabel>
                                        {this.state.postal_address_2}
                                        </FormLabel>
                                    </Col>
                                </Row>
                                <Row className="center">
                                    <Col lg={2} sm={2} md={4}><FormLabel>Suburb: </FormLabel></Col>
                                    <Col lg={10} sm={4} md={8}>
                                        <FormLabel>
                                        {this.state.postal_suburb}
                                        </FormLabel>
                                    </Col>
                                </Row>
                                <Row className="center">
                                    <Col lg={2} sm={2} md={4}><FormLabel>State: </FormLabel></Col>
                                    <Col lg={10} sm={4} md={8}>
                                        <FormLabel>
                                        {this.state.postal_state}
                                        </FormLabel>
                                    </Col>
                                </Row>
                                <Row className="center">
                                    <Col lg={2} sm={2} md={4}><FormLabel>Postcode: </FormLabel></Col>
                                    <Col lg={10} sm={4} md={8}>
                                        <FormLabel>
                                        {this.state.postal_post_code}
                                        </FormLabel>
                                    </Col>
                                </Row>
                                <hr/>
                                
                                {/* Secretaries */}
                                <Row className="center">
                                    <Col><FormLabel><b>Secretaries:</b> </FormLabel></Col>
                                </Row>
                                <Row className="center">
                                    <Col lg={12} sm={12} md={12}>
                                        {this.state.currentNames && this.state.currentNames.map((name, i) => { 
                                            if(name.secretary !== null){
                                                if(name.secretary.status){
                                                    return <ul key={i}>
                                                                <li>{name.secretary.first_name + ' ' + name.secretary.last_name} <Link><i class="fas fa-times-circle" onClick={ () => this.handleDelete(name.id)} style={{marginLeft: '15px'}}/></Link></li>
                                                            </ul>
                                                }
                                            }  
                                        })}
                                    </Col>
                                </Row>
                                <hr/>

                                {/* Hospitals */}
                                <Row className="center">
                                    <Col sm={6} lg={12} ><FormLabel><b>Hospitals:</b> </FormLabel></Col>
                                </Row>
                                <Row className="center">
                                    <Col sm={6} lg={12}  md={12}>
                                        {this.state.hospitalNames && this.state.hospitalNames.map((hospitalname, i) => {   
                                            if(hospitalname.doctor){
                                                    return <ul>
                                                                <li style={{ color: hospitalname.hospital ? ( hospitalname.hospital.status ? 'black' : 'grey') : '' }}>
                                                                    { hospitalname.hospital ? hospitalname.hospital.name : '-' }
                                                                    <Link><i class="fas fa-times-circle" onClick={ () => this.handleDeleteHosp(hospitalname.id)} style={{marginLeft: '15px'}}/></Link>
                                                                </li>
                                                            </ul>
                                            }
                                        })}
                                    </Col>
                                </Row>
                            </Container>
                        )
                    }
                  </div>
                </div>
            </div>
        );
    }
}

export default DoctorView;