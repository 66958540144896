import React, { Component } from 'react';
import { Row, Col, Modal, FormLabel, FormControl, Pagination, Table } from 'react-bootstrap';
import { request } from '../../constants/constants';

class HospitalDoctorList extends Component {
    constructor(props){
        super(props);
        this.state = {
            showAppointmentModal: false,
            showDeleteModal: false,
            doctors: [],
            hospitals: [],
            names: []
        }
    }

    componentDidMount(){
        request('GET', '/doctor_hospitals/', {}, true).then(result => {
            console.log(result)
            const names = result.data.data.map(obj => ({ 
                id: obj.id,
                doctor: obj.doctor,
                hospital: obj.hospital
            }));

            let datas = names.sort((a, b) => (a.doctor.first_name + ' ' + a.doctor.last_name > b.doctor.first_name + ' ' + b.doctor.last_name) ? 1 : -1)

            this.setState({ 
                names: names 
            })

        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });

        request('GET', '/doctors/allDoctorsByAdminOnlyActive', {}, true).then(result => {
            const doctors = result.data.data.map(obj => ({
                id: obj.ID,
                name: obj.title + '. ' + obj.first_name + ' ' + obj.last_name
            }));
            this.setState({ doctors });
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });

        request('GET', '/hospitals/Admin/adminHospitalListOnlyActive', {}, true).then(result => { 
            const hospitals = result.data.data.map(obj => ({ 
                id: obj.ID,
                name: obj.name
            }));
            this.setState({ hospitals });
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });
    }

    /* Handle functions */
    handleDoctor = e => {
        this.setState({
            doctor_id: e.target.value
        })
    }
    
    handleHospital = e => {
        this.setState({
            hospital_id: e.target.value
        })
    }

    handleAppoint = e => {
        const data = {
            hospital_id: this.state.hospital_id,
            doctor_id: this.state.doctor_id
        }
        
        request('POST', '/doctor_hospitals/addRelationship', data, true).then(result => { 
            this.setState({
                isLoading: false,
                showAppointmentModal: false,
                showSuccessModal: true
            })    
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data.message,
                responseCode: err.response.status
            })
        })
    }

    handleRemove = e => {
        console.log(e.target.value);
        const data = {
             id: e.target.value
        }
        request('DELETE', `/doctor_hospitals/${e.target.value}`, data, true).then(result => { 
            this.setState({
                isLoading: false,
            })    
            window.location.href = "/hospdoctorlist";
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data.message,
                responseCode: err.response.status
            })
        })
    }

    handleDelete = e => {
        this.setState({ showDeleteModal: true, currentId: e.target.value})
    }

    /* Modal Functions*/
    handleAppointmentModal = (e) => {
        this.setState({ 
          showAppointmentModal: true 
        })
    }
  
    hideAppointmentModal = () => {
        this.setState({ 
            showAppointmentModal: false 
        })
    }
  
    hideSuccessModal = () => {
        this.setState({ 
            showSuccessModal: false 
        })
        window.location.href = "/hospdoctorlist";
    }

    hideDeleteModal = () =>{
        this.setState({ 
            showDeleteModal: false 
        })
    }

    render() {
        let current_name = null;
        return (
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col><h5 className="m-0 font-weight-bold text-primary center">Hospital - Doctors List</h5></Col>
                            <Col sm={3} style={{textAlign: "right"}}>
                                <button type="submit" className="btn btn-primary center" onClick={this.handleAppointmentModal}><i class="far fa-calendar-check"></i> Add Hospital to Doctor</button>
                            </Col>
                        </Row>
                    </div>
                    <div className="card-body">
                        
                        {/* Modal for appointment of Secretary */}
                        <Modal show={this.state.showAppointmentModal} backdrop="static">
                            <Modal.Header onClick={this.hideAppointmentModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger"> Hospital - Doctor Appointment</h5></Modal.Title>
                            </Modal.Header>
                        <Modal.Body>
                            <Row className='center m-2'>
                                <Col sm={2}><FormLabel>Doctor: </FormLabel></Col>
                                <Col sm={5}>
                                    <FormControl
                                        as = "select"
                                        type="text"
                                        name="secretary"
                                        className="center"
                                        onChange={this.handleDoctor}
                                    >
                                    <option value="-Select-">-Select-</option>
                                    {this.state.doctors.map(doctor => (
                                        <option value={doctor.id}>{doctor.name}</option>
                                    ))}
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row className='center m-2'>
                                <Col sm={2}><FormLabel>Hospital: </FormLabel></Col>
                                <Col sm={5}>
                                    <FormControl
                                    as = "select"
                                    type="text"
                                    name="doctor"
                                    className="center"
                                    onChange={this.handleHospital}
                                    >
                                    <option value="-Select-">-Select-</option>
                                    {this.state.hospitals.map(hospital => (
                                        <option value={hospital.id}>{hospital.name}</option>
                                    ))}
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row className='center m-2'>
                                <p style={{color: '#FF0000', display: this.state.responseCode == 403 || 422? 'block' : 'none' }}>{this.state.error}</p>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary center" onClick={this.hideAppointmentModal}>
                                Cancel
                            </button>
                            <button className="btn btn-primary center" onClick={this.handleAppoint}>
                                Appoint
                            </button>
                        </Modal.Footer>
                        </Modal>

                        {/* Modal for Successfully Appointed */}
                        <Modal show={this.state.showSuccessModal} backdrop="static">
                        <Modal.Header onClick={this.hideSuccessModal} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                                <p>Successfully Appointed!</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary center" onClick={this.hideSuccessModal}>
                                OK
                            </button>
                        </Modal.Footer>
                        </Modal>

                        {/* Modal for Delete  */}
                        <Modal show={this.state.showDeleteModal} backdrop="static">
                            <Modal.Header onClick={this.hideDeleteModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p style={{textAlign: "center"}}>Are you sure you want to delete this?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary center" onClick={this.hideDeleteModal}>
                                    Cancel
                                </button>
                                <button 
                                    className="btn btn-danger center" 
                                    value={this.state.currentId} 
                                    onClick={this.handleRemove}
                                >Delete
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <Table striped bordered hover size="sm" className="center">
                            <thead>
                                <tr className="center" style={{textAlign: 'center'}}>
                                    <th>Actions</th>
                                    <th>Doctors</th>
                                    <th>Hospitals</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.names.map((name, i) => {   
                                    if(name.doctor){
                                        if(name.doctor.status){
                                            if (current_name === null) {
                                                //1st attempt
                                                current_name = name.doctor.first_name + ' ' + name.doctor.last_name
                                            } else {
                                                //after the 1st attemp all process will be here
                                                if (current_name === name.doctor.first_name + ' ' + name.doctor.last_name) {
                                                    return <tr>
                                                                <td><button className="btn btn-danger center" value={name.id} onClick={this.handleDelete}>Remove</button></td> 
                                                                <td></td>  
                                                                <td style={{ color: name.hospital ? ( name.hospital.status ? 'black' : 'grey') : '' }}>{ name.hospital ? name.hospital.name : '-' } </td>
    
                                                           </tr>
                                                } else {
                                                    current_name = name.doctor.first_name + ' ' + name.doctor.last_name;
                                                }
                                            }
                                            return <tr>
                                                    <td><button className="btn btn-danger center" value={name.id} onClick={this.handleDelete}>Remove</button></td> 
                                                    <td>{current_name}</td>
                                                    <td style={{ color: name.hospital ? ( name.hospital.status ? 'black' : 'grey') : '' }}>{ name.hospital ? name.hospital.name : '-' } </td>
                                                  </tr>
                                        }
                                    }
                                 })}
                            </tbody>                           
                        </Table>
                    </div>
                </div>                
            </div>
        );
    }
}

export default HospitalDoctorList;