import React from 'react';
import {Link} from "react-router-dom";
// import * as Icon from 'react-bootstrap-icons';
// import history from '../../history';
import cookie  from 'react-cookies';
import UserContext from '../../contexts/User/Index';
import { request } from '../../constants/constants';
import userPic from '../../assets/images/user.png';

class Header extends React.Component {
  static contextType = UserContext;

  constructor(props){
    super(props);
    this.state = {
      image: userPic, 
      profile_picture: ''
    }
  }

  getProfilePicture() {
    return request('GET', `/users/${this.context.user.user.id}`, {}, true);
    
  }

  init (){
    this.getProfilePicture().then(res => {
      // console.log(res)
        this.setState({
            profile_picture: res.data.data.profile_picture
        })
    })
  }

  componentDidMount(){
    this.init()
  }

  logout = () => {
    cookie.remove("user");
    window.location.href = "/login";
  }

  render() {
    const name = this.context.user.user.first_name + ' ' + this.context.user.user.last_name;
    const id = this.context.user.user.id;
    return (
      <nav className="navbar navbar-expand navbar-light bg-white topbar static-top shadow">
        {/* Sidebar Toggle (Topbar) */}
        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
          <i className="fa fa-bars" />
        </button>
        {/* Topbar Navbar */}
        <ul className="navbar-nav ml-auto">
          <div className="topbar-divider d-none d-sm-block" />
          {/* Nav Item - User Information */}
          <li className="nav-item dropdown no-arrow">
            <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">{name}</span>
              {
                this.state.profile_picture === null ? ( <img className="img-profile rounded-circle" src={userPic}/> ) : ( <img className="img-profile rounded-circle" src={`${this.state.profile_picture}`}/> ) 
              }
            </a>
            {/* Dropdown - User Information */}
            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
              <Link className="dropdown-item" to={`/editprofile/${id}`}>
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                <span className="mr-4 text-gray-600 small">Edit Profile</span>
              </Link>
              <div className="dropdown-divider" />
              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" onClick={this.logout}>
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                  <span className="mr-4 text-gray-600 small">Logout</span>
                </a>
            </div>
          </li>
        </ul>
      </nav>

    )
  }
}

export default Header;
