import React, { Component } from 'react';
import { FormLabel, Modal, FormGroup, FormControl } from 'react-bootstrap';
import { Container, Row, Col, Button } from 'reactstrap';
import { request } from '../../constants/constants';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Loader from "react-loader-spinner";

class PatientBookingView extends Component {
    constructor(props){
        super(props);
        this.state = {
            id: this.props.match.params.id,
            title: '',
            last_name: '',
            middle_name: '',
            date_of_birth: '',
            address_1: '',
            address_2: '',
            suburb: '',
            state: '',
            post_code: '',
            procedure: '', 
            probe: '',
            date: '',
            slot: '',
            status: '',
            notes: '',
            ur_number: '',
            doctors: [],
            hospitals: [],
            hospitalId: '',
            patients:[],

            showEditPatient: false,
            showSuccess: false,
            isLoading: true,
            isLoadingPatientDetails: true
        }
    }

    componentDidMount() { 
        request('GET', `/bookings/singleBooking/${this.state.id}`, {}, true).then(result => { 
            console.log(result);
            this.setState({ 
                patients: result.data.data.patients,
                procedure: result.data.data.procedure,
                probe: result.data.data.probe,
                date: moment(result.data.data.date).format("MMMM D YYYY"),
                slot: result.data.data.slot,
                status: result.data.data.status,
                notes: result.data.data.notes,
                hospitals: result.data.data.hospitals,
                hospitalId: result.data.data.hospitals.id,
                doctors: result.data.data.doctors,
                treated: result.data.data.treated,
                ur_number: result.data.data.ur_number,
                isLoading: false
            })
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleEditPatient = e => {
        const id = e;
        this.setState({
            showEditPatient: true,
            currentPatientId: id,
        })
        
        request('GET', `/patients/${id}`, {}, true).then(result => { 
            this.setState({ 
                title: result.data.data.title,
                first_name: result.data.data.first_name,
                second_name: result.data.data.second_name,
                last_name: result.data.data.last_name,
                date_of_birth: result.data.data.date_of_birth,
                address_1: result.data.data.address_1,
                address_2: result.data.data.address_2,
                suburb: result.data.data.suburb,
                state: result.data.data.state,
                post_code: result.data.data.post_code,
                isLoadingPatientDetails: false
            })
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });

    }

    /* Modal Functions*/

    hideEditPatient = e => {
        this.setState({
            showEditPatient: false
        })
    }

    handleSavePatient = e => {
        const id = e.target.value;
        const data = {
            title: this.state.title,
            first_name: this.state.first_name,
            second_name: this.state.second_name,
            last_name: this.state.last_name,
            date_of_birth: this.state.date_of_birth,
            address_1: this.state.address_1,
            address_2: this.state.address_2,
            suburb: this.state.suburb,
            state: this.state.state,
            post_code: this.state.post_code,
            patient_name: this.state.title + '. ' + this.state.first_name + ' ' + this.state.second_name + ' ' + this.state.last_name
        }


        request('PUT', `/patients/${id}`, data, true).then(result => { 
            console.log(result)
            this.setState({
                isLoading: false,
                showSuccess: true,
                showEditPatient: false
            })
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });
    }


    hideModalSuccess = () => {
        this.setState({ showSuccess: false})
        window.location.href = `/patientsbookingview/${this.state.id}`;
    }


    render() {
        return (
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                    <Row>
                        <Col sm={12} md={6} lg={8}><h5 className="mb-2 font-weight-bold text-primary center">Booking Information</h5></Col>
                        <Col sm={12} lg={4}><Link to={`/patientsbookingedit/${this.state.id}/${this.state.hospitalId}`} className="btn btn-block btn-primary center"><i class="far fa-edit" /> Add Treatment</Link></Col>
                    </Row>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            {/* Modal for Edit Patient */}
                            <Modal show={this.state.showEditPatient} backdrop="static" size="xl">
                                <Modal.Header onClick={this.hideEditPatient} closeButton>
                                    <Modal.Title>
                                        <h5 className="m-0 font-weight-bold text-danger">
                                            {
                                                this.state.isLoading ? ('') : (
                                                    this.state.title + '. ' + this.state.first_name + ' ' + (this.state.second_name ? this.state.second_name + ' ' : '') + this.state.last_name
                                                )
                                            }
                                        </h5>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    {
                                        this.state.isLoadingPatientDetails ? (
                                            <Loader
                                                type="TailSpin"
                                                color="#2850C2"
                                                height={80}
                                                width={80}
                                                timeout={10000} 
                                            />
                                        ) : (
                                            <Container>
                                                <FormGroup>
                                                    <h6>Patient Details</h6>
                                                    <Row className="center">
                                                        <Col sm={12} md={2} lg={2}><FormLabel>Title: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                        <Col sm={12} md={4} lg={4}>
                                                            <FormControl
                                                                as="select"
                                                                type="text"
                                                                name="title"
                                                                className="center"
                                                                value={this.state.title}
                                                                onChange={this.handleChange}
                                                            >
                                                                <option value="Dr">Dr</option>
                                                                <option>Mrs</option>
                                                                <option>Ms</option>
                                                                <option>Mr</option>
                                                            </FormControl>
                                                        </Col>
                                                        <Col sm={12} md={2} lg={2}><FormLabel>Date of Birth: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                        <Col sm={12} md={4} lg={4}>
                                                            <FormControl
                                                                type="date"
                                                                className="center"
                                                                name="date_of_birth"
                                                                value={this.state.date_of_birth}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Row className="center">
                                                        <Col sm={12} md={2} lg={2}><FormLabel>First Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                        <Col sm={12} md={4} lg={2}>
                                                            <FormControl
                                                                type="text"
                                                                name="first_name"
                                                                className="center"
                                                                value={this.state.first_name}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col sm={12} md={2} lg={2}><FormLabel>Middle Name: </FormLabel></Col>
                                                        <Col sm={12} md={4} lg={2}>
                                                            <FormControl
                                                                type="text"
                                                                name="second_name"
                                                                className="center"
                                                                value={this.state.second_name}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col sm={12} md={4} lg={2}><FormLabel>Last Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                        <Col sm={12} md={6} lg={2}>
                                                            <FormControl
                                                                type="text"
                                                                name="last_name"
                                                                className="center"
                                                                value={this.state.last_name}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                                <h6>Home Address</h6>
                                                <FormGroup>
                                                    <Row className="center">
                                                        <Col sm={12} md={4} lg={2}><FormLabel>Address 1: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                        <Col sm={12} md={8} lg={4}>
                                                            <FormControl
                                                                type="text"
                                                                name="address_1"
                                                                className="mb-md-2 center"
                                                                value={this.state.address_1}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col sm={12} md={4} lg={2}><FormLabel>Address 2: </FormLabel></Col>
                                                        <Col sm={12} md={8} lg={4}>
                                                            <FormControl
                                                                type="text"
                                                                name="address_2"
                                                                className="mb-md-2 center"
                                                                value={this.state.address_2}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Row className="center">
                                                        <Col sm={12} md={4} lg={2}><FormLabel>Suburb: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                        <Col sm={12} md={8} lg={2}>
                                                            <FormControl
                                                                type="text"
                                                                name="suburb"
                                                                className="mb-md-2 center"
                                                                value={this.state.suburb}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col sm={12} md={4} lg={2}><FormLabel>State: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                        <Col sm={12} md={8} lg={2}>
                                                            <FormControl
                                                                as="select"
                                                                type="text"
                                                                name="state"
                                                                className="mb-md-2 center"
                                                                value={this.state.state}
                                                                onChange={this.handleChange}
                                                            >
                                                                <option>ACT</option>
                                                                <option>NSW</option>
                                                                <option>VIC</option>
                                                                <option>TAS</option>
                                                                <option>QLD</option>
                                                                <option>SA</option>
                                                                <option>WA</option>
                                                                <option>NT</option>
                                                            </FormControl>
                                                        </Col>
                                                        <Col sm={12} md={4} lg={2}><FormLabel>Post Code:<span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                        <Col sm={12} md={8} lg={2}>
                                                            <FormControl
                                                                type="text"
                                                                name="post_code"
                                                                className="center"
                                                                value={this.state.post_code}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </FormGroup> 
                                            </Container>
                                        )
                                    }
                                                                             
                                </Modal.Body>
                                <Modal.Footer>
                                    <Row className="center">
                                        <button className="btn btn-danger center" onClick={this.hideEditPatient}>
                                            Cancel
                                        </button>
                                        <button className="ml-2 btn btn-primary center" value={this.state.currentPatientId} onClick={this.handleSavePatient}>
                                            Update
                                        </button>
                                    </Row>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal for SUCCESS Updating  */}
                            <Modal show={this.state.showSuccess}>
                                <Modal.Header onClick={this.hideModalSuccess} closeButton>
                                    <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="center" style={{textAlign: 'center'}}>Booking Successfully Updated! </p>                                             
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-primary center" variant="primary" onClick={this.hideModalSuccess}>
                                        OK
                                    </button>
                                </Modal.Footer>
                            </Modal>

                            {
                                this.state.isLoading ? (
                                    <Loader
                                        type="TailSpin"
                                        color="#2850C2"
                                        height={80}
                                        width={80}
                                        timeout={10000} 
                                    />   
                                ) : (
                                    <Container>
                                        <Row className="m-0 center">
                                            <div className="col-4 col-md-2 col-lg-2 center"><FormLabel>Doctor:</FormLabel></div>
                                            <div className="col-8 col-md-6 col-lg-10">{this.state.doctors && this.state.doctors.title + '. ' + this.state.doctors.first_name + ' ' + this.state.doctors.last_name}</div>
                                        
                                            {/* <Col sm={6} md={3} lg={2}><FormLabel>Doctor:</FormLabel></Col>
                                            <Col sm={6} md={6} lg={6}>{this.state.doctors && this.state.doctors.title + '. ' + this.state.doctors.first_name + ' ' + this.state.doctors.last_name}</Col> */}
                                        </Row>
                                        <Row className="m-0 center">
                                            <div className="col-4 col-md-2 col-lg-2 center"><FormLabel>Hospital:</FormLabel></div>
                                            <div className="col-8 col-md-6 col-lg-10">{this.state.hospitals.name}</div>
                                            {/* <Col sm={6} md={3} lg={2}><FormLabel>Hospital:</FormLabel></Col>
                                            <Col sm={6} md={6} lg={6}>{this.state.hospitals.name}</Col> */}
                                        </Row>
                                        <hr/>
                                        <Row className="center"> 
                                            <div className="col-6 col-md-6 col-lg-2" ><h6>Patient Details</h6></div>
                                            <div className="col-4 col-md-6 col-lg-2"><Link onClick={() => this.handleEditPatient(this.state.patients.id)}><i class="fas fa-edit"/></Link></div>
                                        </Row>
                                        <Row className="m-0 center">
                                            <div className="col-6 col-md-4 col-lg-2"><FormLabel>Full Name:</FormLabel></div>
                                            <div className="col-6 col-md-8 col-lg-10">{this.state.patients.title + '. ' + this.state.patients.first_name + ' ' + (this.state.patients.second_name ? this.state.patients.second_name  + ' ' : '') + this.state.patients.last_name}</div>
                                            {/* <Col lg={2}><FormLabel>Full Name:</FormLabel></Col>
                                            <Col sm={4}>{this.state.patients.title + '. ' + this.state.patients.first_name + ' ' + this.state.patients.second_name + ' ' + this.state.patients.last_name}</Col> */}
                                        </Row>
                                        <Row className="m-0 center">
                                            <div className="col-6 col-md-4 col-lg-2"><FormLabel>Date of Birth:</FormLabel></div>
                                            <div className="col-6 col-md-8 col-lg-10">{moment(this.state.patients.date_of_birth).format('MMMM D YYYY')}</div>
                                            {/* <Col lg={2}><FormLabel>Date of Birth:</FormLabel></Col>
                                            <Col sm={4}>{moment(this.state.patients.date_of_birth).format('MMMM D YYYY')}</Col> */}
                                        </Row>
                                        <Row className="m-0 center">
                                            <div className="col-6 col-md-4 col-lg-2"><FormLabel>Home Address:</FormLabel></div>
                                            <div className="col-6 col-md-8 col-lg-10">{this.state.patients.address_1 + ', ' + (this.state.patients.address_2 ? this.state.patients.address_2 + ', ' : '')  + this.state.patients.suburb + ', ' + this.state.patients.state + ', ' + this.state.patients.post_code}</div>
                                            {/* <Col lg={2}><FormLabel>Home Address:</FormLabel></Col>
                                            <Col sm={6}>{this.state.patients.address_1 + ', ' + this.state.patients.address_2 + ', ' + this.state.patients.suburb + ', ' + this.state.patients.state + ', ' + this.state.patients.post_code}</Col> */}
                                        </Row>
                                        <hr />
                                        <h6>Booking Details</h6>
                                        <Row className="m-0 center">
                                            <div className="col-6 col-md-6 col-lg-2"><FormLabel>Procedure:</FormLabel></div>
                                            <div className="col-6 col-md-6 col-lg-4">{this.state.procedure}</div>
                                            <div className="col-6 col-md-6 col-lg-1"><FormLabel>Slot:</FormLabel></div>
                                            <div className="col-6 col-md-6 col-lg-4">{this.state.slot}</div>
                                            
                                            {/* <Col lg={2}><FormLabel>Treatment/Procedure:</FormLabel></Col>
                                            <Col sm={4}>{this.state.procedure}</Col>
                                            <Col lg={1}><FormLabel>Slot:</FormLabel></Col>
                                            <Col sm={4}>{this.state.slot}</Col> */}
                                        </Row>
                                        <Row className="m-0 center">
                                            <div className="col-6 col-md-6 col-lg-2"><FormLabel>Booking Date:</FormLabel></div>
                                            <div className="col-6 col-md-6 col-lg-4">{moment(this.state.date).format("DD-MMMM-YYYY")}</div>
                                            <div className="col-6 col-md-6 col-lg-1" style={{display: this.state.probe ? 'block' : 'none'}}><FormLabel>Probe:</FormLabel></div>
                                            <div className="col-6 col-md-6 col-lg-4">{this.state.probe}</div> 
                                            
                                            {/* <Col lg={2}><FormLabel>Booking Date:</FormLabel></Col>
                                            <Col sm={4}>{moment(this.state.date).format("DD-MMMM-YYYY")}</Col>
                                            <Col lg={1} style={{display: this.state.probe ? 'block' : 'none'}}><FormLabel>Probe:</FormLabel></Col>
                                            <Col sm={4}>{this.state.probe}</Col>                                     */}
                                        </Row>
                                        <Row className="m-0 center">
                                            <div className="col-6 col-md-6 col-lg-2"><FormLabel>Notes:</FormLabel></div>
                                            <div className="col-6 col-md-6 col-lg-4">{this.state.notes}</div>
                                            <div className="col-6 col-md-6 col-lg-1"><FormLabel>Status:</FormLabel></div>
                                            <div className="col-6 col-md-6 col-lg-4">{this.state.status ? 'Active' : 'Cancelled'}</div>
                                            {/* <Col lg={2}><FormLabel>Notes:</FormLabel></Col>
                                            <Col sm={4}>{this.state.notes}</Col>
                                            <Col lg={1}><FormLabel>Status:</FormLabel></Col>
                                            <Col sm={4}>{this.state.status ? 'Active' : 'Cancelled'}</Col> */}
                                        </Row>
                                        <Row className="m-0 center">
                                            <div className="col-6 col-md-6 col-lg-2" style={{display: this.state.ur_number ? 'block' : 'none'}}><FormLabel>UR Number:</FormLabel></div>
                                            <div className="col-6 col-md-6 col-lg-4">{this.state.ur_number}</div>
                                            {/* <Col lg={2} style={{display: this.state.ur_number ? 'block' : 'none'}}><FormLabel>UR Number:</FormLabel></Col>
                                            <Col sm={4}>{this.state.ur_number}</Col> */}
                                        </Row>
                                            {this.state.status ? 
                                                ( <Row className="m-0 center">
                                                        <div className="col-6 col-md-6 col-lg-2"><FormLabel>Treated:</FormLabel></div>
                                                        <div className="col-6 col-md-6 col-lg-4">{this.state.treated ? 'Yes' : 'No'}</div>
                                                    </Row>
                                                )  : ''} 
                                        
                                        {/* <Row className="m-0 center">
                                            <Col lg={2}><FormLabel>Treated:</FormLabel></Col>
                                            <Col sm={4}>{this.state.treated ? 'Yes' : 'No'}</Col>
                                        </Row> */}
                                    </Container>
                                )
                            }

                            
                        </div>  
                    </div>
                </div>                
            </div>
        );
    }
}

export default PatientBookingView;