import React, { Component } from 'react';
import { FormGroup, Col, Row, Container } from 'reactstrap';
import { FormControl, FormLabel, Modal, Form } from 'react-bootstrap';
import { request } from '../../constants/constants';
import './DoctorForm.css';

class DoctorsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            first_name: '',
            last_name: '',
            mobile: '',
            status: '',
            office_telephone: '',
            office_telephone_2: '',
            office_email: '',
            office_facsmile: '',
            office_address: '',
            office_address_2: '',
            office_suburb: '',
            office_state: '',
            office_postcode: '',
            postal_address: '',
            postal_address_2: '',
            postal_suburb: '',
            postal_state: '',
            postal_post_code: '',
            showSuccessModal: false,
            showError: false,
            chkbox: false
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleTitle = e => {
        if(e.target.value === "-Select-"){
            return false;
        }else{
            this.setState({ title: e.target.value })
        }
    }

    handleOfficeState = e => {
        if(e.target.value === "-Select-"){
            return false;
        }else{
            this.setState({ office_state: e.target.value })
        }
    }

    handlePostalState = e => {
        if(e.target.value === "-Select-"){
            return false;
        }else{
            this.setState({ postal_state: e.target.value })
        }
    }

    handleClick = e => {
        console.log(e.target.checked);
        this.setState({
            chkbox: !this.state.chkbox
        })

        if(e.target.checked === true){
            this.setState({
                postal_address: this.state.office_address,
                postal_address_2: this.state.office_address_2,
                postal_suburb: this.state.office_suburb,
                postal_state: this.state.office_state,
                postal_post_code: this.state.office_postcode
            })
        } else {
            console.log(this.state.postal_address)
            this.setState({
                postal_address: '',
                postal_address_2: '',
                postal_suburb: '',
                postal_state: '',
                postal_post_code: ''
            })
        }
    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const data = {
            title: this.state.title,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            mobile: this.state.mobile,
            status: 1,
            office_telephone: this.state.office_telephone,
            office_telephone_2: this.state.office_telephone_2,
            office_email: this.state.office_email,
            office_facsmile: this.state.office_facsmile,
            office_address: this.state.office_address,
            office_address_2: this.state.office_address_2,
            office_suburb: this.state.office_suburb,
            office_state: this.state.office_state,
            office_postcode: this.state.office_postcode,
            postal_address: this.state.postal_address,
            postal_address_2: this.state.postal_address_2,
            postal_suburb: this.state.postal_suburb,
            postal_state: this.state.postal_state,
            postal_post_code: this.state.postal_post_code,
        }
        request('POST', '/doctors/', data, true).then(res => {
            this.setState({
                isLoading: false,
                showSuccessModal: true,
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                errorData: err.response.data.errors,
                showError: true,
            })
        })
    }

    /*Modal Functions */

    hideModal = e => {
        this.setState({ showSuccessModal: false })
        window.location.href = "/doctorstable";
    }
    
    hideModalError = () => {
        this.setState({ showError: false })
    }


    render() {
        return (
            <div class="container-fluid">
                <div className="card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Doctor Details Form</h6>
                  </div>
                  <div className="card-body">
                    {/* Modal for Successful Added */}
                    <Modal show={this.state.showSuccessModal} backdrop="static">
                        <Modal.Header onClick={this.hideModal} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="center" style={{textAlign: "center"}}>Doctor Successfully Added!</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary center" onClick={this.hideModal}>
                                OK
                            </button>
                        </Modal.Footer>
                    </Modal>

                    {/* Modal for ERROR  */}
                    <Modal show={this.state.showError} backdrop="static">
                        <Modal.Header onClick={this.hideModalError} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <p className="text-align" style={{textAlign: "center"}}>{this.state.errorMessage}</p>     
                        {
                            this.state.errorData &&  Object.keys(this.state.errorData).map(key => <p className="center" style={{textAlign: "center"}}>{this.state.errorData[key]}</p>)
                        }                                        
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={this.hideModalError}>
                                OK
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <div className="table-responsive">
                    <form onSubmit={this.handleFormSubmit}>
                    <Container>
                        <FormGroup>
                            <Row className="center">
                                <Col md={12} lg={2}><FormLabel>Title: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        as="select"
                                        type="text"
                                        name="title"
                                        className="center"
                                        value={this.state.title}
                                        onChange={this.handleTitle}
                                    >   
                                        <option>-Select-</option>
                                        <option>Dr</option>
                                        <option>Miss</option>
                                        <option>Mrs</option>
                                        <option>Ms</option>
                                        <option>Mr</option>
                                    </FormControl>
                                </Col>
                                <Col md={12} lg={2}><FormLabel className="mt-md-2 mt-lg-0">Mobile Number: </FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        type="number"
                                        name="mobile"
                                        className="center"
                                        value={this.state.mobile}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row className="center">
                                <Col md={12} lg={2}><FormLabel>First Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        type="text"
                                        name="first_name"
                                        className="center"
                                        value={this.state.first_name}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col md={12} lg={2}><FormLabel className="mt-md-2 mt-lg-0">Last Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        type="text"
                                        name="last_name"
                                        className="center"
                                        value={this.state.last_name}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <hr/>
                        <FormGroup>
                            <Row className="center">
                                <Col md={12} lg={2}><FormLabel>Office Telephone: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        type="number"
                                        name="office_telephone"
                                        className="center"
                                        value={this.state.office_telephone}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col md={12} lg={2}><FormLabel className="mt-md-2 mt-lg-0">Office Telephone 2: </FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        type="number"
                                        name="office_telephone_2"
                                        className="center"
                                        value={this.state.office_telephone_2}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row className="center">
                                <Col md={12} lg={2}><FormLabel>Office Email: </FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        type="email"
                                        name="office_email"
                                        className="center"
                                        value={this.state.office_email}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col md={12} lg={2}><FormLabel className="mt-md-2 mt-lg-0">Office Facsmile: </FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        type="text"
                                        name="office_facsmile"
                                        className="center"
                                        value={this.state.office_facsmile}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <hr/>
                        <FormGroup>
                            <Row className="center">
                                <Col md={12} lg={2}><FormLabel>Office Address: </FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        type="text"
                                        name="office_address"
                                        className="center"
                                        value={this.state.office_address}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col md={12} lg={2}><FormLabel className="mt-md-2 mt-lg-0">Office Address 2: </FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        type="text"
                                        name="office_address_2"
                                        className="center"
                                        value={this.state.office_address_2}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row className="center">
                                <Col md={12} lg={2}><FormLabel>Office Suburb: </FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        type="text"
                                        name="office_suburb"
                                        className="center"
                                        value={this.state.office_suburb}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col md={12} lg={2}><FormLabel className="mt-md-2 mt-lg-0">Office State: </FormLabel></Col>
                                <Col md={12} lg={2}>
                                    <FormControl
                                        as="select"
                                        type="text"
                                        name="office_state"
                                        className="center"
                                        value={this.state.office_state}
                                        onChange={this.handleOfficeState}
                                    >
                                        <option value="-Select-">-Select-</option>
                                        <option value="ACT">ACT</option>
                                        <option value="NSW">NSW</option>
                                        <option value="VIC">VIC</option>
                                        <option value="TAS">TAS</option>
                                        <option value="QLD">QLD</option>
                                        <option value="SA">SA</option>
                                        <option value="WA">WA</option>
                                        <option value="NT">NT</option>
                                    </FormControl>
                                </Col>                               
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row className="center">
                                <Col md={12} lg={2}><FormLabel>Office Postcode: </FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        type="text"
                                        name="office_postcode"
                                        className="center"
                                        value={this.state.office_postcode}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <input type="checkbox" ckecked={this.state.chkbox} onClick={this.handleClick} /> <span className="center" style={{ color: 'red'}}>Click this if you have the same Office and Postal Address</span>
                        <hr/>
                        <FormGroup>
                            <Row className="center">
                                <Col md={12} lg={2}><FormLabel className="mt-md-2 mt-lg-0">Postal Address: </FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        type="text"
                                        name="postal_address"
                                        className="center"
                                        value={this.state.postal_address}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col md={12} lg={2}><FormLabel>Postal Address 2: </FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        type="text"
                                        name="postal_address_2"
                                        className="center"
                                        value={this.state.postal_address_2}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row className="center">
                                <Col md={12} lg={2}><FormLabel>Postal Suburb: </FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        type="text"
                                        name="postal_suburb"
                                        className="center"
                                        value={this.state.postal_suburb}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col md={12} lg={2}><FormLabel className="mt-md-2 mt-lg-0">Postal State: </FormLabel></Col>
                                <Col md={12} lg={2}>
                                    <FormControl
                                        as="select"
                                        type="text"
                                        name="postal_state"
                                        className="center"
                                        value={this.state.postal_state}
                                        onChange={this.handlePostalState}
                                    >
                                        <option value="-Select-">-Select-</option>
                                        <option value="ACT">ACT</option>
                                        <option value="NSW">NSW</option>
                                        <option value="VIC">VIC</option>
                                        <option value="TAS">TAS</option>
                                        <option value="QLD">QLD</option>
                                        <option value="SA">SA</option>
                                        <option value="WA">WA</option>
                                        <option value="NT">NT</option>
                                    </FormControl>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row className="center">
                                <Col md={12} lg={2}><FormLabel>Postal Postcode: </FormLabel></Col>
                                <Col md={12} lg={4}>
                                    <FormControl
                                        type="text"
                                        name="postal_post_code"
                                        className="center"
                                        value={this.state.postal_post_code}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <Row className="center">
                            <Col lg={3} md={12} sm={12}><button style={{width: '100%'}} type="submit" className="btn btn-primary mt-4 center">Submit</button></Col>
                        </Row>
                    </Container>
                    </form>
                    </div>
                  </div>
                </div>
            </div>

            
        );
    }
}

export default DoctorsForm;