import React, { Component } from 'react';
import Pagination from '../../components/Pagination/pagination';
import { Link } from 'react-router-dom';
import { request } from '../../constants/constants';
import ReactTable from 'react-table';
import { Row, Col, Modal, FormLabel, FormControl } from 'react-bootstrap';
import { Button } from 'reactstrap';
import SearchField from "react-search-field";
import Loader from "react-loader-spinner";

class TreatmentProcedure extends Component {
    constructor(props){
        super(props);
        this.state = {
            procedures: [],
            showDeleteModal: false,
            currentId: '',
            searchProcedure: '',
            isLoading: true

        }
       
    }

    componentDidMount(){
        this.init();
    }

    init(){
        this.getProcedure().then(result => {
            const procedures = result.data.data.map(obj => ({
                id: obj.ID,
                type: obj.procedure_type,
                description: obj.description,
                time_required: obj.time_required,
                status: obj.status
            }));
            this.setState({ procedures , isLoading: false});
        }).catch(err => {
            console.error(err.response);
        })
    }

    getProcedure(){
        return request('GET', '/procedures', {}, true);
    }

    handleDelete = (e) => {
        const data = { id: this.state.currentId }

        request('DELETE', `/procedures/${this.state.currentId}`, data, true).then(res => {
            this.setState({
              isLoading: false
            })
            window.location.href = "/treatmentprocedure";
        }).catch(err => {
            console.error(err.response);
        })
    }
    
    /* Modal functions */
    handleDeleteModal = (booking_id) => {
       this.setState({ showDeleteModal: true, currentId: booking_id });
    }

    hideDeleteModal = e => {
        this.setState({ showDeleteModal: false });
    }

    handleSearchProcedure = (e) => {
        const searchProcedure = e;

        if(!searchProcedure){
            this.init();
        } else {
            request('GET', `/procedures/search/searchProcedures?procedure=${searchProcedure}`, {}, true).then(result => {
                const procedures = result.data.data.map(obj => ({
                    id: obj.ID,
                    type: obj.procedure_type,
                    description: obj.description,
                    time_required: obj.time_required,
                    status: obj.status
                }));
                this.setState({procedures});
            }).catch(err => {
                console.error(err.response);
            })
        }
    }

    getResizeValues = (newResized, event) => {
        if(newResized){
            console.log(localStorage.getItem('treatmentTableWidths'), newResized)
            if(localStorage.getItem('treatmentTableWidths')) {
                const widths = JSON.parse(localStorage.getItem('treatmentTableWidths'));
                newResized.forEach(element => {
                    const i = widths.findIndex(width => width.id == element.id);
                    if(i > -1) {
                        const width = widths[i];
                        width.value = element.value
                    } else {
                        widths.push(element);
                    }
                });
                localStorage.setItem("treatmentTableWidths", JSON.stringify(widths));

            } else {
                localStorage.setItem("treatmentTableWidths", JSON.stringify(newResized));
            }
        }
    }

    getColumnWidth(columnId, defaultSize) {
        if(localStorage.getItem('treatmentTableWidths')) {
            const widths =  JSON.parse(localStorage.getItem('treatmentTableWidths'));
            const i = widths.findIndex((element) => element.id == columnId);
            if(i > -1) {
                const columnWith = widths[i].value;
                return columnWith;
            }
            return defaultSize;
        }
        return defaultSize;
    }
    
    render() {
        const columns = [{
            Header: 'Action',
            Cell: row => 
                <React.Fragment>
                    {/* <Link to={`/procedureeditform/${row.original.id}`} ><Button className="btn btn-dark mr-1 center">Edit</Button></Link>
                    <Button className="btn btn-danger center" value={`${row.original.id}`} onClick={this.handleDeleteModal}>Delete</Button> */}
                    <i className="far fa-trash-alt" style={{color: 'red'}} onClick={(e) => this.handleDeleteModal(row.original.id)}/>
                    <Link to={`/procedureeditform/${row.original.id}`} ><i className="far fa-edit ml-2" /></Link>                    
                </React.Fragment>,
            width: this.getColumnWidth('id', 160),
            id: "id",
            accessor: 'id'
          },{
            Header: 'Type of Procedure',
            width: this.getColumnWidth('type', 200),
            id: "type",
            accessor: 'type',
            style: { 'whiteSpace': 'unset' }
          },{
            Header: 'Description',
            width: this.getColumnWidth('description', 350),
            id: "description",
            accessor: 'description',
            style: { 'whiteSpace': 'unset' }
          },{
            Header: 'Time Required',
            width: this.getColumnWidth('time_required', 150),
            id: "time_required",
            accessor: 'time_required'
          },{
            Header: 'Status',
            Cell: row => 
            <React.Fragment>
                {row.original.status == 1 ? 'Active' : 'Inactive'}
            </React.Fragment>,
            width: this.getColumnWidth('status', 250),
            id: "status",
            accessor: row => row.status
          }]

        return (
            <div class="container-fluid procedures-table">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row className="center">
                            <Col sm={12} md={6} lg={4}><h6 className="mb-2 font-weight-bold text-primary">List of General Procedures</h6></Col>
                            <Col sm={12} md={6} lg={4}>
                                <SearchField
                                    placeholder="Search ..."
                                    // onChange={this.handleSearchProcedure}
                                    onSearchClick={this.handleSearchProcedure}
                                    onEnter={this.handleSearchProcedure}
                                    classNames="mb-2 center"
                                    name="searchProcedure"
                                    value={this.state.searchProcedure}
                                />
                            </Col>
                            <Col sm={12} md={12} lg={2}>
                                <Link to="/procedureform"><button style={{width: '100%'}} type="submit" className="btn btn-primary center"><i class="far fa-calendar-check" /> Add Procedure</button></Link>
                            </Col>
                        </Row>
                    </div>
                    <div className="card-body">

                        {/* Modal for Successfully Appointed */}
                        <Modal show={this.state.showDeleteModal} backdrop="static">
                            <Modal.Header onClick={this.hideDeleteModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>Do you really want to delete this Procedure? </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary center" onClick={this.hideDeleteModal}>
                                    Cancel
                                </button>
                                <button className="btn btn-danger center" onClick={this.handleDelete}>
                                    Delete
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <div className="table-responsive center">
                            {
                                this.state.isLoading ? (
                                    <Loader
                                        type="TailSpin"
                                        color="#2850C2"
                                        height={80}
                                        width={80}
                                        timeout={10000} 
                                    />
                                ) : (
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={this.state.procedures}
                                        columns={columns}
                                        defaultExpanded={true}
                                        minRows={0}
                                        onResizedChange={this.getResizeValues}
                                    />
                                )
                            }
                           
                        </div>
                    </div>
                </div>                
            </div>
        );
    }
}

export default TreatmentProcedure;