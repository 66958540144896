import React, { Component } from 'react';
import { API_URL, request } from '../../constants/constants';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class PoAndInvoices extends Component {
    constructor(props){
        super(props);
        this.state = {
            id: this.props.match.params.id,
            invNo: this.props.match.params.invNo,
            ponumber: '',
        }
    }

    componentDidMount(){
        request('GET', `/invoices/getInvoiceForPONumber/${this.state.id}/${this.state.invNo}`, {}, true).then(result => {
            console.log(result);
            this.setState({
                po_number: result.data.data.po_number,
                invoice_path: result.data.data.invoice_path                    
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                errorData: err.response.data.errors,
                showError: true,
            })
        });
    }

    handleChange = e => {
        this.setState({
            ponumber: e.target.value
        })

    }

    handleDownload = e => {
        request('GET', `/invoices/downloadSingleInvoice/${this.state.invoice_path}`, {}, true).then(result => {
            console.log(result);
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                errorData: err.response.data.errors,
                showError: true,
            })
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        const data = {
            po_number: this.state.ponumber
        }
        request('PUT', `/invoices/updatePONumber/${this.state.id}/${this.state.invNo}`, data, true).then(result => { 
            console.log(result)
            this.setState({
                isLoading: true,
                showSuccessModal: true
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                errorData: err.response.data.errors,
                showError: true
            })
        })
    }

    /*Modal Functions */
    
    hideModal = e => {
        this.setState({ showSuccessModal: false })
        window.location.href = `/poandinvoices/${this.state.id}/${this.state.invNo}`;
    }

    hideModalError = () => {
        this.setState({ showError: false })
    }

    render() {
        return (
            <div className="LoginForm">
                {/* Modal for Successful Added */}
                <Modal show={this.state.showSuccessModal}>
                    <Modal.Header onClick={this.hideModal} closeButton>
                        <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{textAlign: "center"}}>Purchase Order Number Successfully Sent!</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary center" onClick={this.hideModal}>
                            OK
                        </button>
                    </Modal.Footer>
                </Modal>

                {/* Modal for ERROR  */}
                <Modal show={this.state.showError}>
                    <Modal.Header onClick={this.hideModalError} closeButton>
                        <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p className="text-align" style={{textAlign: "center"}}></p>     
                    {
                        this.state.errorData &&  Object.keys(this.state.errorData).map(key => <p style={{textAlign: "center"}}>{this.state.errorData[key]}</p>)
                    }                                        
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={this.hideModalError}>
                            OK
                        </button>
                    </Modal.Footer>
                </Modal>
                <form className="p-5" onSubmit={this.handleSubmit}> 
                    <div className="login-container">
                    <p style={{color: '#ff0000'}}>Click here to download INVOICE #{this.state.invNo}: <a href={API_URL + '/invoices/downloadSingleInvoice/' +`${this.state.invoice_path}`} target="_blank"><i class="fas fa-download" /></a></p>
                        <div style={{display: this.state.po_number ? 'none' : 'block'}}>
                            <p>Please enter your PO Number here:</p>
                            <div className="form-group">
                                <input name="ponumber" value={this.state.ponumber} onChange={this.handleChange} type="text" className="form-control" id="ponumber" placeholder="PO Number" />
                            </div>
                            <button type="submit" className="btn btn-primary btn-block">Send</button>
                        </div>
                        {this.state.po_number ? 
                            (<div>
                                <p style={{color: 'red'}}>Your Purchase Order Number has been sent! </p>
                            </div> ) : "" }
                    </div>
                </form>
            </div>
        );
    }
}

export default PoAndInvoices;