import React, { Component } from 'react';
import logo from '../../assets/images/alexus-logo.png';
import { Row, Col, Container, Table, Modal } from 'react-bootstrap';
import moment from 'moment';
import { request } from '../../constants/constants';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


class Invoice extends Component {
    constructor(props){
        super(props);
        this.state = {
            invoice_item_id: '',
            showSuccessModal: false
        }
    }

    removeItem = (invoice_item_id) => {
        this.setState({
            showSuccessModal: true,
            invoice_item_id: invoice_item_id
        })
    }

    hideSuccessModal = () => {
        this.setState({ 
            showSuccessModal: false
        })
    }

    handleRemoveFunction = () => {
        
        request('GET', `/invoices/removeInvoiceItemInGroupInvoices/${this.state.invoice_item_id}`, {}, true).then(result => {
            console.log('result', result)
            window.location.href = "/invoices";
        }).catch(err => { 
            console.log(err.response)
            this.setState({
                isLoading: false,
                error: err.response.data.message,
            })
        })

    }

    render() {

        let prices = this.props.price;
        let procedures = this.props.procedure;
        let invoiceItemCounter = 0;
        
        let gst_con = [];
        let total_con = [];
        let price_con = [];
        let sub_total = 0;
        let total_gst = 0;
        let total_due = 0;
    
        // console.log('prices', prices, 'procedures', procedures)

        prices.forEach(element => {
            price_con.push(parseFloat(element).toFixed(2))
            gst_con.push(parseFloat(element * 0.1).toFixed(2))
            total_con.push(parseFloat(element + ( element * 0.1 )).toFixed(2))

            total_gst = total_gst + (element * 0.1)
            sub_total = sub_total + element
            total_due = total_due + (element + ( element * 0.1 ))
            // total_due = total_gst + sub_total
        });
        
        return (
                <div className="invoice">

                    {/* Modal for Invoice Item Successfully Sent */}
                    <Modal show={this.state.showSuccessModal}>
                        <Modal.Header onClick={this.hideSuccessModal} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="center"  style={{textAlign: "center"}}>Are you sure to want to remove this item?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary center" onClick={this.hideSuccessModal}>
                                Cancel
                            </button>
                            <button className="btn btn-danger center" onClick={this.handleRemoveFunction}>
                                Remove
                            </button>
                        </Modal.Footer>
                    </Modal>


                    <Row className="center" style={{textAlign: "center"}}>
                        <Col>
                            <div className="brand">
                                <img src={logo} className="logo" alt="Logo" />  <br/>
                                <span style={{fontSize: '12px', fontFamily: 'Arial'}}>
                                    ABN: 41-541-228-897<br/>
                                    ACN: 541-228-897
                                </span>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <strong>ALEXUS SERVICES</strong><span style={{fontSize: '10px'}}>&nbsp;PTY LTD</span><br />
                                <span style={{fontSize: '12px', fontFamily: 'Arial'}}>PO Box 2035<br /> Sunbury, VIC, Australia, 3429<br />
                                PH #: Alister: 0400-137-952<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Allan: 0438-341-130<br />
                                EMAIL: info@alexus.com.au</span>
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <div className="addresses"> 
                        <div className="from">
                            <strong>{this.props.hospital_name}</strong><br />
                            {this.props.reference_number}
                            { this.props.reference_number && (<React.Fragment><br/></React.Fragment>)}                
                            {this.props.office_address + " " + (this.props.office_address_2 ? this.props.office_address_2 : '')}<br />
                            {this.props.office_suburb}, {this.props.office_state}, {this.props.office_postcode} <br />
                        </div>
                        <div>
                            <div className="valueTable">
                                <div className="row">
                                    <div className="label">Invoice #: </div>
                                    <div className="value">{ this.props.invoice_number }</div>
                                </div>
                                <div className="row">
                                    <div className="label">Date: </div>
                                    {/* new Date().toISOString().split('T')[0] */}
                                    <div>{ moment(this.props.procedureDate).format("DD/MM/YYYY") }</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="h2-invoice">Invoice</h2>
                    <span>Treated on: { moment(this.props.procedureDate).format("DD/MM/YYYY") }</span><br/>
                    <span>{this.props.doctor_name}</span>
                    <div className="table-responsive">
                        <Table striped bordered hover className="margin-top-15">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Item</th>
                                    <th>Description</th>
                                    <th>Price</th>
                                    <th>GST</th>
                                    <th>Total</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.invoice_information && this.props.invoice_information.map((obj, key) => {
                                        console.log(obj)
                                        if(obj.booking){
                                            let middleName = obj.booking.patient.second_name ? obj.booking.patient.second_name : '';
                                            invoiceItemCounter++;
                                            return <React.Fragment key={key}>
                                                <tr>
                                                    <td>{invoiceItemCounter}</td>
                                                    <td>{obj.booking.procedure}</td>
                                                    <td>
                                                        {obj.booking.patient && obj.booking.patient.title + '. ' + obj.booking.patient.first_name +  ' ' + middleName + ' ' + obj.booking.patient.last_name}<br/>
                                                        UR Number: {obj.booking.ur_number}
                                                    </td>
                                                    <td>{price_con[key]}</td>
                                                    <td>{gst_con[key]}</td>
                                                    <td>{total_con[key]}</td>
                                                    <td><i className="fa fa-minus-circle" title="Remove Item" style={{color: 'red'}} onClick={() => this.removeItem(obj.id)}/></td>
                                                </tr>
                                            </React.Fragment>
                                        }  
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                    <Container>
                        <Row >
                            <Col></Col>
                            <Col>
                                <form>
                                    <div className="valueTable">
                                        <div className="row" style={{ display: "flex"}}>
                                            <div className="label">Total GST:</div>
                                            <div className="value currency" style={{marginLeft: "auto"}}>{parseFloat(total_gst).toFixed(2)}</div>
                                        </div>
                                        <div className="row">
                                            <div className="label" style={{ display: "flex"}}>Subtotal: </div>
                                            <div className="value currency" style={{marginLeft: "auto"}}>{parseFloat(sub_total).toFixed(2)}</div>
                                        </div>
                                        <div className="row">
                                            <div className="label" style={{ display: "flex"}}><h4>Total Due: </h4></div>
                                            <div className="value currency" style={{marginLeft: "auto"}}><h4>{parseFloat(total_due).toFixed(2)}</h4></div>
                                        </div>
                                    </div>
                                </form>
                            </Col>                    
                        </Row>
                    </Container>
                    <div className="footer">
                        <div className="closing">
                            <div>Thank you for your business!</div>
                            <center><a href="www.alexus.com.au">www.alexus.com.au</a></center>
                        </div>
                    </div>
                </div>
        );
    }
}

export default Invoice;