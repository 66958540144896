import React, { Component } from 'react';
import { Row, Col, Modal, FormControl, FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { request } from '../../constants/constants';
import { Button } from 'reactstrap';
import Pagination from '../../components/Pagination/pagination';
import SearchField from "react-search-field";
import Loader from "react-loader-spinner";

class ProbesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            showDeleteModal: false,
            showUpdateModal: false,
            showAddModal: false,
            searchProbe: '',
            isLoading: true,
            showSuccessModal: false
        };
      }

    componentDidMount(){
        this.init();
    }
    
    init(){
       this.getProbe().then(result => {
            const probes = result.data.data.map(obj => ({
                id: obj.ID,
                reference: obj.reference,
                status: obj.active
            }));

            this.setState({probes, isLoading: false});

            }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });  
        
    }

    getProbe(){
        return request('GET', '/probes/', {}, true);
    }

    deleteHandler = e => {
        e.preventDefault();
        const data = {
          id: e.target.value
        } 

        request('DELETE', `/probes/${e.target.value}`, data, true).then(res => {
          this.setState({
            isLoading: false
          })
          window.location.href = "/probestable";
        }).catch(err => {
          console.error(err.response);
          this.setState({
              isLoading: false,
              error: err.response.data
          })
        })
    }    

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleStatus = e => {
        if(e.target.value === "-Select-"){
            return false;
        }
        this.setState({
            status: e.target.value
        })
    }

    handleUpdate = (probe_id) => {
        this.setState({ 
            currentId: probe_id, 
            showUpdateModal: true
        })

        request('GET', `/probes/${probe_id}`, {}, true).then(res => {
            this.setState({
                id: res.data.data.id,
                reference: res.data.data.reference,
                status: res.data.data.active
            })
        })

    }


    updateHandler = e => {
        console.log(e.target.value)
        const data = {
            reference: this.state.reference,
            active: this.state.status
        }
        request('PUT', `/probes/${e.target.value}`, data, true).then(res => {
            console.log(res)
            this.setState({
              isLoading: false
            })
            window.location.href = "/probestable";
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data
            })
        })
    }

    addHandler = e => {
        const data = {
            reference: this.state.newReference,
            active: 1
        }
        console.log(data)
        request('POST', '/probes/', data, true).then(res => {
            console.log(res)
            this.setState({
              isLoading: false,
              showAddModal: false,
              showSuccessModal: true
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                errorData: err.response.data.errors,
            })
        })
    }


    /* Modal Functions */

    handleDelete = (probe_id) => {
        this.setState({ currentId: probe_id, showDeleteModal: true})
    }

    handleAdd = e => {
        this.setState({ showAddModal: true})
    }

    hideDeleteModal = e => {
        this.setState({ showDeleteModal: false })
    }
    

    hideUpdateModal  = e => {
        this.setState({ showUpdateModal: false })
    }

    hideAddModal = e => {
        this.setState({ showAddModal: false })
    }

    handleSearchProbe = (e) => {
        const searchProbe = e; 

        if(!searchProbe){ 
            this.init();
        } else {
            request('GET', `/probes/search/searchProbes?probe=${searchProbe}`, {}, true).then(result => {  
                const probes = result.data.data.map(obj => ({
                    id: obj.ID,
                    reference: obj.reference,
                    status: obj.active
                }));
                this.setState({probes});
            }).catch(err => { 
                console.error(err.response);
                this.setState({
                    isLoading: false,
                    error: err.response
                })
            });
        }
    }

    getResizeValues = (newResized, event) => {
        if(newResized){
            console.log(localStorage.getItem('probesTableWidths'), newResized)
            if(localStorage.getItem('probesTableWidths')) {
                const widths = JSON.parse(localStorage.getItem('probesTableWidths'));
                newResized.forEach(element => {
                    const i = widths.findIndex(width => width.id == element.id);
                    if(i > -1) {
                        const width = widths[i];
                        width.value = element.value
                    } else {
                        widths.push(element);
                    }
                });
                localStorage.setItem("probesTableWidths", JSON.stringify(widths));

            } else {
                localStorage.setItem("probesTableWidths", JSON.stringify(newResized));
            }
        }
    }

    getColumnWidth(columnId, defaultSize) {
        if(localStorage.getItem('probesTableWidths')) {
            const widths =  JSON.parse(localStorage.getItem('probesTableWidths'));
            const i = widths.findIndex((element) => element.id == columnId);
            if(i > -1) {
                const columnWith = widths[i].value;
                return columnWith;
            }
            return defaultSize;
        }
        return defaultSize;
    }

    hideSuccessModal = () => {
        this.setState({
            showSuccessModal: false,
        })
        window.location.href = "/probestable"
    }


    render() {
        const columns = [{
            Header: 'Action',
              Cell: row => 
              <React.Fragment>
                {/* <button className="btn btn-dark mr-1 center" value={`${row.original.id}`} onClick={this.handleUpdate}>Update</button>
                <button className="btn btn-danger center" value={`${row.original.id}`} onClick={this.handleDelete}>Delete</button> */}
                <i className="far fa-trash-alt" style={{color: 'red'}} onClick={() => this.handleDelete(row.original.id)}/>
                <i className="far fa-edit ml-2" onClick={() => this.handleUpdate(row.original.id)}/>
              </React.Fragment>,
              width: this.getColumnWidth('action_id', 160),
              id: "action_id",
              accessor: "id"
          },{
            Header: 'Reference',
            width: this.getColumnWidth('reference', 600),
            id: "reference",
            accessor: 'reference'
          },{
            Header: 'Status',
            style: { 'whiteSpace': 'unset' },
            Cell: row => 
            <React.Fragment>
              {row.original.status === 1 ? 'Active' : 'Inactive'}
            </React.Fragment>,
            width: this.getColumnWidth('status', 180),
            id: "status",
            accessor: row => row.status
          }];

        return (
            <div class="container-fluid probes-table">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row className="center">
                            <Col sm={3} md={6} lg={4}><h6 className="mb-2 font-weight-bold text-primary">List of Probes </h6></Col>       
                            <Col sm={12} md={6} lg={4}>
                                <SearchField
                                    placeholder="Search ..."
                                    // onChange={this.handleSearchProbe}
                                    onSearchClick={this.handleSearchProbe}
                                    onEnter={this.handleSearchProbe}
                                    classNames="mb-2 center"
                                    name="searchProbe"
                                    value={this.state.searchProbe}
                                />
                            </Col>               
                            <Col sm={12} md={12} lg={2}>
                                <button style={{width: '100%'}} type="submit" className="btn btn-primary center" onClick={this.handleAdd}> Add Probe</button>
                            </Col>
                        </Row>
                    </div>
                    <div className="card-body">
                        {/* Modal for Success Adding Probe  */}
                        <Modal show={this.state.showSuccessModal} backdrop="static">
                            <Modal.Header onClick={this.hideSuccessModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>Probe Successfully Added! </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary center" onClick={this.hideSuccessModal}>
                                    Ok
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Delete Probe  */}
                        <Modal show={this.state.showDeleteModal} backdrop="static">
                            <Modal.Header onClick={this.hideDeleteModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>Are you sure you want to delete this Probe?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="center" onClick={this.hideDeleteModal}>
                                    Cancel
                                </Button>
                                <Button 
                                    className="btn btn-danger center" 
                                    value={this.state.currentId} 
                                    onClick={this.deleteHandler}
                                >Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Update Probe  */}
                        <Modal show={this.state.showUpdateModal} backdrop="static">
                            <Modal.Header onClick={this.hideUpdateModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Probe</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className='center m-2'>
                                    <Col sm={2}><FormLabel>Reference: </FormLabel></Col>
                                    <Col sm={7}>
                                        <FormControl
                                            type="text"
                                            name="reference"
                                            className="center"
                                            value={this.state.reference}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className='center m-2'>
                                    <Col sm={2}><FormLabel>Status: </FormLabel></Col>
                                    <Col sm={7}>
                                        <FormControl
                                            as = "select"
                                            type="text"
                                            name="status"
                                            className="center"
                                            value={this.state.status}
                                            onChange={this.handleStatus}
                                        >
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="center" onClick={this.hideUpdateModal}>
                                    Cancel
                                </Button>
                                <Button 
                                    className="btn btn-danger center" 
                                    value={this.state.id} 
                                    onClick={this.updateHandler}
                                >Update
                                </Button>
                            </Modal.Footer>
                        </Modal>


                        {/* Modal for Add Probe  */}
                        <Modal show={this.state.showAddModal} backdrop="static">
                            <Modal.Header onClick={this.hideAddModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Add Probe</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className='center m-2'>
                                    <Col sm={2}><FormLabel>Reference: </FormLabel></Col>
                                    <Col sm={10}>
                                        <FormControl
                                            type="text"
                                            name="newReference"
                                            className="center"
                                            value={this.state.newReference}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                                {  this.state.errorData &&  Object.keys(this.state.errorData).map(key => <p className="center" style={{textAlign: "center"}}>{this.state.errorData[key]}</p>) }
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="center" onClick={this.hideAddModal}>
                                    Cancel
                                </Button>
                                <Button 
                                    className="btn btn-danger center" 
                                    value={this.state.id} 
                                    onClick={this.addHandler}
                                >Add
                                </Button>
                            </Modal.Footer>
                        </Modal>


                        <div className="table-responsive center">
                            {
                                this.state.isLoading ? (
                                    <Loader
                                        type="TailSpin"
                                        color="#2850C2"
                                        height={80}
                                        width={80}
                                        timeout={10000} 
                                    />
                                ) : (   
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={this.state.probes}
                                        columns={columns}
                                        defaultExpanded={true}
                                        minRows={0}
                                        onResizedChange={this.getResizeValues}    
                                    />
                                )
                            }
                            
                        </div>
                    </div>
                </div>                
            </div>
        );
    }
}

export default ProbesTable;