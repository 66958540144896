import React, { Component, useReducer } from 'react';
import { Link } from "react-router-dom";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, FormGroup, Row, Col } from "reactstrap";
import { Modal, FormLabel, FormControl} from 'react-bootstrap';
import { request } from '../../constants/constants';
import NavLink from 'reactstrap/lib/NavLink';
import UserContext from '../../contexts/User/Index';

class GeneralSidebar extends Component {
    static contextType = UserContext;
    
    constructor(props){
        super(props);
        this.state = {
            //For Modal Functions
            show: false,
            showAddPatientModal: false,
            showError: false,
            showSuccessModal: false,

            //for add patient function
            title: '',
            first_name: '',
            last_name: '',
            second_name: '',
            date_of_birth: '',
            address_1: '',
            address_2: '',
            suburb: '',
            state: '', 
            post_code: '',
            procedure_id: '',
            procedures: [],
            doctors: [],

            newPatientId: '',
            newPatientName: '',
            newPatientProcedureId: '',
            newPatientProcedureName: '',
            doctor_id: '',
            showErrorForExistingPatient: false
        }
    }

    componentDidMount(){
        
        request('GET', '/procedures/allProceduresOnlyActive', {}, true).then(result => {
            // console.log(result)
            const procedures = result.data.data.map(obj => ({
                id: obj.ID,
                name: obj.procedure_type,
            }));
            this.setState({ procedures }); 
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response
            })
        })
        
        request('GET', '/procedures/allProceduresOnlyActive', {}, true).then(result => {
            // console.log(result)
            const procedures = result.data.data.map(obj => ({
                id: obj.ID,
                name: obj.procedure_type,
            }));
            this.setState({ procedures }); 
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response
            })
        })

        request('GET', '/doctors/admin/allDoctorsByBookingCycle', {}, true).then(result => {
            // console.log("doctors", result)
            const doctors = result.data.data.map(obj => ({
                id: obj.doctor.id,
                name: obj.doctor.title + '. ' + obj.doctor.first_name + ' ' + obj.doctor.last_name
            }));
            doctors.sort((a, b) => a.name > b.name ? 1 : -1);
            this.setState({ doctors })
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleAddPatient = (e) => {
        const data = {
            title: this.state.title,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            second_name: this.state.second_name,
            date_of_birth: this.state.date_of_birth,
            address_1: this.state.address_1,
            address_2: this.state.address_2,
            suburb: this.state.suburb,
            state: this.state.state,
            post_code: this.state.post_code,
            procedure_id: this.state.procedure_id,
            doctor_id: this.state.doctor_id
        }

        request('POST', '/patients/', data, true).then(res => {
            this.setState({
                isLoading: false, 
                showSuccessModal: true,
                successMessage: "Patient Successfully Added!",
                showAddPatientModal: false,
                newPatientId: res.data.data.ID,
                newPatientName: res.data.data.title + '. ' + res.data.data.first_name + ' ' + res.data.data.second_name + ' ' + res.data.data.last_name,
                newPatientProcedureId: res.data.data.procedure,
                newPatientProcedureName: res.data.data.procedure_name,
                newDoctorId: this.state.doctor_id
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                patientErrorData: err.response.data.message,
                errorData: err.response.data.errors,
                errorCode: err.response.status,
                showError: true
            }, () => {
                this.setState({
                    showError: this.state.errorCode == 409? false : true
                }, () => {
                    this.setState({
                        showErrorForExistingPatient: this.state.showError ? false : true
                    })
                })
            })
        })
    }

    handleContinueCreatingPatient = (e) => {

        const data = {
            title: this.state.title,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            second_name: this.state.second_name,
            date_of_birth: this.state.date_of_birth,
            address_1: this.state.address_1,
            address_2: this.state.address_2,
            suburb: this.state.suburb,
            state: this.state.state,
            post_code: this.state.post_code,
            procedure_id: this.state.procedure_id,
            doctor_id: this.state.doctor_id
        }
        request('POST', '/patients/continue-creating-duplicate-patient', data, true).then(res => {
            this.setState({
                showErrorForExistingPatient: false,
                isLoading: false, 
                showSuccessModal: true,
                successMessage: "Patient Successfully Added!",
                showAddPatientModal: false,
                newPatientId: res.data.data.ID,
                newPatientName: res.data.data.title + '. ' + res.data.data.first_name + ' ' + res.data.data.second_name + ' ' + res.data.data.last_name,
                newPatientProcedureId: res.data.data.procedure,
                newPatientProcedureName: res.data.data.procedure_name,
                newDoctorId: this.state.doctor_id
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                errorData: err.response.data.errors,
                showError: true,
            })
        })

    }


    /* Modal Funtions */

    hideErrorForExistingPatient = () => {
        this.setState({
            showErrorForExistingPatient: false,
        })
    }

    handleShowModal = e => {
        this.setState({
            show: true,
        })
    }

    hideModal = () => {
        this.setState({
            show: false
        })
    }

    hideAddPatientModal = () => {
        this.setState({ showAddPatientModal: false })
    }

    handleNewPatient = () => {
        this.setState({ showAddPatientModal: true, show: false })

    }

    hideModalError = () => {
        this.setState({ showError: false })
    }

    hideModalSuccess = e => {
        this.setState({ showSuccessModal: false,  })
        window.location.href = "/doctorsbooking";
    }

    handleOldPatient = e => {
        window.location.href = "/doctorsbooking";
    }    

    render() {
        const userPermission = this.context.user.user.permission;
        return (
            <React.Fragment>        
                {/* Calendar Menu */}
                <li className="nav-item">
                    <Link className="nav-link " to="/bookingscalendar">
                        <i className="fas fa-calendar-alt" />
                        <span>Summary</span>
                    </Link>
                    <Link className="nav-link " to="/main-calendar">
                        <i className="fas fa-calendar-alt" />
                        <span>Calendar</span>
                    </Link>
                 </li>

                {
                    this.context.user && (userPermission !== 'staff' ? (
                        <React.Fragment>
                            {/* /* All about Bookings Dropdown Menu */ }
                            <li className="nav-item">
                                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseBookings" aria-expanded="true" aria-controls="collapsePages">
                                    <i className="far fa-calendar-check" />
                                    <span>Bookings</span>
                                </a>
                                <div id="collapseBookings" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                                    <div className="bg-white py-2 collapse-inner rounded">
                                    <span className="collapse-item" onClick={this.handleShowModal}>New Booking</span>
                                    <Link to="/generalBookings"  className="collapse-item" > Bookings List</Link>
                                    <Link to="/bookingcyclepage" className="collapse-item" >Booking Cycle List</Link>
                                    <Link to="/bookingcycleofflist" className="collapse-item">Booking Cycle Off List</Link>
                                    {/* <Link to="/cancelledbookingstable" className="collapse-item">Cancelled Bookings</Link> */}
                                    </div>
                                </div>
                            </li>

                            {/* /* All about Tables Dropdown Menu */ }
                            <li className="nav-item">
                                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTables" aria-expanded="true" aria-controls="collapsePages">
                                    <i className="far fa-calendar-check" />
                                    <span>Tables</span>
                                </a>
                                <div id="collapseTables" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                                    <div className="bg-white py-2 collapse-inner rounded">
                                    <Link to="/doctorstable"  className="collapse-item" > Doctors</Link>
                                    <Link to="/hospitalstable"  className="collapse-item" > Hospitals</Link>
                                    <Link to="/userstable"  className="collapse-item" > Users</Link>
                                    <Link to="/probestable"  className="collapse-item" > Probes</Link>
                                    <Link to="/invoices"  className="collapse-item" > Invoices</Link>
                                    <Link to="/treatmentprocedure"  className="collapse-item" > Procedures</Link>
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseReports" aria-expanded="true" aria-controls="collapsePages">
                                    <i className="far fa-calendar-check" />
                                    <span>Reports</span>
                                </a>
                                <div id="collapseReports" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                                    <div className="bg-white py-2 collapse-inner rounded">
                                        <Link to="/patient-bookings-report"  className="collapse-item" > Total Bookings</Link>
                                        <Link to="/bookings-by-category"  className="collapse-item" > Bookings by Category </Link>
                                        <Link to="/invoices-report"  className="collapse-item" > Invoices </Link>
                                        <Link to="/reconciliation-report"  className="collapse-item" > Management Invoices </Link>
                                    </div>
                                </div>
                            </li>
                        </React.Fragment>
                    ) : "")
                }

                {/* Modal for ERROR  */}
                <Modal show={this.state.showError}>
                    <Modal.Header onClick={this.hideModalError} closeButton>
                        <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p className="text-align" style={{textAlign: "center"}}>{this.state.errorMessage}</p>     
                    {
                        this.state.errorData &&  Object.keys(this.state.errorData).map((key, i) => <p key={i} style={{textAlign: "center"}}>{this.state.errorData[key]}</p>)
                    }                                        
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={this.hideModalError}>
                            OK
                        </button>
                    </Modal.Footer>
                </Modal>

                {/* Modal for ERROR for Existing patient  */}
                <Modal show={this.state.showErrorForExistingPatient}>
                    <Modal.Header onClick={this.hideErrorForExistingPatient} closeButton>
                        <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {
                        this.state.patientErrorData && <p className="center" style={{textAlign: "center"}}>{this.state.patientErrorData}. Do you still want to continue? </p>
                    }                                        
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary center" onClick={this.handleContinueCreatingPatient}>
                            Yes
                        </button>
                        <button className="btn btn-danger center" onClick={this.hideErrorForExistingPatient}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>

                {/* Modal for Patient Successfully Added  */}
                <Modal show={this.state.showSuccessModal}>
                    <Modal.Header onClick={this.hideModalSuccess} closeButton>
                    <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation!</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="center" style={{textAlign: "center"}}>{this.state.successMessage}</p>     
                    </Modal.Body>
                    <Modal.Footer>
                        <Link
                            to={{
                                pathname: '/doctorsbooking',
                                state: {
                                        newPatientId: this.state.newPatientId, 
                                        newPatientName: this.state.newPatientName,
                                        newPatientProcedureId: this.state.newPatientProcedureId,
                                        newPatientProcedureName: this.state.newPatientProcedureName,
                                        newDoctorId: this.state.newDoctorId
                                    }
                            }}
                            onClick={this.hideModalSuccess}
                        ><button className="btn btn-primary center">OK</button>
                        {/* <button className="btn btn-primary center" onClick={this.hideModalSuccess}>OK</button> */}
                        </Link>
                        
                    </Modal.Footer>
                </Modal>

                {/* Modal for New Patient or Old Patient */}
                <Modal show={this.state.show}>
                    <Modal.Header onClick={this.hideModal} closeButton>
                        <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="center" style={{textAlign: "center"}}>
                        <p style={{textAlign: 'center'}}>Please select the type of Patient. </p>
                        <button className="btn btn-primary center" onClick={this.handleNewPatient}> New Patient </button>      
                        <button className="btn btn-primary center" style={{marginLeft: '10px'}} onClick={this.handleOldPatient}> Existing Patient  </button>                          
                    </Modal.Body>
                </Modal>

                {/* Modal for Add Patient  */}
                <Modal 
                    show={this.state.showAddPatientModal} 
                    backdrop="static"
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header onClick={this.hideAddPatientModal} closeButton>
                        <Modal.Title><h5 className="m-0 font-weight-bold text-primary">Add Patient:</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup>
                            <Row className="center">
                                <Col lg={2}><FormLabel>Title: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                <Col sm={3}>
                                    <FormControl
                                        as="select"
                                        type="text"
                                        name="title"
                                        className="center"
                                        value={this.state.title}
                                        onChange={this.handleChange}
                                    >
                                        <option value="">-Select-</option>
                                        <option value="Dr">Dr</option>
                                        {/* <option value="Miss">Miss</option> */}
                                        <option value="Mrs">Mrs</option>
                                        <option value="Ms">Ms</option>
                                        <option value="Mr">Mr</option>
                                    </FormControl>
                                </Col>
                            </Row>
                        </FormGroup>    
                        <hr/>
                        <FormGroup>
                            <Row className="center">
                                <Col sm={2}>First Name: <span style={{color: 'red'}}>*</span></Col>
                                <Col sm={2}>
                                    <FormControl
                                        type="text"
                                        name="first_name"
                                        className="center"
                                        value={this.state.first_name}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Col>
                                <Col sm={2}><FormLabel>Middle Name: </FormLabel></Col>
                                <Col sm={2}>
                                    <FormControl
                                        type="text"
                                        name="second_name"
                                        className="center"
                                        value={this.state.second_name}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col sm={2}><FormLabel>Last Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                <Col sm={2}>
                                    <FormControl
                                        type="text"
                                        name="last_name"
                                        className="center"
                                        value={this.state.last_name}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <hr/>
                        <FormGroup>
                            <Row className="center">
                                <Col lg={2}><FormLabel>Date of Birth: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                <Col sm={4}>
                                    <FormControl
                                        type="date"
                                        className="center"
                                        name="date_of_birth"
                                        value={this.state.date_of_birth}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <hr/>
                        <FormGroup>
                            <Row className="center">
                                <Col sm={2}><FormLabel>Address 1: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                <Col sm={4}>
                                    <FormControl
                                        type="text"
                                        name="address_1"
                                        className="center"
                                        value={this.state.address_1}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col sm={2}><FormLabel>Address 2: </FormLabel></Col>
                                <Col sm={4}>
                                    <FormControl
                                        type="text"
                                        name="address_2"
                                        className="center"
                                        value={this.state.address_2}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row className="center">
                                <Col sm={2}><FormLabel>Suburb: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                <Col sm={2}>
                                    <FormControl
                                        type="text"
                                        name="suburb"
                                        className="center"
                                        value={this.state.suburb}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col sm={2}><FormLabel>State: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                <Col sm={2}>
                                    <FormControl
                                        as="select"
                                        type="text"
                                        name="state"
                                        className="center"
                                        value={this.state.state}
                                        onChange={this.handleChange}
                                    >
                                        <option value="">-Select-</option>
                                        <option value="ACT">ACT</option>
                                        <option value="NSW">NSW</option>
                                        <option value="VIC">VIC</option>
                                        <option value="TAS">TAS</option>
                                        <option value="QLD">QLD</option>
                                        <option value="SA">SA</option>
                                        <option value="WA">WA</option>
                                        <option value="NT">NT</option>
                                    </FormControl>
                                </Col>
                                <Col sm={2}><FormLabel>Post Code: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                <Col sm={2}>
                                    <FormControl
                                        type="text"
                                        name="post_code"
                                        className="center"
                                        value={this.state.post_code}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>      
                        <hr />
                        <FormGroup className="center">
                            <Row className="center">
                                <Col sm={2}><FormLabel>Doctor: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                <Col sm={4}>
                                    <FormControl
                                        as="select"
                                        type="text"
                                        name="doctor_id"
                                        className="center"
                                        onChange={this.handleChange}
                                    >
                                        <option value="-Select-">-Select-</option>
                                        {this.state.doctors.map((doctor, i) => 
                                            <option key={i} value={doctor.id}>{doctor.name}</option>
                                        )}
                                    </FormControl>
                                </Col>
                                <Col sm={2}><FormLabel>Procedure: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                <Col sm={4}>
                                    <FormControl
                                        as="select"
                                        type="text"
                                        name="procedure_id"
                                        className="center"
                                        onChange={this.handleChange}
                                    >
                                        <option value="">-Select-</option>
                                        {this.state.procedures.map((procedure, i) => 
                                            <option key={i} value={procedure.id}>{procedure.name}</option>
                                        )}
                                    </FormControl>
                                </Col>
                            </Row>
                        </FormGroup>                                  
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary center" onClick={this.hideAddPatientModal}>
                            Cancel
                        </button >
                        <button className="btn btn-primary center" onClick={this.handleAddPatient}>
                            Add
                        </button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}

export default GeneralSidebar;
