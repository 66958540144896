import React, { Component } from 'react';
import { request } from '../../constants/constants';
import ReactTable from 'react-table';
import Pagination from '../../components/Pagination/pagination';
import { Col, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import UserContext from '../../contexts/User/Index';
import SearchField from "react-search-field";
import { data } from 'jquery';
import Loader from "react-loader-spinner";

class DoctorsTable extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
        doctors: [],
        showDeleteModal: false,
        currentId: '',
        searchDoctor: '',
        isLoading: true
    };
  }

  componentDidMount() {
    // request('GET', '/doctors/getAllDoctorsByAdmin', {}, true).then(result => {
    //   const doctors = result.data.data.map(obj => ({
    //     id: obj.ID,
    //     name: obj.title + '. ' + obj.first_name + ' ' + obj.last_name,
    //     mobile: obj.mobile,
    //     status: obj.status,
    //     office_telephone: obj.office_telephone, 
    //     office_telephone_2: obj.office_telephone_2,
    //     office_email: obj.office_email,
    //     office_facsmile: obj.office_facsmile,
    //     office_address: obj.office_address + ' ' + obj.office_address_2 + ' ' + obj.office_suburb + ' ' +  obj.office_state + ' ' + obj.office_postcode, 
    //     postal_address: obj.postal_address + ' ' + obj.postal_address_2 + ' ' + obj.postal_suburb + ' ' +  obj.postal_state + ' ' + obj.postal_post_code
    //   }));
    //   this.setState({ doctors});
    // })

    this.init();
  }

  init(){
    this.getDoctors().then(result => {
        console.log(result)
        const doctors = result.data.data.map(obj => ({
          id: obj.ID,
          name: obj.title + '. ' + obj.first_name + ' ' + obj.last_name,
          mobile: obj.mobile,
          status: obj.status,
          office_telephone: obj.office_telephone, 
          office_telephone_2: obj.office_telephone_2,
          office_email: obj.office_email,
          office_facsmile: obj.office_facsmile,
          office_address: obj.office_address + ' ' + obj.office_address_2 + ' ' + obj.office_suburb + ' ' +  obj.office_state + ' ' + obj.office_postcode, 
          postal_address: obj.postal_address + ' ' + obj.postal_address_2 + ' ' + obj.postal_suburb + ' ' +  obj.postal_state + ' ' + obj.postal_post_code
        }));
        this.setState({ doctors, isLoading: false});
    }).catch(err => { 
        console.error(err.response);
        this.setState({
            isLoading: false,
            error: err.response.data
        })
    });
  }

  getDoctors(){
    return request('GET', '/doctors/getAllDoctorsByAdmin', {}, true);
  }

  deleteHandler = e => {
    const data = {
      id: e.target.value
    }

    request('DELETE', `/doctors/${e.target.value}`, data, true).then(res => {
      this.setState({
        isLoading: true,
      })
      window.location.href = "/doctorstable";
    }).catch(err => {
      console.error(err.response);
      this.setState({
        isLoading: false,
        error: err.response.data
      })
    })
  }

  handleSearchDoctor = e => {
    const searchDoctor = e ;
    this.setState({
        searchDoctor: e
    }, () => {
        if(!searchDoctor){ 
            this.init();
        } else {
            request('GET', `/doctors/search/searchForDoctorName?doctor=${this.state.searchDoctor}`, {}, true).then(result => {  
                console.log(result)
                const doctors = result.data.data.map(obj => ({
                  id: obj.ID,
                  name: obj.title + '. ' + obj.first_name + ' ' + obj.last_name,
                  mobile: obj.mobile,
                  status: obj.status,
                  office_telephone: obj.office_telephone, 
                  office_telephone_2: obj.office_telephone_2,
                  office_email: obj.office_email,
                  office_facsmile: obj.office_facsmile,
                  office_address: obj.office_address + ' ' + obj.office_address_2 + ' ' + obj.office_suburb + ' ' +  obj.office_state + ' ' + obj.office_postcode, 
                  postal_address: obj.postal_address + ' ' + obj.postal_address_2 + ' ' + obj.postal_suburb + ' ' +  obj.postal_state + ' ' + obj.postal_post_code
                }));
                this.setState({ doctors});
            }).catch(err => { 
                console.error(err.response);
                this.setState({
                    isLoading: false,
                    error: err.response
                })
            });
        }
    })
  }

  handleDelete = e => {
    this.setState({ currentId: e.target.value, showDeleteModal: true})
  }

  hideDeleteModal = e => {
    this.setState({ showDeleteModal: false })
  }

  getResizeValues = (newResized, event) => {
    if(newResized){
        console.log(localStorage.getItem('doctorsTableWidths'), newResized)
        if(localStorage.getItem('doctorsTableWidths')) {
            const widths = JSON.parse(localStorage.getItem('doctorsTableWidths'));
            newResized.forEach(element => {
                const i = widths.findIndex(width => width.id == element.id);
                if(i > -1) {
                    const width = widths[i];
                    width.value = element.value
                } else {
                    widths.push(element);
                }
            });
            localStorage.setItem("doctorsTableWidths", JSON.stringify(widths));

        } else {
            localStorage.setItem("doctorsTableWidths", JSON.stringify(newResized));
        }
    }
}

  getColumnWidth(columnId, defaultSize) {
      if(localStorage.getItem('doctorsTableWidths')) {
          const widths =  JSON.parse(localStorage.getItem('doctorsTableWidths'));
          const i = widths.findIndex((element) => element.id == columnId);
          if(i > -1) {
              const columnWith = widths[i].value;
              return columnWith;
          }
          return defaultSize;
      }
      return defaultSize;
  }
  
    render() {
      const userPermission = this.context.user.user.permission;

      const columns = [{
        Header: 'Action',
        Cell: row => 
          <React.Fragment>
            {userPermission==='secretary' ? <Link to={`/doctorsbooking/${row.original.id}`} ><Button className="btn btn-dark mr-1">Book</Button></Link> : ''}
            {/* <Link to={`/doctorview/${row.original.id}`} ><Button className="btn btn-dark mr-1 center">View</Button></Link>
            <Button className="btn btn-danger center" value={`${row.original.id}`} onClick={this.handleDelete}>Delete</Button> */}
            <i className="far fa-trash-alt" value={`${row.original.id}`} style={{color: 'red'}} onClick={this.handleDelete}/>
            <Link to={`/doctorview/${row.original.id}`} ><i class="far fa-eye ml-2" /></Link>
          </React.Fragment>,
        width: this.getColumnWidth('action_id', 160),
        id: "action_id",
        style: { 'whiteSpace': 'unset' },
        accessor: "action_id"
      },{
        Header: 'Full Name',
        width: this.getColumnWidth('name', 250),
        id: "name",
        accessor: 'name',
        style: { 'whiteSpace': 'unset' }
      },{
        Header: 'Office Telephone',
        width: this.getColumnWidth('office_telephone', 200),
        id: "office_telephone",
        accessor: 'office_telephone',
        style: { 'whiteSpace': 'unset' }
      },{
        Header: 'Status',
        Cell: row => 
        <React.Fragment>
          {row.original.status === 1 ? 'Active' : 'Inactive'}
        </React.Fragment>,
        width: this.getColumnWidth('status', 200),
        id: "status",
        style: { 'whiteSpace': 'unset' }
      }];


        return (
            <div class="container-fluid doctors-table" id="center">
                <div className="card shadow mb-4">
                  <div className="card-header py-3">
                    <Row>
                      <Col sm={3} md={6} lg={4}><h6 className="mb-2 font-weight-bold text-primary">List of Doctors</h6></Col>
                      <Col sm={12} md={6} lg={4}>
                        <SearchField
                          placeholder="Search ..."
                          // onChange={this.handleSearchDoctor}
                          onSearchClick={this.handleSearchDoctor}
                          onEnter={this.handleSearchDoctor}
                          classNames="mb-2 center"
                          name="searchDoctor"
                          value={this.state.searchDoctor}
                        />
                      </Col>
                      <Col sm={12} md={12} lg={2}>
                        <Link to="/doctorsform"><button button style={{width: '100%'}} type="submit" className="btn btn-primary center"> <i class="fas fa-user-md" /> Add Doctor</button></Link>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-body">
                    {/* Modal for Delete Booking  */}
                    <Modal show={this.state.showDeleteModal} backdrop="static">
                      <Modal.Header onClick={this.hideDeleteModal} closeButton>
                          <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <p className='center' style={{textAlign: "center"}}>Are you sure you want to delete this Doctor?</p>
                      </Modal.Body>
                      <Modal.Footer>
                          <Button variant="secondary" className="center" onClick={this.hideDeleteModal}>
                              Cancel
                          </Button>
                          <Button 
                              className="btn btn-danger center" 
                              value={this.state.currentId} 
                              onClick={this.deleteHandler}
                          >Delete
                          </Button>
                      </Modal.Footer>
                    </Modal>

                    <div className="table-responsive">
                      {
                        this.state.isLoading ? (
                          <Loader
                            type="TailSpin"
                            color="#2850C2"
                            height={80}
                            width={80}
                            timeout={10000} 
                          />
                        ) : (
                          <ReactTable
                            PaginationComponent={Pagination}
                            data={this.state.doctors}
                            columns={columns}
                            defaultExpanded={true}
                            minRows={0}
                            onResizedChange={this.getResizeValues} 
                          />
                        )
                      }
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}

export default DoctorsTable;