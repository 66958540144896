import React, { Component } from 'react';
import { Modal, FormControl, FormLabel } from 'react-bootstrap';
import { Row, Col, Button, Container, FormGroup } from 'reactstrap';
import Calendar from 'react-calendar';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { request } from '../../constants/constants';
import moment from 'moment';
import { Multiselect } from 'multiselect-react-dropdown';
import Pagination from '../../components/Pagination/pagination';
import SearchField from "react-search-field";
import Loader from "react-loader-spinner";

class BookingCycleOffList extends Component {
    constructor(props){
        super(props);
        this.state = {
            bookingCycleOffList: [],
            doctors: [],
            hospitals: [],
            procedures: [],
            proceduresNames: [],
            currentProcedures2: [],
            showUpdateModal: false,
            showSuccessModal: false,
            showDeleteModal: false,
            searchOffList: '',
            isLoading: true,
            isLoadingUpdate: true,
        }
    }

    componentDidMount(){
        this.init();
    }

    init(){
        this.getBookingOffList().then(result => {
            console.log(result)
            const bookingCycleOffList = result.data.data.map(obj => ({
                id: obj.id,
                doctorName: obj.doctor.title + '. ' + obj.doctor.first_name + ' ' + obj.doctor.last_name,
                hospitalName: obj.hospital.name,
                procedures: obj.procedures,
                slot: obj.slot,
                date: obj.date,
                status: obj.status
            })); 
            this.setState({ bookingCycleOffList, isLoading: false }); 
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response
            })
        }) 
    }

    getBookingOffList() {
        return request('GET', '/booking_cycle_off_list/', {}, true);
    }

    handleUpdate = (booking_cycle_off_list_id) => {
        const id = booking_cycle_off_list_id;
        this.state.choicesProcedures = [];
        this.setState({
            showUpdateModal: true
        })

        request('GET', '/doctors/allDoctorsByAdminOnlyActive', {}, true).then(result => { 
            console.log(result)
            const doctors = result.data.data.map(obj => ({
                id: obj.ID,
                name: obj.title + '. ' + obj.first_name + ' ' + obj.last_name,
                // hospitals: obj.hospitals !== [] ? obj.hospitals : [],
                hospitals: (typeof obj.hospitals !== 'undefined' && obj.hospitals.length > 0) ? obj.hospitals : [],
            }));

            this.setState({ doctors, isLoadingUpdate: false });
            this.renderHospital(id)
        }).catch(err => { 
            this.setState({
                isLoadingUpdate: false,
                error: err.response
            })
        });
    }

    renderHospital = (id) => {

        request('GET', `/booking_cycle_off_list/${id}`, {}, true).then(result => { 
            console.log('result', result)
            this.setState({ 
                bookingCycleOffListid: result.data.data.id,
                doctor_id: result.data.data.doctor.id,
                hospital_id: result.data.data.hospital.id,
                hospital_name: result.data.data.hospital.name,
                slot: result.data.data.slot,
                currentDate: result.data.data.date,
                status: result.data.data.status,
                procedure_id: result.data.data.procedures.length > 0 && (result.data.data.procedures.map(obj => obj.hospital_procedures.length > 0 && (obj.hospital_procedures.map(item => ({ id: item.procedure_id, name: item.procedure_type }) ))  ) )
            }, () => {
                const doctorIndex = this.state.doctors.findIndex(doctor => doctor.id === this.state.doctor_id);
                const doctor = this.state.doctors[doctorIndex];

                this.setState({
                    hospitals: doctor.hospitals,
                })               
                
                this.state.procedure_id.map(procedure_item => {
                    if(procedure_item){
                        procedure_item.map(item => {
                            // this.state.currentProcedures2.push({id: item.id, name: item.name})
                            this.setState(prevState => ({
                                currentProcedures2: [...prevState.currentProcedures2, ({id: item.id, name: item.name})]
                            }))
                        })
                    }
                    
                });

                request('GET', `/hospital_procedures/${this.state.hospital_id}`, {}, true).then(result => {
                    console.log('hospital_procedures', result)
                    const hospitalProc = result.data.data.map(procedure => ({
                        id: procedure.procedure_id,
                        name: procedure.procedure_type
                    }));
                    this.setState({
                        choicesProcedures: hospitalProc                        
                    });
                }).catch(err => {
                    console.error(err);
                    this.setState({
                        isLoading: false,
                        error: err.response
                    })
                })
            });
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        }) 

    }

    handleDoctor = e => {
        const id = e.target.value;
        if(id=="-Select-"){
            return false
        }
        const index = this.state.doctors.findIndex(doctor => doctor.id === id);
        const doctor = this.state.doctors[index];
        this.setState({
            hospitals: doctor.hospitals,
            doctor_id: id
        })
    }

    handleChange = e => {
        const val = e.target.value;
        if(val === "-Select-"){
            return false;
        }
        this.setState({
            [e.target.name]: val
        })
    }

    handleHospital = e => {
        const hospitalId = e.target.value; 
        if(hospitalId==="-Select-"){
            return false
        }
        this.setState({
            hospital_id: hospitalId
        })
    }

    // onChange = date =>{ 
    //     this.setState({ 
    //         currentDate: date
    //     }) 
    // }

    onSelect = (selectedList, selectedItem) => {
        console.log(selectedList)
        this.setState({
            selectedProcedures: selectedList
        })
    }

    onRemove = (selectedList, removedItem) => {
        this.setState({
            selectedProcedures: selectedList
        })
    }

    handleSaveUpdate = e => {
        e.preventDefault();
        const data = {
            hospital_id: this.state.hospital_id,
            doctor_id: this.state.doctor_id,
            procedure_id: this.state.selectedProcedures ? this.state.selectedProcedures : this.state.currentProcedures2,
            slot: this.state.slot,
            date: moment(this.state.currentDate).format('YYYY-MM-DD'),
            status: this.state.status
        }

        // console.log(data)
        request('PUT', `/booking_cycle_off_list/${this.state.bookingCycleOffListid}`, data, true).then(res => {
            // console.log(res)
            this.setState({
                isLoading: false,
                showUpdateModal: false,
                showSuccessModal: true,
                successMessage: res.data.message
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                errorData: err.response.data.errors,
                showError: true,
            })
        })

    }

    handleDelete = (booking_cycle_off_list_id) => {
        this.setState({
            currentId: booking_cycle_off_list_id,
            showDeleteModal: true
        })
    }

    deleteHandler = e => {
        const data = {
          id: e.target.value
        }
    
        request('DELETE', `/booking_cycle_off_list/${e.target.value}`, data, true).then(res => {
          this.setState({
            isLoading: true,
          })
          window.location.href = "/bookingcycleofflist";
        }).catch(err => {
          console.error(err.response);
          this.setState({
            isLoading: false,
            error: err.response.data
          })
        })
    }

    /*Modal Functions*/

    hideModalUpdate = () => {
        this.setState({
            showUpdateModal: false,
            currentProcedures2: [],
            proceduresNames: []
        })
    }

    hideModalSuccess = () => {
        this.setState({ showSuccessModal: false })
        window.location.href = "/bookingcycleofflist";
    }

    hideDeleteModal = e => {
        this.setState({ showDeleteModal: false })
    }

    handleSearchOfflist = (e) => {
        this.setState({ isLoading: true }); 
        const searchOffList = e;
        if(!searchOffList){
            this.init();
        } else {
            request('GET', `/booking_cycle_off_list/search/searchBookingOffList?offList=${searchOffList}`, {}, true).then(result => {
                const bookingCycleOffList = result.data.data.map(obj => ({
                    id: obj.id,
                    doctorName: obj.doctor.title + '. ' + obj.doctor.first_name + ' ' + obj.doctor.last_name,
                    hospitalName: obj.hospital.name,
                    procedures: obj.procedures,
                    slot: obj.slot,
                    date: obj.date,
                    status: obj.status
                })); 
                this.setState({ bookingCycleOffList, isLoading: false }); 
            }).catch(err => {
                console.error(err.response);
                this.setState({
                    isLoading: false,
                    error: err.response
                })
            }) 
        }
    }

    getResizeValues = (newResized, event) => {
        if(newResized){
            console.log(localStorage.getItem('bookingCycleOffListTableWidths'), newResized)
            if(localStorage.getItem('bookingCycleOffListTableWidths')) {
                const widths = JSON.parse(localStorage.getItem('bookingCycleOffListTableWidths'));
                newResized.forEach(element => {
                    const i = widths.findIndex(width => width.id == element.id);
                    if(i > -1) {
                        const width = widths[i];
                        width.value = element.value
                    } else {
                        widths.push(element);
                    }
                });
                localStorage.setItem("bookingCycleOffListTableWidths", JSON.stringify(widths));

            } else {
                localStorage.setItem("bookingCycleOffListTableWidths", JSON.stringify(newResized));
            }
        }
    }

    getColumnWidth(columnId, defaultSize) {
        if(localStorage.getItem('bookingCycleOffListTableWidths')) {
            const widths =  JSON.parse(localStorage.getItem('bookingCycleOffListTableWidths'));
            const i = widths.findIndex((element) => element.id == columnId);
            if(i > -1) {
                const columnWith = widths[i].value;
                return columnWith;
            }
            return defaultSize;
        }
        return defaultSize;
    }

    render() {
        const mutiselectStyle = {
            option: { 
                color: "black"
            }
          };
          
        const columns = [{ 
            Header: 'Action',
            Cell: row => 
                <React.Fragment>
                    {/* <button className="btn btn-dark mr-1 center" value={`${row.original.id}`} onClick={this.handleUpdate}>Update</button>
                    <button className="btn btn-danger center" value={`${row.original.id}`} onClick={this.handleDelete}>Delete</button> */}
                    <i className="far fa-trash-alt" style={{color: 'red'}} onClick={() => this.handleDelete(row.original.id) }/>
                    <i className="far fa-edit ml-2" onClick={() => this.handleUpdate(row.original.id)}/>
                </React.Fragment>,
            width: this.getColumnWidth('action_id', 160),
            id: "action_id",
            style: { 'whiteSpace': 'unset' }
        },{
            Header: 'Hospital',
            width: this.getColumnWidth('hospital', 200),
            id: "hospital",
            accessor: 'hospitalName'
            
        },{
            Header: 'Doctor',
            width: this.getColumnWidth('doctor', 200),
            id: "doctor",
            accessor: 'doctorName'
        },{
            Header: 'Procedures',
            Cell: row => row.original.procedures.length > 0 && row.original.procedures.map(procedure => procedure.hospital_procedures.length > 0 && (procedure.hospital_procedures.map(item => <ul><li>{item.procedure_type}</li></ul>  )) ),
            width: this.getColumnWidth('procedures', 200),
            id: "procedures",
            accessor: 'procedures',
        },{
            Header: 'Slot',
            Cell: row => row.original.slot && row.original.slot,
            width: this.getColumnWidth('slot', 100),
            id: "slot",
            accessor: 'slot'
        },{
            Header: 'Date',
            Cell: row => (<span>{moment(row.original.date).format("dddd, DD-MMMM-YYYY")}</span>),
            width: this.getColumnWidth('date', 150),
            id: "date",
            accessor: row => (row.date)
        },{
            Header: 'Status',
            Cell: row => (<span> {row.original.status ? "Active" : "Inactive"} </span>),
            width: this.getColumnWidth('status', 150),
            id: "status",
        }];

        return (
            <div className="container-fluid booking-offlist-page">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col sm={3} md={6} lg={4}><h5 className="mb-2 font-weight-bold text-primary center">Booking Cycle Off List</h5></Col>
                            <Col sm={12} md={6} lg={4}>
                                <SearchField
                                    placeholder="Search ..."
                                    // onChange={this.handleSearchOfflist}
                                    onSearchClick={this.handleSearchOfflist}
                                    onEnter={this.handleSearchOfflist}
                                    classNames="mb-2 center"
                                    name="searchOffList"
                                    value={this.state.searchOffList}
                                />
                            </Col>
                            <Col sm={12} md={12} lg={2}>
                                <Link to='/bookingcycleofflistform' ><button style={{width: '100%'}} type="submit" className="btn btn-primary center" onClick={this.handleAddOffList}><i class="far fa-calendar-check" /> Add Data</button></Link>
                            </Col>
                        </Row>
                    </div>
                    <div className="card-body">

                        {/* Modal for Delete Booking  */}
                        <Modal show={this.state.showDeleteModal} backdrop="static">
                            <Modal.Header onClick={this.hideDeleteModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>Are you sure you want to delete this Booking Cycle Off List?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="center" onClick={this.hideDeleteModal}>
                                    Cancel
                                </Button>
                                <Button 
                                    className="btn btn-danger center" 
                                    value={this.state.currentId} 
                                    onClick={this.deleteHandler}
                                >Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Successfully Added  */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideModalSuccess} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>{this.state.successMessage}</p>     
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideModalSuccess}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Update Booking Cycle Off List */}
                        <Modal 
                            show={this.state.showUpdateModal} 
                            size="lg"
                            backdrop="static"
                        >
                            <Modal.Header onClick={this.hideModalUpdate} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Update: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    this.state.isLoadingUpdate ? (
                                        <Loader
                                            type="TailSpin"
                                            color="#2850C2"
                                            height={80}
                                            width={80}
                                            // timeout={10000} 
                                        />
                                    ) : (
                                        <Container>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col md={12} lg={2}><FormLabel>Doctor: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col md={12} lg={4}>
                                                        <FormControl
                                                            as="select"
                                                            type="text"
                                                            name="doctor"
                                                            onChange={this.handleDoctor}
                                                            value={this.state.doctor_id}
                                                            className="center"
                                                        >
                                                            <option value="-Select-">-Select-</option>
                                                            {this.state.doctors.map(doctor => ( 
                                                                <option value={doctor.id}> {doctor.name} </option>
                                                            ))}
                                                        </FormControl>
                                                    </Col>
                                                    <Col md={12} sm={2} lg={2}><FormLabel className="mt-md-2">Procedure/s: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col md={12} sm={4} lg={4}>
                                                        <Multiselect
                                                            options={this.state.choicesProcedures} 
                                                            selectedValues={this.state.currentProcedures2} 
                                                            onSelect={this.onSelect} 
                                                            onRemove={this.onRemove} 
                                                            displayValue="name"
                                                            className="center"
                                                            style={mutiselectStyle}
                                                            avoidHighlightFirstOption="true"
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col md={12} lg={2}><FormLabel>Hospital: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col md={12} lg={4}>
                                                        <FormControl
                                                            as="select"
                                                            type="text"
                                                            name="hospital"
                                                            onChange={this.handleHospital}
                                                            value={this.state.hospital_id}
                                                            className="center"
                                                        >
                                                            <option value="-Select-">-Select-</option>
                                                            {this.state.hospitals.map(hospital => {
                                                                if(hospital){
                                                                    if(hospital.pivot.deleted_at){
                                                                        return null;
                                                                    } else {
                                                                        return <option value={hospital.id}> {hospital.name} </option>;
                                                                    }
                                                                }
                                                                return false;
                                                            })}
                                                            
                                                        </FormControl>
                                                    </Col>
                                                    <Col md={12} lg={2}><FormLabel className="mt-md-2">Slot: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col md={12} lg={4}>
                                                        <FormControl
                                                            as="select"
                                                            type="text"
                                                            name="slot"
                                                            onChange={this.handleChange}
                                                            value={this.state.slot}
                                                            className="center"
                                                        >
                                                            <option value="-Select-">-Select-</option>
                                                            <option value="AM">AM</option>
                                                            <option value="PM">PM</option>                                           
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col md={12} lg={2}><FormLabel>Date: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col md={12} lg={4}>
                                                        <FormControl
                                                            type="date"
                                                            className="center"
                                                            name="currentDate"
                                                            value={this.state.currentDate}
                                                            onChange={this.handleChange}
                                                        />
                                                        {/* <Calendar
                                                            onChange={this.onChange}
                                                            tileClassName={({ date }) => { 
                                                                if(this.state.currentDate === moment(date).format("YYYY-MM-DD")){
                                                                    return 'react-calendar__tile--active'
                                                                }                                   
                                                            }}
                                                        /> */}
                                                    </Col>
                                                    <Col md={12} lg={2}><FormLabel className="mt-md-2">Status: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col md={12} lg={4}>
                                                        <FormControl
                                                            as="select"
                                                            type="text"
                                                            name="status"
                                                            onChange={this.handleChange}
                                                            value={this.state.status}
                                                            className="center"
                                                        >
                                                            <option value="-Select-">-Select-</option>
                                                            <option value="1">Active</option>
                                                            <option value="0">Inactive</option>
                                                        </FormControl>
                                                    </Col>                                            
                                                </Row>
                                            </FormGroup>
                                        </Container>
                                    )
                                }
                                
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-danger center" onClick={this.hideModalUpdate}>
                                    Cancel
                                </button>
                                <button className="btn btn-primary center" onClick={this.handleSaveUpdate}>
                                    Update
                                </button>
                            </Modal.Footer>
                        </Modal>
                        {
                            this.state.isLoading ? (
                                <Loader
                                    type="TailSpin"
                                    color="#2850C2"
                                    height={80}
                                    width={80}
                                    // timeout={10000}
                                />     
                            ) : (
                                <ReactTable
                                    PaginationComponent={Pagination}
                                    data={this.state.bookingCycleOffList}
                                    columns={columns}
                                    minRows={0}
                                    style={{overflow:'wrap'}}
                                    className="center"        
                                    onResizedChange={this.getResizeValues}                  
                                />
                            )
                        }
                    </div>
                </div>                
            </div>
        );
    }
}

export default BookingCycleOffList;