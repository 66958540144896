import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { request } from '../../constants/constants';
import { FormLabel, Modal, FormControl, Form } from 'react-bootstrap';
import Loader from "react-loader-spinner";

class HospitalView extends Component {
    constructor (props){
        super(props);
        this.state = {
            id: this.props.match.params.id,
            procedures: [],
            procedureNames: [],
            showModal: false,
            showSuccessModal: false,
            // toggleVisible: false,
            // toggleVisiblePO: false,
            reference_number: '',
            procedure_id: '',
            isLoading: true
        }
        this.onToggle = this.onToggle.bind(this)
        this.onTogglePO = this.onTogglePO.bind(this)
    }

    getHospital() {
        return request ('GET', `/hospitals/${this.state.id}`, {}, true);
    }

    init(){
        this.getHospital().then(res => {
            this.setState({
                code: res.data.data.code,
                name: res.data.data.name,
                office_telephone: res.data.data.office_telephone,
                office_telephone_2: res.data.data.office_telephone_2,
                office_facsmile: res.data.data.office_facsmile,
                office_email: res.data.data.office_email,
                office_address: res.data.data.office_address,
                office_address_2: res.data.data.office_address_2,
                office_suburb: res.data.data.office_suburb,
                office_state: res.data.data.office_state,
                office_postcode: res.data.data.office_postcode,
                reference_number: res.data.data.reference_number,
                
                postal_address: res.data.data.postal_address,
                postal_address_2: res.data.data.postal_address_2,
                postal_suburb: res.data.data.postal_suburb,
                postal_state: res.data.data.postal_state,
                postal_postcode: res.data.data.postal_postcode,

                contact_name: res.data.data.contact_title + '. ' + res.data.data.contact_first_name + ' ' + res.data.data.contact_last_name,
                contact_mobile: res.data.data.contact_mobile,
                contact_telephone: res.data.data.contact_telephone,
                contact_facsmile: res.data.data.contact_facsmile,
                contact_email: res.data.data.contact_email,

                website: res.data.data.website,
                num_rooms: res.data.data.num_rooms,
                status: res.data.data.status,
                isLoading: false
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
            })
        })
    }

    componentDidMount() {
        this.init()
        /*Get all procedures to add in the hospital */
        request('GET', '/procedures/allProceduresOnlyActive', {}, true).then(result => {
            const procedures = result.data.data.map(obj => ({
                id: obj.ID,
                type: obj.procedure_type,
            }));
            this.setState({procedures});
        }).catch(err => {
            this.setState({
                isLoading: false,
                error: err.response
            })
        })

        /*Get all the Procedures of this Hospital*/
        request('GET', `/hospital_procedures/${this.state.id}`, {}, true).then(result => {
            const procedureNames = result.data.data.map(obj => ({ 
                procedure_type: obj.procedure_type,
                procedure_id: obj.procedure_id,
                hospital: obj.hospital_id,
                price: obj.price,
                status: obj.status,
                id: obj.id
            }));
            let datas = procedureNames.sort((a, b) => (a.hospital.name > b.hospital.name) ? 1 : -1)
            this.setState({ 
                procedureNames: procedureNames 
            })
        }).catch(err => {
            this.setState({
                isLoading: false,
                error: err.response
            })
        })

        request('GET', `/hospitals/${this.state.id}`, {}, true).then(result => { 
            this.setState({ 
                toggleVisible: result.data.data.group_invoice, 
                toggleVisiblePO: result.data.data.purchase_order
            })   
        });
    }

    onToggle = (e) => {
        const data = {
            group_invoice: !this.state.toggleVisible
        } 

        request('PATCH', `/hospitals/groupInvoices/${e.target.value}`, data, true).then(result => { 
            this.setState({
                toggleVisible: result.data.data.group_invoice
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                error: err.response.data,
            })
        })
    }

    onTogglePO = (e) => {
        const data = {
            purchase_order: !this.state.toggleVisiblePO
        }

        request('PATCH', `/hospitals/purchaseOrders/${e.target.value}`, data, true).then(result => { 
            // console.log(result)
            this.setState({
                // isLoading: true,
                toggleVisiblePO: result.data.data.purchase_order
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
            })
        })
    }

    /* Handle Functions*/
    handleProcedure = e => {
        // console.log(e.target.value, e.target.id)
        this.setState({
            procedure_id: e.target.value
        })
    }

    handleChange = e => {
        this.setState({ 
            [e.target.name]: e.target.value
        })
    }

    handleEditProcedure = (id, procedure_id, procedure_type, price) => {
        // console.log(procedure_type)
        const hospProcedureId = id;
        this.setState({
            showModal: true,
            titleModal: "Edit Hospital/Procedure",
            buttonContent: "Update",
            procedure_id: procedure_id,
            procedure_type: procedure_type,
            price: price.toFixed(2),
            hospProcedureId: hospProcedureId            
        })
    }

    handleDeleteProcedure = e => {
        this.setState({
            hospProcIdDelete: e,
            showDeleteModal: true
        })
    }

    handleRemove = e => {
        const data = {
            hospProcIdDelete: e.target.value
        }
        request('DELETE', `/hospital_procedures/${this.state.hospProcIdDelete}`, data, true).then(result => { 
            window.location.href = `/hospitalview/${this.state.id}`; 
        }).catch(err => {
            this.setState({
                isLoading: false,
                error: err.response
            })
        })
    }

    handleAction = () => {
        const data = {
            hospital_id: this.state.id,
            procedure_id: this.state.procedure_id,
            price: this.state.price,
            status: 1
        }

        // console.log(this.state.hospProcedureId, data)

        if(this.state.hospProcedureId) {
            request('PUT', `/hospital_procedures/${this.state.hospProcedureId}`, data, true).then(result => { 
                this.setState({
                    isLoading: false,
                    showModal: false,
                    showSuccessModal: true,
                    successMessage: "Successfully Updated!"
                })
            }).catch(err => {
                this.setState({
                    isLoading: false,
                    error: err.response.data.message,
                    responseCode: err.response.status
                })
            })
        } else {
            request('POST', '/hospital_procedures/addRelationship', data, true).then(result => { 
                this.setState({
                    isLoading: false,
                    showModal: false,
                    showSuccessModal: true,
                    successMessage: "Successfully Added!"
                })
            }).catch(err => {
                console.error(err.response);
                this.setState({
                    isLoading: false,
                    error: err.response.data.message,
                    responseCode: err.response.status
                })
            })
        }
    }

    /* Modal Functions*/

    handleAddProcedure = e => {
        this.setState({ 
            showModal: true, 
            titleModal: "Add Procedure to Hospital",
            buttonContent: "Add",
            procedure_type: "",
            price: ""
        })
    }

    hideModal = e => {
        this.setState({ showModal: false })
    }

    hideSuccessModal = e =>{
        this.setState({ showSuccessModal: false })
        window.location.href = `/hospitalview/${this.state.id}`;
    }

    /*Delete Secretary Modal */
    hideDeleteModal = () => {
        this.setState({ 
            showDeleteModal: false 
        })
    }

    render() {
        return (
            <div class="container-fluid" id="center">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Container>
                            <Row>
                                <Col sm={12} md={12} lg={2}><h6 className="m-0 font-weight-bold text-primary">Hospital Information</h6></Col>
                                <Col sm={12} md={6} lg={3}>
                                    <button style={{width: '100%'}} type="submit" className="btn btn-primary center mt-2 mt-lg-0" onClick={this.handleAddProcedure}><i class="far fa-calendar-check"></i> Add Procedure </button>
                                </Col>
                                <Col sm={12} md={6} lg={2}>
                                    <Link to={`/hospitaledit/${this.state.id}`}><button style={{width: '100%'}} type="submit" className="btn btn-primary center mt-2 mt-lg-0"><i class="far fa-edit" /> Edit Hospital</button></Link>
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    <Form.Check 
                                        type="switch"
                                        id="custom-switch"
                                        label="Send one invoice for all"
                                        onClick={this.onToggle}
                                        checked={this.state.toggleVisible}
                                        value={this.state.id}
                                        className="mt-2 mt-lg-0"
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={2}>
                                    <Form.Check 
                                        type="switch"
                                        id="custom-switch-po"
                                        label="Add PO#"
                                        onClick={this.onTogglePO}
                                        checked={this.state.toggleVisiblePO}
                                        value={this.state.id}
                                        className="mt-2 mt-lg-0"
                                    />
                                </Col>
                                
                            </Row>
                        </Container>
                    </div>
                    <div className="card-body">
                        {/* Modal for Adding of procedure to hospital */}
                        <Modal show={this.state.showModal} backdrop="static">
                            <Modal.Header onClick={this.hideModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">{this.state.titleModal}</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className='center m-2'>
                                    <Col sm={2}><FormLabel>Procedure: </FormLabel></Col>
                                    <Col sm={10}>
                                        <FormControl
                                        as = "select"
                                        type="text"
                                        name="doctor"
                                        className="center"
                                        value={this.state.procedure_id}
                                        onChange={this.handleProcedure}
                                        >
                                        <option value="-Select-">-Select-</option>
                                        {this.state.procedures.map(procedure => (
                                            <option value={procedure.id}>{procedure.type}</option>
                                        ))}
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row className='center m-2'>
                                    <Col sm={2}><FormLabel>Price: </FormLabel></Col>
                                    <Col sm={10}>
                                        <FormControl
                                            type="number"
                                            name="price"
                                            className="center"
                                            step="0.01"
                                            value={this.state.price}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className='center m-2'>
                                    <p style={{color: '#FF0000', display: this.state.responseCode === 403 || 422 ? 'block' : 'none' }}>{this.state.error}</p>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary center" onClick={this.hideModal}>
                                    Cancel
                                </button>
                                <button className="btn btn-primary center" value={this.state.hospProcedureId} onClick={this.handleAction}>
                                    {this.state.buttonContent}
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Successfully Added */}
                        <Modal show={this.state.showSuccessModal} backdrop="static">
                            <Modal.Header onClick={this.hideSuccessModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>{this.state.successMessage}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideSuccessModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>  

                        {/* Modal for Delete  */}
                        <Modal show={this.state.showDeleteModal} backdrop="static">
                            <Modal.Header onClick={this.hideDeleteModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>Are you sure you want to remove this Procedure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary center" onClick={this.hideDeleteModal}>
                                    Cancel
                                </button>
                                <button 
                                    className="btn btn-danger center" 
                                    value={this.state.hospProcIdDelete} 
                                    onClick={this.handleRemove}
                                >Delete
                                </button>
                            </Modal.Footer>
                        </Modal>
                        
                        {
                            this.state.isLoading ? (
                                <Loader
                                        type="TailSpin"
                                        color="#2850C2"
                                        height={80}
                                        width={80}
                                        timeout={10000} 
                                    />
                            ) : (
                                <Container>
                                    <Row className="m-2">
                                        <Col sm={6}>
                                            <Row className="center">
                                                <FormLabel><b>General Details:</b> </FormLabel>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Code: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.code}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                            <div className="col-6 col-md-4 col-lg-4"><FormLabel>Name: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.name}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Status: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.status === 1 ? 'Active' : 'Inactive'}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Reference Number: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    {
                                                        this.state.reference_number && (
                                                            <FormLabel>
                                                                {this.state.reference_number}
                                                            </FormLabel>
                                                        )
                                                    }
                                                    
                                                </div>
                                            </Row>
                                        </Col>
                                        
                                        <Col sm={6}>
                                            <Row className="center">
                                                <FormLabel><b>Telephone / Fax:</b> </FormLabel>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Office Telephone: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.office_telephone}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Office Telephone 2: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                    {this.state.office_telephone_2}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Office Email: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                    {this.state.office_email}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Office Facsmile: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                    {this.state.office_facsmile}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row className="m-2">
                                        <Col sm={6} md={6} lg={6}>
                                            <Row className="center">
                                                <FormLabel><b>Office Address:</b> </FormLabel>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Address 1: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.office_address}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Address 2: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.office_address_2}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Suburb: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.office_suburb}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>State: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.office_state}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Postcode: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.office_postcode}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                        </Col>

                                        <Col sm={6} md={6} lg={6}>
                                            <Row className="center"><FormLabel><b>Postal Address:</b> </FormLabel></Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Address 1: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.postal_address}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Address 2: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.postal_address_2}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Suburb: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.postal_suburb}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>State: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.postal_state}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Postcode: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.postal_postcode}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                
                                    <hr/>
                                    <Row className="m-2">
                                        <Col sm={6} md={6} lg={6}>
                                            <Row className="center">
                                                <FormLabel><b>Contact Person:</b> </FormLabel>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Name: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.contact_name}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Mobile: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.contact_mobile}
                                                    </FormLabel>
                                                </div>
                                                {/* <Col sm={2}><FormLabel>Number of Rooms: </FormLabel></Col>
                                                <Col sm={4}>
                                                    <FormLabel>
                                                        {this.state.num_rooms}
                                                    </FormLabel>
                                                </Col> */}
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Telephone: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.contact_telephone}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Email: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.contact_email}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Facsmile: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.contact_facsmile}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-4 col-lg-4"><FormLabel>Website: </FormLabel></div>
                                                <div className="col-6 col-md-8 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.website}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row className="m-2">
                                        <Col sm={6}><FormLabel><b>Procedures: </b></FormLabel></Col>
                                    </Row>
                                    <Row className="m-4">
                                        <Col sm={6} md={6} lg={6}>
                                            <Row className="center"> 
                                                <FormLabel><b>Names: </b></FormLabel>
                                            </Row>
                                            <Row className="center"> 
                                                <FormLabel> 
                                                    {this.state.procedureNames.map(name  => (
                                                        <ul>
                                                            <li style={{ color: name ? ( name.status ? 'black' : 'grey') : '' }}>
                                                                { name ? name.procedure_type : '-' } 
                                                            </li>
                                                        </ul>
                                                    ))}
                                                </FormLabel> 
                                            </Row>
                                        </Col>
                                        <Col sm={6} md={6} lg={6}>
                                            <Row className="center">
                                                <FormLabel><b>Prices: </b></FormLabel>
                                            </Row>
                                            <Row className="center">
                                                <FormLabel> 
                                                    {this.state.procedureNames.map(name => (
                                                        <ul>
                                                            <li style={{ color: name ? ( name.status ? 'black' : 'grey') : '' }}>
                                                                { name ? name.price.toFixed(2) : '-' } 
                                                                <Link><i style={{marginLeft: '50px'}} class="fas fa-edit" onClick={() => this.handleEditProcedure(name.id, name.procedure_id, name.procedure_type, name.price)}/></Link>
                                                                <Link><i style={{marginLeft: '15px'}} class="fas fa-times-circle" onClick={() => this.handleDeleteProcedure(name.id)}/></Link>
                                                            </li>
                                                        </ul>
                                                    ))}
                                                </FormLabel>
                                            </Row>
                                        </Col>
                                    </Row>              
                                </Container>
                            )
                        }
                        
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default HospitalView;