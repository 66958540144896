import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { Modal, Form, FormControl, FormLabel, FormGroup} from 'react-bootstrap';
import { request } from '../../constants/constants';
import Pagination from '../../components/Pagination/pagination';
import SearchField from "react-search-field";
import Loader from "react-loader-spinner";
import moment from 'moment';

class BookingCyclePage extends Component {
    constructor(props){
        super(props);
        this.state = {
            bookingCycle: [],
            showDeleteModal: false,
            toggleInactiveBookingCycle: false,
            showUpdateBookingStatusModal: false,
            isLoading: true,
            end_date: ""
            
        }
        this.onToggleInactiveBookingCycle = this.onToggleInactiveBookingCycle.bind(this)
        this.onToggleStatus = this.onToggleStatus.bind(this)
    }

    getBookingCycle(){
        return request('GET', '/booking_cycles/', {}, true)
    }

    init(){
        this.getBookingCycle().then(result => {
            console.log('result.data.data', result.data.data)
            this.setState({ 
                bookingCycle: result.data.data, 
                isLoading: false
            }); 
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoading: false,
                error: err
            })
        })       
    }

    componentDidMount(){
        this.init();
    }

    handleDelete = (booking_id) => {
        this.setState({
            currentId: booking_id,
            showDeleteModal: true
        })
    }

    updateStatusHandler = e => {
        const currentBookingId = e.target.value;
        const data = {
            status: !this.state.currentStatus,
            id: e.target.value,
            end_date: this.state.end_date
        }
      
        request('PATCH', `/booking_cycles/update-status/${currentBookingId}`, data, true).then(result => {
            window.location.href = "/bookingcyclepage";
        })
    }

    deleteHandler = e => {
        // const data = {
        //   id: e.target.value,
        //   end_date: this.state.end_date
        // }
        
    
        request('DELETE', `/booking_cycles/${e.target.value}`, {}, true).then(res => {
          this.setState({
            isLoading: true,
          })
          window.location.href = "/bookingcyclepage";
        }).catch(err => {
          console.error(err.response);
          this.setState({
            isLoading: false,
            error: err.response.data
          })
        })
    }

    handleSearch = e => {
        const searched_data = e;
        this.setState({
            searched_data: e,
            isLoading: true,
        }, () => {  
            if(!this.state.searched_data){ 
                this.init();
            } else {
                request('GET', `/booking_cycles/search/searchForBookingCycle?searched_data=${e}`, {}, true).then(result => {  
                    console.log("result", result)
                    this.setState({
                        bookingCycle: result.data.data,
                        isLoading: false
                    })
                }).catch(err => { 
                    console.error(err.response);
                    this.setState({
                        isLoading: false,
                        error: err.response
                    })
                });
            }
        })
    }

    onToggleInactiveBookingCycle = (e) => {
        
        this.setState({
            toggleInactiveBookingCycle: !this.state.toggleInactiveBookingCycle
        })

        if(e.target.checked){
            request('GET', '/booking_cycles/inactive-cycles', {}, true).then(result => {
                console.log('inactive-cycle', result)
                this.setState({ 
                    bookingCycle: result.data.data 
                }); 
            })      
          }else{
            this.init(); 
          }

    }

    onToggleStatus = (e, status) => {
        const booking_id = e;

        this.setState({
            showUpdateBookingStatusModal:  true,
            currentId: booking_id,
            currentStatus: status
        })

    }

    getResizeValues = (newResized, event) => {
        if(newResized){
            console.log(localStorage.getItem('bookingCycleTableWidths'), newResized)
            if(localStorage.getItem('bookingCycleTableWidths')) {
                const widths = JSON.parse(localStorage.getItem('bookingCycleTableWidths'));
                newResized.forEach(element => {
                    const i = widths.findIndex(width => width.id == element.id);
                    if(i > -1) {
                        const width = widths[i];
                        width.value = element.value
                    } else {
                        widths.push(element);
                    }
                });
                localStorage.setItem("bookingCycleTableWidths", JSON.stringify(widths));

            } else {
                localStorage.setItem("bookingCycleTableWidths", JSON.stringify(newResized));
            }
        }
    }

    getColumnWidth(columnId, defaultSize) {
        if(localStorage.getItem('bookingCycleTableWidths')) {
            const widths =  JSON.parse(localStorage.getItem('bookingCycleTableWidths'));
            const i = widths.findIndex((element) => element.id == columnId);
            if(i > -1) {
                const columnWith = widths[i].value;
                return columnWith;
            }
            return defaultSize;
        }
        return defaultSize;
    }

    deleteBookingCycle = (booking_id) => {

        this.setState({

        })
    }

    /* Modal Function */
    hideDeleteModal = e => {
        this.setState({ showDeleteModal: false, showUpdateBookingStatusModal: false })
    }

    getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) { 
          return {
            style: {
              color:
                rowInfo.original.week == 1 ? "#000000" : (rowInfo.original.week == 2 ? "#c10001" : ( rowInfo.original.week == 3 ? '#9900ff' : (rowInfo.original.week == 4 ? '#009900' : '')))
                //background:  rowInfo.original.week == 1 ? "#fefae0" : (rowInfo.original.week == 2 ? "#edf6f9" : ( rowInfo.original.week == 3 ? '#83c5be' : (rowInfo.original.week == 4 ? '#ffe5d4' : '')))
            },
          };
        }
        return {};
      };

    onChange = (e) =>{ 
        this.setState({ 
            end_date: e.target.value 
        }) 
    }

    render() {
    
        const columns = [{ 
            Header: 'Action',
            Cell: row => 
                <React.Fragment>
                    <i className="far fa-trash-alt" style={{color: 'red'}} onClick={() => this.handleDelete(row.original.id)}/>
                    <Link to={`/bookingcycleupdateform/${row.original.id}`} ><i className="far fa-edit ml-2"/></Link>
                   {/* <Link to={`/bookingcycleupdateform/${row.original.id}`} ><button className="btn btn-dark mr-1 center">Update</button></Link> */}
                    {/* <button className="btn btn-danger center" value={`${row.original.id}`} onClick={this.handleDelete}>Delete</button> */}
                </React.Fragment>,
            width: this.getColumnWidth('action_id', 100),
            id: "action_id",
            style: { 'whiteSpace': 'unset' },
            accessor: row => row && row.id
        },{
            Header: 'Hospital',
            Cell: row => (<span>{row.original.hospital && row.original.hospital.name}</span>),
            width: this.getColumnWidth('hospital', 180),
            id: "hospital",
            // accessor: "hospital"
            accessor: row => row.hospital && row.hospital.name
        },{
            Header: 'Doctor',
            Cell: row => (<span>{row.original.doctor && row.original.doctor.title + '. ' + row.original.doctor.first_name + ' ' + row.original.doctor.last_name}</span>),
            width: this.getColumnWidth('doctor', 180),
            id: "doctor",
            // accessor: "doctor"
            accessor: row => row.doctor && row.doctor.last_name

        },{
            Header: 'Procedures',
            // Cell: row => (console.log('row', row.original)),
            Cell: row => (<span>{row.original.procedures && row.original.procedures.map(procedure => procedure.hospital_procedures.map(item => <ul><li>{item.procedure_type}</li></ul>))}</span>),
            width: this.getColumnWidth('procedure', 250),
            id: "procedure",
            // accessor: "procedure"
            // accessor: row => row.procedures && row.procedures.map(procedure => procedure.hospital_procedures.map(item => item.procedure_type))
        },{
            Header: 'Slot',
            width: this.getColumnWidth('slot', 100),
            id: 'slot',
            Cell: row => (<span>{row.original && row.original.slot}</span>),
            accessor: row => row && row.slot
        },{
            Header: 'Day',
            Cell: row => (<span>{row.original && row.original.day}</span>),
            width: this.getColumnWidth('day', 150),
            id: "day",
            // accessor: "day"
            accessor: row => row && row.day, 
        },{
            Header: 'Week',
            Cell: row =>(<span>{row.original && row.original.week}</span>),
            width: this.getColumnWidth('week', 200),
            id: 'week',
            // accessor: 'week'
            accessor: row => row.week,
        },{
            Header: 'Start Date',
            Cell: row =>(<span>{row.original && (row.original.start_date ? row.original.start_date : "No Start Date")}</span>),
            width: this.getColumnWidth('start_date', 200),
            id: 'start_date',
            // accessor: 'start_date'
            accessor: row => row.start_date,
        },{
            Header: 'End Date',
            Cell: row =>(<span>{row.original && (row.original.end_date ? row.original.end_date : "No End Date")}</span>),
            width: this.getColumnWidth('end_date', 200),
            id: 'end_date',
            // accessor: 'end_date'
            accessor: row => row.end_date,
        },{
            Header: 'Status',
            width: 150,
            style: { 'whiteSpace': 'unset' },
            id: 'status',
            width: this.getColumnWidth('status', 150),
            // accessor: 'status',
            accessor: row => row.status,
            Cell: (row)  => {
                return (
                    <React.Fragment>
                        <div className="cell-text">
                        {
                            <Form.Check 
                                type="switch"
                                label=""
                                id={row.original.id}
                                onChange={() => this.onToggleStatus(row.original.id, row.original.status)}
                                checked={row.original.status}
                                className="center"
                                
                            />
                        }
                        {
                            <span className="center" style={{color: "red"}}>{row.original.status == 0 ? moment(row.original.end_date).format('YYYY-MM-DD') : ""}</span>
                        }
                        </div>
                    </React.Fragment>
                )
            }
        }];

        return (
            <div className="container-fluid booking-cycle-page">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col sm={3} md={6} lg={3}><h5 className="mb-2 font-weight-bold text-primary center">Booking Cycle List</h5></Col>
                            <Col sm={12} md={6} lg={3}>
                                <SearchField
                                    placeholder="Search ..."
                                    // onChange={this.handleSearch}
                                    onSearchClick={this.handleSearch}
                                    onEnter={this.handleSearch}
                                    classNames="mb-2 center"
                                    name="search_data"
                                    value={this.state.searched_data}
                                />
                            </Col>
                            <Col sm={12} md={12} lg={3}>
                                <Form.Check 
                                    type="switch"
                                    id="custom-switch"
                                    label="Show only INACTIVE Booking Cycles"
                                    onClick={this.onToggleInactiveBookingCycle}
                                    checked={this.state.toggleInactiveBookingCycle}
                                    value={this.state.toggleInactiveBookingCycle}
                                    className="center mb-2"
                                />
                            </Col>
                            <Col sm={12} md={12} lg={3}>
                                <Link to='/bookingcycleform'><button style={{width: '100%'}} type="submit" className="btn btn-primary center" onClick={this.handleAppointmentModal}><i class="far fa-calendar-check" /> Add Data</button></Link>
                            </Col>
                        </Row>
                    </div>
                    <div className="card-body">

                        {/* Modal for Delete Booking  */}
                        <Modal show={this.state.showDeleteModal} backdrop="static">
                            <Modal.Header onClick={this.hideDeleteModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/* <FormGroup> */}
                                    <p className="center" style={{textAlign: "center"}}>Are you sure you want to delete this Booking Cycle permanently?</p>
                                    {/* <Row className="center">
                                        <Col xs={4} sm={4} md={4} lg={3}><FormLabel style={{textAlign: "center"}}>End Date: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col xs={8} sm={8} md={8} lg={9}>
                                            <FormControl
                                                type="date"
                                                className="center"
                                                name="date"
                                                value={this.state.date}
                                                onChange={this.onChange}
                                            />
                                        </Col>
                                    </Row> */}
                                {/* </FormGroup> */}
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary center" onClick={this.hideDeleteModal}>
                                    Cancel
                                </button>
                                <button 
                                    className="btn btn-danger center" 
                                    value={this.state.currentId} 
                                    onClick={this.deleteHandler}
                                >Delete
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Update Booking Status */}
                        <Modal show={this.state.showUpdateBookingStatusModal} backdrop="static">
                            <Modal.Header onClick={this.hideDeleteModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormGroup>
                                    <p className="center" style={{textAlign: "center"}}>Are you sure you want to {this.state.currentStatus ? "remove" : "activate"}  this booking cycle?</p>
                                    <Row className="center">
                                        <Col xs={4} sm={4} md={4} lg={3}><FormLabel style={{textAlign: "center"}}>End Date: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col xs={8} sm={8} md={8} lg={9}>
                                            <FormControl
                                                type="date"
                                                className="center"
                                                name="date"
                                                value={this.state.date}
                                                onChange={this.onChange}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary center" onClick={this.hideDeleteModal}>
                                    Cancel
                                </button>
                                <button 
                                    className="btn btn-danger center" 
                                    value={this.state.currentId}
                                    onClick={this.updateStatusHandler}
                                >{this.state.currentStatus ? "Remove" : "Activate"}
                                </button>
                            </Modal.Footer>
                        </Modal>

                    {
                        this.state.isLoading ? (
                            <Loader
                                type="TailSpin"
                                color="#2850C2"
                                height={80}
                                width={80}
                                // timeout={10000} //3 secs
                            />
                        ) : (
                            <ReactTable
                                PaginationComponent={Pagination}
                                data={this.state.bookingCycle}
                                columns={columns}
                                minRows={0}
                                style={{overflow:'wrap'}}
                                className="center"   
                                getTrProps={this.getTrProps}
                                onResizedChange={this.getResizeValues}                   
                            />
                        )   
                    }
                   
                    </div>
                </div>                
            </div>
        );
    }
}

export default BookingCyclePage;