import React, { Component } from 'react';
import { Row, Col, Modal, FormControl, FormLabel, Table } from 'react-bootstrap';
import { request } from '../../constants/constants';

class TreatmentPerHospital extends Component {
    constructor(props){
        super(props);
        this.state = {
            showModal: false,
            showSuccessModal: false,
            hospitals: [],
            procedures: [],
            names: [],
            price: ''
        }  
    }
    
    componentDidMount(){

        request('GET', '/hospitals/Admin/adminHospitalListOnlyActive', {}, true).then(result => { 
            const hospitals = result.data.data.map(obj => ({ 
                id: obj.ID,
                name: obj.name
            }));
            this.setState({ hospitals });
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });

        request('GET', '/procedures/allProceduresOnlyActive', {}, true).then(result => {
            const procedures = result.data.data.map(obj => ({
                id: obj.ID,
                type: obj.procedure_type,
            }));
            this.setState({procedures});
        }).catch(err => {
            this.setState({
                isLoading: false,
                error: err.response
            })
        })

        request('GET', '/hospital_procedures/', {}, true).then(result => {
            console.log(result)
            const names = result.data.data.map(obj => ({ 
                procedure_type: obj.procedure_type,
                hospital: obj.hospital,
                price: obj.price,
                status: obj.status 
            }));
            
            let datas = names.sort((a, b) => (a.hospital.name > b.hospital.name) ? 1 : -1)

            this.setState({ 
                names: names 
            })
        }).catch(err => {
            this.setState({
                isLoading: false,
                error: err.response
            })
        })
    }

    /* Handle Functions */

    handleHospital = e => {
        this.setState({
            hospital_id: e.target.value
        })
    }

    handleProcedure = e => {
        this.setState({
            procedure_type: e.target.value
        })
    }

    handleAdd = e => {
        const data = {
            hospital_id: this.state.hospital_id,
            procedure_type: this.state.procedure_type,
            price: this.state.price,
            status: 1
        }

        request('POST', '/hospital_procedures/addRelationship', data, true).then(result => { 
            this.setState({
                isLoading: false,
                showModal: false,
                showSuccessModal: true
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data.message,
                responseCode: err.response.status
            })
        })

    }

    handleChange = e => {
        this.setState({ 
            [e.target.name]: e.target.value
        })
    }

    /* Modal Functions*/

    handleModal = e => {
        this.setState({ showModal: true })
    }
    
    hideModal = e => {
        this.setState({ showModal: false })
    }

    hideSuccessModal = e =>{
        this.setState({ showSuccessModal: false })
        window.location.href = "/treatmentperhospital";
    }
    
    render() {

        let current_name = null;

        return (
            <div class="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col><h5 className="m-0 font-weight-bold text-primary center">Treatment/Procedure per Hospitals</h5></Col>
                            <Col sm={3} style={{textAlign: "right"}}>
                                <button type="submit" className="btn btn-primary center" onClick={this.handleModal}><i class="far fa-calendar-check"></i> Add Procedure to Hospitals</button>
                            </Col>
                        </Row>
                    </div>
                    <div className="card-body">

                        {/* Modal for Adding of procedure to hospital */}
                        <Modal show={this.state.showModal} backdrop="static">
                        <Modal.Header onClick={this.hideModal} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Add Procedure to Hospital</h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className='center m-2'>
                                <Col sm={2}><FormLabel>Hospital: </FormLabel></Col>
                                <Col sm={7}>
                                    <FormControl
                                        as = "select"
                                        type="text"
                                        name="secretary"
                                        className="center"
                                        onChange={this.handleHospital}
                                    >
                                    <option value="-Select-">-Select-</option>
                                    {this.state.hospitals.map(hospital => (
                                        <option value={hospital.id}>{hospital.name}</option>
                                    ))}
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row className='center m-2'>
                                <Col sm={2}><FormLabel>Procedure: </FormLabel></Col>
                                <Col sm={7}>
                                    <FormControl
                                    as = "select"
                                    type="text"
                                    name="doctor"
                                    className="center"
                                    onChange={this.handleProcedure}
                                    >
                                    <option value="-Select-">-Select-</option>
                                    {this.state.procedures.map(procedure => (
                                        <option value={procedure.type}>{procedure.type}</option>
                                    ))}
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row className='center m-2'>
                                <Col sm={2}><FormLabel>Price: </FormLabel></Col>
                                <Col sm={7}>
                                    <FormControl
                                        type="number"
                                        name="price"
                                        className="center"
                                        step="0.01"
                                        value={this.state.price}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                            <Row className='center m-2'>
                                <p style={{color: '#FF0000', display: this.state.responseCode === 403 || 422 ? 'block' : 'none' }}>{this.state.error}</p>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary center" onClick={this.hideModal}>
                                Cancel
                            </button>
                            <button className="btn btn-primary center" onClick={this.handleAdd}>
                                Add Procedure
                            </button>
                        </Modal.Footer>
                        </Modal>

                        {/* Modal for Successfully Added */}
                        <Modal show={this.state.showSuccessModal} backdrop="static">
                            <Modal.Header onClick={this.hideSuccessModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                    <p>Successfully Added!</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideSuccessModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>                        

                        <div className="table-responsive center">
                            <Table striped bordered hover size="sm" className="center">
                                <thead>
                                    <tr>
                                        <th>Hospitals</th>
                                        <th>Procedures</th>
                                        <th>Prices</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.names.map((name, i) => {   
                                        if(name.hospital){
                                            if(name.hospital.status){
                                                if (current_name === null) {
                                                    //1st attempt
                                                    current_name = name.hospital.name;
                                                } else {
                                                    //after the 1st attemp all process will be here
                                                    if (current_name === name.hospital.name) {
                                                        return <tr>
                                                                    <td></td>  
                                                                    {/* <td>{name.procedure_type}</td>  
                                                                    <td>{name.price.toFixed(2)}</td>   */}
                                                                    <td style={{ color: name ? ( name.status ? 'black' : 'grey') : '' }}>{ name ? name.procedure_type : '-' }</td>
                                                                    <td style={{ color: name ? ( name.status ? 'black' : 'grey') : '' }}>{ name ? name.price.toFixed(2) : '-' }</td>
                                                            </tr>
                                                    } else {
                                                        current_name = name.hospital.name;
                                                    }
                                                }
                                                return <tr>
                                                    <td>{current_name}</td>
                                                    {/* <td>{name.procedure_type}</td> 
                                                    <td>{name.price.toFixed(2)}</td>   */}
                                                    <td style={{ color: name ? ( name.status ? 'black' : 'grey') : '' }}>{ name ? name.procedure_type : '-' }</td>
                                                    <td style={{ color: name ? ( name.status ? 'black' : 'grey') : '' }}>{ name ? name.price.toFixed(2) : '-' }</td>
                                                </tr>
                                            }
                                        }
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TreatmentPerHospital;