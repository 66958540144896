import React, { Component } from 'react';
import { FormLabel, FormControl, Col, Row } from "react-bootstrap";
import { Bar } from 'react-chartjs-2';
import { request } from '../../constants/constants';
import { Modal } from "react-bootstrap";
import './Reports.css'
import UserContext from '../../contexts/User/Index';


class BookingsByCategory extends Component {
    static contextType = UserContext;

    constructor(props){
        super(props);
        this.state = {
            doctors: [],
            hospitals: [],
            monthlyTotalBookings: [],
            doctorId: '',
            hospitalId: '',
            server_root: '',
            showPerDoctor: true,
            showPerHospital: false,
            showExportButton: false,
            showEmailSuccessSent: false,
        }
    }

    componentDidMount(){
        request('GET', '/doctors/allDoctorsByAdminOnlyActive', {}, true).then((result) => {
            const doctors = result.data.data.map((obj) => ({
                id: obj.ID,
                name: obj.title + ". " + obj.first_name + " " + obj.last_name,
                hospitals: obj.hospitals,
            }))
            this.setState({ doctors });
        })

        request('GET', '/hospitals/Admin/adminHospitalListOnlyActive', {}, true).then((result) => {
            const hospitals = result.data.data.map((obj) => ({
              id: obj.id,
              name: obj.name,
            }))
            this.setState({ hospitals })
        })

        request('GET', '/urls/get-server-root', {}, true).then((result) => {
            this.setState({
                server_root: result.data.data
            })
        })

        this.setState({
            current_user_email: this.context.user.user.email,
            current_name: this.context.user.user.first_name + ' ' + this.context.user.user.last_name
        })
    }

    reportByDoctor = () => {
        this.setState({
            monthlyTotalBookings: [],
            showPerDoctor: true,
            showPerHospital: false
        })
    }

    reportByHospital = () => {
        this.setState({
            monthlyTotalBookings: [],
            showPerDoctor: false,
            showPerHospital: true
        })
    }

    handleHospital = (e) => {
        const hospitalId =  e.target.value;

        if(hospitalId != '-Select-'){
            this.setState({
                showExportButton: true,
                hospitalId: hospitalId
            })
        } else {
            this.setState({
                showExportButton: false
            })
        }

        request('GET', `/report/hospital/monthly/getBookingReportByHospital/${hospitalId}`, {}, true).then((result) => {
            // console.log('getBookingReportByHospital', result)
            this.setState({
                monthlyTotalBookings: result.data.data,
                name:  result.data.hospital_name
            })
        }).catch((err) => {
            console.error(err.response);
        })

        this.setState({
            hospitalId: hospitalId
        })
    }

    handleDoctor = (e) => {
        const doctorId =  e.target.value;
        if(doctorId != '-Select-'){
            this.setState({
                showExportButton: true,
                doctorId: doctorId
            })
        } else {
            this.setState({
                showExportButton: false
            })
        }

        request('GET', `/report/doctor/monthly/getBookingReportByDoctor/${doctorId}`, {}, true).then((result) => {
            this.setState({
                monthlyTotalBookings: result.data.data,
                name: result.data.doctor_name
            })
        }).catch((err) => {
            console.error(err.response);
        })

        this.setState({
            doctorId: doctorId
        })
    }

    sendEmailReport = (format) => {

        const data = {
            user_email: this.state.current_user_email,
            user_name: this.state.current_name,
        }

        if(format == 'doctor'){
            request('POST', `/exporting/doctor/monthly/email-report/${this.state.doctorId}`, data, true).then((result) => {
                this.setState({
                    showEmailSuccessSent: true,
                    successMessage: result.data.message
                })
            }).catch((err) => {
                console.error(err.response);
                this.setState({
                    isLoading: false,
                    errorCode: err.response.status,
                    errorMessage: err.response.data.message,
                });
            });

        }

        if(format == 'hospital'){
            request('POST', `/exporting/hospital/monthly/email-report/${this.state.hospitalId}`, data, true).then((result) => {
                this.setState({
                    showEmailSuccessSent: true,
                    successMessage: result.data.message
                })
            }).catch((err) => {
                console.error(err.response);
                this.setState({
                    isLoading: false,
                    errorCode: err.response.status,
                    errorMessage: err.response.data.message,
                });
            });

        }

    }

    hideEmailholder = () => {
        this.setState({
            showEmailSuccessSent: false
        })
    }

    render() {

        const { monthlyTotalBookings } = this.state;

        const monthly_labels = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];

        const monthlyData = {
            labels: monthly_labels,
            datasets: [{
                label: 'Total Amount',
                data: monthlyTotalBookings,
                backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(201, 203, 207, 0.2)'
                ],
                borderColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)'
                ],
                borderWidth: 1
            }]
        }

        return (
            <div className="container-fluid p-3 p-md-5">
                <script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
                <div className="row justify-center mb-4">
                    <div className="col-sm-12 col-md-10 col-lg-12">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800"> Bookings Report Per Category</h1>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        {
                                            this.state.showPerDoctor && (
                                                <Row>
                                                    <Col lg={4} md={12} sm={12}>
                                                        <FormLabel className="center"> Doctor: </FormLabel>
                                                    </Col>
                                                    <Col lg={8} md={12} sm={12}>
                                                        <FormControl
                                                            as="select"
                                                            type="text"
                                                            name="doctor"
                                                            className="center"
                                                            onChange={this.handleDoctor}
                                                        >
                                                            <option value="-Select-">-Select-</option>
                                                            {this.state.doctors.map(doctor => ( 
                                                                <option value={doctor.id}> {doctor.name} </option>
                                                            ))}
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            )
                                        }

                                        {
                                            this.state.showPerHospital && (
                                                <Row>
                                                    <Col lg={4} md={12} sm={12}>
                                                        <FormLabel className="center"> Hospital: </FormLabel>
                                                    </Col>
                                                    <Col lg={8} md={12} sm={12}>
                                                        <FormControl
                                                            as = "select"
                                                            type="text"
                                                            name="hospital"
                                                            className="center"
                                                            onChange={this.handleHospital}
                                                        >   
                                                            <option value="-Select-">-Select-</option>
                                                            {this.state.hospitals.map(obj => (
                                                                <option value={obj.id} name={obj.name}> {obj.name} </option>                                                                                  
                                                            ))} 
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            )
                                        }

                                        <div className="dropdown no-arrow">
                                            <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400" />
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                                                <div className="dropdown-header">Show reports by: </div>
                                                <a className="dropdown-item" onClick={this.reportByDoctor}> Doctor </a>
                                                <a className="dropdown-item" onClick={this.reportByHospital}> Hospital </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="report-card-body">
                                        {/* Modal for showing notes in Calendar*/}
                                        <Modal show={this.state.showEmailSuccessSent} backdrop="static">
                                            <Modal.Header onClick={this.hideEmailholder} closeButton>
                                                <Modal.Title>
                                                    <h5 className="m-0 font-weight-bold text-danger">Confirmation:</h5>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <p className="center" style={{textAlign: 'center' }}>{ this.state.successMessage }</p>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button className="btn btn-primary center" onClick={this.hideEmailholder}>
                                                    Okay
                                                </button>
                                                
                                            </Modal.Footer>
                                        </Modal>
                                        <div className="chart-area">
                                            <article className="canvas-container">
                                                {
                                                    this.state.showExportButton && (
                                                        this.state.showPerDoctor ? (
                                                            <React.Fragment>
                                                                <a href={this.state.server_root + `/api/report/doctor/monthly/download-report/${this.state.doctorId}`}><button className="btn btn-primary mr-lg-5 float-right center"> Download as XLS</button></a>
                                                                <button className="btn btn-primary mr-2 float-right center" onClick={(e) => this.sendEmailReport('doctor')}> Send Email</button>                                                        
                                                            </React.Fragment>
                                                           ) : (
                                                            <React.Fragment> 
                                                                <a href={this.state.server_root + `/api/report/hospital/monthly/download-report/${this.state.hospitalId}`}><button className="btn btn-primary mr-lg-5 float-right center"> Download as XLS</button></a>
                                                                <button className="btn btn-primary mr-2 float-right center" onClick={(e) => this.sendEmailReport('hospital')}> Send Email</button>                                                        
                                                            </React.Fragment>
                                                        )
                                                    )
                                                }

                                                <Bar 
                                                    id='monthlyReport'
                                                    data={monthlyData}
                                                    width={100}
                                                    height={25}    
                                                    options={{ maintainAspectRatio: false }}                                        
                                                />
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BookingsByCategory;