import React, { Component } from 'react';
import './ResetPassword.css';
import { request } from '../../constants/constants';
import { FormGroup, FormControl, Button, Modal } from 'react-bootstrap';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            password_confirmation: ''                      

        };
    }

    doValidation(data) {
        let errors = {};
        if(!data.password) {
            errors.email = 'The password field is required.';
        }
        if(!data.password_confirmation) {
            errors.email = 'The password confirmation field is required.';
        }

        if(data.password_confirmation !== data.password) {
            errors.email = 'Password did not match.';
        }else{
            errors.email = 'SUCCESS';
        }
        
        return { errors };
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        const params = new URLSearchParams(document.location.search);
        const token = params.get('token');
        const data = {
            password: this.state.password,
            password_confirmation: this.state.password,
            token: token
        }
        const validationResult = this.doValidation(data);
        if(validationResult.errors.email == "SUCCESS"){
            request('POST', '/auth/resetpassword', data, false).then(result => {
                window.location.href = "/login";
            })
        }
    }

    render() {
        return (
            <div className="LoginForm">

                <form className="p-5" onSubmit={this.handleSubmit}> 
                    <div className="login-container">
                        <h6 className="login-h6">Please enter your new password</h6>
                        <FormGroup>
                            <label className="login-label">New password</label>
                            <FormControl
                                name="password" 
                                value={this.state.value} 
                                onChange={this.handleChange} 
                                type="password" 
                                className="form-control" 
                                id="password" 
                                placeholder="New password"
                            />
                        </FormGroup>
                        <FormGroup>
                            <label className="login-label">Confirm New Password</label>
                            <FormControl
                                value={this.state.password_confirmation} 
                                onChange={this.handleChange} 
                                name="password_confirmation" 
                                type="password" 
                                className="form-control" 
                                id="password_confirmation" 
                                placeholder="Confirm password"
                            />
                        </FormGroup>
                        <button type="submit"  className="btn btn-primary btn-block login-btn"> UPDATE </button>
                    </div>

                </form>
       
            </div>
        );
    }
}

export default ResetPassword;