import React, { Component } from 'react';
import UserContext from '../../contexts/User/Index';
import { Route , Redirect} from 'react-router-dom';
import { Modal } from 'react-bootstrap';

class SecAndAdminRoute extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            showUnauthorisedModal: false
        }
    }

    componentDidMount(){
        if(this.context.user.user.permission == "staff"){
            this.setState({
                showUnauthorisedModal: true
            })
        }
        // console.log("this.context.user")
    }

    hideModal = () => {
        this.setState({
            showUnauthorisedModal: false
        })
    }


    render() {
        if (this.context.user && this.context.user.user.permission != "staff") {
            return <Route {...this.props}></Route>
        } 
        return (
            <div>
                {/* Modal for Unauthorised*/}
                <Modal show={this.state.showUnauthorisedModal}>
                    <Modal.Header onClick={this.hideModal} closeButton>
                        <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Warning: </h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="center" style={{ textAlign: "center"}}>You don't have access to this page</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary center" onClick={this.hideModal}>
                            OK
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
       
    }
}

export default SecAndAdminRoute;