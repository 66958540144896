import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { request } from '../../constants/constants';
import './Reports.css'
import { Modal } from "react-bootstrap";
import UserContext from '../../contexts/User/Index';

class PatientBookingsReport extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            showDailyReport: true,
            showWeeklyReport: false,
            showMonthlyReport: false,
            showYearlyReport: false,
            showEmailSuccessSent: false,
            successMessage: '',
            dailyBookingsCount: []
        }
    }

    componentDidMount(){
        request('GET', '/report/day/getReportPerDay', {}, true).then((result) => {
            // console.log('result', result)
            this.setState({
                dailyBookingsCount: result.data.count,
                dailyDate: result.data.week
            })
        }).catch((err) => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                errorCode: err.response.status,
                errorMessage: err.response.data.message,
            });
        });

        request('GET', '/urls/get-server-root', {}, true).then((result) => {
            this.setState({
                server_root: result.data.data
            })
        })

        this.setState({
            current_user_email: this.context.user.user.email,
            current_name: this.context.user.user.first_name + ' ' + this.context.user.user.last_name
        })

    }

    reportsByDay = () => {
        this.setState({
            showDailyReport: true,
            showWeeklyReport: false,
            showMonthlyReport: false,
            showYearlyReport: false
        })
    }

    reportsByWeek = () => {
        request('GET', '/report/week/getReportPerWeek', {}, true).then((result) => {
            this.setState({
                weeklyBookingsCount: result.data.countBookings,
                weeklyName: result.data.data
            })
        }).catch((err) => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                errorCode: err.response.status,
                errorMessage: err.response.data.message,
            });
        });

        this.setState({
            showDailyReport: false,
            showWeeklyReport: true,
            showMonthlyReport: false,
            showYearlyReport: false
        })
    }

    reportsByMonth = () =>{

        request('GET', '/report/month/getReportPerMonth', {}, true).then((result) => {
            this.setState({
                monthlyBookingsCount: result.data.data,
            })
        }).catch((err) => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                errorCode: err.response.status,
                errorMessage: err.response.data.message,
            });
        });

        this.setState({
            showDailyReport: false,
            showWeeklyReport: false,
            showMonthlyReport: true,
            showYearlyReport: false
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    } 

    hideEmailholder = () => {
        this.setState({
            showEmailSuccessSent: false
        })
    }

    fileHandler = (e) => {
        
        this.setState({
            setSelectedFile: e.target.files[0],
            setIsSelected: true
        })
       
    }

    sendEmailDailyReport = () => {
        const data = {
            user_email: this.state.current_user_email,
            user_name: this.state.current_name
        }

        request('POST', `/exporting/daily/email-report`, data, true).then((result) => {
            this.setState({
                showEmailSuccessSent: true,
                successMessage: result.data.message
            })
        }).catch((err) => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                errorCode: err.response.status,
                errorMessage: err.response.data.message,
            });
        });

    }

    sendEmailWeeklyReport = () => {
        const data = {
            user_email: this.state.current_user_email,
            user_name: this.state.current_name
        }

        request('POST', `/exporting/weekly/email-report`, data, true).then((result) => {
            this.setState({
                showEmailSuccessSent: true,
                successMessage: result.data.message
            })
        }).catch((err) => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                errorCode: err.response.status,
                errorMessage: err.response.data.message,
            });
        });

    }

    sendEmailMonthlyReport = () => {

        const data = {
            user_email: this.state.current_user_email,
            user_name: this.state.current_name
        }

        request('POST', `/exporting/monthly/email-report`, data, true).then((result) => {
            this.setState({
                showEmailSuccessSent: true,
                successMessage: result.data.message
            })
        }).catch((err) => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                errorCode: err.response.status,
                errorMessage: err.response.data.message,
            });
        });
        
    }


    render() {
        const { dailyBookingsCount, dailyDate, weeklyBookingsCount, weeklyName, monthlyBookingsCount} = this.state;


        const monthly_labels = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];

        const weekly_labels = weeklyName;

        const daily_labels = dailyDate; 

        const monthlyData = {
            labels: monthly_labels,
            datasets: [{
                label: 'Total Bookings',
                data: monthlyBookingsCount,
                backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(201, 203, 207, 0.2)'
                ],
                borderColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)'
                ],
                borderWidth: 1
            }]
        }

        this.state.monthly_labels = monthly_labels;

        const dailyData = {
            labels: daily_labels,
            datasets: [{
                label: 'Total Bookings',
                data: dailyBookingsCount,
                backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(201, 203, 207, 0.2)'
                ],
                borderColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)'
                ],
                borderWidth: 1
            }]
        }       

        const weeklyData = {
            labels: weekly_labels,
            datasets: [{
                label: 'Total Bookings',
                data: weeklyBookingsCount,
                backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(201, 203, 207, 0.2)'
                ],
                borderColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)'
                ],
                borderWidth: 1
            }]
        }

        const { showWeeklyReport, showMonthlyReport, showDailyReport, } = this.state;

        
        return (
            <div className="container-fluid p-3 p-md-5">
                <script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
                <div className="row justify-center mb-4">
                    <div className="col-sm-12 col-md-10 col-lg-12">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800"> Patient Bookings Report</h1>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Latest Bookings</h6>
                                        <div className="dropdown no-arrow">
                                            {/* <Link to="/export-excel"><i class="fas fa-file-export mr-lg-5"> Export </i></Link> */}

                                            <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400" />
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                                                <div className="dropdown-header">Show reports by: </div>
                                                <a className="dropdown-item" onClick={this.reportsByDay}> Day </a>
                                                <a className="dropdown-item" onClick={this.reportsByWeek}> Week </a>
                                                <a className="dropdown-item" onClick={this.reportsByMonth}> Month </a>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="report-card-body">
                                        {/* Modal for showing notes in Calendar*/}
                                        <Modal show={this.state.showEmailSuccessSent} backdrop="static">
                                        <Modal.Header onClick={this.hideEmailholder} closeButton>
                                            <Modal.Title>
                                                <h5 className="m-0 font-weight-bold text-danger">Confirmation:</h5>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                           <p className="center" style={{textAlign: 'center' }}>{ this.state.successMessage }</p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-primary center" onClick={this.hideEmailholder}>
                                                Okay
                                            </button>
                                            
                                        </Modal.Footer>
                                        </Modal>


                                        <div className="chart-area">
                                            <article className="canvas-container">
                                                {
                                                    showMonthlyReport && (
                                                        <React.Fragment>
                                                            <a href={this.state.server_root + '/api/report/month/download-report'}><button className="btn btn-primary mr-lg-5 float-right center"> Download as XLS</button></a>
                                                            <button className="btn btn-primary mr-2 float-right center" onClick={this.sendEmailMonthlyReport}>Send Email</button>
                                                        </React.Fragment>
                                                    )
                                                }

                                                {
                                                    showMonthlyReport && (
                                                        <Bar 
                                                            id='monthlyReport'
                                                            data={monthlyData}
                                                            width={100}
                                                            height={25}    
                                                            options={{ maintainAspectRatio: false }}                                        
                                                        />
                                                    )
                                                }

                                                {
                                                    showWeeklyReport && (
                                                        <React.Fragment>
                                                            <a href={this.state.server_root + '/api/report/week/download-report'}><button className="btn btn-primary mr-lg-5 float-right center"> Download as XLS</button></a>
                                                            <button className="btn btn-primary mr-2 float-right center" onClick={this.sendEmailWeeklyReport}>Send Email</button>
                                                        </React.Fragment>
                                                    )
                                                }

                                                {
                                                    showWeeklyReport && (
                                                        <Bar 
                                                            id='weeklyReport'
                                                            data={weeklyData}
                                                            width={100}
                                                            height={25}    
                                                            options={{ maintainAspectRatio: false }}                                        
                                                        />
                                                    )
                                                }

                                                {
                                                    showDailyReport && (
                                                        <React.Fragment>
                                                            <a href={this.state.server_root + '/api/report/day/download-report'}><button className="btn btn-primary mr-lg-5 mr-sm-0 float-right center"> Download as XLS</button></a>
                                                            <button className="btn btn-primary mr-2 float-right center" onClick={this.sendEmailDailyReport}> Send Email</button>
                                                        </React.Fragment>
                                                    )
                                                }

                                                {
                                                    showDailyReport && (
                                                        <Bar 
                                                            id='dailyReport'
                                                            data={dailyData}
                                                            width={100}
                                                            height={25}    
                                                            options={{ maintainAspectRatio: false }}                                        
                                                        />
                                                    )
                                                }
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PatientBookingsReport;