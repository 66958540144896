import React, { Component } from 'react';
import { Container, FormGroup, Row, Col, Button } from 'reactstrap';
import { FormControl, FormLabel, Modal } from 'react-bootstrap';
import Calendar from 'react-calendar';
import { request } from '../../constants/constants';
import moment from 'moment';
import { Multiselect } from 'multiselect-react-dropdown';

class BookingCycleOffListForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            doctors: [],
            hospitals: [],
            procedures: [],
            slot: '',
            status: '',
            showSuccessModal: false,
            showError: false,
            date: ''
        }
    }

    componentDidMount(){
        request('GET', '/doctors/allDoctorsByAdminOnlyActive', {}, true).then(result => { 
            const doctors = result.data.data.map(obj => ({
                id: obj.ID,
                name: obj.title + '. ' + obj.first_name + ' ' + obj.last_name,
                hospitals: obj.hospitals
            }));
            this.setState({ doctors }); 
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });   
    }

    handleDoctor = e => {
        const id = e.target.value;
        if(id=="-Select-"){
            return false
        }
        const index = this.state.doctors.findIndex(doctor => doctor.id == id);
        const doctor = this.state.doctors[index];
        this.setState({
            hospitals: doctor.hospitals,
            doctor_id: id
        })
    }

    onChange = (e) =>{ 
        this.setState({ 
            date: e.target.value 
        }) 
    }

    handleChange = e => {
        const val = e.target.value;
        if(val === "-Select-"){
            return false;
        }
        this.setState({
            [e.target.name]: val
        })
    }

    handleHospital = e => {
        console.log(e.target.value)

        const hospitalId = e.target.value; 
        if(hospitalId==="-Select-"){
            return false
        }
        this.setState({
            hospital_id: hospitalId
        })

        request('GET', `/hospital_procedures/${hospitalId}`, {}, true).then(result => {
            const hospitalProc = result.data.data.map(procedure => ({
                id: procedure.procedure_id,
                name: procedure.procedure_type
            }));
            this.setState({
                procedures: hospitalProc
            });
            // console.log(this.state.procedures)
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoading: false,
                error: err.response
            })
        })
    }

    onSelect = (selectedList, selectedItem) => {
        this.setState({
            selectedProcedures: selectedList
        })
    }

    onRemove = (selectedList, removedItem) => {
        this.setState({
            selectedProcedures: selectedList
        })
    } 

    handleFormSubmit = e => {
        e.preventDefault(); 
        const data =  { 
            doctor_id: this.state.doctor_id,
            hospital_id: this.state.hospital_id,
            procedure_id: this.state.selectedProcedures,
            date: moment(this.state.date).format('YYYY-MM-DD'),
            slot: this.state.slot,
            status: this.state.status
        }

        request('POST', '/booking_cycle_off_list/', data, true).then(res => {
            this.setState({
                isLoading: false, 
                showSuccessModal: true,
                successMessage: "Booking Cycle Off List Data Successfully Added!"
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                errorData: err.response.data.errors,
                errorMessage: err.response.data.message,
                showError: true,
            })
        })

    }

    /* Modal Functions*/
    hideModalSuccess = () => {
        this.setState({ showSuccessModal: false })
        window.location.href = "/bookingcycleofflist";
    }

    hideModalError = () => {
        this.setState({ showError: false })
    }

    render() {
        const mutiselectStyle = {
            option: { 
                color: "black",
            },
          };
        return (
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Container>
                            <Row>
                                <Col><h5 className="m-0 font-weight-bold text-primary center">Booking Cycle Off List Form</h5></Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="card-body">
                        {/* Modal for Successfully Added  */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideModalSuccess} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation!</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>{this.state.successMessage}</p>     
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideModalSuccess}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for ERROR  */}
                        <Modal show={this.state.showError}>
                            <Modal.Header onClick={this.hideModalError} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <p className="center" style={{textAlign: "center"}}>{this.state.errorMessage}</p>     
                            {
                                this.state.errorData &&  Object.keys(this.state.errorData).map(key => <p className="center" style={{textAlign: "center"}}>{this.state.errorData[key]}</p>)
                            } 
                            
                                                                   
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={this.hideModalError}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <form onSubmit={this.handleFormSubmit}>
                            <Container>
                                <FormGroup>
                                    <Row className="center">
                                        <Col md={12} lg={2}><FormLabel>Doctor: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col md={12} lg={4} sm={4}>
                                            <FormControl
                                                as="select"
                                                type="text"
                                                name="doctor"
                                                onChange={this.handleDoctor}
                                                className="center"
                                            >
                                                <option value="-Select-">-Select-</option>
                                                {this.state.doctors.map(doctor => ( 
                                                    <option value={doctor.id}> {doctor.name} </option>
                                                ))}
                                            </FormControl>
                                        </Col>
                                        <Col md={12} sm={2} lg={2}><FormLabel className="mt-md-2 mt-lg-0">Procedure: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col md={12} sm={4} lg={4}>
                                            <Multiselect
                                                options={this.state.procedures} 
                                                selectedValues={this.state.selectedValue} 
                                                onSelect={this.onSelect} 
                                                onRemove={this.onRemove} 
                                                displayValue="name"
                                                className="center"
                                                style={mutiselectStyle}
                                                avoidHighlightFirstOption="true"
                                            />
                                        </Col>
                                        
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row className="center">
                                        <Col md={12} lg={2}><FormLabel>Hospital: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col md={12} lg={4} sm={4}>
                                            <FormControl
                                                as="select"
                                                type="text"
                                                name="hospital"
                                                onChange={this.handleHospital}
                                                className="center"
                                            >
                                                <option value="-Select-">-Select-</option>
                                                {this.state.hospitals.map(hospital => ( 
                                                    (hospital.pivot.deleted_at ? "" : <option value={hospital.id}> {hospital.name} </option>)
                                                ))}
                                            </FormControl>
                                        </Col>
                                        <Col md={12} lg={2}><FormLabel className="mt-md-2 mt-lg-0">Slot: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col md={12} lg={4} sm={4}>
                                            <FormControl
                                                as="select"
                                                type="text"
                                                name="slot"
                                                onChange={this.handleChange}
                                                className="center"
                                            >
                                                <option value="-Select-">-Select-</option>
                                                <option value="AM">AM</option>
                                                <option value="PM">PM</option>                                           
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row className="center">
                                        <Col md={12} sm={2} lg={2}><FormLabel>Date: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col md={12} sm={4} lg={4}>
                                            <FormControl
                                                type="date"
                                                className="center"
                                                name="date"
                                                value={this.state.date}
                                                onChange={this.onChange}
                                            />
                                        </Col>
                                        <Col md={12} lg={2}><FormLabel className="mt-md-2  mt-lg-0">Status: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col md={12} lg={4} sm={4}>
                                            <FormControl
                                                as="select"
                                                type="text"
                                                name="status"
                                                onChange={this.handleChange}
                                                className="center"
                                            >
                                                <option value="-Select-">-Select-</option>
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row className="center">
                                        <Col sm={12} md={12} lg={2}><button style={{ width: '100%'}} type="submit" className="btn btn-primary mt-4 center" >Submit</button></Col>
                                    </Row>
                                </FormGroup>
                            </Container>
                        </form>
                    </div>
                </div>                
            </div>
        );
    }
}

export default BookingCycleOffListForm;