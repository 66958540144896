import React, { Component } from 'react';
import { FormGroup, Col, FormControl, Row, FormLabel, Container } from 'react-bootstrap';
import { request } from '../../constants/constants';

class PatientEdit extends Component {
    constructor (props){
        super(props);
        this.state = {
            id: this.props.match.params.id
        }
    }

    getPatient() {
        console.log(this.state.id);
        return request('GET', `/patients/${this.state.id}`, {}, true);
    }

    init (){
        this.getPatient().then(res => {
            console.log(res);
            this.setState({
                title: res.data.data.title,
                first_name: res.data.data.first_name,
                last_name: res.data.data.last_name,
                second_name: res.data.data.second_name,
                date_of_birth: res.data.data.date_of_birth,
                address_1: res.data.data.address_1,
                address_2: res.data.data.address_2,
                suburb: res.data.data.suburb,
                state: res.data.data.state,
                post_code: res.data.data.post_code
            })
        })
    }

    componentDidMount() {
        this.init()
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
    }



    render() {
        return (
            <div class="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">PATIENT EDIT FORM</h6>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <form onSubmit={this.handleFormSubmit}>
                                <Container>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={1}><FormLabel>Title: </FormLabel></Col>
                                            <Col sm={3}>
                                                <FormControl
                                                    as="select"
                                                    type="text"
                                                    name="title"
                                                    className="center"
                                                    value={this.state.title}
                                                    onChange={this.handleChange}
                                                >
                                                    <option>Dr</option>
                                                    <option>Miss</option>
                                                    <option>Mrs</option>
                                                    <option>Ms</option>
                                                    <option>Mr</option>
                                                </FormControl>
                                            </Col>
                                            <Col lg={2}><FormLabel>Date of Birth: </FormLabel></Col>
                                            <Col sm={4}>
                                                <FormControl
                                                    type="date"
                                                    className="center"
                                                    name="date_of_birth"
                                                    value={this.state.date_of_birth}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <hr/>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col sm={1}><FormLabel>First Name: </FormLabel></Col>
                                            <Col sm={3}>
                                                <FormControl
                                                    type="text"
                                                    name="first_name"
                                                    className="center"
                                                    value={this.state.first_name}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col sm={1}><FormLabel>Middle Name: </FormLabel></Col>
                                            <Col sm={3}>
                                                <FormControl
                                                    type="text"
                                                    name="second_name"
                                                    className="center"
                                                    value={this.state.second_name}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col sm={1}><FormLabel>Last Name: </FormLabel></Col>
                                            <Col sm={3}>
                                                <FormControl
                                                    type="text"
                                                    name="last_name"
                                                    className="center"
                                                    value={this.state.last_name}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <hr/>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col sm={2}><FormLabel>Address 1: </FormLabel></Col>
                                            <Col sm={4}>
                                                <FormControl
                                                    type="text"
                                                    name="address_1"
                                                    className="center"
                                                    value={this.state.address_1}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col sm={2}><FormLabel>Address 2: </FormLabel></Col>
                                            <Col sm={4}>
                                                <FormControl
                                                    type="text"
                                                    name="address_2"
                                                    className="center"
                                                    value={this.state.address_2}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col sm={1}><FormLabel>Suburb: </FormLabel></Col>
                                            <Col sm={3}>
                                                <FormControl
                                                    type="text"
                                                    name="suburb"
                                                    className="center"
                                                    value={this.state.suburb}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col sm={1}><FormLabel>State: </FormLabel></Col>
                                            <Col sm={3}>
                                                <FormControl
                                                    as="select"
                                                    type="text"
                                                    name="state"
                                                    className="center"
                                                    value={this.state.state}
                                                    onChange={this.handleChange}
                                                >
                                                    <option>ACT</option>
                                                    <option>NSW</option>
                                                    <option>VIC</option>
                                                    <option>TAS</option>
                                                    <option>QLD</option>
                                                    <option>SA</option>
                                                    <option>WA</option>
                                                    <option>NT</option>
                                                </FormControl>
                                            </Col>
                                            <Col sm={1}><FormLabel>Post Code: </FormLabel></Col>
                                            <Col sm={3}>
                                                <FormControl
                                                    type="text"
                                                    name="post_code"
                                                    className="center"
                                                    value={this.state.post_code}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <Row className="center">
                                        <Col><button type="submit" className="btn mt-4 btn-primary" variant="primary">Update</button></Col>
                                    </Row>
                                </Container>
                            </form>
                        </div>
                    </div>
                </div>                
            </div>
        );
    }
}

export default PatientEdit;