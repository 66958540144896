import React, { Component } from 'react';
import "./main-menu-style.css";
import logo from "../assets/images/alexus-logo.png";
import bgCounter from "../assets/images/work-3.jpg";
import alexusServicesImage from "../assets/images/alexus-services.png";
import { request } from '../constants/constants';
import $ from 'jquery';

class MainMenu extends Component {
    constructor(props){
        super(props);
        this.state = {
            total_patients: 0,
            total_doctors: 0,
            total_hospitals: 0,
            total_bookings: 0
        }
    }

    componentDidMount(){
        request('GET', '/get_total/get-total-patients', {}, true).then(result => {
            console.log(result)
            this.setState({
                total_patients: result.data.data
            })
        }).catch(err => { 
            console.error(err.response);
        });

        request('GET', '/get_total/get-total-hospitals', {}, true).then(result => {
            console.log(result)
            this.setState({
                total_hospitals: result.data.data
            })
        }).catch(err => { 
            console.error(err.response);
        });

        request('GET', '/get_total/get-total-doctors', {}, true).then(result => {
            console.log(result)
            this.setState({
                total_doctors: result.data.data
            })
        }).catch(err => { 
            console.error(err.response);
        });

        request('GET', '/get_total/get-total-bookings', {}, true).then(result => {
            console.log(result)
            this.setState({
                total_bookings: result.data.data
            })
        }).catch(err => { 
            console.error(err.response);
        });

        $( document ).ready(function() {
            $("#accordionSidebar").hide();
        });

        

    }


    render() {
        return (
            <React.Fragment>
                {/* HEADER */}
                <header id="header" className="fixed-top">
                    <div className="container align-items-center justify-content-between" id="navbar-div">
                        <a href="/index" className="logo-new-style p-3"><img src={logo} alt="" className="img-fluid" /></a>
                        <nav className="navbar navbar-expand-lg">
                            <a className="navbar-brand" id="navbar-brand" href="/index">Alexus Services</a>
                            <button className="navbar-toggler mobile-nav-toggle" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa fa-bars"/>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                                <div className="navbar-nav">
                                    <a className="nav-link scrollto active mr-4" href="#hero">Home</a> <span className="nav-divider mr-15px clr-white"> | </span>
                                    <a className="nav-link scrollto mr-4" href="#about">About Us</a> <span className="nav-divider mr-15px clr-white"> | </span>
                                    <a className="nav-link scrollto mr-4" href="#services">Lithotripsy</a> <span className="nav-divider mr-15px clr-white"> | </span>
                                    <a className="nav-link scrollto mr-4" href="#work">New Customer</a> <span className="nav-divider mr-15px clr-white"> | </span>
                                    <a className="nav-link scrollto mr-4" href="https://dev.alexus.com.au/login" target="_blank">Online Booking</a> <span className="nav-divider mr-15px clr-white"> | </span>
                                    <a className="nav-link scrollto mr-4" href="#contact">Contact Us</a>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
                {/* End of HEADER */}

                {/* Hero Section */}
                <div id="hero" className="hero route bg-image" style={{backgroundColor: '#2c4cc4'}}>
                {/* '#2c4cc4' */}
                {/* <div id="hero" className="hero route bg-image" style={{backgroundImage: `url(${bgOverlay})`}}> */}
                    <div className="overlay-itro" />
                        <div className="hero-content display-table">
                        <div className="table-cell">
                            <div className="container">
                                {/*<p class="display-6 color-d">Hello, world!</p>*/}
                                <h1 className="hero-title mb-4">ALEXUS SERVICES</h1>
                                {/* <p class="hero-subtitle"><span class="typed" data-typed-items="Designer, Developer, Freelancer, Photographer"></span></p> */}
                                {/* <p class="pt-3"><a class="btn btn-primary btn js-scroll px-4" href="#about" role="button">Learn More</a></p> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Hero Section */}

                {/* About Us */}
                <section id="about" className="about-mf sect-pt6 route">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="box-shadow-full text-center">
                                    <div>
                                        <div className="about-me pt-4 pt-md-0">
                                            <div className="title-box-2">
                                                <h5 className="title-left">
                                                    About Us
                                                </h5>
                                            </div>
                                            {/* <p class="lead">
                                            Alexus Services Pty. Ltd. Profile
                                        </p> */}
                                            <p className="lead" style={{fontStyle: 'italic'}}>
                                            more about us and what we do - coming soon
                                            </p>
                                            <img src={alexusServicesImage} alt="" className="img-fluid center" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End About Section */}

                {/* LITHOTRIPSY  */}
                <section id="services" className="services-mf sect-pt6 route">
                    <div className="container mb-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="title-box text-center">
                                    <h3 className="title-a">
                                    Lithotripsy
                                    </h3>
                                    <p className="subtitle-a">
                                    Procedure Overview
                                    </p>
                                    <div className="line-mf" />
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <h5> What is Lithotripsy? </h5>
                            <p className="lead">
                                Lithotripsy is a noninvasive (the skin is not pierced) procedure used to treat kidney stones that are too large to pass through the urinary tract. Lithotripsy treats kidney stones by sending focused ultrasonic energy or shock waves directly to the stone first located with fluoroscopy (a type of x-ray “movie”) or ultrasound (high frequency sound waves). The shock waves break a large stone into smaller stones that will pass through the urinary system. Lithotripsy allows persons with certain types of stones in the urinary system to avoid an invasive surgical procedure for stone removal. It should be noted that lithotripsy is not suitable for all types of kidney stones.
                            </p>
                            <p className="lead">
                                There are two types of shock wave technology. The original lithotripsy machines sent the shock waves through water in a tub in which the person being treated was placed. This technology remains in use today. More recently, machines have been developed that send shock waves through padded cushions on a table, so the procedure does not involve immersing a person in water. Alexus services uses the more recent machine.
                            </p>
                            <p className="lead">
                                Other procedures that may be used to treat kidney stones include:
                            </p>
                            <ul className="lead">
                                <li>
                                    <b>urethroscopy or ureteroscopy</b> - endoscopic procedures in which stones in the urethra or ureter may be removed with a device inserted through a short, flexible or rigid, lighted tube, called an endoscope or cystoscope.
                                </li>
                                <li>
                                    <b>percutaneous nephrolithotomy (tunnel surgery)</b> - a surgical procedure for stones which cannot be treated with lithotripsy or endoscopic procedures. It involves the removal of a stone through a thin tube tunneled through a small incision in the back into the kidney.
                                </li>
                                <li>
                                    <b>open surgery</b> - a more invasive surgical procedure using a larger incision to directly access the stone
                                </li>
                                <li>
                                    <b>stent</b> - a synthetic, tubular device that may be used along with other procedures. A stent may be inserted through a special scope into the urinary tract to allow stones to pass more easily.
                                </li>
                            </ul>
                            <h5 className="mb-3 mt-3">
                                About kidney stones:
                            </h5>
                            <p className="lead">
                                When substances that are normally excreted through the kidneys remain in the urinary tract, they may crystallize and harden into a kidney stone. If the stones break free of the kidney, they can travel through, and get lodged in, the narrower passages of the urinary tract. Some kidney stones are small or smooth enough to pass easily through the urinary tract without discomfort. Other stones may have rough edges or grow as large as a pea causing extreme pain as they travel through or become lodged in the urinary tract. The areas most prone to trapping kidney stones are the bladder, ureters, and urethra.
                            </p>
                            <p className="lead">
                                Most kidney stones that develop are small enough to pass without intervention. However, in about 20 percent of cases the stone is greater than 2 centimeters (about one inch) and may require treatment. Most kidney stones are composed of calcium; however, there are other types of kidney stones that can develop. Types of kidney stones include:
                            </p>
                            <ul className="lead">
                                <li>
                                    <b>calcium stones</b><br />
                                    Calcium, a normal part of a healthy diet used in bones and muscles is normally flushed out with the rest of the urine. However, excess calcium not used by the body may combine with other waste products to form a stone.
                                </li>
                                <li>
                                    <b>struvite stones</b><br />
                                    Struvite stones, composed of magnesium, phosphate, and ammonia may occur after a urinary tract infection.
                                </li>
                                <li>
                                    <b>uric acid stone</b><br />
                                    Uric acid stones may occur when urine is too acidic, as in certain conditions such as gout or malignancies.
                                </li>
                            <li>
                                <b>cystine stones</b><br />
                                Cystine stones consist of cystine, one of the building blocks that make up muscles, nerves, and other parts of the body.
                            </li>
                            </ul>
                            <h5 className="mb-3 mt-3">
                                How does the urinary system work?
                            </h5>
                            <p className="lead">
                                The body takes nutrients from food and converts them to energy. After the body has taken the food that it needs, waste products are left behind in the bowel and in the blood.
                            </p>
                            <p className="lead">
                                The urinary system keeps chemicals, such as potassium and sodium, and water in balance by removing a type of waste, called urea, from the blood. Urea is produced when foods containing protein, such as meat, poultry, and certain vegetables, are broken down in the body. Urea is carried in the bloodstream to the kidneys.
                            </p>
                            <h5 className="mb-3 mt-3">
                                Urinary system parts and their functions:
                            </h5>
                            <p className="lead"><b>two kidneys</b> -  a pair of purplish-brown organs located below the ribs toward the middle of the back. Their function is to:
                            </p>
                            <ul className="lead">
                                <li>remove liquid waste from the blood in the form of urine</li>
                                <li>keep a stable balance of salts and other substances in the blood</li>
                                <li>produce erythropoietin, a hormone that aids the formation of red blood cells</li>
                            </ul>
                            <p className="lead">
                                The kidneys remove urea from the blood through tiny filtering units called nephrons. Each nephron consists of a ball formed of small blood capillaries, called a glomerulus, and a small tube called a renal tubule. Urea, together with water and other waste substances, forms the urine as it passes through the nephrons and down the renal tubules of the kidney.
                            </p>
                            <p className="lead"><b>two ureters</b> -  narrow tubes that carry urine from the kidneys to the bladder. Muscles in the ureter walls continually tighten and relax forcing urine downward, away from the kidneys. If urine backs up, or is allowed to stand still, a kidney infection can develop. About every 10 to 15 seconds, small amounts of urine are emptied into the bladder from the ureters.</p>
                            <p className="lead"><b>bladder</b> - a triangle-shaped, hollow organ located in the lower abdomen. It is held in place by ligaments that are attached to other organs and the pelvic bones. The bladder's walls relax and expand to store urine, and contract and flatten to empty urine through the urethra. The typical healthy adult bladder can store up to two cups of urine for two to five hours.
                            </p>
                            <p className="lead"><b>urethra </b> - the tube that allows urine to pass outside the body. The brain signals the bladder muscles to tighten, which squeezes urine out of the bladder. At the same time, the brain signals the sphincter muscles to relax to let urine exit the bladder through the urethra. When all the signals occur in the correct order, normal urination occurs.
                            </p>
                            <h4 className="mb-3 mt-3">
                            Reasons for the Procedure
                            </h4>
                            <p className="lead">
                                When kidney stones become too large to pass through the urinary tract, they may cause severe pain and may also block the flow of urine. An infection may develop. Lithotripsy may be performed to treat certain types of kidney stones in certain locations within the urinary tract.
                            </p>
                            <p className="lead">
                                There may be other reasons for your physician to recommend lithotripsy. If you are unsure you should consult your physician.
                            </p>
                            <h4 className="mb-3 mt-3">
                                Risks of the Procedure
                            </h4>
                            <p className="lead">
                                You may want to ask your physician about the amount of radiation used during the procedure and the risks related to your particular situation. It is a good idea to keep a record of your past history of radiation exposure, such as previous scans and other types of x-rays, so that you can inform your physician. Risks associated with radiation exposure may be related to the cumulative number of x-ray examinations and/or treatments over a long period of time. Alexus services will use Ultrasound to pinpoint the stone wherever possible but in some instances the use of x-ray cannot be avoided.
                            </p>
                            <p className="lead">
                                Complications of lithotripsy may include, but are not limited to, the following
                            </p>
                            <ul className="lead">
                                <li>bleeding around the kidney</li>
                                <li>infection</li>
                                <li>obstruction of the urinary tract by stone fragments</li>
                            </ul>
                            <p className="lead">
                                Contraindications for lithotripsy include, but are not limited to, the following:
                            </p>
                            <ul className="lead">
                                <li>pregnancy</li>
                                <li>a large aortic aneurysm</li>
                                <li>certain bleeding conditions</li>
                                <li>certain skeletal deformities that prevent accurate focus of shock waves</li>
                            </ul>
                            <p className="lead">
                                Patients with cardiac pacemakers should notify their physician. Lithotripsy may be performed on patients with pacemakers with the approval of a cardiologist and using certain precautions. Rate-responsive pacemakers that are implanted in the abdomen may be damaged during lithotripsy.
                            </p>
                            <p className="lead">
                                There may be other risks depending upon your specific medical condition. Be sure to discuss any concerns with your physician prior to the procedure.
                            </p>
                            <p className="lead">
                                Obesity and intestinal gas may interfere with a lithotripsy procedure. Again your physician should be consulted if you have a concern.
                            </p>
                            <h4 className="mb-3 mt-3">
                                Before the Procedure
                            </h4>
                            <ul className="lead">
                                <li>Your physician will explain the procedure to you and offer you the opportunity to ask any questions that you might have about the procedure.</li>
                                <li>You will be asked to sign a consent form that gives your permission to do the procedure. Read the form carefully and ask questions if something is not clear.</li>
                                <li>In addition to a complete medical history, your physician may perform a complete physical examination to ensure you are in good health before undergoing the procedure. You may undergo blood tests or other diagnostic tests.</li>
                                <li>Fasting before the procedure may be indicated, depending upon the type of anesthetic or sedation used. You will be given instructions on how many hours to fast before the procedure if necessary.</li>
                                <li>If you are pregnant or suspect that you may be pregnant, you should notify your physician.</li>
                                <li>Notify your physician if you are sensitive to or allergic to any medications, latex, tape or anesthetic agents (local and general).</li>
                                <li>Notify your physician of all medications (prescription and over-the-counter) and herbal supplements that you are taking.</li>
                                <li>Notify your physician if you have a history of bleeding disorders or if you are taking any anticoagulant (blood-thinning) medications, aspirin, or other medications that affect blood clotting. It may be necessary for you to stop these medications prior to the procedure.</li>
                                <li>You may receive a sedative prior to the procedure to help you relax. If an anesthetic or sedative is given before or during the procedure, you may need someone to drive you home afterwards.</li>
                                <li>Based upon your medical condition, your physician may request other specific preparation.</li>
                            </ul>
                            <h4 className="mb-3 mt-3">
                                During the Procedure
                            </h4>
                            <p className="lead">
                                Lithotripsy may be performed on an outpatient basis or as part of your stay in the hospital. Procedures may vary depending on your condition and your and your physician’s practices.
                            </p>
                            <p className="lead">
                                Generally, lithotripsy follows this process:
                            </p>
                            <ol className="lead">
                                <li>An intravenous (IV) line will be inserted in your arm or hand.</li>
                                <li>You may receive a sedative or anesthetic agent to ensure that you remain still and pain-free during the procedure.</li>
                                <li>After the sedation has taken effect, you will be positioned on a water-filled cushion or immersed in a water-filled tub.</li>
                                <li>After the stone(s) has been located with fluoroscopy or ultrasound, you will be positioned for the most direct access to the stone.</li>
                                <li>A sequence of shock waves will be created to shatter the kidney stone(s).</li>
                                <li>The stone(s) will be monitored by fluoroscopy or ultrasound during the procedure.</li>
                                <li>A stent may be placed in the ureter to help the stone fragments (gravel) pass.</li>
                                <li>On most occasions a maximum of 3000 shocks is given, however at the surgeons guidance more or less can be given in certain circumstances.</li>
                            </ol>
                            <h4 className="mb-3 mt-3">
                                After the Procedure
                            </h4>
                            <p className="lead">After the surgery you will be taken to the recovery room for observation. Once your blood pressure, pulse, and breathing are stable and you are alert, you will be taken to your hospital room or discharged home.</p>
                            <p className="lead">You may resume your usual diet and activities unless your physician advises you differently.</p>
                            <p className="lead">You will be encouraged to drink extra fluids to dilute the urine and reduce the discomfort of passing stone fragments.</p>
                            <p className="lead">You may notice blood in your urine for a few days or longer after the procedure. This is normal.</p>
                            <p className="lead">Take a pain reliever for soreness as recommended by your physician. Aspirin or certain other pain medications may increase the chance of bleeding. Be sure to take only recommended medications. Consult your physician about this.</p>
                            <p className="lead">You may be given antibiotics after the procedure. Be sure to take the medication exactly as prescribed.</p>
                            <p className="lead">You may be asked to strain your urine so that remaining stones or stone fragments can be sent to the lab for examination.</p>
                            <p className="lead">A follow-up appointment will be scheduled within a few weeks after the procedure. If a stent was placed during the procedure, it may be removed at this time.</p>
                            <p className="lead">Notify your physician to report any of the following:</p>
                            <ul className="lead">
                                <li>fever and/or chills</li>
                                <li>burning with urination</li>
                                <li>urinary frequency or urgency</li>
                                <li>lower back pain</li>
                            </ul>
                            <p className="lead">Your physician may give you additional or alternate instructions after the procedure, depending on your particular situation.</p>
                        </div>
                    </div>
                </section>
                {/* End of LITHOTRIPSY Section */}

                {/* Counter Section */}
                <div className="section-counter paralax-mf bg-image" style={{backgroundImage: `url(${bgCounter})`}}>
                    <div className="overlay-mf" />
                    <div className="container position-relative">
                        <div className="row">
                            <div className="col-sm-3 col-lg-3">
                                <div className="counter-box counter-box pt-4 pt-md-0">
                                <div className="counter-ico">
                                    <span className="ico-circle"><i className="fa fa-user-injured" /></span>
                                </div>
                                <div className="counter-num">
                                    <p className="counter purecounter">{this.state.total_patients}</p>                                    
                                    <span className="counter-text">TOTAL PATIENTS</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-lg-3">
                                <div className="counter-box pt-4 pt-md-0">
                                <div className="counter-ico">
                                    <span className="ico-circle"><i className="fa fa-user-md" /></span>
                                </div>
                                <div className="counter-num">
                                <p className="counter purecounter">{this.state.total_doctors}</p>      
                                    <span className="counter-text">TOTAL DOCTORS</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-lg-3">
                                <div className="counter-box pt-4 pt-md-0">
                                <div className="counter-ico">
                                    <span className="ico-circle"><i className="fa fa-hospital" /></span>
                                </div>
                                <div className="counter-num">
                                    <p className="counter purecounter">{this.state.total_hospitals}</p>      
                                    <span className="counter-text">TOTAL HOSPITALS</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-lg-3">
                                <div className="counter-box pt-4 pt-md-0">
                                <div className="counter-ico">
                                    <span className="ico-circle"><i className="fa fa-check" /></span>
                                </div>
                                <div className="counter-num">
                                <p className="counter purecounter">{this.state.total_bookings}</p>      
                                    <span className="counter-text">NUMBER OF SUCCESSFULL TREATMENTS</span>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End of Counter Section */}


                {/* New Customer */}
                <section id="work" className="portfolio-mf sect-pt6 route">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                            <div className="title-box text-center">
                                <h3 className="title-a">
                                How to book our services
                                </h3>
                                {/* <p class="subtitle-a">
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                            </p> */}
                                <div className="line-mf" />
                            </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="container text-center mb-3">
                            <h5> New Surgeons:</h5>
                            <p className="lead">If you are a Urologist / NUM and would like to enquire about using our service, please email <a href="info@alexus.com.au" style={{textDecorationLine: "underline"}}>info@alexus.com.au</a> </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End of New Customer */}

                {/* Contact Us */}
                <section id="contact" className="paralax-mf footer-paralax bg-image sect-mt4 route" style={{backgroundImage: 'url(assets/img/overlay-bg.jpg)'}}>
                    <div className="overlay-mf" />
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="contact-mf">
                                    <div id="contact" className="box-shadow-full">
                                        <div>
                                            <div className="title-box-2 pt-4 pt-md-0 text-center">
                                                <h5 className="title-left">
                                                    Get in Touch
                                                </h5>
                                            </div>
                                            <div className="more-info">
                                                {/* <p className="lead text-center">
                                                    Our Contact Details
                                                </p> */}
                                                <ul className="list-ico text-center">
                                                    <li><span className="fa fa-map-marker-alt" /> P.O. Box 2035, Sunbury Victoria 3429</li>
                                                    <li><span className="fa fa-mobile-alt" /> Allan - 0438-341-130</li>
                                                    <li><span className="fa fa-mobile-alt" /> Alister - 0400-137-952</li>
                                                    <li><span className="fa fa-envelope" /> allan@alexus.com.au </li>
                                                    <li><span className="fa fa-envelope" /> alister@alexus.com.au </li>
                                                </ul>
                                            </div>
                                            <div className="socials  text-center">
                                                <ul>
                                                    <li><a href><span className="ico-circle"><i className="fab fa-facebook-f"></i></span></a></li>
                                                    <li><a href><span className="ico-circle"><i className="fab fa-instagram" /></span></a></li>
                                                    <li><a href><span className="ico-circle"><i className="fab fa-twitter" /></span></a></li>
                                                    <li><a href><span className="ico-circle"><i className="fab fa-linkedin" /></span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End of Contact Us */}

                {/* Footer */}
                <footer>
                    <div classname="container">
                        <div classname="row">
                        <div classname="col-sm-12">
                            <div classname="copyright-box">
                            <p classname="copyright">© Copyright 2009. All Rights Reserved, Alexus Services Pty. Ltd.</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </footer>
                {/* End of Footer */}



            </React.Fragment>
        );
    }
}

export default MainMenu;