import React, { Component } from 'react';
import { FormControl, FormLabel, Modal } from 'react-bootstrap';
import { Col, FormGroup, Row, Container, Button } from 'reactstrap';
import { request } from '../../constants/constants';
import Loader from "react-loader-spinner";

class HospitalEdit extends Component {
    constructor (props){
        super(props);
        this.state = {
            id: this.props.match.params.id,
            showSuccessModal: false,
            showError: false,
            chkbox: false,
            isLoading: true
        }
    }

    getHospital() {
        return request ('GET', `/hospitals/${this.state.id}`, {}, true);
    }

    init(){
        this.getHospital().then(res => {
            this.setState({
                // code: res.data.data.code,
                name: res.data.data.name,
                office_telephone: res.data.data.office_telephone,
                office_telephone_2: res.data.data.office_telephone_2,
                office_facsmile: res.data.data.office_facsmile,
                office_email: res.data.data.office_email,
                office_address: res.data.data.office_address,
                office_address_2: res.data.data.office_address_2,
                office_suburb: res.data.data.office_suburb,
                office_state: res.data.data.office_state,
                office_postcode: res.data.data.office_postcode,
                postal_address: res.data.data.postal_address,
                postal_address_2: res.data.data.postal_address_2,
                postal_suburb: res.data.data.postal_suburb,
                postal_state: res.data.data.postal_state,
                postal_postcode: res.data.data.postal_postcode,
                contact_title: res.data.data.contact_title, 
                contact_first_name: res.data.data.contact_first_name, 
                contact_last_name: res.data.data.contact_last_name,
                contact_mobile: res.data.data.contact_mobile,
                contact_telephone: res.data.data.contact_telephone,
                contact_facsmile: res.data.data.contact_facsmile,
                contact_email: res.data.data.contact_email,
                website: res.data.data.website,
                num_rooms: res.data.data.num_rooms,
                status: res.data.data.status,
                reference_number: res.data.data.reference_number,
                isLoading: false
            })
        })
    }

    componentDidMount() {
        this.init()
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleStatus = e => {
        this.setState({
            status: e.target.value
        })
    }

    handleClick = e => {
        this.setState({
            chkbox: !this.state.chkbox
        })
        if(e.target.checked === true){
            this.setState({
                postal_address: this.state.office_address,
                postal_address_2: this.state.office_address_2,
                postal_suburb: this.state.office_suburb,
                postal_state: this.state.office_state,
                postal_postcode: this.state.office_postcode
            })
        } else {
            this.setState({
                postal_address: '',
                postal_address_2: '',
                postal_suburb: '',
                postal_state: '',
                postal_postcode: ''
            })
        }
    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const data = {
            // code: this.state.code,
            name: this.state.name,
            reference_number: this.state.reference_number,
            office_telephone: this.state.office_telephone,
            office_telephone_2: this.state.office_telephone_2,
            office_email: this.state.office_email,
            office_facsmile: this.state.office_facsmile,
            office_address: this.state.office_address,
            office_address_2: this.state.office_address_2,
            office_suburb: this.state.office_suburb,
            office_state: this.state.office_state,
            office_postcode: this.state.office_postcode,
            postal_address: this.state.postal_address,
            postal_address_2: this.state.postal_address_2,
            postal_suburb: this.state.postal_suburb,
            postal_state: this.state.postal_state,
            postal_postcode: this.state.postal_postcode,
            contact_title: this.state.contact_title,
            contact_first_name: this.state.contact_first_name,
            contact_last_name: this.state.contact_last_name,
            contact_mobile: this.state.contact_mobile,
            contact_telephone: this.state.contact_telephone,
            contact_email: this.state.contact_email,
            contact_facsmile: this.state.contact_facsmile,
            website: this.state.website,
            num_rooms: this.state.num_rooms,
            status: this.state.status
        }

        request('PUT', `/hospitals/${this.state.id}`, data, true).then(res => {
            console.log(res)
            this.setState({
                isLoading: false,
                showSuccessModal: true,
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                errorData: err.response.data.errors,
                showError: true,
            })
        })
    }

    /* Modal Functions */
    hideModal = e => {
        this.setState({ showSuccessModal: true })
        window.location.href = `/hospitalview/${this.state.id}`;
    }

    hideModalError = () => {
        this.setState({ showError: false })
    }

    render() {
        return (
            <div class="container-fluid" id="center">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Container>
                            <Row>
                                <Col><h6 className="m-0 font-weight-bold text-primary">Hospital Edit Form</h6></Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="card-body">
                        {/* Modal for Successful Added */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>Hospital Successfully Updated!</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for ERROR  */}
                        <Modal show={this.state.showError} backdrop="static">
                            <Modal.Header onClick={this.hideModalError} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <p className="text-align" style={{textAlign: "center"}}>{this.state.errorMessage}</p>     
                            {
                                this.state.errorData &&  Object.keys(this.state.errorData).map(key => <p className="center" style={{textAlign: "center"}}>{this.state.errorData[key]}</p>)
                            }                                        
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={this.hideModalError}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>
                            {
                                this.state.isLoading ? (
                                    <Loader
                                        type="TailSpin"
                                        color="#2850C2"
                                        height={80}
                                        width={80}
                                        timeout={10000} 
                                    />
                                ) : (
                                    <form onSubmit={this.handleFormSubmit}>
                                        <Container>
                                            <FormGroup>
                                                <Row className="center">
                                                    {/* <Col lg={2}><FormLabel>Code: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="code"
                                                            className="center"
                                                            value={this.state.code}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col> */}
                                                    <Col lg={2}><FormLabel>Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="name"
                                                            className="center"
                                                            value={this.state.name}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col lg={2}><FormLabel>Status: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            as="select"
                                                            type="text"
                                                            name="status"
                                                            className="center"
                                                            value={this.state.status}
                                                            onChange={this.handleStatus}
                                                        >
                                                            <option value="1">Active</option>
                                                            <option value="0">Inactive</option>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col lg={2}><FormLabel>Reference Number: </FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="reference_number"
                                                            className="center"
                                                            value={this.state.reference_number}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <hr/>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={2}><FormLabel>Hospital Telephone: </FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="number"
                                                            name="office_telephone"
                                                            className="center"
                                                            value={this.state.office_telephone}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col sm={2}><FormLabel>Hospital Telephone 2: </FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="number"
                                                            name="office_telephone_2"
                                                            className="center"
                                                            value={this.state.office_telephone_2}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={2}><FormLabel>Hospital Email: </FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="email"
                                                            name="office_email"
                                                            className="center"
                                                            value={this.state.office_email}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col sm={2}><FormLabel>Hospital Facsmile: </FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="office_facsmile"
                                                            className="center"
                                                            value={this.state.office_facsmile}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <hr/>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={2}><FormLabel>Hospital Address: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="office_address"
                                                            className="center"
                                                            value={this.state.office_address}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col sm={2}><FormLabel>Hospital Address 2: </FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="office_address_2"
                                                            className="center"
                                                            value={this.state.office_address_2}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={2}><FormLabel>Hospital Suburb: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="office_suburb"
                                                            className="center"
                                                            value={this.state.office_suburb}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col sm={2}><FormLabel>Hospital State: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={2}>
                                                        <FormControl
                                                            as="select"
                                                            type="text"
                                                            name="office_state"
                                                            className="center"
                                                            value={this.state.office_state}
                                                            onChange={this.handleChange}
                                                        >
                                                            <option>ACT</option>
                                                            <option>NSW</option>
                                                            <option>VIC</option>
                                                            <option>TAS</option>
                                                            <option>QLD</option>
                                                            <option>SA</option>
                                                            <option>WA</option>
                                                            <option>NT</option>
                                                        </FormControl>
                                                    </Col>                               
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={2}><FormLabel>Hospital Postcode: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="office_postcode"
                                                            className="center"
                                                            value={this.state.office_postcode}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <input type="checkbox" ckecked={this.state.chkbox} onClick={this.handleClick} /> <span className="center" style={{ color: 'red'}}>Click this if you have the same Office and Postal Address</span>
                                            <hr/>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={2}><FormLabel>Postal Address: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="postal_address"
                                                            className="center"
                                                            value={this.state.postal_address}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col sm={2}><FormLabel>Postal Address 2: </FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="postal_address_2"
                                                            className="center"
                                                            value={this.state.postal_address_2}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={2}><FormLabel>Postal Suburb: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="postal_suburb"
                                                            className="center"
                                                            value={this.state.postal_suburb}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col sm={2}><FormLabel>Postal State: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={2}>
                                                        <FormControl
                                                            as="select"
                                                            type="text"
                                                            name="postal_state"
                                                            className="center"
                                                            value={this.state.postal_state}
                                                            onChange={this.handleChange}
                                                        >
                                                            <option>ACT</option>
                                                            <option>NSW</option>
                                                            <option>VIC</option>
                                                            <option>TAS</option>
                                                            <option>QLD</option>
                                                            <option>SA</option>
                                                            <option>WA</option>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={2}><FormLabel>Postal Postcode: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="postal_postcode"
                                                            className="center"
                                                            value={this.state.postal_postcode}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <hr/>
                                            <h6>Contact Person</h6><br />
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col lg={2}><FormLabel>Title: </FormLabel></Col>
                                                    <Col sm={2}>
                                                        <FormControl
                                                            as="select"
                                                            type="text"
                                                            name="contact_title"
                                                            className="center"
                                                            value={this.state.contact_title}
                                                            onChange={this.handleChange}
                                                        >
                                                            <option>Dr</option>
                                                            <option>Miss</option>
                                                            <option>Mrs</option>
                                                            <option>Ms</option>
                                                            <option>Mr</option>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm={2}><FormLabel>First Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={2}>
                                                        <FormControl
                                                            type="text"
                                                            name="contact_first_name"
                                                            className="center"
                                                            value={this.state.contact_first_name}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col sm={2}><FormLabel>Last Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={2}>
                                                        <FormControl
                                                            type="text"
                                                            name="contact_last_name"
                                                            className="center"
                                                            value={this.state.contact_last_name}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={2}><FormLabel>Mobile: </FormLabel></Col>
                                                    <Col sm={2}>
                                                        <FormControl
                                                            type="number"
                                                            name="contact_mobile"
                                                            className="center"
                                                            value={this.state.contact_mobile}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col sm={2}><FormLabel>Telephone: </FormLabel></Col>
                                                    <Col sm={2}>
                                                        <FormControl
                                                            type="number"
                                                            name="contact_telephone"
                                                            className="center"
                                                            value={this.state.contact_telephone}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col sm={2}><FormLabel>Email: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={2}>
                                                        <FormControl
                                                            type="text"
                                                            name="contact_email"
                                                            className="center"
                                                            value={this.state.contact_email}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={2}><FormLabel>Facsmile: </FormLabel></Col>
                                                    <Col sm={2}>
                                                        <FormControl
                                                            type="text"
                                                            name="contact_facsmile"
                                                            className="center"
                                                            value={this.state.contact_facsmile}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <hr/>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={2}><FormLabel>Website: </FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="website"
                                                            className="center"
                                                            value={this.state.website}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    {/* <Col sm={2}><FormLabel>Number of Rooms: </FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="number"
                                                            name="num_rooms"
                                                            className="center"
                                                            value={this.state.num_rooms}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col> */}
                                                </Row>
                                            </FormGroup><br />
                                            <Row className="center">
                                                <Col><button type="submit" className="btn btn-primary center">Update</button></Col>
                                            </Row>
                                        </Container>
                                    </form>
                                )
                            }
                        
                    </div>
                </div>             
            </div>
        );
    }
}

export default HospitalEdit;