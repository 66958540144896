import React, { Component } from 'react';
import { Row, Col, Container, FormGroup, FormLabel, FormControl, Table, Form, Modal, Button } from 'react-bootstrap';
import { API_URL, request } from '../../constants/constants';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Invoice from '../Invoices/Invoice';
import ReactTable from 'react-table';
import Pagination from "react-js-pagination";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class Invoices extends Component {
    constructor(props){
        super(props);
        this.state = {
            hospitals: [],
            doctors: [],
            invoices: [],
            hospitalId: '',
            activePage: 1,
            payment_status: '',
            hospitalGroupInvoice: [],
            toggleVisible: false,
            toggleVisiblePO: false,
            status: '',
            index:'',
            showModal: false,
            showSuccessModal: false, 
            viewInvoice: false,
            price: [],
            isLoading: true,
            isLoadingPrintInvoice: false,
            showErrorSendingEmail: false,
            chkboxCancelled: false,
            showErrorModal: false,
            isChecked: false,
            showMarkAsUnpaid: false,
            noClickedInvoice: false,
            payment_date: "",
            markAsPaidCheckBoxes: [],
            successMessage: "",
            merged: 0,
            items: [],
            patients: [],
            booking: [],
            selected_invoices: [],
            chkboxPaid: false,
            showMarkAsPaid: false,
            showSearch: false,
            showDeleteInvoice: false,
            isLoadingDeleteInvoice: false,
            isSelected: false,
            merge_date: "",
            current_invoice_number: "",
            total_paid_invoices: 0
        }

        this.handlePaid = this.handlePaid.bind(this);
    }

    componentDidMount(){
        this.init()

        this.getTotalPaidInvoices([]);
       

        request('GET', '/hospitals/Admin/adminHospitalListOnlyActive', {}, true).then(result => {
            // console.log(result)
            const hospitals = result.data.data.map(obj => ({
                id: obj.id,
                name: obj.name
            }));
            this.setState({ hospitals }); 
        });

        this.selectedCheckboxes = new Set();

    }

    getTotalPaidInvoices(invNumbers){

        const data = {
            inv_number: invNumbers
        }

        request('POST', `/invoices/total/get-total-paid-invoices`, data, true).then(result => {
            // console.log("tatal_paid_invoices: ", result)
            this.setState({ total_paid_invoices: result.data.data })
        });
        
    }

    

    init(){
        this.getAllInvoices().then(result => {
            // console.log('getAllInvoices', result)

            const current_page = result.data.pagination.current_page;
            const totalItemsCount = result.data.pagination.total;

            const invoices_data = result.data.data;
            this.setState({ 
                activePage: current_page,
                totalItemsCount: totalItemsCount,
                invoices: invoices_data,
                isLoading: false
            });
        });
    }

    getAllInvoices(){
        return request('GET', `/invoices/get-all-invoices?page=${this.state.activePage}`, {}, true);
    }


    handleHospital = e => {
        const hospitalId = e.target.value;
        this.setState({
            hospitalId: hospitalId
        })
    }

    handlePaymentStatus = (e) => {
        const paymentStatus = e.target.value;
        this.setState({
            payment_status: paymentStatus
        })
    }

    handleApplyFilter = () => {
        const data = {
            hospital_id: this.state.hospitalId == "-Select-" ? "" : this.state.hospitalId,
            payment_status: this.state.payment_status == "-Select-" ? "" : this.state.payment_status
        }
      
        this.setState({
            isLoading: true
        });

        request('POST', `/invoices/filter/apply-filter`, data, true).then((result) => {
            const invoices = result.data.data;
            this.setState({
                invoices: result.data.data,
                isLoading: false
            })
        })
    }

    handleInvoiceStatus = (e) => {
        // console.log("e", e.target.id)
        const invId = e.target.id
        const data = {
            status: e.target.value == "DONE" ? "PENDING" : "DONE"
        }

        request('PUT', `/invoices/updateInvoice/${e.target.id}`, data, true).then(result => {
            // console.log(result.data.data)
            const {invoices}  = this.state;
            const index = invoices.findIndex(invoice => invoice.ID == invId)
            let invoice = invoices[index]
            invoice.status = result.data.data.status
            this.setState({
                showModal: true,
                invoices: invoices
            })
        })
    }

    handleSendEmailInvoice = (invNumber) => {
        // console.log(invNumber)
        this.setState({
            isLoading: true
        })

        request('GET', `/invoices/SendEmailInvoiceManually/${invNumber}`, {}, true).then(result => {
            this.setState({ 
                successMessage: result.data.message, 
                showSuccessModal: true,
                isLoading: false,
            })
        }).catch(err => { 
            console.log(err.response)
            this.setState({
                isLoading: false,
                error: err.response.data.message,
                showErrorSendingEmail: true
            })
        })

    }

    handleViewInvoice = (e) => {
        // console.log('invoice_id', e)

        const invoice_id = e;

        // console.log('invoice_id', invoice_id)
        request('GET', `/invoices/getSingleInvoiceByInvoiceId/${invoice_id}`, {}, true).then(result => {
            // console.log('getSingleInvoiceByInvoiceId', result)

            const itemData = result.data.data.items.map(obj => {
                this.setState({
                    // invoice_number: invoice_id,
                    invoice_number: obj.invoice_number,
                    hospital_id: obj.hospital.id,
                    hospital_name: obj.hospital.name,
                    doctor_name: obj.booking && obj.booking.doctor.title + '. ' + obj.booking.doctor.first_name + ' ' + obj.booking.doctor.last_name,
                    office_address: obj.hospital.office_address,
                    office_address_2: obj.hospital.office_address_2,
                    office_suburb: obj.hospital.office_suburb,
                    office_state: obj.hospital.office_state,
                    office_postcode: obj.hospital.office_postcode,
                    reference_number: obj.hospital.reference_number,
                    procedure: obj.booking &&  obj.procedure,
                    procedureDate: obj.booking && obj.booking.date
                }, () => {
                    this.setState({
                        price: [],
                        final_procedures: [],
                        invoice_information: []
                    })
                    if(obj.booking){
                        this.getProcedurePrice(this.state.hospital_id, this.state.procedure, obj.booking.id, obj, invoice_id)
                    }
                    this.setState({
                        isLoading: false
                    })
                })
            })
        });
        this.setState({
            viewInvoice: true
        })
    }

    getProcedurePrice = (hospital_id, procedure, booking_id, items, invoice_id) => {
        request('GET', `/hospital_procedures/getProcedure/${hospital_id}/${booking_id}/${invoice_id}`, {}, true).then((result2, prod_key) => { 
            // console.log('getProcedurePrice', result2, prod_key)
            // return result2.data.data.price
            this.setState({
                price: [ ...this.state.price, result2.data.data],
                final_procedures: [ ...this.state.final_procedures, procedure],
                invoice_information: [ ...this.state.invoice_information, items]
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response
            })
        });
    }

    handleMarkasPaid = () => {
    
        const paymentFunction = "MarkasPaid";
        this.paymentMethod(paymentFunction);

    }

    handleMarkasUnPaid = () => {
        const paymentFunction = "MarkasUnPaid";
        this.paymentMethod(paymentFunction);
    }

    // handleGenerateOldInvoices = () => {
    //     request('GET', '/bookings/function/generateOldInvoices', {}, true).then((result) => { 
    //         console.log(result)
    //     }).catch(err => {
    //         console.error(err.response);
    //         this.setState({
    //             isLoading: false,
    //             error: err.response
    //         })
    //     });
    // }

    handleGenerateOldInvoices = () => {
        request('GET', '/invoices/pdf/generatePdfFiles', {}, true).then((result) => { 
            // console.log(result)
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response
            })
        });
    }

    paymentMethod = (paymentFunction) => {
        this.setState({
            markAsPaidCheckBoxes: [],
        })
        var converted = Array.from(this.selectedCheckboxes)
        this.setState({
            showMarkAsPaid: paymentFunction == "MarkasPaid" ? true : false,
            showMarkAsUnpaid: paymentFunction == "MarkasUnPaid" ? true : false,
            markAsPaidCheckBoxes: this.state.markAsPaidCheckBoxes.concat(converted)
        }, () => {
            if(this.state.markAsPaidCheckBoxes.length == 0){
                this.setState({
                    noClickedInvoice: true
                })
            } else {
                this.setState({
                    noClickedInvoice: false
                })
            }
        })
    }

    handleUnpaid = () => {
        const data = {
            invoice_numbers: this.state.markAsPaidCheckBoxes,
        }

        request('PATCH', '/invoices/unpaid-payment/unpaid-payment-status', data, true).then(result => { 

            const updated_invoices = result.data.data.map(obj => {
                this.updateInvoicesDetails(obj.id, obj.payment_status, null)
            })

            this.setState({
                isLoading: false,
                showMarkAsUnpaid: false
            })

            this.selectedCheckboxes = new Set();
            this.state.markAsPaidCheckBoxes = [];

            
            //Get the current total paid invoices
            // console.log("Marking as unPaid")
            // this.getTotalPaidInvoices();

        }).catch(err => { 
            console.log(err.response)
            this.setState({
                isLoading: false,
                error: err.response.data.message,
            })
        });
    }

    hideMarkAsPaid = () => {
        this.setState({
            showMarkAsPaid: false,
            showMarkAsUnpaid: false
        })
    }

    handleSavePayment = () => {

        const data = {
            invoice_numbers: this.state.markAsPaidCheckBoxes,
            payment_date: this.state.payment_date
        }

         request('PATCH', '/invoices/payment/update-payment-status', data, true).then(result => {
            // console.log("result", result)
            const updated_invoices = result.data.data.map(obj => {
                this.updateInvoicesDetails(obj.id, obj.payment_status, obj.paid_date)
            })
            
            this.setState({
                isLoading: false,
                showMarkAsPaid: false
            })

            this.selectedCheckboxes = new Set();
            this.state.markAsPaidCheckBoxes = [];

            //Get the current total paid invoices
            // console.log("Marking as Paid")
            // this.getTotalPaidInvoices();
                
                
         }).catch(err => { 
            console.log(err.response)
            this.setState({
                isLoading: false,
                // error: err.response.data.message,
            })
        });

        


    }

    updateInvoicesDetails = (inv_id, inv_payment_status, inv_paid_date) => {

        let invoice_index = this.state.invoices.findIndex(elem => (elem.ID == inv_id))
        this.state.invoices[invoice_index].payment_status = inv_payment_status
        this.state.invoices[invoice_index].paid_date = inv_paid_date

        const selected_invoices_index = this.state.selected_invoices.indexOf(inv_id);
        if (selected_invoices_index > -1) {
            this.state.selected_invoices.splice(selected_invoices_index, 1);
        }

    }

    

    handleMergeinvoices = () => {

        this.setState({
            isLoading: true, 
            merged: 1
        })

        request('GET', '/invoices/merge/mergeInvoicesPerHospital', {}, true).then(result => { 
            this.setState({
                isLoading: false,
                showSuccessModal: true,
                successMessage: "Merged Successfully! "
            })
        }).catch(err => { 
            console.log(err.response)
            this.setState({
                isLoading: false,
                error: err.response.data.message,
                errorCode: err.response.status,
                showErrorModal: true
            })
        });

    }

    handlePrintPerInvoice = () => {
        const invoice_number = this.state.invoice_number
        const hospitalId = this.state.hospital_id
        this.setState({
            isLoadingPrintInvoice: true
        })

        request('GET', `/invoices/printSingleInvoice/${hospitalId}/${invoice_number}`, {}, true).then(result => { 
            // console.log('printSingleInvoice', result)
            const invoice_url = result.data.url;
            this.setState({
                isLoadingPrintInvoice: false
            })
            if(invoice_url){
                const win = window.open(invoice_url, '_blank');
                if(win){
                    win.focus();    
                }
            }
        }).catch(err => { 
            console.log(err.response)
            this.setState({
                isLoadingPrintInvoice: false,
                showErrorModal: true,
                viewInvoice: false,
                errorMessage: err.response.data.message
            })
        });
    }

    handleGenerateInvoice = e => {
        request('GET', '/invoices/generateInvoice/SendEmail', {}, true).then(result => { 
            // console.log(result)
        });
    }

    handlePaid = (e) => {

        let invNumber = parseInt(e.target.value)

        if(e.target.checked){ 
            this.selectedCheckboxes.add(invNumber);
        } else {

            this.selectedCheckboxes.delete(invNumber);
            const index = this.state.markAsPaidCheckBoxes.indexOf(invNumber);

            if (index > -1) {
                this.state.markAsPaidCheckBoxes.splice(index, 1);
            }
        }  
        
        var converted = Array.from(this.selectedCheckboxes)

        this.setState({
            selected_invoices: converted
        })

        //Get the current total paid invoices
        // console.log("Marking as unPaid")
        this.getTotalPaidInvoices(converted);

    }

    getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
          return {
            style: {
              color:
                rowInfo.original.payment_status == "PAID" 
                  ? "#009900"
                  : "#000000"
            }
          };
        }
        return {};
      };
    
    //Modal Functions
    hideModal = () => {
        this.setState({ showModal: false })
    }
    
    hideSuccessModal = () => {
        this.setState({ 
            showSuccessModal: false
        })

        window.location.href = '/invoices';
    }

    hideViewInvoice = () => {
        this.setState({
            viewInvoice: false
        })
    }

    hideErrorSendingEmail = () => {
        this.setState({
            showErrorSendingEmail: false
        })
    }

    hideErrorModal = () => {
        this.setState({
            showErrorModal: false
        })
    }

    getResizeValues = (newResized, event) => {
        if(newResized){
            // console.log(localStorage.getItem('invoicesTableWidths'), newResized)
            if(localStorage.getItem('invoicesTableWidths')) {
                const widths = JSON.parse(localStorage.getItem('invoicesTableWidths'));
                newResized.forEach(element => {
                    const i = widths.findIndex(width => width.id == element.id);
                    if(i > -1) {
                        const width = widths[i];
                        width.value = element.value
                    } else {
                        widths.push(element);
                    }
                });
                localStorage.setItem("invoicesTableWidths", JSON.stringify(widths));

            } else {
                localStorage.setItem("invoicesTableWidths", JSON.stringify(newResized));
            }
        }
    }

    getColumnWidth(columnId, defaultSize) {
        if(localStorage.getItem('invoicesTableWidths')) {
            const widths =  JSON.parse(localStorage.getItem('invoicesTableWidths'));
            const i = widths.findIndex((element) => element.id == columnId);
            if(i > -1) {
                const columnWith = widths[i].value;
                return columnWith;
            }
            return defaultSize;
        }
        return defaultSize;
    }

    handleClickDate = (e) => {
        const merge_date = e.target.value;
        this.setState({ merge_date: merge_date })
    }

    handleClickPayment = (e) => {
        this.setState({ payment_date: e.target.value })
    }

    getTotalAmountOfInvoices = (amount) =>  {
        let total_amount = 0;

        total_amount = amount + (amount * 0.1);
        return total_amount;       
    }

    showSearchFunctions = () => {
        setTimeout(function() {
            this.setState({
                showSearch: !this.state.showSearch
            })
        }.bind(this), 500)

       
    }

    handleShowDeleteModal = (invoiceId) => {
        const invoice_number = invoiceId;

        this.setState({
            showDeleteInvoice: true,
            current_invoice_number: invoice_number
        })

    }

    hideModalDeleteInvoice = () => {
        this.setState({
            showDeleteInvoice: false
        })
    }

    handleDeleteInvoice = () => {
        this.setState({
            isLoading: true,
            showDeleteInvoice: false
        })
        request('DELETE', `/invoices/delete/${this.state.current_invoice_number}`, {}, true).then(result => { 
            this.setState({
                isLoading: false,
            })
            window.location.href = '/invoices';
        }).catch(err => { 
            console.log(err.response)
        });

    }


    handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber
        }, () => {
            this.init();
        });
    }

    render() {
    
        const columns = [
            {  
                Header: 'Invoice Date',  
                accessor: row => row && row.invoice_date,
                width: this.getColumnWidth('invoice_date', 160),
                id: "invoice_date",
                Cell: (row) => {
                    return (
                      <React.Fragment>
                        <div className="cell-text">
                          {row.original.invoice_date && moment(row.original.invoice_date).format("DD/MM/YYYY")}
                        </div>
                      </React.Fragment>
                    )
                }
            },

            {  
                Header: 'Invoice No.',  
                // accessor: 'invoice_no',
                accessor: row => row.items && row.items.map(obj => { return obj.invoice_number }),
                width: this.getColumnWidth('invoice_no', 100),
                id: "invoice_no",
                Cell: (row) => {
                    let cur_invoice_number = null;
                    return  (
                        <React.Fragment>
                            {
                                // row.original.items && row.original.items[0].invoice_number
                                row.original.items && row.original.items.map(obj => { 
                                    if(obj){
                                        if(obj.invoice_number != cur_invoice_number){
                                            cur_invoice_number = obj.invoice_number;
                                            return obj.invoice_number;
                                        }
                                    } 
                                })
                            }
                        </React.Fragment>
                    )
                }
                // Cell: (row) => (console.log('row', row))
            },

            {
                Header: 'PO No.',  
                // accessor: 'po_no',
                accessor: row => row && row.po_number,
                width: this.getColumnWidth('po_no', 160),
                id: "po_no",
                Cell: (row) => {
                    return (
                      <React.Fragment>
                        <div className="cell-text">
                          <span>{row.original.po_number}</span>
                        </div>
                      </React.Fragment>
                    )
                }
            },

            {
                Header: 'Doctor',  
                accessor: (row) => {
                    return  (
                        <React.Fragment>
                            {
                                row.items && row.items.map(obj => {
                                    return <React.Fragment> 
                                        {
                                            (obj.booking && obj.booking.doctors && obj.booking.doctors.last_name)
                                        }
                                        </React.Fragment>
                                    
                                })
                            }
                        </React.Fragment>
                    )
                },
                width: this.getColumnWidth('doctor', 250),
                id: "doctor",
                Cell: (row) => {
                    let current_doctor_name = null
                    return (
                        <React.Fragment>
                          <div className="cell-text">
                            {
                                row.original.items && row.original.items.map(obj => {
                                    if(obj.booking){
                                        let doctor_name = obj.booking && obj.booking.doctor && obj.booking.doctor.title + '. ' + obj.booking.doctor.first_name + ' ' + obj.booking.doctor.last_name
                                        if(current_doctor_name != doctor_name){
                                            current_doctor_name = doctor_name
                                            return <React.Fragment>
                                                {
                                                    <span>
                                                        {doctor_name}<br />                                               
                                                    </span>                                           
                                                }   
                                            </React.Fragment>
                                        }
                                    }
                                })
                            }
                          </div>
                        </React.Fragment>
                      )
                }
            },

            {
                Header: 'Hospital',  
                // accessor: 'hospital',
                accessor: row => row.hospital && row.hospital.name,
                width: this.getColumnWidth('hospital', 250),
                id: "hospital",
                Cell: (row) => {
                    return (
                        <React.Fragment>
                          <div className="cell-text">
                            {
                                row.original.hospital && ( <span>{row.original.hospital.name}</span>)
                            }
                          </div>
                        </React.Fragment>
                      )
                }
            }, 

            {
                Header: 'Total Amount',  
                // accessor: 'total_amount',
                accessor: row => row && row.total_amount,
                width: this.getColumnWidth('total_amount', 250),
                id: "total_amount",
                Cell: (row) => {
                    return (
                        <React.Fragment>
                          <div className="cell-text">
                                {
                                    <React.Fragment>
                                        <span>{(row.original.total_amount && this.getTotalAmountOfInvoices(row.original.total_amount)).toFixed(2)}
                                        
                                        </span>
                                    </React.Fragment>
                                      
                                }
                          </div>
                        </React.Fragment>
                      )
                }
            },

            {
                Header: 'No. of Transactions', 
                accessor: row => row && row.total_items,
                width: this.getColumnWidth('transactions', 250),
                id: "transactions",
                Cell: (row) => {
                    return (
                        <React.Fragment>
                          <div className="cell-text">
                            <span>{row.original.total_items}</span>
                          </div>
                        </React.Fragment>
                      )
                }
            },

            {
                Header: 'Payment Status', 
                // accessor: 'payment_status',
                accessor: row => row && row.payment_status,
                width: this.getColumnWidth('payment_status', 180),
                id: "payment_status",
                Cell: (row) => {
                    return (
                        <React.Fragment>
                          <div className="cell-text">
                            <span>{row.original.payment_status} {row.original.payment_status == "PAID" ? (' - ' + moment(row.original.paid_date).format('DD/MM/YYYY')) : ""}</span>
                          </div>
                        </React.Fragment>
                      )
                }
                
            },

            {
                Header: 'Email Sent', 
                // accessor: 'email_status',
                accessor: row => row && row.email_status,
                width: this.getColumnWidth('email_status', 180),
                id: "email_status",
                Cell: (row) => {
                    return (
                        <React.Fragment>
                          <div className="cell-text">
                            <span>{row.original.email_status ? "YES" : "NO"}</span>
                          </div>
                        </React.Fragment>
                      )
                }
            },

            {
                Header: 'Invoice Status', 
                // accessor: 'invoice_status',
                accessor: row => row && row.status,
                width: this.getColumnWidth('invoice_status', 180),
                id: "invoice_status",
                style: { textAlign: 'center'},
                Cell: (row) => {
                    return (
                        <React.Fragment>
                          <div className="cell-text">
                                <button 
                                    id={row.original.ID}
                                    value={row.original.status} 
                                    className={row.original.status == "PENDING" ? 'btn btn-danger center' : (row.original.status == 'DONE' ? 'btn btn-primary center' : 'btn btn-default center')}
                                    onClick={this.handleInvoiceStatus}
                                >
                                    {row.original.status}
                                </button>
                          </div>
                        </React.Fragment>
                      )
                }

            },

            {
                Header: 'Action', 
                accessor: 'action',
                width: this.getColumnWidth('action', 180),
                id: "action",
                style: {textAlign: 'center'},
                Cell: (row) => {
                    return (
                        <React.Fragment>
                          <div className="cell-text">
                            <Link id={row.original.ID} onClick={() =>this.handleViewInvoice(row.original.ID)}><span className="center" style={{fontSize: "13px", color: "#0645AD"}}>view</span></Link> |  {row.original.status === "DONE" ? ( <Link><span style={{fontSize: "13px", color: "#0645AD"}} onClick={(e) => this.handleSendEmailInvoice(row.original.ID)}>send | </span></Link>) : "" } <Link onClick={(e) => this.handleShowDeleteModal(row.original.ID)}><span className="center" style={{fontSize: "13px", color: "#8B0000"}}>delete</span></Link>
                          </div>
                        </React.Fragment>
                    )
                }
            },

            {
                Header: 'Mark as paid',  
                accessor: 'mark_as_paid',
                width: this.getColumnWidth('mark_as_paid', 180),
                id: "mark_as_paid",
                style: { textAlign: 'center' },
                Cell: (row) => {
                    return (
                        <React.Fragment>
                            <div className="cell-text">
                                <input
                                    type="checkbox"
                                    onChange={this.handlePaid}
                                    value={row.original.ID}
                                    id={row.original.ID}
                                    name="markAsPaidCheckBoxes" 
                                    checked={this.state.selected_invoices.indexOf(row.original.ID) !== -1 ? true : false}
                                />
                            </div>
                        </React.Fragment>
                      )
                }
               
            }
        ]; 
        
        return (
            <div className="container-fluid invoice-table">
                <div className="card shadow mb-4 mt-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col sm={3} md={6} lg={8}><h5 className="mb-2 font-weight-bold text-primary center">Invoices</h5></Col>
                            <Col sm={12} md={6} lg={3}>
                                <button style={{width: '100%'}} className='btn btn-primary center float-right' onClick={this.handleGenerateInvoice}>Generate Invoices</button>
                                {/* <button style={{width: '100%'}} className='btn btn-primary center float-right' onClick={this.handleMergeinvoices}> Merge Invoices</button>                                */}
                            </Col>
                            <Col sm={12} md={6} lg={1}>
                                <i className="fas fa-search mt-lg-2" onClick={this.showSearchFunctions}/>
                            </Col>


                        </Row>
                    </div>
                    <div className="card-body">

                        {/* Modal Invoice Delete */}
                        <Modal show={this.state.showDeleteInvoice} backdrop="static">
                            <Modal.Header onClick={this.hideModalDeleteInvoice} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{'textAlign': 'center'}}> Are you sure you want to delete the Invoice# { this.state.current_invoice_number } ?  </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-danger center" onClick={this.hideModalDeleteInvoice}>
                                    Cancel
                                </button>
                                <button className="btn btn-primary center" onClick={this.handleDeleteInvoice}>
                                    Yes
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Mark as UnPaid  */}
                        <Modal show={this.state.showMarkAsUnpaid} backdrop="static">
                            <Modal.Header onClick={this.hideMarkAsPaid} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Payment </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {   
                                        !this.state.noClickedInvoice ? (
                                            <p className="center" style={{'textAlign': 'center'}}> Are you sure you want to mark this as unpaid? </p>

                                        ) : (   
                                            <p className="center" style={{'textAlign': 'center'}}> Please select invoice first.</p>
                                        )
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-danger center" onClick={this.hideMarkAsPaid}>
                                    Cancel
                                </button>
                                <button className="btn btn-primary center" onClick={this.handleUnpaid}>
                                    Yes
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Mark as Paid  */}
                        <Modal show={this.state.showMarkAsPaid} backdrop="static">
                            <Modal.Header onClick={this.hideMarkAsPaid} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Payment </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormGroup>
                                    {
                                        !this.state.noClickedInvoice ? (
                                            <Row className="center">
                                                <Col sm={3} md={3} lg={3}>
                                                    <FormLabel> Date: </FormLabel>
                                                </Col>
                                                <Col sm={9} md={9} lg={9}>
                                                    <FormControl
                                                        type="date"
                                                        className="center"
                                                        name="payment_date"
                                                        value={this.state.payment_date}
                                                        onChange={this.handleClickPayment}
                                                    />
                                                </Col>
                                            </Row>   
                                        ) : (
                                            <p className="center" style={{'textAlign': 'center'}}> Please select invoice first.</p>
                                        )
                                    }
                                   
                                </FormGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-danger center" onClick={this.hideMarkAsPaid}>
                                    Cancel
                                </button>
                                <button className="btn btn-primary center" onClick={this.handleSavePayment}>
                                    Save
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for ERROR for UR Number  */}
                        <Modal show={this.state.showErrorModal}>
                            <Modal.Header onClick={this.hideErrorModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: 'center'}}>{this.state.errorCode && (<span>{this.state.error}</span>)}</p>                                             
                                <p className="center" style={{textAlign: 'center'}}>{this.state.errorMessage && (<span>{this.state.errorMessage}</span>)}</p>  
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={this.hideErrorModal}>
                                    OK
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Error Sending Invoice via email  */}
                        <Modal show={this.state.showErrorSendingEmail} backdrop="static">
                            <Modal.Header onClick={this.hideErrorSendingEmail} closeButton>
                                <Modal.Title><h6 className="m-0 font-weight-bold text-danger">Error: </h6></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    this.state.error && (<p style={{textAlign: "center"}}>{this.state.error}</p>)
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideErrorSendingEmail}>
                                    Okay
                                </button>
                            </Modal.Footer>
                        </Modal>


                        {/* Modal for view invoice */}
                        <Modal show={this.state.viewInvoice} backdrop="static" className="center" size="lg">
                            <Modal.Header onClick={this.hideViewInvoice} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger"></h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    this.state.isLoadingPrintInvoice ? (
                                        <Loader
                                            type="TailSpin"
                                            color="#2850C2"
                                            height={80}
                                            width={80}
                                            timeout={15000} 
                                        />
                                    ) : (
                                        <Invoice 
                                            invoice_information = {this.state.invoice_information}
                                            invoice_number={this.state.invoice_number}
                                            hospital_name={this.state.hospital_name}
                                            doctor_name= {this.state.doctor_name}
                                            office_address={this.state.office_address}
                                            office_address_2={this.state.office_address_2}
                                            office_suburb={this.state.office_suburb}
                                            office_state={this.state.office_state}
                                            office_postcode={this.state.office_postcode}
                                            procedure={this.state.final_procedures}
                                            patientName={this.state.patientName}
                                            reference_number={this.state.reference_number}
                                            price={this.state.price}
                                            procedureDate={this.state.procedureDate}
                                        />
                                    )
                                }

                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.handlePrintPerInvoice}>
                                    Print
                                </button>
                                <button className="btn btn-secondary center" onClick={this.hideViewInvoice}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>
                        
                        {/* Modal for Delete Booking  */}
                        <Modal show={this.state.showModal} backdrop="static">
                            <Modal.Header onClick={this.hideModal} closeButton>
                                <Modal.Title><h6 className="m-0 font-weight-bold text-danger">Confirmation: </h6></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p style={{textAlign: "center"}}>Success! </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideModal}>
                                    Okay
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Email Successfully Sent */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideSuccessModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center"  style={{textAlign: "center"}}>{this.state.successMessage ? this.state.successMessage : "Email Successfully Sent"}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideSuccessModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <FormGroup>
                            {
                                this.state.showSearch && (
                                    <Row className="center">
                                        <Col sm={12} md={4} lg={1}><FormLabel className="mb-2">Hospital: </FormLabel></Col>
                                        <Col sm={12} md={8} lg={3}>
                                            <FormControl
                                                as = "select"
                                                type="text"
                                                name="hospital_id"
                                                className="center"
                                                onChange={this.handleHospital}
                                            >   
                                                <option value="-Select-">Select All</option>
                                                {this.state.hospitals.map(hospital => ( 
                                                    <option value={hospital.id}> {hospital.name} </option>
                                                ))}
                                            </FormControl>
                                        </Col>
                                        <Col sm={12} md={4} lg={1}><FormLabel className="mb-2">Payment Status: </FormLabel></Col>
                                        <Col sm={12} md={8} lg={2}>
                                            <FormControl
                                                as = "select"
                                                type="text"
                                                name="hospital_id"
                                                className="center"
                                                onChange={this.handlePaymentStatus}
                                            >   
                                                <option value="-Select-">Select All</option>
                                                <option value="PAID">PAID</option>
                                                <option value="UNPAID">UNPAID</option>
                                            </FormControl>
                                        </Col>
                                        <Col sm={12} md={8} lg={3}>
                                            <button
                                                type="button"
                                                className="btn btn-primary center btn-block mt-4 mt-md-0"
                                                onClick={this.handleApplyFilter}
                                            >
                                                Search
                                            </button>
                                        </Col>
                                        
                                    </Row>
                                )
                            }
                            
                                {/* <Col sm={12} md={4} lg={1}>
                                    <FormLabel> Select Date: </FormLabel>
                                </Col>
                                <Col sm={12} md={8} lg={3}>
                                    <FormControl
                                        type="date"
                                        className="center"
                                        name="merge_date"
                                        value={this.state.merge_date}
                                        onChange={this.handleClickDate}
                                    />
                                </Col> */}
                            <Row className="center">
                                {/* <Col sm={12} md={12} lg={2}>
                                    <button className='btn btn-dark center btn-block mt-2 mt-lg-0 btn-sm' value={this.state.hospitalId} onClick={this.handleMergeinvoices}>Merge Invoices</button>
                                </Col>  */}
                                <Col sm={12} md={12} lg={2}>
                                    <button className='btn btn-dark center btn-block mt-2 mt-lg-0 btn-sm' value={this.state.hospitalId} onClick={this.handleMarkasPaid}>Mark as Paid</button>
                                </Col>
                                <Col sm={12} md={12} lg={2}>
                                    <button className='btn btn-dark center btn-block mt-2 mt-lg-0 btn-sm' value={this.state.hospitalId} onClick={this.handleMarkasUnPaid}>Mark as Unpaid</button>
                                </Col>
                                <Col sm={12} md={12} lg={8}>
                                    <p className="lead text-right"> Total Paid Invoices: {this.state.total_paid_invoices.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</p>
                                </Col>
                                {/* <Col sm={12} md={12} lg={2}>
                                    <button className='btn btn-dark center btn-block mt-2 mt-lg-0' value={this.state.hospitalId} onClick={this.handleGenerateOldInvoices}>Generate Old Invoices</button>
                                </Col> */}
                            </Row>
                        </FormGroup>
                        
                        {
                            this.state.isLoading ? (
                                <Loader
                                    type="TailSpin"
                                    color="#2850C2"
                                    height={80}
                                    width={80}
                                />
                            ) : (
                                <React.Fragment>
                                    <ReactTable
                                        data={this.state.invoices}
                                        columns={columns}
                                        style={{ overflow: "wrap" }}
                                        showPagination={false}
                                        className="center"
                                        minRows={0}
                                        getTrProps={this.getTrProps}
                                        onResizedChange={this.getResizeValues} 
                                    />
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={20}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                        itemClass="page-link"
                                    />

                                </React.Fragment>
                                
                            )
                        }
                        
                    </div>
                </div>                
            </div>
        );
    }
}

export default Invoices;