import React, { Component } from 'react';
import { Container, Col, Row, FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { request } from '../../constants/constants';
import Loader from "react-loader-spinner";

class UserView extends Component {
    constructor(props){
        super(props);
        this.state = {
            id: this.props.match.params.id,
            user: [],
            isLoading: true
        }
    }

    getUser(){
        return request('GET', `/users/${this.state.id}`, {}, true);
    }

    init(){
        this.getUser().then(res => {
        console.log(res);
            this.setState({
                user_id:  res.data.data.email,
                name:  res.data.data.title + '. ' +  res.data.data.first_name + ' ' +  res.data.data.last_name, 
                email:  res.data.data.email,
                office_telephone:   res.data.data.office_telephone,
                mobile_number:  res.data.data.mobile_number,
                permission:  res.data.data.permission,
                status:  res.data.data.status,
                isLoading: false
            })
        })
    }

    componentDidMount() {
       this.init()
    }

    render() {
        return (
            <div class="container-fluid" >
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Container>
                            <Row >
                                <Col sm={12} md={12} lg={6}><h6 className="m-0 font-weight-bold text-primary">User Information</h6></Col>
                                <Col md={3}></Col>
                                <Col sm={12} md={12} lg={3}>
                                    <Link to={`/useredit/${this.state.id}`}><button style={{width: '100%'}}  type="submit" className="btn btn-primary center mt-2 mt-lg-0"><i class="far fa-edit" /> Edit User</button></Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="card-body">
                        {
                            this.state.isLoading ? (
                                <Loader
                                    type="TailSpin"
                                    color="#2850C2"
                                    height={80}
                                    width={80}
                                    timeout={10000} 
                                />
                            ) : (
                                <Container>
                                    <Row className="center">
                                        <Col sm={6} md={12}>
                                            <FormLabel><b>General Details:</b> </FormLabel>
                                            <Row className="center">
                                                <div className="col-6 col-md-6 col-lg-4"><FormLabel>Name: </FormLabel></div>
                                                <div className="col-6 col-md-6 col-lg-8">
                                                    <FormLabel>
                                                    {this.state.name}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-6 col-lg-4"><FormLabel>Email: </FormLabel></div>
                                                <div className="col-6 col-md-6 col-lg-8">
                                                    <FormLabel>
                                                    {this.state.email}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-6 col-lg-4"><FormLabel>Office Telephone: </FormLabel></div>
                                                <div className="col-6 col-md-6 col-lg-8">
                                                    <FormLabel>
                                                    {this.state.office_telephone}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-6 col-lg-4"><FormLabel>Mobile Number: </FormLabel></div>
                                                <div className="col-6 col-md-6 col-lg-8">
                                                    <FormLabel>
                                                    {this.state.mobile_number}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                        </Col>

                                        <Col sm={6} md={12}>
                                            <Row className="center">
                                                <div className="col-6 col-md-6 col-lg-4"></div>
                                                <div className="col-6 col-md-6 col-lg-8"></div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-6 col-lg-4"><FormLabel>User ID: </FormLabel></div>
                                                <div className="col-6 col-md-6 col-lg-8">
                                                    <FormLabel>
                                                        {this.state.user_id}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-6 col-lg-4"><FormLabel>Permission: </FormLabel></div>
                                                <div className="col-6 col-md-6 col-lg-8">
                                                    <FormLabel>
                                                    {this.state.permission}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            <Row className="center">
                                                <div className="col-6 col-md-6 col-lg-4"><FormLabel>Status: </FormLabel></div>
                                                <div className="col-6 col-md-6 col-lg-8">
                                                    <FormLabel>
                                                    {this.state.status === 1 ? 'Active' : 'Inactive'}
                                                    </FormLabel>
                                                </div>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </Container>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default UserView;