import React, { Component } from 'react';
import { Container, FormGroup, Row, Col, FormLabel, FormControl, Modal } from 'react-bootstrap';
import { request } from '../../constants/constants';
import Loader from "react-loader-spinner";

class TreatmentProcedureEdit extends Component {
    constructor(props){
        super(props);
        this.state = {
            id: this.props.match.params.id,
            showSuccessModal: false,
            showError: false,
            isLoading: true
        }
    }
    
    getDoctor() {
        return request('GET', `/procedures/${this.state.id}`, {}, true);
    }

    init (){
        this.getDoctor().then(res => {
            this.setState({ 
                procedure_type: res.data.data.procedure_type,
                description: res.data.data.description,
                time_required: res.data.data.time_required,
                status: res.data.data.status,
                isLoading: false
            })
            // console.log(this.state.status)
        })
    }

    componentDidMount(){
        this.init()
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const data = { 
            procedure_type: this.state.procedure_type,
            description: this.state.description,
            time_required: this.state.time_required,
            status: this.state.status
        }

        request('PUT', `/procedures/${this.state.id}`, data, true).then(res => {
            this.setState({
                isLoading: false,
                showSuccessModal: true
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                errorData: err.response.data.errors,
                showError: true,
            })
        })
    }

    /* Modal Functions */

    hideSuccessModal = () => {
        this.setState({ 
            showSuccessModal: false 
        })
        window.location.href = "/treatmentprocedure";
    }

    hideModalError = () => {
        this.setState({ showError: false })
    }

    render() {
        return (
            <div class="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6>Treatment/Procedure Edit Form</h6>
                    </div>
                    <div className="card-body">
                        {/* Modal for Successfully Appointed */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideSuccessModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>Treatment/Procedure Successfully Updated!</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideSuccessModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for ERROR  */}
                        <Modal show={this.state.showError}>
                            <Modal.Header onClick={this.hideModalError} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <p className="text-align" style={{textAlign: "center"}}>{this.state.errorMessage}</p>     
                            {
                                this.state.errorData &&  Object.keys(this.state.errorData).map(key => <p style={{textAlign: "center"}}>{this.state.errorData[key]}</p>)
                            }                                        
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={this.hideModalError}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>
                        
                        <form onSubmit={this.handleFormSubmit}>
                            {
                                this.state.isLoading ? (
                                    <Loader
                                        type="TailSpin"
                                        color="#2850C2"
                                        height={80}
                                        width={80}
                                        timeout={10000} 
                                    />
                                ) : (
                                    <Container>
                                        <FormGroup className="m-3">
                                            <Row className="center">
                                                <Col lg={2} md={12} sm={12}><FormLabel>Type of Procedure: </FormLabel></Col>
                                                <Col lg={5} md={12} sm={12}>
                                                    <FormControl
                                                        type="text"
                                                        name="procedure_type"
                                                        className="center"
                                                        value={this.state.procedure_type}
                                                        onChange={this.handleChange}
                                                    />
                                                    
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup className="m-3">
                                            <Row className="center ">
                                                <Col lg={2} md={12} sm={12}><FormLabel>Description: </FormLabel></Col>
                                                <Col lg={5} md={12} sm={12}>
                                                    <FormControl
                                                        as="textarea"
                                                        name="description"
                                                        className="center"
                                                        value={this.state.description}
                                                        onChange={this.handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup className="m-3">
                                            <Row className="center">
                                                <Col lg={2} md={12} sm={12}><FormLabel>Time Required: </FormLabel></Col>
                                                <Col lg={5} md={12} sm={12}>
                                                    <FormControl
                                                        type="number"
                                                        name="time_required"
                                                        className="center"
                                                        value={this.state.time_required}
                                                        onChange={this.handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup className="m-3">
                                            <Row className="center">
                                                <Col lg={2} md={12} sm={12}><FormLabel>Status: </FormLabel></Col>
                                                <Col lg={4} md={12} sm={12}>
                                                    <FormControl
                                                        as="select"
                                                        name="status"
                                                        type="text"
                                                        className="center"
                                                        value={this.state.status}
                                                        onChange={this.handleChange}
                                                    >
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <Row className="center">
                                            <Col md={12} sm={12} lg={3}><button type="submit" className="btn btn-block btn-primary mt-4 center" >Update</button></Col>
                                        </Row>
                                    </Container>
                                )
                            }
                            
                        </form>
                    </div>
                </div>                
            </div>
        );
    }
}

export default TreatmentProcedureEdit;