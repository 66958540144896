import React, { Component } from 'react';
import { Container, FormGroup, Row, Col, FormControl, FormLabel, Modal } from 'react-bootstrap';
import { request } from '../../constants/constants';

class TreatmentProcedureForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            treatment_procedure: '',
            description: '',
            time: '',
            showSuccessModal: false,
            showError: false
        }
    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const data = { 
            procedure_type: this.state.treatment_procedure, 
            description: this.state.description,
            time_required: this.state.time,
            status: 1
        }

        request('POST', '/procedures', data, true).then(res => {
            this.setState({
                isLoading: false,
                showSuccessModal: true
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                errorData: err.response.data.errors,
                errorMessage: err.response.data.message,
                showError: true,
            })
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    /*Modal Functions */

    hideSuccessModal = () => {
        this.setState({ 
            showSuccessModal: false 
        })
        window.location.href = "/treatmentprocedure";
    }

    hideModalError = () => {
        this.setState({ showError: false })
    }

    render() {
        return (
            <div class="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6>Treatment/Procedure Details Form</h6>
                    </div>
                    <div className="card-body">
                        {/* Modal for Successfully Appointed */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideSuccessModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>Treatment/Procedure Successfully Added!</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideSuccessModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for ERROR  */}
                        <Modal show={this.state.showError}>
                            <Modal.Header onClick={this.hideModalError} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <p className="center" style={{textAlign: "center"}}>{this.state.errorMessage && this.state.errorMessage}</p>     
                            {
                                this.state.errorData &&  Object.keys(this.state.errorData).map(key => <p className="center" style={{textAlign: "center"}}>{this.state.errorData[key]}</p>)
                            }   
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={this.hideModalError}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <form onSubmit={this.handleFormSubmit}>
                            <Container>
                                <FormGroup className="m-3">
                                    <Row className="center">
                                        <Col lg={2} md={12} sm={12}><FormLabel>Type of Procedure: </FormLabel></Col>
                                        <Col lg={5} md={12} sm={12}>
                                            <FormControl
                                                type="text"
                                                name="treatment_procedure"
                                                className="center"
                                                value={this.state.treatment_procedure}
                                                onChange={this.handleChange}
                                            />
                                             
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup className="m-3">
                                    <Row className="center ">
                                        <Col lg={2} md={12} sm={12}><FormLabel>Description: </FormLabel></Col>
                                        <Col lg={5} md={12} sm={12}>
                                            <FormControl
                                                as="textarea"
                                                name="description"
                                                className="center"
                                                value={this.state.description}
                                                onChange={this.handleChange}
                                            />
                                             
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup className="m-3">
                                    <Row className="center">
                                        <Col lg={2} md={12} sm={12}><FormLabel>Time Required: </FormLabel></Col>
                                        <Col lg={5} md={12} sm={12}>
                                            <FormControl
                                                type="number"
                                                name="time"
                                                className="center"
                                                value={this.state.time}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <Row className="center">
                                    <Col sm={12} md={12} lg={3}><button type="submit" className="btn btn-block btn-primary mt-4 center" >Submit</button></Col>
                                </Row>
                            </Container>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default TreatmentProcedureForm;