import React, { Component } from 'react';
import { Container, FormGroup, Row, Col, Button } from 'reactstrap';
import { FormLabel, FormControl, Modal } from 'react-bootstrap';
import { request } from '../../constants/constants';
import Loader from "react-loader-spinner";

class UsersForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            office_telephone: '',
            mobile_number: '',
            permission: '',
            showError: false,
            showSuccessModal: false,
            isLoading: false
        }
    }
    
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        {this.state.status === null || this.state.status === '' ? this.state.status = true : this.state.status = this.state.status}
        console.log(this.state.status);
        const data = {
            title: this.state.title,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            password: this.state.password,
            office_telephone: this.state.office_telephone,
            mobile_number: this.state.mobile_number,
            permission: this.state.permission
        } 
        request('POST', '/auth/register', data, false).then(res => {
            this.setState({
                isLoading: false,
                showSuccessModal: true,
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                errorData: err.response.data.errors,
                showError: true,
            })
        })
    } 

    /*Modal Functions */

    hideModalError = () => {
        this.setState({ showError: false })
    }

    hideModal = e => {
        this.setState({ showSuccessModal: false })
        window.location.href = "/userstable";
    }

    render() {
        return (
            <div class="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6>User Details Form</h6>
                    </div>
                    <div className="card-body">
                        {/* Modal for ERROR  */}
                        <Modal show={this.state.showError}>
                            <Modal.Header onClick={this.hideModalError} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <p className="center" style={{textAlign: "center"}}>{this.state.errorMessage}</p>     
                            {
                                this.state.errorData &&  Object.keys(this.state.errorData).map(key => <p className="center" style={{textAlign: "center"}}>{this.state.errorData[key]}</p>)
                            }                                        
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={this.hideModalError}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Successful Added */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>User Successfully Added!</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <div className="table-responsive">
                            {
                                this.state.isLoading ? (
                                    <Loader
                                        type="TailSpin"
                                        color="#2850C2"
                                        height={80}
                                        width={80}
                                        timeout={10000} 
                                    />
                                ) : (
                                    <form onSubmit={this.handleFormSubmit}>
                                        <Container>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col lg={2}><FormLabel>Title: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={2}>
                                                        <FormControl
                                                            as="select"
                                                            type="text"
                                                            name="title"
                                                            className="center"
                                                            value={this.state.title}
                                                            onChange={this.handleChange}
                                                        >
                                                            <option value="-Select-">-Select-</option>
                                                            <option value="Dr">Dr</option>
                                                            <option value="Mrs">Mrs</option>
                                                            <option value="Ms">Ms</option>
                                                            <option value="Mr">Mr</option>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={2}><FormLabel>First Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="first_name"
                                                            className="center"
                                                            value={this.state.first_name}
                                                            onChange={this.handleChange}
                                                            
                                                        />
                                                    </Col>
                                                    <Col sm={2}><FormLabel>Last Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="last_name"
                                                            className="center"
                                                            value={this.state.last_name}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={2}><FormLabel>Email: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="text"
                                                            name="email"
                                                            className="center"
                                                            value={this.state.email}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col sm={2}><FormLabel>Password: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="password"
                                                            name="password"
                                                            className="center"
                                                            value={this.state.password}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={2}><FormLabel>Office Telephone: </FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="number"
                                                            name="office_telephone"
                                                            className="center"
                                                            value={this.state.office_telephone}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col sm={2}><FormLabel>Mobile Number: </FormLabel></Col>
                                                    <Col sm={4}>
                                                        <FormControl
                                                            type="number"
                                                            name="mobile_number"
                                                            className="center"
                                                            value={this.state.mobile_number}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">    
                                                    <Col lg={2}><FormLabel>Permission: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                        <Col sm={4}>
                                                            <FormControl
                                                                as="select"
                                                                type="text"
                                                                name="permission"
                                                                value={this.state.permission}
                                                                onChange={this.handleChange}
                                                                className="center"
                                                            >
                                                                <option value="-Select-">-Select-</option>
                                                                <option value="super_administrator">super_administrator</option>
                                                                <option value="administrator">administrator</option>
                                                                <option value="staff">staff</option>
                                                                <option value="secretary">secretary</option>                                                        
                                                            </FormControl>
                                                    </Col>
                                                
                                                </Row>
                                            </FormGroup>
                                            <Row className="center">
                                                <Col><button type="submit" className="btn btn-primary mt-4 center" >Submit</button></Col>
                                            </Row>
                                        </Container>
                                    </form>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UsersForm;