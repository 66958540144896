import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { request } from '../../constants/constants';
import UserContext from '../../contexts/User/Index';
import { Modal } from "react-bootstrap";

class InvoicesReport extends Component {
    static contextType = UserContext;

    constructor(props){
        super(props);
        this.state = {
            monthlyPaidTotalInvoices: [],
            monthlyUnpaidTotalInvoices: [], 
            showPaidInvoices: true,
            showUnpaidInvoices: false,
            showEmailSuccessSent: false,
            server_root: '',
            name: 'Paid Invoices',
            total_paid_invoices: 0,
            show_total_invoices: "block"
         }
    }

    componentDidMount(){
        this.init();

        request('GET', '/exporting/paid-invoices/total', {}, true).then((result) => {
            console.log('result-paid-invoices', result)
            this.setState({
                total_paid_invoices: result.data.data,
            })
        }).catch((err) => {
            console.error(err.response);
        })

        request('GET', '/report/invoice/monthly/getReportOfPaidInvoices', {}, true).then((result) => {
            console.log('result', result)
            this.setState({
                monthlyPaidTotalInvoices: result.data.data,
            })
        }).catch((err) => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                errorCode: err.response.status,
                errorMessage: err.response.data.message,
            })
        })

        request('GET', '/urls/get-server-root', {}, true).then((result) => {
            this.setState({
                server_root: result.data.data
            })
        })

        this.setState({
            current_user_email: this.context.user.user.email,
            current_name: this.context.user.user.first_name + ' ' + this.context.user.user.last_name
        })

    }

    init(){
        this.getMonthlyTotalPaidInvoices().then((result) => {
            console.log('result', result)
            this.setState({
                monthlyPaidTotalInvoices: result.data.data,
                monthlyUnpaidTotalInvoices: []
            })
        }).catch((err) => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                errorCode: err.response.status,
                errorMessage: err.response.data.message,
            })
        })
    }

    getMonthlyTotalPaidInvoices(){
        return request('GET', '/report/invoice/monthly/getReportOfPaidInvoices', {}, true);
    }

    reportPaidInvoices = () => {
        this.init();

        this.setState({
            showPaidInvoices: true,
            showUnpaidInvoices: false,
            name: 'Paid Invoices',
            show_total_invoices: "block"
        });
    }

    reportUnpaidInvoices = () => {

        request('GET', '/report/invoice/monthly/getReportOfUnpaidInvoices', {}, true).then((result) => {
            this.setState({
                monthlyUnpaidTotalInvoices: result.data.data,
                monthlyPaidTotalInvoices: []              
            })
        }).catch((err) => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                errorCode: err.response.status,
                errorMessage: err.response.data.message,
            })
        })

        this.setState({
            showPaidInvoices: false,
            showUnpaidInvoices: true,
            name: 'Unpaid Invoices',
            show_total_invoices: "none"
        });
        
    }

    sendEmailReport = (format) => {

        const data = {
            user_email: this.state.current_user_email,
            user_name: this.state.current_name,
        }

        if(format == 'paid'){
            request('POST', '/exporting/paid-invoices/monthly/email-report', data, true).then((result) => {
                this.setState({
                    showEmailSuccessSent: true,
                    successMessage: result.data.message
                })
            }).catch((err) => {
                console.error(err.response);
                this.setState({
                    isLoading: false,
                    errorCode: err.response.status,
                    errorMessage: err.response.data.message,
                });
            });
        }

        if(format == 'unpaid'){
            request('POST', '/exporting/unpaid-invoices/monthly/email-report', data, true).then((result) => {
                this.setState({
                    showEmailSuccessSent: true,
                    successMessage: result.data.message
                })
            }).catch((err) => {
                console.error(err.response);
                this.setState({
                    isLoading: false,
                    errorCode: err.response.status,
                    errorMessage: err.response.data.message,
                });
            });
        }
        
    }

    hideEmailholder = () => {
        this.setState({
            showEmailSuccessSent: false
        })
    }


    render() {
        const { monthlyPaidTotalInvoices, monthlyUnpaidTotalInvoices } = this.state;

        const monthly_labels = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];

        const monthlyPaidData = {
            labels: monthly_labels,
            datasets: [{
                label: 'Total Amount',
                data: monthlyPaidTotalInvoices,
                backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(201, 203, 207, 0.2)'
                ],
                borderColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)'
                ],
                borderWidth: 1
            }]
        }

        const monthlyUnpaidData = {
            labels: monthly_labels,
            datasets: [{
                label: 'Total Amount',
                data: monthlyUnpaidTotalInvoices,
                backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(201, 203, 207, 0.2)'
                ],
                borderColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)'
                ],
                borderWidth: 1
            }]
        }



        return (
            <div className="container-fluid p-3 p-md-5">
                <script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
                <div className="row justify-center mb-4">
                    <div className="col-sm-12 col-md-10 col-lg-12">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800"> Invoices Total Amount</h1>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">{this.state.name} Monthly Total Invoices  <span id="total_invoices" style={{ display: this.state.show_total_invoices }}> {this.state.total_paid_invoices}</span></h6>
                                        <div className="dropdown no-arrow">
                                            <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400" />
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                                                <div className="dropdown-header">Show reports by: </div>
                                                <a className="dropdown-item" onClick={this.reportPaidInvoices}> Paid Invoices </a>
                                                <a className="dropdown-item" onClick={this.reportUnpaidInvoices}> Unpaid Invoices </a>
                                                {/* <a className="dropdown-item" onClick={this.reportsByYear}>Year</a> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="report-card-body">
                                        <Modal show={this.state.showEmailSuccessSent} backdrop="static">
                                            <Modal.Header onClick={this.hideEmailholder} closeButton>
                                                <Modal.Title>
                                                    <h5 className="m-0 font-weight-bold text-danger">Confirmation:</h5>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <p className="center" style={{textAlign: 'center' }}>{ this.state.successMessage }</p>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button className="btn btn-primary center" onClick={this.hideEmailholder}>
                                                    Okay
                                                </button>
                                                
                                            </Modal.Footer>
                                        </Modal>
                                        <div className="chart-area" >
                                            <article className="canvas-container">
                                                {
                                                    this.state.monthlyPaidTotalInvoices.length > 0 ? (
                                                        <React.Fragment>
                                                            <a href={this.state.server_root + '/api/report/paid-invoice/monthly/download-report'}><button className="btn btn-primary mr-lg-5 float-right center"> Download as XLS</button></a>
                                                            <button className="btn btn-primary mr-2 float-right center" onClick={(e) => this.sendEmailReport('paid')}> Send Email</button> 
                                                        </React.Fragment>
                                                        
                                                    ) : (
                                                        <React.Fragment>
                                                            <a href={this.state.server_root + '/api/report/unpaid-invoice/monthly/download-report'}><button className="btn btn-primary mr-lg-5 float-right center"> Download as XLS</button></a>
                                                            <button className="btn btn-primary mr-2 float-right center" onClick={(e) => this.sendEmailReport('unpaid')}> Send Email</button> 
                                                        </React.Fragment>
                                                        
                                                    )
                                                }

                                                {
                                                    this.state.showPaidInvoices && (
                                                        <Bar 
                                                            id='monthlyReport'
                                                            data={monthlyPaidData}
                                                            width={100}
                                                            height={25}    
                                                            options={{ maintainAspectRatio: false }}                                        
                                                        />
                                                    )   
                                                }

                                                {
                                                    this.state.showUnpaidInvoices && (
                                                        <Bar 
                                                            id='monthlyReport'
                                                            data={monthlyUnpaidData}
                                                            width={100}
                                                            height={25}    
                                                            options={{ maintainAspectRatio: false }}                                        
                                                        />
                                                    )
                                                }
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InvoicesReport;