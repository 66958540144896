import React, { Component } from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { request } from '../../constants/constants';
import { FormLabel, FormControl, Modal, Container } from 'react-bootstrap';
import AvatarEditor from 'react-avatar-editor';
import userPic from '../../assets/images/user.png';
import cookies from 'react-cookies';
import Loader from "react-loader-spinner";


class EditProfile extends Component {
    constructor (props){
        super(props);
        this.state = {
            image: userPic,
            id: this.props.match.params.id,            
            width: 200,
            height: 200,
            rotate: 0,
            // position: { x: 0.5, y: 0.5 },
            showSuccessModal: false,
            showErrorModal: false,
            profile_picture: '',
            showModalPwdReset: false,
            isLoading: true,
            new_password: '',
            confirm_new_password: ''
        }
    }

    getDoctor() {
        return request('GET', `/users/${this.state.id}`, {}, true);
    }
    
    init (){
        this.getDoctor().then(res => {
            this.setState({
                title:  res.data.data.title,
                first_name: res.data.data.first_name,
                last_name: res.data.data.last_name, 
                email: res.data.data.email,
                office_telephone: res.data.data.office_telephone,
                mobile_number: res.data.data.office_telephone,
                profile_picture: res.data.data.profile_picture,
                isLoading: false
            })
        })
    }

    componentDidMount() {
        this.init()
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        
        const data = {
            title: this.state.title,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            office_telephone: this.state.office_telephone,
            mobile_number: this.state.mobile_number,
        }

        request('PUT', `/users/${this.state.id}`, data, true).then(res => {
            console.log(res.data);
            const data = cookies.load('user');
            data.user = res.data.data;
            cookies.save('user', data , {path: '/'});
            this.setState({
                isLoading: false
            })
            window.location.href = `/editprofile/${this.state.id}`;
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                showErrorModal: true,
                errorMessage: err.response.data.message,

            })
        })
    }

    setEditorRef = editor => {
        if (editor) this.editor = editor;
    };

    handleNewImage = e => {
        console.log(e.target.files[0])
        this.setState({ profile_picture: e.target.files[0] });
    }

    handleSave = e => {
        e.preventDefault();
        if(typeof(this.state.profile_picture) === 'string'){
            this.setState({
                showErrorModal: true,
                errInvvalidImage: "Please select an image!"
            })
            return false;
        }else{
            if(!this.state.profile_picture.name.match(/\.(jpg|jpeg|png|gif)$/)){
                this.setState({ 
                    showErrorModal: true,
                    errInvvalidImage: "Please select valid image!" });
                return false;
            }else{
                const img = this.editor.getImageScaledToCanvas().toDataURL();
                const data = {
                    profile_picture: img
                }

                request('PATCH', `/users/profile/${this.state.id}`, data, true).then(res => {
                    this.setState({
                        isLoading: false,
                        showSuccessModal: true,
                        successfullMessage: "Profile Picture Successfully Updated!"
                    })
                }).catch(err => {
                    console.error(err.response);
                })
            }
        }
    }

    pwdResetHandler = e => {
        const new_password = this.state.new_password;
        const confirm_new_password = this.state.confirm_new_password;

        if(new_password !== confirm_new_password){
            this.setState({
                pwdResetErrorMessage: "New password doesn't match confirmation."
            })
        } else {
            this.setState({
                pwdResetErrorMessage: ""
            })

            const data = {
                password: this.state.confirm_new_password
            }

            request('PATCH', `/users/passwordReset/${this.state.id}`, data, true).then(res => {
                this.setState({
                    showSuccessModal: true,
                    showModalPwdReset: false,
                    successfullMessage: "Password Reset Successfully!"
                })
            }).catch(err => {
                console.error(err.response);
            })
        }
    }

    /*Modal Functions */
    handleShowModalPwdReset = e => {
        this.setState({
            showModalPwdReset: true
        })
    }

    hideModalPwdReset = e => {
        this.setState({
            showModalPwdReset: false
        })
    }

    hideModal = e => {
        this.setState({ showSuccessModal: false })
        window.location.href = `/editprofile/${this.state.id}`;
    }

    hideErrorModal = e => {
        this.setState({ hideErrorModal: false })
        window.location.href = `/editprofile/${this.state.id}`;
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col lg={8} md={8} sm={12}><h5 className="m-0 font-weight-bold text-primary center mb-2">Edit Profile</h5></Col>
                            <Col sm={12} md={4} lg={2}>
                                <button type="submit" className="btn btn-primary btn-block center" onClick={this.handleShowModalPwdReset}> <i className="fas fa-key" /> Reset Password</button>
                            </Col>
                        </Row>
                    </div>
                    <div className="card-body">
                        <Modal show={this.state.showModalPwdReset} backdrop="static">
                            <Modal.Header onClick={this.hideModalPwdReset} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Reset Password: </h5></Modal.Title>
                            </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <FormGroup>
                                    <Row className="center">
                                        <Col sm={6}><FormLabel>New Password: </FormLabel></Col>
                                        <Col sm={6}>
                                            <FormControl
                                                type="password"
                                                name="new_password"
                                                className="center"
                                                value={this.state.new_password}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row className="center">
                                        <Col sm={6}><FormLabel>Confirm New Password: </FormLabel></Col>
                                        <Col sm={6}>
                                            <FormControl
                                                type="password"
                                                name="confirm_new_password"
                                                className="center"
                                                value={this.state.confirm_new_password}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row className="center">
                                        <Col sm={12}><FormLabel className="text-danger">{this.state.pwdResetErrorMessage} </FormLabel></Col>
                                    </Row>
                                </FormGroup>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-danger center" onClick={this.hideModalPwdReset}>
                                Cancel
                            </button>
                            <button 
                                className="btn btn-primary center" 
                                value={this.state.currentId} 
                                onClick={this.pwdResetHandler}
                            >Reset
                            </button>
                        </Modal.Footer>

                        </Modal>
                        
                        {/* Modal for Successfully Profile Picture Updated */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: 'center'}}>{this.state.successfullMessage}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Error Login */}
                        <Modal show={this.state.showErrorModal}>
                            <Modal.Header onClick={this.hideErrorModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p style={{ textAlign: 'center'}} className="center" >{this.state.errInvvalidImage && this.state.errInvvalidImage} {this.state.errorMessage && this.state.errorMessage}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideErrorModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <form onSubmit={this.handleFormSubmit}>
                            <div className="card">
                                <div className="card-body row">
                                        {
                                            this.state.isLoading ? (
                                                <Loader
                                                    type="TailSpin"
                                                    color="#2850C2"
                                                    height={80}
                                                    width={80}
                                                />
                                            ) : (
                                                <React.Fragment>
                                                    <div className="col-lg-4 col-md-12 col-12 text-center">
                                                        <Container>
                                                            <Row>
                                                                <Col>
                                                                    <AvatarEditor
                                                                        ref={this.setEditorRef}
                                                                        image={this.state.profile_picture === null ? this.state.image : this.state.profile_picture}
                                                                        // width={this.state.width}
                                                                        // height={this.state.height}
                                                                        style={{ width: '100%', height: '100%'}}
                                                                        borderRadius={this.state.width / (100 / 50)}
                                                                        scale={1.2}
                                                                        rotate={0}
                                                                        position={this.state.position}
                                                                        className="editor-canvas"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                        <div className="choose-file mt-2 text-center">
                                                            <input type="file" className="center" name="newProfilePic" onChange={this.handleNewImage}/><br/>
                                                            <button type="submit" className="btn btn-primary center mt-2 btn-block" onClick={this.handleSave}>Upload</button>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-8 col-md-12 col-12">
                                                        <FormGroup>
                                                            <Row className="center mt-md-3 mt-sm-3">
                                                                <Col lg={4} md={4} sm={12}><FormLabel>Title: </FormLabel></Col>
                                                                <Col lg={8} md={8} sm={12}>
                                                                    <FormControl
                                                                        as="select"
                                                                        type="text"
                                                                        name="title"
                                                                        className="center"
                                                                        value={this.state.title}
                                                                        onChange={this.handleChange}
                                                                    >
                                                                        <option>Dr</option>
                                                                        <option>Miss</option>
                                                                        <option>Mrs</option>
                                                                        <option>Ms</option>
                                                                        <option>Mr</option>
                                                                    </FormControl>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Row className="center">
                                                                <Col lg={4} md={4} sm={12}><FormLabel>First Name: </FormLabel></Col>
                                                                <Col lg={8} md={8} sm={12}>
                                                                    <FormControl
                                                                        type="text"
                                                                        name="first_name"
                                                                        className="center"
                                                                        value={this.state.first_name}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Row className="center">
                                                                <Col lg={4} md={4} sm={12}><FormLabel>Last Name: </FormLabel></Col>
                                                                <Col lg={8} md={8} sm={12}>
                                                                    <FormControl
                                                                        type="text"
                                                                        name="last_name"
                                                                        className="center"
                                                                        value={this.state.last_name}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Row className="center">
                                                                <Col lg={4} md={4} sm={12}><FormLabel>Email: </FormLabel></Col>
                                                                <Col lg={8} md={8} sm={12}>
                                                                    <FormControl
                                                                        type="text"
                                                                        name="email"
                                                                        className="center"
                                                                        value={this.state.email}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Row className="center">
                                                                <Col lg={4} md={4} sm={12}><FormLabel>Mobile Number: </FormLabel></Col>
                                                                <Col lg={8} md={8} sm={12}>
                                                                    <FormControl
                                                                        type="text"
                                                                        name="mobile_number"
                                                                        className="center"
                                                                        value={this.state.mobile_number}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Row className="center">
                                                                <Col lg={4} md={4} sm={12}><FormLabel>Office Telephone: </FormLabel></Col>
                                                                <Col lg={8} md={8} sm={12}>
                                                                    <FormControl
                                                                        type="text"
                                                                        name="office_telephone"
                                                                        className="center"
                                                                        value={this.state.office_telephone}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                        <Row className="center">
                                                            <Col><button type="submit" className="btn btn-primary btn-block center">Update</button></Col>
                                                        </Row>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>                
            </div>
        );
    }
}

export default EditProfile;