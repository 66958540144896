import React, { Component } from 'react';
import { Row, Col, Button, FormControl, Modal, FormLabel } from 'react-bootstrap';
import ReactTable from 'react-table';
import { request } from '../../constants/constants';
import moment from 'moment';  
import Pagination from '../../components/Pagination/pagination';
import Loader from "react-loader-spinner";

class SecretaryCancelledBookingsTable extends Component {
    constructor(props) {
        super(props);
            this.state = {
                bookings: [],
                currentDeleteId: '',
                isLoading: true
            };
    }

    componentDidMount() {
        request('GET', '/bookings/get/secretaryCancelledBookings', {}, true).then(result => { 
            this.setState({
                bookings: result.data.data,
                isLoading: false
            })
        })
    }

    handlerRestore = e => {
        const data = {
            id: e.target.value
        }
        request('PATCH', `/bookings/restoreBooking/${e.target.value}`, data, true).then(result => { 
            this.setState({
                isLoading: true
            })
            window.location.href = "/cancelledbookingstable";
        }).catch(err => { 
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                errCode: err.response.status
            })
            this.setState({
                showError: this.state.errCode === 400 ? true : false
            })
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleUpdateBookingDate = e => {
        const data = { 
            date: this.state.date, 
            bookingId: this.state.currentDeleteId,
            slot: this.state.slot,
            doctorId: this.state.doctorId
        }
        request('PUT', `/bookings/bookingDateUpdate/${e.target.value}`, data, true).then(res => {
            this.setState({
                isLoading: false
            })
            window.location.href = "/patientsbookingtable";
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                errCode: err.response.status,
                errMessage: err.response.data.message
            })
            this.setState({
                errMessage: this.state.errCode === 400 ? this.state.errMessage : ''
            })
        })
    }
    
    //Modal Functions

    hideModal = () => {
        this.setState({ show: false });
    };

    showRestoreModal = (id, date, doctorId, slot) => {
        this.setState({ 
            show: true, 
            currentDeleteId: id, 
            date: date,
            doctorId: doctorId, 
            slot: slot
        });
    }

    hideModalError = () => {
        this.setState({ showError: false})
    }

    getResizeValues = (newResized, event) => {
        if(newResized){
            console.log(localStorage.getItem('secCancelledbookingsTableWidths'), newResized)
            if(localStorage.getItem('secCancelledbookingsTableWidths')) {
                const widths = JSON.parse(localStorage.getItem('secCancelledbookingsTableWidths'));
                newResized.forEach(element => {
                    const i = widths.findIndex(width => width.id == element.id);
                    if(i > -1) {
                        const width = widths[i];
                        width.value = element.value
                    } else {
                        widths.push(element);
                    }
                });
                localStorage.setItem("secCancelledbookingsTableWidths", JSON.stringify(widths));

            } else {
                localStorage.setItem("secCancelledbookingsTableWidths", JSON.stringify(newResized));
            }
        }
    }

    getColumnWidth(columnId, defaultSize) {
        if(localStorage.getItem('secCancelledbookingsTableWidths')) {
            const widths =  JSON.parse(localStorage.getItem('secCancelledbookingsTableWidths'));
            const i = widths.findIndex((element) => element.id == columnId);
            if(i > -1) {
                const columnWith = widths[i].value;
                return columnWith;
            }
            return defaultSize;
        }
        return defaultSize;
    }


    render() {
        const columns = [{
            Header: 'Action',
            Cell: row => 
            <React.Fragment>
                <button className="btn btn-dark mr-1 center" onClick={(e) => this.showRestoreModal(`${row.original.ID}`, `${row.original.date}`, `${row.original.doctors.id}`, `${row.original.slot}`)} >Rebook</button>
            </React.Fragment>,
            width: this.getColumnWidth('id', 100),
            id: "id",
            style: { 'whiteSpace': 'unset' },
            className: 'center',
            accessor: 'id'
        },{
            Header: 'Patient Name',
            Cell: row => (<span> {row.original.patients && row.original.patients.title  + '. ' + row.original.patients.first_name + ' ' + row.original.patients.second_name + ' ' + row.original.patients.last_name} </span> ),
            width: this.getColumnWidth('patient', 200),
            id: "patient",
            // id: row => row.patients && row.patients.id,
            accessor: row => row.patients && row.patients.first_name
        },{
            Header: 'Doctor',
            Cell: row => (<span> {row.original.doctors && row.original.doctors.title + '. ' + row.original.doctors.first_name + ' ' + row.original.doctors.last_name} </span>),
            width: this.getColumnWidth('doctor', 200),
            id: "doctor",
            // id: row => row.doctors && row.doctors.id,
            accessor: row => row.doctors && row.doctors.first_name
        },{
            Header: 'Hospital',
            Cell: row => (<span> {row.original.hospitals && row.original.hospitals.name} </span>),
            width: this.getColumnWidth('hospital', 250),
            id: "hospital",
            // id: row => row.doctors && row.hospitals.id,
            accessor: row => row.doctors && row.hospitals.name
        },
        {
            Header: 'Cancellation Reason',
            Cell: row => (<span> {row.original.cancellation_reason} </span>),
            width: this.getColumnWidth('cancellation_reason', 250),
            id: "cancellation_reason",
            accessor: 'cancellation_reason'
        },
        {
            Header: 'Date & Time',
            Cell: row => (<span> {row.original.slot}, {moment(row.original.date).format("DD-MMMM-YYYY")} </span>),
            width: this.getColumnWidth('date_time', 250),
            id: "date_time",
            accessor: 'date'
        }]

        return (
            <div class="container-fluid secretary-cancelled-bookings-table">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col><h6 className="m-0 font-weight-bold text-primary">Cancelled Bookings</h6></Col>
                        </Row>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">

                            {/* Modal for ERROR  */}
                            <Modal show={this.state.showError}>
                                <Modal.Header onClick={this.hideModalError} closeButton>
                                    <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Slot of the day already taken.</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="center">
                                        <Col lg={4}><FormLabel>Change Booking Date: </FormLabel></Col>
                                        <Col sm={6}>
                                            <FormControl
                                                type="date"
                                                className="center"
                                                name="date"
                                                value={this.state.date}
                                                onChange={this.handleChange}
                                            />
                                        </Col>      
                                    </Row>  
                                    <br /> 
                                    <Row className="center">
                                        <Col lg={4}><FormLabel>Change Booking Slot: </FormLabel></Col>
                                        <Col sm={6}>
                                            <FormControl
                                                as="select"
                                                name="slot"
                                                className="center"
                                                value={this.state.slot}
                                                onChange={this.handleChange}
                                            >
                                                <option value="-Select-">-Select-</option>
                                                <option value="AM">AM</option>
                                                <option value="PM">PM</option>                                            
                                            </FormControl>
                                        </Col>
                                    </Row> 
                                    <p className="m-0 text-danger center">{this.state.errMessage}</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-secondary center" onClick={this.hideModalError}>
                                        Cancel
                                    </button>
                                    <button className="btn btn-primary center" value={this.state.currentDeleteId} onClick={this.handleUpdateBookingDate}>
                                        Rebook
                                    </button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal for Delete Booking  */}
                            <Modal show={this.state.show} backdrop="static" onClick={this.hideModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation:</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                <p className="center" style={{textAlign: 'center'}}>Are you sure you want to rebook this record? </p>
                                   
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-primary center"  onClick={this.hideModal}>
                                        Cancel
                                    </button>
                                    <button 
                                        className="btn btn-danger center" 
                                        value={this.state.currentDeleteId} 
                                        onClick={this.handlerRestore}
                                    >Rebook
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            {
                                this.state.isLoading ? (
                                    <Loader
                                        type="TailSpin"
                                        color="#2850C2"
                                        height={80}
                                        width={80}
                                        timeout={10000} 
                                    />
                                ) : (
                                    <ReactTable
                                        className="center"
                                        PaginationComponent={Pagination}
                                        data={this.state.bookings}
                                        columns={columns}
                                        defaultExpanded={true}
                                        minRows={0}
                                        onResizedChange={this.getResizeValues}
                                    />
                                )
                            }

                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SecretaryCancelledBookingsTable;