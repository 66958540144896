import React, { Component } from 'react';
import { Row, Col, FormLabel, FormControl, FormGroup, Modal} from 'react-bootstrap';
import ReactTable from 'react-table';
import Pagination from '../../components/Pagination/pagination';
import { request } from '../../constants/constants';
import moment from 'moment';
import Loader from "react-loader-spinner";
import { Link } from 'react-router-dom';
import './Reports.css';

class Reconciliation extends Component {
    constructor(props){
        super(props);
        this.state = {
            start_date: '',
            end_date: '',
            successMessage: '',
            gross_total_amount: 0,
            isLoading: true,
            isLoadingPrintRecon: false,
            showSuccessModal: false,
            recon_data: []
        }
    }
    
    componentDidMount(){

        request('GET', `/reconciliations/`, {}, true).then(result => { 
            this.setState({
                recon_data: result.data.data,
                isLoading: false
            })     
        }).catch(err => { 
            console.log(err.response)
        });

    }

    handleClickDate = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }



    getResizeValues = (newResized, event) => {
        if(newResized){
            // console.log(localStorage.getItem('invoicesTableWidths'), newResized)
            if(localStorage.getItem('reconciliationTableWidths')) {
                const widths = JSON.parse(localStorage.getItem('reconciliationTableWidths'));
                newResized.forEach(element => {
                    const i = widths.findIndex(width => width.id == element.id);
                    if(i > -1) {
                        const width = widths[i];
                        width.value = element.value
                    } else {
                        widths.push(element);
                    }
                });
                localStorage.setItem("reconciliationTableWidths", JSON.stringify(widths));

            } else {
                localStorage.setItem("reconciliationTableWidths", JSON.stringify(newResized));
            }
        }
    }

    getColumnWidth(columnId, defaultSize) {
        if(localStorage.getItem('reconciliationTableWidths')) {
            const widths =  JSON.parse(localStorage.getItem('reconciliationTableWidths'));
            const i = widths.findIndex((element) => element.id == columnId);
            if(i > -1) {
                const columnWith = widths[i].value;
                return columnWith;
            }
            return defaultSize;
        }
        return defaultSize;
    }

    handleGenerate = () => {
        this.setState({
            isLoading: true
        })
        
        const data = {
            start_date: this.state.start_date,
            end_date: this.state.end_date
        }

        request('POST', `/reconciliations/gerenate-reconciliation-report`, data, true).then(result => { 
            console.log(result.data.data)
            this.setState({
                showSuccessModal: true,
                isLoading: false,
                successMessage: result.data.message,
                recon_data: result.data.data
            })    

        }).catch(err => { 
            console.log(err.response)
            this.setState({
                isLoading: false,
                successMessage: err.response.data.message
            }) 
        });

    }

    hideSuccessModal = () => {
        this.setState({
            showSuccessModal: false
        })
    }


    render() {

        const columns = [ 
            {  
                Header: 'Invoice Date.',  
                id: 'invoice_date',
                width: this.getColumnWidth('invoice_date', 150),
                Cell: (row) => (<span>{`${moment(row.original.invoice_date).format('DD/MM/YYYY')}`}</span>),
                accessor: row => row.invoice_date,                
            },

            {
                Header: 'Invoice Period',  
                id: 'period',
                width: this.getColumnWidth('period', 200),
                Cell: (row) => (<span>{`${moment(row.original.from_date).format('DD/MM/YYYY')} - ${moment(row.original.to_date).format('DD/MM/YYYY')}`}</span>),
                accessor: row => row && row.from_date
            },

            {
                Header: 'Alexus Reference',  
                id: 'alexus_reference',
                width: this.getColumnWidth('alexus_reference', 150),
                Cell: (row) => (<span><Link className="center text-primary" to={`/alexus-report/${row.original.id}`}>{`${row.original.alexus_reference}`}</Link></span>),
                accessor: row => row && row.alexus_reference
            },

            {
                Header: "Exus Invoice",
                id: 'exus_reference',
                width: this.getColumnWidth('exus_reference', 150),
                Cell: (row) => (<span><Link className="center text-primary" to={`/exus-report/${row.original.id}`}>{`${(row.original.exus_reference)}`}</Link></span>),
                accessor: row => row.exus_reference
            },

            {
                Header: "ALS Invoice",
                id: 'als_reference',
                width: this.getColumnWidth('als_reference', 150),
                Cell: (row) => (<span><Link className="center text-primary" to={`/als-report/${row.original.id}`}>{`${(row.original.als_reference)}`}</Link></span>),
                accessor: row => row.als_reference
            },

            {
                Header: "Invoice Amount",
                id: 'total_amount',
                width: this.getColumnWidth('total_amount', 150),
                Cell: (row) => (<span>{`${(row.original.total_amount)}`}</span>),
                accessor: row => row.total_amount
            },

            {
                Header: "No. of Transactions",
                id: 'number_of_transactions',
                width: this.getColumnWidth('number_of_transactions', 150),
                Cell: (row) => (<span>{`${(row.original.number_of_transactions)}`}</span>),
                accessor: row => row.number_of_transactions
            }
        ];


        return (
            <div className="container-fluid p-3 p-md-5">
                <div className="row justify-center mb-4">
                    <div className="col-sm-12 col-md-10">
                        <FormGroup>
                            <Row>
                                <Col sm={3} md={3} lg={2} className="text-right">
                                    <FormLabel className="center">Start Date:</FormLabel>
                                </Col>
                                <Col sm={9} md={9} lg={3}>
                                    <FormControl
                                        type="date"
                                        className="center"
                                        name="start_date"
                                        value={this.state.start_date}
                                        onChange={this.handleClickDate}
                                    />
                                </Col>
                                <Col sm={3} md={3} lg={2} className="text-right">
                                    <FormLabel className="center"> End Date: </FormLabel>
                                </Col>
                                <Col sm={9} md={9} lg={3}>
                                    <FormControl
                                        type="date"
                                        className="center"
                                        name="end_date"
                                        value={this.state.end_date}
                                        onChange={this.handleClickDate}
                                    />
                                </Col>
                                <Col sm={9} md={9} lg={2} className="text-right">
                                    <button className="btn btn-primary center" onClick={this.handleGenerate} style={{ width: '100%'}}> Generate </button>
                                </Col>
                            </Row>   
                        </FormGroup>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col sm={3} md={6} lg={8}>
                                <h5 className="mb-2 font-weight-bold text-primary center">Management Fee Invoices</h5>
                            </Col>
                        </Row>
                    </div>
                    <div className="card-body">
                        {/* Modal Successfully Generated Report */}
                        <Modal show={this.state.showSuccessModal} backdrop="static">
                            <Modal.Header onClick={this.hideSuccessModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{'textAlign': 'center'}}> {this.state.successMessage} </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideSuccessModal}>
                                    Okay
                                </button>
                            </Modal.Footer>
                        </Modal>
                        {
                            this.state.isLoading ? (
                                <Loader
                                    type="TailSpin"
                                    color="#2850C2"
                                    height={80}
                                    width={80}
                                    timeout={15000} //10 secs
                                />
                            ) : (
                                <React.Fragment>
                                    <ReactTable
                                        data={this.state.recon_data}
                                        columns={columns}
                                        style={{ overflow: "wrap" }}
                                        PaginationComponent={Pagination}
                                        className="center"
                                        minRows={0}
                                        getTrProps={this.getTrProps}
                                        onResizedChange={this.getResizeValues} 
                                    />
                                </React.Fragment>
                               
                            )
                        }
                    </div>
                </div>
            </div>

        );
    }
}

export default Reconciliation;