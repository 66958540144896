import React, { Component } from 'react';
import { Row, Col, Container, FormGroup, FormLabel, FormControl, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { request } from '../../constants/constants';
import moment from 'moment';
import UserContext from '../../contexts/User/Index';
import { Multiselect } from 'multiselect-react-dropdown';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class PatientsBookingEdit extends Component {
    static contextType = UserContext;
    constructor (props){
        super(props);
        this.state = {
            id: this.props.match.params.id,
            hospitalId: this.props.match.params.hospId,
            hospitals: [],
            doctors: [],
            procedures: [],
            probe: [],
            date:'',
            booking_status: '',
            showSuccess: false,
            disableFunctionTreated: false,
            disableFunctionCancelled: false,
            showErrorUrNumber: false,
            isLoading: true
        }
    }

    componentDidMount() { 
        request('GET', `/bookings/singleBooking/${this.state.id}`, {}, true).then(result => { 
            console.log(result)
            this.setState({
                hospitalId: result.data.data.hospitals.id,
                hospitals: result.data.data.hospitals,
                doctors: result.data.data.doctors, 
                booking_date: moment(result.data.data.date).format("dddd, MMMM D YYYY"),
                patient_id: result.data.data.patients.id,
                procedure: result.data.data.procedure, 
                procedure_id: result.data.data.procedure_id,
                slot: result.data.data.slot,
                probeRef: result.data.data.probe,
                notes: result.data.data.notes,
                date: result.data.data.date,
                status: result.data.data.status,
                chkboxTreated: result.data.data.treated,
                chkboxCancelled:  result.data.data.status ? false : true,
                patients: result.data.data.patients,
                ur_number: result.data.data.ur_number,
                patient_name: result.data.data.patients.title + '. ' + result.data.data.patients.first_name + ' ' + result.data.data.patients.second_name + ' ' + result.data.data.patients.last_name,
                isLoading: false                
            })
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });
        
        request('GET', `/hospital_procedures/${this.state.hospitalId}`, {}, true).then(result => {
            const procedures = result.data.data.map(obj => ({
                id: obj.procedure_id,
                name: obj.procedure_type
            }));
            this.setState({ procedures }); 
        });        

        request('GET', '/probes/getOnlyActive', {}, true).then(result => {
            const probe = result.data.data.map(obj => ({
                id: obj.ID,
                name: obj.reference
            }));
            this.setState({ probe }); 
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });

        request('GET', `/patients/`, {}, true).then(result => { 
            // console.log(result)
            const allPatients = result.data.data.map(obj => ({
                id: obj.ID,
                name: obj.title + '. ' + obj.first_name + ' ' + obj.second_name + ' ' + obj.last_name
            }));
            this.setState({
                allPatients
            })
            
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // handleBookingStatus = e =>{
    //     this.setState({ booking_status: e.target.value })
    // }

    handleTreated = e => {

        this.setState({
            chkboxTreated: !this.state.chkboxTreated
        })

        if(e.target.checked === true){
            this.setState({
                chkboxTreated: 1,
                disableFunctionCancelled: true
            })
        }else{
            this.setState({
                chkboxTreated: 0,
                disableFunctionCancelled: false
            })
        } 
    }

    handleCancelled = e => {
        this.setState({
            chkboxCancelled: !this.state.chkboxCancelled
        })

        if(e.target.checked === true){
            this.setState({
                booking_status: 0,
                disableFunctionTreated: true,
            })
        }else{
            this.setState({
                booking_status: 1,
                disableFunctionTreated: false
            })
        }
    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })

        let errors = '';
        let formIsValid = true;

        if(this.state.chkboxTreated){
            if(!this.state.ur_number && this.state.probeRef){
                formIsValid = false;
                this.setState({ errorMessage: "Please add UR number"});
            } else if (!this.state.probeRef && this.state.ur_number){
                formIsValid = false;
                this.setState({ errorMessage: "Please add probe"});
            }  else if (!this.state.ur_number && !this.state.probeRef) {
                formIsValid = false;
                this.setState({ errorMessage: "Please add UR number and probe"});
            } else {
                formIsValid = true;
            }
        }
        
        if(formIsValid){
            const data = { 
                patient_id: this.state.patient_id,
                procedure: this.state.procedure,
                procedure_id: this.state.procedure_id,
                slot: this.state.slot,
                probeRef: this.state.probeRef,
                notes: this.state.notes,
                date: this.state.date,
                doctor_id: this.state.doctors.id,
                booking_status: this.state.booking_status,
                treated: this.state.chkboxTreated,
                ur_number: this.state.ur_number
            }

            // console.log(data)
            request('PUT', `/bookings/bookingUpdate/${this.state.id}`, data, true).then(res => {
                console.log("bookingUpdate", res)
                this.setState({
                    isLoading: false,
                    showSuccess: true
                })
            }).catch(err => {
                console.error(err.response);
                this.setState({
                    isLoading: false,
                    error: err.response.data,
                    errCode: err.response.status
                })
                this.setState({
                    showError: this.state.errCode == 400 ? true : false
                })
            })   
        }else {
            this.setState({ showErrorUrNumber: true, isLoading: false })
        }
    }

    hideModalError = () => {
        this.setState({ showError: false })
    }

    hideModalSuccess = () => {
        this.setState({ showSuccess: false})
        window.location.href = `/patientsbookingview/${this.state.id}`;
    }

    hideErrorUrNumber = () => {
        this.setState({
            showErrorUrNumber: false
        })
    }

    render() {
        const userPermission = this.context.user.user.permission;
        const mutiselectStyle = {
            option: { 
                color: "black",
            },
          };
        return (
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col>
                                {/* <h5 className="m-0 font-weight-bold text-primary center">
                                    {this.state.title + '. ' + this.state.first_name + ' ' + this.state.last_name}
                                </h5> */}
                                <h5 className="m-0 font-weight-bold text-primary center">
                                    <p className="m-0 center">{this.state.doctors && (this.state.doctors.title + '. ' + this.state.doctors.first_name + ' ' + this.state.doctors.last_name + ' - ' + this.state.hospitals.name)}</p>
                                </h5>
                                <p className="m-0 center"> {this.state.booking_date} - {this.state.slot}</p>
                                <p className="m-0 center">Patient: {this.state.patient_name}</p>
                            </Col>
                        </Row>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">

                            {/* Modal for SUCCESS Updating  */}
                            <Modal show={this.state.showSuccess}>
                                <Modal.Header onClick={this.hideModalSuccess} closeButton>
                                    <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="center" style={{textAlign: 'center'}}>Booking Successfully Updated! </p>                                             
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={this.hideModalSuccess}>
                                        OK
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            
                            {/* Modal for ERROR  */}
                            <Modal show={this.state.showError}>
                                <Modal.Header onClick={this.hideModalError} closeButton>
                                    <Modal.Title><h5 className="m-0 font-weight-bold text-danger">NOTICE:</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="text-align"><h5>Slot of the day already taken.</h5></p>                                             
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={this.hideModalError}>
                                        OK
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal for ERROR for UR Number  */}
                            <Modal show={this.state.showErrorUrNumber}>
                                <Modal.Header onClick={this.hideErrorUrNumber} closeButton>
                                    <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="center" style={{textAlign: 'center'}}>{this.state.errorMessage}</p>                                             
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={this.hideErrorUrNumber}>
                                        OK
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <form onSubmit={this.handleFormSubmit}>

                                {
                                    this.state.isLoading ? (
                                        <Loader
                                            type="TailSpin"
                                            color="#2850C2"
                                            height={80}
                                            width={80}
                                            timeout={10000} //3 secs
                                        />
                                    ) : (

                                        <Container>
                                            <h6>Booking Details</h6>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={2} md={6} lg={2}><FormLabel>Treatment/Procedure: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={4} md={6} lg={4}>
                                                        {/* <Multiselect
                                                            options={this.state.procedures} 
                                                            selectedValues={this.state.currentProcedures} 
                                                            onSelect={this.onSelect} 
                                                            onRemove={this.onRemove} 
                                                            displayValue="name"
                                                            className="center"
                                                            style={mutiselectStyle}
                                                            avoidHighlightFirstOption="true"
                                                        /> */}
                                                        <FormControl
                                                            as="select"
                                                            type="text"
                                                            name="procedure_id"
                                                            className="center"
                                                            value={this.state.procedure_id}
                                                            onChange={this.handleChange}
                                                        >
                                                            {this.state.procedures.map(obj => ( 
                                                                <option value={obj.id}> {obj.name} </option>
                                                            ))}
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm={2} md={6} lg={1} style={{display: userPermission === 'administrator' || userPermission === 'super_administrator' ? 'block' : 'none'}}><FormLabel>UR Number: </FormLabel></Col>
                                                    <Col sm={4} md={6} lg={4} className="mt-md-2 mt-lg-0" style={{display: userPermission === 'administrator' || userPermission === 'super_administrator' ? 'block' : 'none'}}>
                                                        <FormControl
                                                            type="text"
                                                            name="ur_number"
                                                            className="center"
                                                            value={this.state.ur_number}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <Row className="center">
                                                <Col sm={2} md={6} lg={2}><FormLabel>Booking Date: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                <Col sm={4} md={6} lg={4}>
                                                    <FormControl
                                                        type="date"
                                                        className="center"
                                                        name="date"
                                                        value={this.state.date}
                                                        onChange={this.handleChange}
                                                    />
                                                </Col>
                                                <Col sm={2} md={6} lg={1} className="mt-md-2 mt-lg-0" style={{display: userPermission === 'administrator' || userPermission === 'super_administrator' ? 'block' : 'none'}}><FormLabel>Probe: </FormLabel></Col>
                                                <Col sm={4} md={6} lg={4} className="mt-md-2 mt-lg-0" style={{display: userPermission === 'administrator' || userPermission === 'super_administrator' ? 'block' : 'none'}}>
                                                    <FormControl
                                                        as="select"
                                                        name="probeRef"
                                                        className="center"
                                                        value={this.state.probeRef}
                                                        onChange={this.handleChange}
                                                    >
                                                        <option value="-Select-">-Select-</option>
                                                        {this.state.probe.map(probe => ( 
                                                            <option key={probe.id} value={probe.name}> {probe.name} </option>
                                                        ))}                                            
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row className="center">
                                                <Col sm={2} md={6} lg={2}><FormLabel>Notes: </FormLabel></Col>
                                                <Col sm={4} md={6} lg={4}>
                                                    <FormControl 
                                                        as="textarea"
                                                        name="notes"
                                                        className="center"
                                                        value={this.state.notes}
                                                        onChange={this.handleChange}
                                                    />
                                                </Col>
                                                <Col sm={2} md={6} lg={1} className="mt-md-2 mt-lg-0"><FormLabel>Slot: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                <Col sm={4} md={6} lg={4} className="mt-md-2 mt-lg-0">
                                                    <FormControl
                                                        as="select"
                                                        name="slot"
                                                        className="center"
                                                        value={this.state.slot}
                                                        onChange={this.handleChange}
                                                    >
                                                        <option value="AM">AM</option>
                                                        <option value="PM">PM</option>                                            
                                                    </FormControl>
                                                </Col>
                                                {/* <Col lg={1}><FormLabel>Status: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                <Col sm={2}>
                                                    <FormControl
                                                        as="select"
                                                        type="text"
                                                        name="booking_status"
                                                        className="center"
                                                        value={this.state.booking_status}
                                                        onChange={this.handleBookingStatus}
                                                    >
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>                                            
                                                    </FormControl>
                                                </Col> */}
                                            </Row><br />
                                            <FormGroup>
                                                <Row className="center">                                        
                                                    <Col sm={12} md={0} lg={3}></Col>
                                                    <Col sm={12} md={6} lg={5} style={{display: userPermission === 'administrator' || userPermission === 'super_administrator' ? 'block' : 'none'}}>
                                                        <input 
                                                            type="checkbox" 
                                                            disabled={this.state.chkboxCancelled ? true : this.state.disableFunctionTreated} 
                                                            defaultChecked={this.state.chkboxTreated} 
                                                            checked={this.state.chkboxTreated} 
                                                            onClick={this.handleTreated} 
                                                        /> 
                                                            <span className="center" style={{ color: 'red'}}> Mark as treated</span>
                                                    </Col>
                                                    <Col sm={12} md={6} lg={4}>
                                                        <input type="checkbox" disabled={this.state.chkboxTreated ? true : this.state.disableFunctionCancelled} defaultChecked={this.state.chkboxCancelled} checked={this.state.chkboxCancelled} onClick={this.handleCancelled} /> <span className="center" style={{ color: 'red'}}>Cancelled patient</span>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <Row sm={12} md={12} lg={4}>
                                                <Col><button style={{width: '100%'}} className="btn mt-4 btn-primary center" variant="primary">Update</button></Col>
                                            </Row>
                                        </Container>
                                    )
                                }
                                
                            </form>
                        </div>
                    </div>
                </div>                
            </div>
        );
    }
}

export default PatientsBookingEdit;