import React, { Component } from 'react';
import { Row, Col, Button, FormControl } from 'react-bootstrap';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { request } from '../../constants/constants';
import moment from 'moment';    
import Modal from 'react-bootstrap/Modal';
import Pagination from '../../components/Pagination/pagination';
import Loader from "react-loader-spinner";

class PatientsBookingTable extends Component {
    constructor(props) {
        super(props);
            this.state = {
                bookings: [],
                currentDeleteId: '',
                cancellation_reason: '',
                isLoading: true        
            };
    }
    
    componentDidMount() {
        this.init();
    }

    init(){
        this.getBookings().then(result => { 
            this.setState({
                bookings: result.data.data,
                isLoading: false 
            })
        })
    }

    getBookings(){
        return request('GET', '/bookings/patient/allBookingsForSec', {}, true);
    }

    deleteHandler = e =>{
        const data = {
            id: e.target.value,
            cancellation_reason: this.state.cancellation_reason
        }
        request('POST', `/bookings/cancel/cancelBooking/${e.target.value}`, data, true).then(res => {
            this.setState({
              isLoading: true
            })
            window.location.href = "/patientsbookingtable";
          }).catch(err => { 
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data
            })
          })
    }

    hideModal = () => {
        this.setState({ show: false });
    };

    showDeleteModal = (booking_id) => {
        this.setState({ show: true, currentDeleteId: booking_id });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getResizeValues = (newResized, event) => {
        if(newResized){
            console.log(localStorage.getItem('patientsBookingTableWidths'), newResized)
            if(localStorage.getItem('patientsBookingTableWidths')) {
                const widths = JSON.parse(localStorage.getItem('patientsBookingTableWidths'));
                newResized.forEach(element => {
                    const i = widths.findIndex(width => width.id == element.id);
                    if(i > -1) {
                        const width = widths[i];
                        width.value = element.value
                    } else {
                        widths.push(element);
                    }
                });
                localStorage.setItem("patientsBookingTableWidths", JSON.stringify(widths));

            } else {
                localStorage.setItem("patientsBookingTableWidths", JSON.stringify(newResized));
            }
        }
    }

    getColumnWidth(columnId, defaultSize) {
        if(localStorage.getItem('patientsBookingTableWidths')) {
            const widths =  JSON.parse(localStorage.getItem('patientsBookingTableWidths'));
            const i = widths.findIndex((element) => element.id == columnId);
            if(i > -1) {
                const columnWith = widths[i].value;
                return columnWith;
            }
            return defaultSize;
        }
        return defaultSize;
    }

    render() {
        const columns = [{
            Header: 'Action',
            Cell: row => 
            <React.Fragment>
                {/* <Link to={`/patientsbookingview/${row.original.ID}`}><Button className="btn btn-dark mr-1 center">View</Button></Link>
                <button className="btn btn-danger center" value={`${row.original.ID}`} onClick={this.showDeleteModal} >Cancel</button> */}
                <Link to={`/patientsbookingview/${row.original.ID}`}><i class="far fa-eye" /></Link>
                <i className="fa fa-minus-circle ml-2" style={{color: 'red'}} onClick={() => this.showDeleteModal(row.original.ID)} />

            </React.Fragment>,
            width: this.getColumnWidth('id', 160),
            id: "id",
            className: 'center',
            accessor: 'id'
        },{
            Header: 'Patient Names',
            Cell: row => (<span> {row.original.patients && row.original.patients.title + '. ' + row.original.patients.first_name +  ' ' + row.original.patients.second_name + ' ' + row.original.patients.last_name} </span>),
            width: this.getColumnWidth('patient', 200),
            id: "patient",
            accessor: row => row.patients && row.patients.first_name
        },{
            Header: 'Doctor',
            Cell: row => (<span> {row.original.doctors && row.original.doctors.title + '. ' + row.original.doctors.first_name + ' ' + row.original.doctors.last_name} </span>),
            width: this.getColumnWidth('doctor', 200),
            id: "doctor",
            accessor: row => row.doctors && row.doctors.first_name
        },{
            Header: 'Hospital',
            Cell: row => (<span> {row.original.hospitals && row.original.hospitals.name} </span>),
            width: this.getColumnWidth('hospital', 200),
            id: "hospital",
            accessor: row => row.hospitals && row.hospitals.name
        },{
            Header: 'Procedure',
            Cell: row => (<span> {row.original.procedure} </span>),
            width: this.getColumnWidth('procedure', 200),
            id: "procedure",
            accessor: row => row.procedure && row.procedure
        },{
            Header: 'UR Number',
            Cell: row => (<span> {row.original.ur_number} </span>),
            width: this.getColumnWidth('ur_number', 200),
            id: "ur_number",
            accessor: row => row.ur_number && row.ur_number
        },{
            Header: 'Time and Date ',
            Cell: row => (<span> {row.original.slot}, {moment(row.original.date).format("DD-MMMM-YYYY")}</span>),
            width: this.getColumnWidth('date', 200),
            id: "date",
            accessor: row => row.date && row.date
        },{
            Header: 'Booking Status',
            Cell: row => (<span> {row.original.treated ? 'Treated' : (row.original.status ? 'Active' : 'Cancelled' )} </span>),
            width: this.getColumnWidth('treated', 200),
            id: "treated",
            accessor: row => row.treated && row.treated
        }];
        
        return (
            <div class="container-fluid patients-booking-table">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col><h6 className="m-0 font-weight-bold text-primary">Patient Bookings</h6></Col>
                        </Row>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">

                            {/* Modal for Delete Booking  */}
                            <Modal show={this.state.show} backdrop="static">
                                <Modal.Header onClick={this.hideModal} closeButton>
                                    <Modal.Title><h6>Are you sure you want to CANCEL this booking?</h6></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <h6>Cancellation Reason:</h6>
                                    <FormControl 
                                        as="textarea"
                                        name="cancellation_reason"
                                        className="center"
                                        onChange={this.handleChange}
                                    />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" className="center" onClick={this.hideModal}>
                                        Cancel
                                    </Button>
                                    <Button 
                                        className="btn btn-danger center" 
                                        value={this.state.currentDeleteId} 
                                        onClick={this.deleteHandler}
                                    >Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {
                                this.state.isLoading ? (
                                    <Loader
                                        type="TailSpin"
                                        color="#2850C2"
                                        height={80}
                                        width={80}
                                        timeout={10000} 
                                    />
                                ) : (
                                    <ReactTable 
                                        className="center"
                                        PaginationComponent={Pagination}
                                        data={this.state.bookings}
                                        columns={columns}
                                        defaultExpanded={true}
                                        minRows={0}
                                        onResizedChange={this.getResizeValues}   
                                    />
                                )
                            }

                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PatientsBookingTable;