import React, { Component } from 'react';
import { request } from '../../constants/constants';
import ReactTable from 'react-table';
import Pagination from '../../components/Pagination/pagination';
import { Row, Col, Button, FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Modal, FormControl, FormLabel } from 'react-bootstrap';
import UserContext from '../../contexts/User/Index';
import SearchField from "react-search-field";
import Loader from "react-loader-spinner";

class HospitalsTable extends Component {
  static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            hospitals: [],
            procedures: [],
            hospitalDiscounts: [],
            procedure_name: [],
            showDiscountModal: false,
            showSuccessModal: false,
            showDiscountInfo: false,
            showCycleModal: false,
            procedure_id: '',
            successMessage: "",
            displayErrorLine: "none",
            discountNumber1: '',
            discountNumber2: '',
            discountPrice1: '',
            discountPrice2: '',
            discountPrice3: '',
            searchHospital: '',
            isLoading: true
        };
      }

    componentDidMount() {
      this.init();
    }

    init(){
      this.getHospital().then(result => {
        const hospitals = result.data.data.map(obj => ({
          id: obj.id,
          code: obj.code,
          name: obj.name,
          office_telephone: obj.office_telephone, 
          office_telephone_2: obj.office_telephone_2,
          office_facsmile: obj.office_facsmile, 
          office_email: obj.office_email,
          office_address: obj.office_address + ' ' + obj.office_address_2 + ' ' + obj.office_suburb + ' ' + obj.office_state,
          postal_address: obj.postal_address + ' ' + obj.postal_address_2 + ' ' + obj.postal_suburb + ' ' + obj.postal_state,
          contact_name: obj.contact_title + ' ' + obj.contact_first_name + ' ' + obj.contact_last_name,
          contact_mobile: obj.contact_mobile,
          contact_telephone: obj.contact_telephone,
          contact_facsmile: obj.contact_facsmile,
          website: obj.website,
          num_rooms: obj.num_rooms,
          status: obj.status
        }));
        this.setState({ hospitals, isLoading: false });
      })
    }

    getHospital = () => {
      return request('GET', '/hospitals/Admin/adminHospitalList', {}, true);
    }

    deleteHandler = e => {
      this.setState({
          isLoading: false
      })
      const data = {
        id: e.target.value
      }

      request('DELETE', `/hospitals/${e.target.value}`, data, true).then(res => {
        this.setState({
          isLoading: false
        })
        window.location.href = "/hospitalstable";
      }).catch(err => {
        console.error(err.response);
        this.setState({
            isLoading: false,
            error: err.response.data
        })
      })
    }

    /*Handle Functions*/
    handleProcedure = e => {
      this.setState({
          procedure_id: e.target.value
      })
    }

    handleDelete = (hospital_id) => {
      this.setState({ currentId: hospital_id, showDeleteModal: true})
    }

    handleChange = e => {
      this.setState({
          [e.target.name]: e.target.value
      })
    }

    /* Modal Function */
    handleAddDiscount = (hospitalId) => {
      // console.log(hospitalId)
      this.setState({
        showDiscountModal: true,
        hospitalId: hospitalId
      })

      request('GET', `/hospital_procedures/${hospitalId}`, {}, true).then(result => {
        const hospitalProc = result.data.data.map(procedure => ({
            id: procedure.procedure_id,
            procedure_name: procedure.procedure_type
        }));
        this.setState({
            procedures: hospitalProc
        });
        // console.log(this.state.procedures)
      }).catch(err => {
        console.error(err);
        this.setState({
            isLoading: false,
            error: err.response
        })
      })
     
    }

    handleEditTimes = (hospitalDiscountId, hospitalId, procedure_id) => {
      this.setState({
        hospitalId: hospitalId,
        showDiscountModal: true,
        showDiscountInfo: false,
        procedure_id: procedure_id,
        currenthospitalDiscountId: hospitalDiscountId
      })

      request('GET', `/hospital_procedures/${hospitalId}`, {}, true).then(result => {
        const hospitalProc = result.data.data.map(procedure => ({
            id: procedure.procedure_id,
            procedure_name: procedure.procedure_type
        }));
        this.setState({
            procedures: hospitalProc
        });
        console.log(this.state.procedures)
      }).catch(err => {
        console.error(err);
        this.setState({
            isLoading: false,
            error: err.response
        })
      })

      request('GET', `/hospital_discounts/getById/${hospitalDiscountId}`, {}, true).then(result => {
        // console.log(result)
        this.setState({
            hospitalDiscountId: result.data.data.id,
            hospital_id: result.data.data.hospital_id,
            procedure_name: result.data.data.procedure_name,
            procedure_id: result.data.data.procedure_id,
            times: result.data.data.times,
            first_discount_number: result.data.data.first_discount_number,
            first_discount_price: result.data.data.first_discount_price,
            second_discount_number: result.data.data.second_discount_number,
            second_discount_price: result.data.data.second_discount_price,
            rest_discount_price: result.data.data.rest_discount_price
        });
    }).catch(err => {
      console.error(err);
      this.setState({
          isLoading: false,
          error: err.response
      })
    })
  }

  handleAddCycle = (hospitalDiscountId, hospitalId, times) => {
    const forInputDiscountNumber = [];
    const forInputDiscountPrice = [];
    
    for (var i = 1; i <= times; i++) {      
      if(times === 2){
        if (i===1){ 
          var wordNum = 'First'; 
        } else { 
          var wordNum = 'Rest';
        }
      } else if (times === 3){
        if (i===1){ 
          var wordNum = 'First';
        }else if(i===2){ 
          var wordNum ='Second';
        }else {
          var wordNum ='Rest';
        }
      }

      forInputDiscountNumber.push(<span><FormControl
                                    type="number"
                                    name={"discountNumber" + i}
                                    className="center"
                                    placeholder={wordNum}
                                    value={this.state.discountNumber}
                                    disabled = {wordNum === 'Rest' ? true : false}
                                    onChange={this.handleChange}
                                /><br/></span>);
      forInputDiscountPrice.push(<span><FormControl
                                    type="number"
                                    name={"discountPrice" + i}
                                    className="center"
                                    value={this.state.discountPrice}
                                    onChange={this.handleChange}
                                /><br/></span>);
    }
    this.setState({
      showCycleModal: true, 
      showDiscountInfo: false,
      forInputDiscountNumber: forInputDiscountNumber,
      forInputDiscountPrice: forInputDiscountPrice,
      hospitalId: hospitalId,
      hospitalDiscountId: hospitalDiscountId
    }, () => {
      console.log(this.state.wordNum)
    })
  }

  handleSaveRange = e => {
    const hospitalId = this.state.hospitalId;
    const hospitalDiscountId = this.state.hospitalDiscountId;

    const data = {
      hospital_id: hospitalId,
      first_discount_number: this.state.discountNumber1,
      first_discount_price: this.state.discountPrice1,
      second_discount_number: this.state.discountNumber2,
      second_discount_price: this.state.discountPrice2,
      rest_discount_price: this.state.discountPrice3
    }

    request('PUT', `/hospital_discounts/update/${hospitalDiscountId}`, data, true).then(result => {
      console.log(result)
      this.setState({
          showDiscountModal: false,
          showSuccessModal: true,
          showCycleModal: false,
          successMessage: "Successfully Added!"
      });
    }).catch(err => {
      console.error();
      this.setState({
        isLoading: false,
        errorData: err.response.data.errors,
        errorExist: err.response.data.message,
        error: err.response.data,
        displayErrorLine: "block"
      })
    })
  }

    handleShowDiscount = e => {
      const hospitalId = e ;
      request('GET', `/hospital_discounts/${hospitalId}`, {}, true).then(result => {
        console.log(result)
        this.setState({
          hospitalDiscounts: result.data.data.map(obj => ({
            hospitalDiscountId: obj ? obj.id : "",
            hospital_id: obj ? obj.hospital_id : "",
            procedure_name: obj.procedure_name,
            procedure_id: obj.procedure_id,
            times: obj ? obj.times : "",
            first_discount_number: obj ? obj.first_discount_number : "",
            first_discount_price: obj ? obj.first_discount_price : "",
            second_discount_number: obj ? obj.second_discount_number : "",
            second_discount_price: obj ? obj.second_discount_price : "",
            rest_discount_price: obj ? obj.rest_discount_price : ""
          }))
        });
        this.setState({
          showDiscountInfo: true
        })
      }).catch(err => {
        console.error(err);
        this.setState({
          isLoading: false,
          error: err.response
        })
      })
    }

    hideDiscountModal = () => {
      this.setState({
        showDiscountModal: false,
        errorData: '',
        errorExist: '',
        procedure_name: '',
        times: ''
      })
    }

    hideDiscountInfo = () => {
      this.setState({ 
        showDiscountInfo: false,
      })
      window.location.href ="/hospitalstable";
    }

    handleAddDiscountTimes = e => {
      if(this.state.currenthospitalDiscountId){
        const data = {
          times: this.state.times,
          hospital_id: this.state.hospitalId,
          procedure_id: this.state.procedure_id
        }
        request('PUT', `/hospital_discounts/update/${this.state.currenthospitalDiscountId}`, data, true).then(result => {
          // console.log(result)
          this.setState({
              showDiscountModal: false,
              showSuccessModal: true,
              successMessage: "Successfully Updated!"
          });
        }).catch(err => {
          console.error();
          this.setState({
            isLoading: false,
            errorData: err.response.data.errors,
            errorExist: err.response.data.message,
            error: err.response.data,
            displayErrorLine: "block"
          })
        })
      }else{
        const data = {
          times: this.state.times,
          hospital_id: this.state.hospitalId,
          procedure_id: this.state.procedure_id
        }
        request('POST', `/hospital_discounts`, data, true).then(result => {
          // console.log(result)
          this.setState({
              showDiscountModal: false,
              showSuccessModal: true,
            successMessage: "Successfully Added!"
          });
        }).catch(err => {
          console.error();
          this.setState({
            isLoading: false,
            errorData: err.response.data.errors,
            errorExist: err.response.data.message,
            error: err.response.data,
            displayErrorLine: "block"
          })
        })
      }

    }
    
    hideModal = e => {
      this.setState({ showSuccessModal: false, showDeleteModal: false })
    }

    hideCycleModal = e => {
      this.setState({ showCycleModal: false})
    }

    handleSearchHospital = (e) => {
      const searchHospital = e ;
      if(!searchHospital){ 
        this.init();
      } else {
          request('GET', `/hospitals/search/searchHospitals?hospital=${searchHospital}`, {}, true).then(result => {  
              console.log(result)
              const hospitals = result.data.data.map(obj => ({
                id: obj.id,
                code: obj.code,
                name: obj.name,
                office_telephone: obj.office_telephone, 
                office_telephone_2: obj.office_telephone_2,
                office_facsmile: obj.office_facsmile, 
                office_email: obj.office_email,
                office_address: obj.office_address + ' ' + obj.office_address_2 + ' ' + obj.office_suburb + ' ' + obj.office_state,
                postal_address: obj.postal_address + ' ' + obj.postal_address_2 + ' ' + obj.postal_suburb + ' ' + obj.postal_state,
                contact_name: obj.contact_title + ' ' + obj.contact_first_name + ' ' + obj.contact_last_name,
                contact_mobile: obj.contact_mobile,
                contact_telephone: obj.contact_telephone,
                contact_facsmile: obj.contact_facsmile,
                website: obj.website,
                num_rooms: obj.num_rooms,
                status: obj.status
              }));
              this.setState({ hospitals });
              
          }).catch(err => { 
              console.error(err.response);
              this.setState({
                  isLoading: false,
                  error: err.response
              })
          });
      }
    }

    getResizeValues = (newResized, event) => {
      if(newResized){
          console.log(localStorage.getItem('hospitalsTableWidths'), newResized)
          if(localStorage.getItem('hospitalsTableWidths')) {
              const widths = JSON.parse(localStorage.getItem('hospitalsTableWidths'));
              newResized.forEach(element => {
                  const i = widths.findIndex(width => width.id == element.id);
                  if(i > -1) {
                      const width = widths[i];
                      width.value = element.value
                  } else {
                      widths.push(element);
                  }
              });
              localStorage.setItem("hospitalsTableWidths", JSON.stringify(widths));

          } else {
              localStorage.setItem("hospitalsTableWidths", JSON.stringify(newResized));
          }
      }
  }

  getColumnWidth(columnId, defaultSize) {
      if(localStorage.getItem('hospitalsTableWidths')) {
          const widths =  JSON.parse(localStorage.getItem('hospitalsTableWidths'));
          const i = widths.findIndex((element) => element.id == columnId);
          if(i > -1) {
              const columnWith = widths[i].value;
              return columnWith;
          }
          return defaultSize;
      }
      return defaultSize;
  }

  hideDeleteModal = () => {
    this.setState({
      showDeleteModal: false
    });
  }
  
    render() {
        const columns = [{
            Header: 'Action',
            Cell: row => 
              <React.Fragment>
                {/* <Link to={`/hospitalview/${row.original.id}`} ><Button className="btn btn-dark mr-1 center">View</Button></Link>
                <Button className="btn btn-danger center" >Delete</Button> */}
                <i className="far fa-trash-alt" style={{color: 'red'}} onClick={() => this.handleDelete(row.original.id)}/>
                <Link to={`/hospitalview/${row.original.id}`} ><i class="far fa-eye ml-2" /></Link>

              </React.Fragment>, 
              width: this.getColumnWidth('action_id', 180),
              id: "action_id",
              accessor: row => row.ID
          },{
            Header: 'Code',
            width: this.getColumnWidth('code', 180),
            id: "code",
            accessor: 'code'
          },{
            Header: 'Name',
            width: this.getColumnWidth('name', 250),
            id: "name",
            accessor: 'name',
            style: { 'whiteSpace': 'unset' } 
          },
          {
            Header: 'Status',
            Cell: row => 
            <React.Fragment>
              {row.original.status === 1 ? 'Active' : 'Inactive'}
            </React.Fragment>,
            width: this.getColumnWidth('status', 180),
            id: "status",
            accessor: row => row.status
          },
          {
            Header: "Add Discounts",
            Cell: row => (<button className="btn btn-primary center" onClick={(e => this.handleAddDiscount(row.original.id))}>Add Discount</button>),
            width: this.getColumnWidth('add_discounts', 180),
            id: "add_discounts",
          },
          {
            Header: "Show Discounts",
            Cell: row => (<button className="btn btn-secondary center" onClick={(e => this.handleShowDiscount(row.original.id))}>Discount Info</button>),
            width: this.getColumnWidth('show_discounts', 180),
            id: "show_discounts",
          }
        ];

        return (
            <div className="container-fluid hospitals-table">
                {/* <h1 class="h3 mb-2 text-gray-800">Tables</h1> */}
                {/* <p class="mb-4">DataTables is a third party plugin that is used to generate the demo table below. For more information about DataTables, please visit the official DataTables documentation.</p> */}
                <div className="card shadow mb-4">
                  <div className="card-header py-3">
                    <Row>
                      <Col sm={3} md={6} lg={4}><h6 className="mb-2 font-weight-bold text-primary">List of Hospitals</h6></Col>
                      <Col sm={12} md={6} lg={4}>
                        <SearchField
                          placeholder="Search ..."
                          // onChange={this.handleSearchHospital}
                          onSearchClick={this.handleSearchHospital}
                          onEnter={this.handleSearchHospital}
                          classNames="mb-2 center"
                          name="searchHospital"
                          value={this.state.searchHospital}
                        />
                      </Col>
                      <Col sm={12} md={12} lg={2}>
                        <Link to="/hospitalsform"><button style={{width: '100%'}} type="submit" className="btn btn-primary center"> <i class="far fa-hospital" /> Add Hospital</button></Link>
                      </Col>
                    </Row>
                    
                  </div>
                  <div className="card-body">
                    {/* Modal for Add Cycle of Discounts */}
                    <Modal show={this.state.showCycleModal} backdrop="static">
                        <Modal.Header onClick={this.hideCycleModal} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Discount Range: </h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <Row className="center">
                            <Col sm={4}>
                              <p className="center">Discount Number:</p>
                              {
                                this.state.forInputDiscountNumber && this.state.forInputDiscountNumber 
                              }
                            </Col>
                            <Col sm={6}>
                            <p className="center">Discount Price:</p>
                              {
                                this.state.forInputDiscountPrice && this.state.forInputDiscountPrice
                              }
                            </Col>
                          </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary center" onClick={this.handleSaveRange}>
                                Save
                            </button>
                        </Modal.Footer>
                    </Modal>

                    {/* Modal for Successful Added */}
                    <Modal 
                      show={this.state.showDiscountInfo} 
                      backdrop="static"
                      size = "lg"
                    >
                        <Modal.Header onClick={this.hideDiscountInfo} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Discount Information: </h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <span className="center">
                            {this.state.hospitalDiscounts.length > 0 ? <span>Procedures:</span> : <span>No discount available.</span>}
                            {
                              this.state.hospitalDiscounts && 
                              this.state.hospitalDiscounts.map(obj => 
                                <span><ul className="m-2">
                                  <li>{obj.procedure_name} - {obj.times} times <Link><i class="fas fa-edit" onClick={(e => this.handleEditTimes(obj.hospitalDiscountId, obj.hospital_id, obj.procedure_id))}/> <i class="fas fa-plus-circle" onClick={(e => this.handleAddCycle(obj.hospitalDiscountId, obj.hospital_id, obj.times))}/></Link>
                                  </li>
                                </ul>
                              {obj.times === 3 ? (<p className="m-20 center">{"First"} {obj.first_discount_number} - {obj.first_discount_price }<br/>
                                                                            {"Second"} {obj.second_discount_number} - {obj.second_discount_price}<br/>
                                                                            {"Rest"} - {obj.rest_discount_price}</p>  ) : 
                                                  (<p className="m-20 center">{"First"} {obj.first_discount_number} - {obj.first_discount_price }<br/>
                                                                              {"Rest"} - {obj.rest_discount_price}</p>)}
                                                        
                                </span>) 
                            }
                          </span>
                        
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary center" onClick={this.hideDiscountInfo}>
                                Okay
                            </button>
                        </Modal.Footer>
                    </Modal>

                    {/* Modal for Successful Added */}
                    <Modal show={this.state.showSuccessModal} backdrop="static">
                        <Modal.Header onClick={this.hideModal} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <p style={{textAlign: "center"}} className="center">{this.state.successMessage}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary center" onClick={this.hideModal}>
                                Okay
                            </button>
                        </Modal.Footer>
                    </Modal>

                    {/* Modal for Delete Booking  */}
                    <Modal show={this.state.showDeleteModal} backdrop="static">
                      <Modal.Header onClick={this.hideDeleteModal} closeButton>
                          <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <p className="center" style={{textAlign: "center"}}>Are you sure you want to delete this Hospital?</p>
                      </Modal.Body>
                      <Modal.Footer>
                          <Button variant="secondary" className="center" onClick={this.hideDeleteModal}>
                              Cancel
                          </Button>
                          <Button 
                              className="btn btn-danger center" 
                              value={this.state.currentId} 
                              onClick={this.deleteHandler}
                          >Delete
                          </Button>
                      </Modal.Footer>
                    </Modal>

                    {/* Add Discount Modal */}
                    <Modal show={this.state.showDiscountModal} backdrop="static">
                      <Modal.Header onClick={this.hideDiscountModal} closeButton>
                          <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Times of Discount: </h5></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row className="center m-2">
                          <Col sm={4}><FormLabel>Procedure: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                          <Col sm={8}>
                              <FormControl
                              as = "select"
                              type="text"
                              name="procedure_id"
                              className="center"
                              value={this.state.procedure_id}
                              onChange={this.handleProcedure}
                              >
                              <option value="-Select-">-Select-</option>
                              {this.state.procedures.map(procedure => (
                                  <option value={procedure.id}>{procedure.procedure_name}</option>
                              ))}
                              </FormControl>
                            </Col>
                          </Row>
                          <Row className="center m-2">
                            <Col sm={4}><FormLabel>How many times you will add discount? <span style={{color: 'red'}}>*</span></FormLabel></Col>
                            <Col sm={8}>
                              <FormControl
                                type="number"
                                name="times"
                                className="center"
                                value={this.state.times}
                                onChange={this.handleChange}
                              />
                            </Col>
                          </Row>
                          <Row className="center m-2" >
                            {
                              this.state.errorData &&  Object.keys(this.state.errorData).map(key => <p style={{textAlign: "center", color: "#FF0000", display: this.state.displayErrorLine}} className="center">{this.state.errorData[key]} {this.state.errorExist}</p>)
                            }
                            {
                               this.state.errorExist && <p style={{textAlign: "center", color: "#FF0000", display: this.state.displayErrorLine}} className="center">{this.state.errorExist}</p>
                            }
                          </Row>
                      </Modal.Body>
                      <Modal.Footer>
                          <button className="btn btn-danger center" onClick={this.hideDiscountModal}>
                              Cancel
                          </button>
                          <button 
                              className="btn btn-primary center" 
                              value={this.state.currentId} 
                              onClick={this.handleAddDiscountTimes}
                          >Save
                          </button>
                      </Modal.Footer>
                    </Modal>

                    <div className="table-responsive center">
                      {
                        this.state.isLoading ? (
                          <Loader
                              type="TailSpin"
                              color="#2850C2"
                              height={80}
                              width={80}
                              timeout={10000} 
                          />
                        ) : (
                          <ReactTable
                            PaginationComponent={Pagination}
                            data={this.state.hospitals}
                            columns={columns}
                            defaultPageSize={10}
                            minRows={0}
                            style={{overflow:'wrap'}}
                            onResizedChange={this.getResizeValues}
                          />
                        )
                      }
                    
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}

export default HospitalsTable;