import React, { Component } from 'react';
import { Col, Row, Button, Modal, FormControl } from 'react-bootstrap';
import { request } from '../../constants/constants';
import { Link } from 'react-router-dom';
import moment from 'moment';    
import ReactTable from 'react-table';
import Loader from "react-loader-spinner";
import SearchField from "react-search-field";
import Pagination from '../../components/Pagination/pagination';

class AdminBookingPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            currentDeleteId: '',
            searchedVal: '',
            searchDoctor: '',
            searchHospital: '',
            searchPatient: '', 
            first_name: '',
            isLoading: true,
        }
    }

    getBookings(){
        return request('GET', '/bookings/allBookingsForAdmin/', {}, true);
    }

    init(){
        this.getBookings().then(result => {
            console.log("result", result)
            const booking_details = result.data.data.map(val => ({
                patient_name: val.patients.first_name + ' ' + val.patients.last_name,
                doctor_name: val.doctors.title + '. ' + val.doctors.first_name + ' ' + val.doctors.last_name,
                hospital_name: val.hospitals.name,
                procedure: val.procedure
            }));
            this.setState({
                booking_details: booking_details,
                isLoading: false
            })
            this.setState({
                bookings: result.data.data
            })
        }).catch(err => { 
            console.error(err.response);
            this.setState({
                isLoading: false,
                // error: err.response.data
            })
        });
    }

    
    componentDidMount() {
        this.init();
    }

    showDeleteModal = (booking_id) => {
        this.setState({ show: true, currentDeleteId: booking_id });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    deleteHandler = e =>{
        const data = {
            id: e.target.value,
            cancellation_reason: this.state.cancellation_reason
        }
        
        request('POST', `/bookings/cancel/cancelBooking/${e.target.value}`, data, true).then(res => {
            // console.log(res);
            // this.setState({
            //   isLoading: true,
            //   show: false
            // })
            // window.location.href = "/generalBookings";
          }).catch(err => { 
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data
            })
          })
    }

    hideModal = e => {
        this.setState({ show: false })
    }

    handleOnChangeSearch = e => {
        this.setState({
            [e.target.name]: e.target.value
        })     
    }

    handleApplyFilter = () => {
        const data = {
            searchPatient: this.state.searchPatient,
            searchDoctor: this.state.searchDoctor,
            searchHospital: this.state.searchHospital,
        }
        this.setState({
            isLoading: true
        })       
        request('POST', `/bookings/search/searchForBooking`, data, true).then(result => {
            // console.log('@handleApplyFilter', result)
            this.setState({
                bookings: result.data.data,
                isLoading: false
            })
        });
    }
    
    handleReload = () => {
        window.location.href = '/generalBookings';
    }

    getResizeValues = (newResized, event) => {
        if(newResized){
            // console.log(localStorage.getItem('adminBookingTableWidths'), newResized)
            if(localStorage.getItem('adminBookingTableWidths')) {
                const widths = JSON.parse(localStorage.getItem('adminBookingTableWidths'));
                newResized.forEach(element => {
                    const i = widths.findIndex(width => width.id == element.id);
                    if(i > -1) {
                        const width = widths[i];
                        width.value = element.value
                    } else {
                        widths.push(element);
                    }
                });
                localStorage.setItem("adminBookingTableWidths", JSON.stringify(widths));

            } else {
                localStorage.setItem("adminBookingTableWidths", JSON.stringify(newResized));
            }
        }
    }

    getColumnWidth(columnId, defaultSize) {
        if(localStorage.getItem('adminBookingTableWidths')) {
            const widths =  JSON.parse(localStorage.getItem('adminBookingTableWidths'));
            const i = widths.findIndex((element) => element.id == columnId);
            if(i > -1) {
                const columnWith = widths[i].value;
                return columnWith;
            }
            return defaultSize;
        }
        return defaultSize;
    }
    
    render() {        
        // console.log("Bookings Collection", this.state.bookings)
        const columns = [{
            Header: 'Action',
            Cell: row => 
            <React.Fragment>
                {/* <Link to={`/patientsbookingview/${row.original.ID}`}><Button className="btn btn-dark mr-1 center">View</Button></Link>
                <button className="btn btn-danger center" value={`${row.original.ID}`} onClick={this.showDeleteModal} >Cancel</button> */}
                <i className="fa fa-minus-circle" style={{color: 'red'}} onClick={(e) => this.showDeleteModal(row.original.ID)} />
                <Link to={`/patientsbookingview/${row.original.ID}`}><i class="far fa-eye ml-2" /></Link>

            </React.Fragment>,
            className: 'center',
            width: this.getColumnWidth('action_id', 160),
            id: "action_id",
            accessor: props => props.patients && (props.patients.first_name ? props.patients.first_name : '')
        },{
            Header: 'Patient',
            Cell: row => (<span>{row.original.patients && row.original.patients.title + '. ' + row.original.patients.first_name + ' ' + row.original.patients.last_name }</span>),
            width: this.getColumnWidth('patient', 200),
            id: "patient",
            accessor: props => props.patients && (props.patients.first_name ? props.patients.first_name : '')
        },{
            Header: 'Doctor',
            Cell: row => (<span> {row.original.doctors && row.original.doctors.title + '. ' + row.original.doctors.first_name + ' ' + row.original.doctors.last_name} </span>),
            width: this.getColumnWidth('doctor', 200),
            id: "doctor",
            accessor: props => props.doctors && (props.doctors.first_name ? props.doctors.first_name : ''),
        },{
            Header: 'Hospital',
            Cell: row => (<span> {row.original.hospitals && row.original.hospitals.name} </span>),
            width: this.getColumnWidth('hospital', 200),
            id: "hospital",
            accessor: props => props.hospitals && (props.hospitals.name ? props.hospitals.name : ''),
        },{
            Header: 'Procedure',
            Cell: row => (<span> {row.original.procedure} </span>),
            width: this.getColumnWidth('procedure', 150),
            id: "procedure",
            accessor: row => row.procedure
        },{
            Header: 'UR Number',
            Cell: row => (<span> {row.original.ur_number} </span>),
            width: this.getColumnWidth('ur_number', 150),
            id: "ur_number",
            accessor: row => row.ur_number
        },{
            Header: 'Time and Date ',
            Cell: row => (<span> {row.original.slot}, {moment(row.original.date).format("DD-MMMM-YYYY")}</span>),
            width: this.getColumnWidth('time_date', 200),
            id: "time_date",
            accessor: row => row.date
        },{
            Header: 'Status',
            Cell: row => (<span> {row.original.treated ? 'Treated' : (row.original.status ? 'Active' : 'Cancelled' )} </span>),
            width: this.getColumnWidth('status', 100),
            id: "status",
            accessor: row => row.status
        }];

        return (
            <div className="container-fluid admin-bookings">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col><h5 className="mb-4 m-md-0 font-weight-bold text-primary center"><Link onClick={this.handleReload}> All Bookings</Link></h5></Col>
                            <Col sm={4} md={4} lg={2}>
                                <FormControl
                                    type="text"
                                    name="searchPatient"
                                    value={this.state.searchPatient}
                                    onChange={this.handleOnChangeSearch}
                                    className="mb-4 m-md-0 center"
                                    placeholder="Search Patient ..."
                                />
                            </Col>
                            <Col sm={4} md={4} lg={2}>
                                <FormControl
                                    type="text"
                                    name="searchDoctor"
                                    value={this.state.searchDoctor}
                                    onChange={this.handleOnChangeSearch}
                                    className="mb-4 m-md-0 center"
                                    placeholder="Search Doctor ..."
                                />
                            </Col>
                            <Col sm={4} md={4} lg={2} className="m-0 mt-md-3 mt-lg-0">
                                <FormControl
                                    type="text"
                                    name="searchHospital"
                                    value={this.state.searchHospital}
                                    onChange={this.handleOnChangeSearch}
                                    className="mb-4 m-md-0 center"
                                    placeholder="Search Hospital ..."
                                />
                            </Col>
                            <Col sm={4} md={4} lg={2} className="m-0 mt-md-3 mt-lg-0">
                                <button type="button" className="mb-4 m-md-0 btn btn-primary center" onClick={this.handleApplyFilter}>Search</button>
                            </Col>
                            {/* <Col>
                                <SearchField
                                    placeholder="Search Patient..."
                                    onChange={this.handleSearchPatient}
                                    onSearchClick={this.handleSearchPatient}
                                    classNames="center"
                                    name="searchPatient"
                                    value={this.state.searchPatient}
                                />
                            </Col>
                            <Col>
                                <SearchField
                                    placeholder="Search Doctor..."
                                    onChange={this.handleSearchDoctor}
                                    onSearchClick={this.handleSearchDoctor}
                                    classNames="center"
                                    name="searchDoctor"
                                    value={this.state.searchDoctor}
                                />
                            </Col>
                            <Col>
                                <SearchField
                                    placeholder="Search Hospital..."
                                    onChange={this.handleSearchHospital}
                                    onSearchClick={this.handleSearchHospital}
                                    classNames="center"
                                    name="searchHospital"
                                    value={this.state.searchHospital}
                                />
                            </Col> */}
                            {/* <Col sm={2}>
                                <Link to="/doctorsbooking"><button type="submit" className="btn btn-primary center"> <i class="far fa-calendar-check" /> New Booking</button></Link>
                            </Col> */}
                        </Row>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            
                            {/* Modal for Delete Booking  */}
                            <Modal show={this.state.show} backdrop="static">
                                <Modal.Header onClick={this.hideModal} closeButton>
                                    <Modal.Title><h6>Are you sure you want to CANCEL this booking?</h6></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <h6>Cancellation Reason:</h6>
                                    <FormControl 
                                        as="textarea"
                                        name="cancellation_reason"
                                        className="center"
                                        onChange={this.handleChange}
                                    />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" className="center" onClick={this.hideModal}>
                                        Cancel
                                    </Button>
                                    <Button 
                                        className="btn btn-danger center" 
                                        value={this.state.currentDeleteId} 
                                        onClick={this.deleteHandler}
                                    >Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            
                            {
                                this.state.isLoading ? ( 
                                    <Loader
                                        type="TailSpin"
                                        color="#2850C2"
                                        height={80}
                                        width={80}
                                    />
                                ) : (
                                    <ReactTable 
                                        className="center"
                                        PaginationComponent={Pagination}
                                        data={this.state.bookings}
                                        columns={columns}
                                        minRows={0}
                                        onResizedChange={this.getResizeValues}
                                    />
                                )
                            }
                            
                        </div>
                    </div>
                </div>                
            </div>
        );
    }
}

export default AdminBookingPage;