import React, { Component } from 'react';
import { Container, FormGroup, Row, Col, Button } from 'reactstrap';
import { FormControl, FormLabel, Modal } from 'react-bootstrap';
import { request } from '../../constants/constants';
import { Multiselect } from 'multiselect-react-dropdown';
import moment from 'moment';
import { Link } from 'react-router-dom';

class BookingCycleForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            doctors: [],
            hospitals: [],
            procedures: [],
            startDateW1: [],
            startDateW2: [],
            startDateW3: [],
            startDateW4: [],
            showSuccessModal: false,
            showError: false,
            showCommenceDateModal: false,
            showWeekOne: false,
            showWeekTwo: false,
            showWeekThree: false,
            showWeekFour: false,
            start_date: ""
        }
    }

    componentDidMount(){
        request('GET', '/doctors/allDoctorsByAdminOnlyActive', {}, true).then(result => { 
            const doctors = result.data.data.map(obj => ({
                id: obj.ID,
                name: obj.title + '. ' + obj.first_name + ' ' + obj.last_name,
                hospitals: obj.hospitals
            }));
            this.setState({ doctors }); 
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });      

        request('GET', '/booking_cycles/getDates', {}, true).then(result => { 
            this.setState({ 
                 startDateW1: Object.entries(result.data.start_dates_week_one),  
                 startDateW2: Object.entries(result.data.start_dates_week_two),
                 startDateW3: Object.entries(result.data.start_dates_week_three),
                 startDateW4: Object.entries(result.data.start_dates_week_four)  
            }); 
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        });  
    }

    handleDoctor = e => {
        const id = e.target.value;
        if(id==="-Select-"){
            return false
        }
        const index = this.state.doctors.findIndex(doctor => doctor.id == id);
        const doctor = this.state.doctors[index];
        this.setState({
            hospitals: doctor.hospitals,
            doctor_id: id
        })
    }

    handleHospital = e => {
        const hospitalId = e.target.value; 
        if(hospitalId==="-Select-"){
            return false
        }
        this.setState({
            hospital_id: hospitalId
        })

        request('GET', `/hospital_procedures/${hospitalId}`, {}, true).then(result => {
            // console.log('result', result)
            const hospitalProc = result.data.data.map(procedure => ({
                id: procedure.procedure_id,
                name: procedure.procedure_type
            }));
            this.setState({
                procedures: hospitalProc
            });
            // console.log(this.state.procedures)
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoading: false,
                error: err.response
            })
        })
    }

    handleProcedure = e => {
        const procedureId = e.target.value;

        if(procedureId ==="-Select-"){
            return false
        }
        this.setState({
            procedure_id: procedureId
        })
    }

    handleChange = e => {
        const val = e.target.value;
        if(val === "-Select-"){
            return false;
        }
        this.setState({
            [e.target.name]: val
        })
    }

    onSelect = (selectedList, selectedItem) => {
        this.setState({
            selectedProcedures: selectedList
        })
    }

    onRemove = (selectedList, removedItem) => {
        this.setState({
            selectedProcedures: selectedList
        })
    }  
    
    handleFormSubmit = e => {
        e.preventDefault();
       
        const data =  {
            start_date: this.state.start_date,
            doctor_id: this.state.doctor_id,
            hospital_id: this.state.hospital_id,
            procedure_id: this.state.selectedProcedures,
            slot: this.state.slot,
            day: this.state.day,
            week: this.state.week,
            status: 1
        }
        
        // console.log("data", data)
        request('POST', '/booking_cycles/', data, true).then(res => {
            console.log("booking_cycles_res", res)
            this.setState({
                isLoading: false, 
                showSuccessModal: true,
                successMessage: "Booking Cycle Data Successfully Added!"
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                errorMessage: err.response.data.message,
                // errorData: err.response.data.errors,
                showError: true,
            })
        })
    }

    /* Modal Functions */
    hideModalSuccess = () => {
        this.setState({ showSuccessModal: false })
        window.location.href = "/bookingcyclepage";
    }
    
    hideModalError = () => {
        this.setState({ showError: false })
    }

    handleCommenceDates = () => {
        this.setState({
            showCommenceDateModal: true
        })
    }

    hideCommenceDateModal = () => {
        this.setState({
            showCommenceDateModal: false
        })
    }

    /* Commencement Dates onClick Functions */

    handleWeekOne = () => {
        this.setState({
            showWeekOne: !this.state.showWeekOne
        })
    }
    
    handleWeekTwo = () => {
        this.setState({
            showWeekTwo: !this.state.showWeekTwo
        })
    }
    
    handleWeekThree= () => {
        this.setState({
            showWeekThree: !this.state.showWeekThree
        })
    }

    handleWeekFour = () => {
        this.setState({
            showWeekFour: !this.state.showWeekFour
        })
    }

    onChange = (e) =>{ 
        this.setState({ 
            start_date: e.target.value 
        }) 
    }


    render() {
        const mutiselectStyle = {
            option: { 
                color: "black",
            },
          };
        return (
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Container>
                            <Row>
                                <Col sm={12} md={6} lg={9}><h5 className="mb-sm-2 font-weight-bold text-primary center">Booking Cycle Form</h5></Col>
                                <Col sm={12} md={6} lg={3}>
                                    <button type="submit" style={{width: '100%'}} className="btn btn-primary center" onClick={this.handleCommenceDates}><i class="far fa-calendar-check"></i> Commencement Dates</button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="card-body">

                        {/* Modal for Commencement Dates */}
                        <Modal 
                            show={this.state.showCommenceDateModal}
                            backdrop="static"
                        >
                            <Modal.Header onClick={this.hideCommenceDateModal} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Commencement Dates: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="center">
                                <Link onClick={this.handleWeekOne}> Week 1: </Link><br/>
                                <ul style={{display: this.state.showWeekOne ? 'block' : 'none'}}>
                                    {this.state.startDateW1.map((item, index) => {
                                        return <li> {moment(item[1]).format('MMMM D YYYY')} </li>;                                     
                                    })}
                                </ul>
                                
                                <Link onClick={this.handleWeekTwo}> Week 2: </Link><br/>
                                <ul style={{display: this.state.showWeekTwo ? 'block' : 'none'}}>
                                    {this.state.startDateW2.map((item, index) => {
                                        return <li> {moment(item[1]).format('MMMM D YYYY')} </li>;                                     
                                    })}
                                </ul>

                                <Link onClick={this.handleWeekThree}> Week 3: </Link><br/>
                                <ul style={{display: this.state.showWeekThree ? 'block' : 'none'}}>
                                    {this.state.startDateW3.map((item, index) => {
                                        return <li> {moment(item[1]).format('MMMM D YYYY')} </li>;                                     
                                    })}
                                </ul>

                                <Link onClick={this.handleWeekFour}> Week 4: </Link>
                                <ul style={{display: this.state.showWeekFour ? 'block' : 'none'}}>
                                    {this.state.startDateW4.map((item, index) => {
                                        return <li> {moment(item[1]).format('MMMM D YYYY')} </li>;                                     
                                    })}
                                </ul>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideCommenceDateModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Patient Successfully Added  */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideModalSuccess} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation!</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>{this.state.successMessage}</p>     
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideModalSuccess}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for ERROR  */}
                        <Modal show={this.state.showError}>
                            <Modal.Header onClick={this.hideModalError} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <p className="center" style={{textAlign: "center"}}>{this.state.errorMessage}</p>     
                            {
                                this.state.errorData &&  Object.keys(this.state.errorData).map(key => <p className="center" style={{textAlign: "center"}}>{this.state.errorData[key]}</p>)
                            }                                        
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={this.hideModalError}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>


                        <form onSubmit={this.handleFormSubmit}>
                            <Container>
                                <FormGroup>
                                    <Row className="center">
                                        <Col sm={12} md={4} lg={2}><FormLabel>Doctor: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col sm={12} md={8} lg={4}>
                                            <FormControl
                                                as="select"
                                                type="text"
                                                name="doctor"
                                                onChange={this.handleDoctor}
                                                className="mb-md-2 center"
                                            >
                                                <option value="-Select-">-Select-</option>
                                                {this.state.doctors.map(doctor => ( 
                                                    <option value={doctor.id}> {doctor.name} </option>
                                                ))}
                                            </FormControl>
                                        </Col>
                                        <Col sm={12} md={4} lg={1}><FormLabel>Slot: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col sm={12} md={8} lg={4}>
                                            <FormControl
                                                as="select"
                                                type="text"
                                                name="slot"
                                                onChange={this.handleChange}
                                                className="center"
                                            >
                                                <option value="-Select-">-Select-</option>
                                                <option value="AM">AM</option>
                                                <option value="PM">PM</option>                                           
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row className="center">
                                        <Col sm={12} md={4} lg={2}><FormLabel>Hospital: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col sm={12} md={8} lg={4}>
                                            <FormControl
                                                as="select"
                                                type="text"
                                                name="hospital"
                                                onChange={this.handleHospital}
                                                className="mb-md-2 center"
                                            >
                                                <option value="-Select-">-Select-</option>
                                                {this.state.hospitals.map(hospital => ( 
                                                    (hospital.pivot.deleted_at ? "" : <option value={hospital.id}> {hospital.name} </option>)
                                                ))}
                                            </FormControl>
                                        </Col>
                                        <Col sm={12} md={4} lg={1}><FormLabel>Day: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col sm={12} md={8} lg={4}>
                                            <FormControl
                                                as="select"
                                                type="text"
                                                name="day"
                                                onChange={this.handleChange}
                                                className="center"
                                            >
                                                <option value="-Select-">-Select-</option>
                                                <option value="Monday">Monday</option>
                                                <option value="Tuesday">Tuesday</option>
                                                <option value="Wednesday">Wednesday</option>
                                                <option value="Thursday">Thursday</option>
                                                <option value="Friday">Friday</option>
                                                <option value="Saturday">Saturday</option>
                                                <option value="Sunday">Sunday</option>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row className="center">
                                        <Col sm={12} md={4} lg={2}><FormLabel>Treatment/Procedure: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col sm={12} md={8} lg={4}>
                                            <Multiselect
                                                options={this.state.procedures} 
                                                selectedValues={this.state.selectedValue} 
                                                onSelect={this.onSelect} 
                                                onRemove={this.onRemove} 
                                                displayValue="name"
                                                className="center"
                                                style={mutiselectStyle}
                                                avoidHighlightFirstOption="true"
                                            />
                                        </Col>
                                        <Col sm={12} md={4} lg={1}><FormLabel>Week: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col sm={12} md={8} lg={4}>
                                            <FormControl
                                                as="select"
                                                type="text"
                                                name="week"
                                                onChange={this.handleChange}
                                                className="mt-md-2 center"
                                            >
                                                <option value="-Select-">-Select-</option>
                                                <option value="1" style={{ color: '#000000'}}>Week 1</option>
                                                <option value="2" style={{ color: '#c10001'}}>Week 2</option>
                                                <option value="3" style={{ color: '#9900ff'}}>Week 3</option>
                                                <option value="4" style={{ color: '#009900'}}>Week 4</option>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row className="center">
                                        <Col sm={12} md={4} lg={2}><FormLabel>Start Date: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col sm={12} md={8} lg={4}>
                                            <FormControl
                                                type="date"
                                                className="center"
                                                name="date"
                                                value={this.state.date}
                                                onChange={this.onChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="center">
                                        <Col sm={12} md={12} lg={2}><button style={{width: '100%'}} type="submit" className="btn btn-primary mt-4 center" >Submit</button></Col>
                                    </Row>
                                </FormGroup>
                            </Container>
                        </form>
                    </div>
                </div>                
            </div>
        );
    }
}

export default BookingCycleForm;