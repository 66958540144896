import React, { Component } from 'react';
import { request } from '../../constants/constants';
import { FormControl, FormLabel, Modal } from 'react-bootstrap';
import { Col, FormGroup, Row, Button, Container } from 'reactstrap';
import Loader from "react-loader-spinner";

class DoctorEdit extends Component {
    constructor (props){
        super(props);
        this.state = {
            id: this.props.match.params.id,
            showSuccessModal: false,
            chkbox: false,
            isLoading: true
        }
    }

    getDoctor() {
        console.log(this.state.id);
        return request('GET', `/doctors/${this.state.id}`, {}, true);
    }

    init (){
        this.getDoctor().then(res => {
            this.setState({
                title: res.data.data.title,
                first_name: res.data.data.first_name, 
                last_name: res.data.data.last_name,
                user_id: res.data.data.user_id,
                status: res.data.data.status,
                mobile: res.data.data.mobile,
                office_telephone: res.data.data.office_telephone,
                office_telephone_2: res.data.data.office_telephone_2,
                office_facsmile: res.data.data.office_facsmile,
                office_email: res.data.data.office_email,
                office_address: res.data.data.office_address,
                office_address_2: res.data.data.office_address_2,
                office_suburb: res.data.data.office_suburb,
                office_state: res.data.data.office_state,
                office_postcode: res.data.data.office_postcode,
                postal_address: res.data.data.postal_address,
                postal_address_2: res.data.data.postal_address_2,
                postal_suburb: res.data.data.postal_suburb,
                postal_state: res.data.data.postal_state,
                postal_post_code: res.data.data.postal_post_code,
                isLoading: false
            })
        })
    }

    componentDidMount() {
        this.init()
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleClick = e => {
        this.setState({
            chkbox: !this.state.chkbox
        })
        if(e.target.checked === true){
            this.setState({
                postal_address: this.state.office_address,
                postal_address_2: this.state.office_address_2,
                postal_suburb: this.state.office_suburb,
                postal_state: this.state.office_state,
                postal_post_code: this.state.office_postcode
            })
        } else {
            this.setState({
                postal_address: '',
                postal_address_2: '',
                postal_suburb: '',
                postal_state: '',
                postal_post_code: ''
            })
        }
    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const data = {
            title: this.state.title,
            first_name: this.state.first_name,
            last_name: this.state.last_name,           
            status: this.state.status,
            mobile: this.state.mobile,
            office_telephone: this.state.office_telephone,
            office_telephone_2: this.state.office_telephone_2,
            office_email: this.state.office_email,
            office_facsmile: this.state.office_facsmile,
            office_address: this.state.office_address,
            office_address_2: this.state.office_address_2,
            office_suburb: this.state.office_suburb,
            office_state: this.state.office_state,
            office_postcode: this.state.office_postcode,
            postal_address: this.state.postal_address,
            postal_address_2: this.state.postal_address_2,
            postal_suburb: this.state.postal_suburb,
            postal_state: this.state.postal_state,
            postal_post_code: this.state.postal_post_code
        }
        request('PUT', `/doctors/${this.state.id}`, data, true).then(res => {
            console.log(res)
            this.setState({
                isLoading: false,
                showSuccessModal: true
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                errorData: err.response.data.errors,
                showError: true,
            })
        })
    }

    /*Modal Functions */

    hideModal = e => {
        this.setState({ showSuccessModal: false })
        window.location.href = `/doctorview/${this.state.id}`;
    }
    
    hideModalError = () => {
        this.setState({ showError: false })
    }

    render() {
        return (
            <div class="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Doctor Edit Form</h6>
                    </div>
                    <div className="card-body">
                        {/* Modal for Successful Login */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>Doctor Successfully Updated!</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for ERROR  */}
                        <Modal show={this.state.showError} backdrop="static">
                            <Modal.Header onClick={this.hideModalError} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <p className="text-align" style={{textAlign: "center"}}>{this.state.errorMessage}</p>     
                            {
                                this.state.errorData &&  Object.keys(this.state.errorData).map(key => <p style={{textAlign: "center"}}>{this.state.errorData[key]}</p>)
                            }                                        
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={this.hideModalError}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                    <div className="table-responsive">
                    <form onSubmit={this.handleFormSubmit}>
                        {
                            this.state.isLoading ? (
                                <Loader
                                    type="TailSpin"
                                    color="#2850C2"
                                    height={80}
                                    width={80}
                                    timeout={10000} 
                                />
                            ) : (
                                <Container>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12}><FormLabel>Title: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={2} md={12}>
                                                <FormControl
                                                    as="select"
                                                    type="text"
                                                    name="title"
                                                    className="center"
                                                    value={this.state.title}
                                                    onChange={this.handleChange}
                                                >
                                                    <option value="">-Select-</option>
                                                    <option value="Dr">Dr</option>
                                                    <option value="Miss">Miss</option>
                                                    <option value="Mrs">Mrs</option>
                                                    <option value="Ms">Ms</option>
                                                    <option value="Mr">Mr</option>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12}><FormLabel>First Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="text"
                                                    name="first_name"
                                                    className="center"
                                                    value={this.state.first_name}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col lg={2} md={12}><FormLabel className='mt-2 mt-lg-0'>Last Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="text"
                                                    name="last_name"
                                                    className="center"
                                                    value={this.state.last_name}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12}><FormLabel>Mobile Number: </FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="number"
                                                    name="mobile"
                                                    className="center"
                                                    value={this.state.mobile}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col lg={2} md={12}><FormLabel className='mt-2 mt-lg-0'>Status: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    as="select"
                                                    name="status"
                                                    type="text"
                                                    className="center"
                                                    value={this.state.status}
                                                    onChange={this.handleChange}
                                                >
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <hr/>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12}><FormLabel>Office Telephone: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="number"
                                                    name="office_telephone"
                                                    className="center"
                                                    value={this.state.office_telephone}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col lg={2} md={12}><FormLabel className='mt-2 mt-lg-0'>Office Telephone 2: </FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="number"
                                                    name="office_telephone_2"
                                                    className="center"
                                                    value={this.state.office_telephone_2}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12}><FormLabel>Office Email: </FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="email"
                                                    name="office_email"
                                                    className="center"
                                                    value={this.state.office_email}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col lg={2} md={12}><FormLabel className='mt-2 mt-lg-0'>Office Facsmile: </FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="text"
                                                    name="office_facsmile"
                                                    className="center"
                                                    value={this.state.office_facsmile}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <hr/>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12}><FormLabel>Office Address: </FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="text"
                                                    name="office_address"
                                                    className="center"
                                                    value={this.state.office_address}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col lg={2} md={12}><FormLabel className='mt-2 mt-lg-0'>Office Address 2: </FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="text"
                                                    name="office_address_2"
                                                    className="center"
                                                    value={this.state.office_address_2}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12}><FormLabel>Office Suburb: </FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="text"
                                                    name="office_suburb"
                                                    className="center"
                                                    value={this.state.office_suburb}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col lg={2} md={12}><FormLabel className='mt-2 mt-lg-0'>Office State: </FormLabel></Col>
                                            <Col lg={2} md={12}>
                                                <FormControl
                                                    as="select"
                                                    type="text"
                                                    name="office_state"
                                                    className="center"
                                                    value={this.state.office_state}
                                                    onChange={this.handleChange}
                                                >
                                                    <option value="">-Select-</option>
                                                    <option value="ACT">ACT</option>
                                                    <option value="NSW">NSW</option>
                                                    <option value="VIC">VIC</option>
                                                    <option value="TAS">TAS</option>
                                                    <option value="QLD">QLD</option>
                                                    <option value="SA">SA</option>
                                                    <option value="WA">WA</option>
                                                    <option value="NT">NT</option>
                                                </FormControl>
                                            </Col>                               
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12}><FormLabel>Office Postcode: </FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="text"
                                                    name="office_postcode"
                                                    className="center"
                                                    value={this.state.office_postcode}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <input type="checkbox" ckecked={this.state.chkbox} onClick={this.handleClick} /> <span className="center" style={{ color: 'red'}}>Click this if you have the same Office and Postal Address</span>
                                    <hr/>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12}><FormLabel>Postal Address: </FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="text"
                                                    name="postal_address"
                                                    className="center"
                                                    value={this.state.postal_address}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col lg={2} md={12}><FormLabel className='mt-2 mt-lg-0'>Postal Address 2: </FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="text"
                                                    name="postal_address_2"
                                                    className="center"
                                                    value={this.state.postal_address_2}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12}><FormLabel>Postal Suburb: </FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="text"
                                                    name="postal_suburb"
                                                    className="center"
                                                    value={this.state.postal_suburb}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col lg={2} md={12}><FormLabel className='mt-2 mt-lg-0'>Postal State: </FormLabel></Col>
                                            <Col lg={2} md={12}>
                                                <FormControl
                                                    as="select"
                                                    type="text"
                                                    name="postal_state"
                                                    className="center"
                                                    value={this.state.postal_state}
                                                    onChange={this.handleChange}
                                                >
                                                    <option value="">-Select-</option>
                                                    <option value="ACT">ACT</option>
                                                    <option value="NSW">NSW</option>
                                                    <option value="VIC">VIC</option>
                                                    <option value="TAS">TAS</option>
                                                    <option value="QLD">QLD</option>
                                                    <option value="SA">SA</option>
                                                    <option value="WA">WA</option>
                                                    <option value="NT">NT</option>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12}><FormLabel>Postal Postcode: </FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="text"
                                                    name="postal_post_code"
                                                    className="center"
                                                    value={this.state.postal_post_code}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            
                                        </Row>
                                    </FormGroup>
                                    <Row className="center">
                                        <Col lg={3} md={12} sm={12}><button style={{width: "100%"}} type="submit" className="btn btn-primary center" >Update</button></Col>
                                    </Row>
                                </Container>
                            )
                        }
                    
                    </form>                   
                    </div>
                </div>
                </div>
                
            </div>
        );
    }
}

export default DoctorEdit;