import React, { Component } from 'react';
import { Router } from "react-router-dom";
import { UserProvider } from "./contexts/User/Index";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-table/react-table.css';
import cookies from 'react-cookies';
import history from './history';
import Header from './components/Headers/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Routes from './routes/Routes';
// import SecretaryPage from './pages/Calendar/SecretaryPage';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: cookies.load('user')
    }
  }

  setUser = (data) => {
    this.setState({
      user: data
    });
  }

  componentDidMount(){
    if(this.state.user) {
        cookies.save('user', {
          ...this.state.user
        }, {path: '/'});
        this.setState({
          user: cookies.load('user')
        })
    }
  }
  
  render() {
    return (
      <Router history={history}>
        <UserProvider 
          value={{
            user: this.state.user,
            setUser: this.setUser
          }}
        >        
          <div id="wrapper" classname="App">
            {
              this.state.user && <Sidebar />
              // this.state.user && (this.state.user.user.permission === 'secretary' ? '' : <Sidebar />)
            }
              <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                  {
                    this.state.user && <Header />
                  }
                  <Routes />
                </div>
              </div>
          </div>
        </UserProvider>
      </Router>
    );
  }
}

export default App;
