import React, { Component } from 'react';
import { FormGroup, FormControl, Button, Modal } from 'react-bootstrap';
import { request } from '../../constants/constants';
import cookie  from 'react-cookies';
import './Login.css';
import { Link } from 'react-router-dom';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            showUnauthorisedModal: false,
            showUserInactiveModal: false
        }
    }
    
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const data = {
            email: this.state.email,
            password: this.state.password
        }
        request('POST', '/auth/login', data, false).then(res => {
            this.setState({
                isLoading: false
            })

            // console.log("USER ", res.data);
            cookie.save('user', res.data, {path: '/'});

            if(res.data.user.permission === "secretary"){
                window.location.href = "/secretarypage";
            }else{
                window.location.href = "/bookingscalendar";
            }
            
        }).catch(err => {
            console.log(err)
            this.setState({
                isLoading: false,
                error: err.response.data,
                errResponseCode: err.response.status,
            }, () => {
                this.setState({
                    showUnauthorisedModal: this.state.errResponseCode === 401 ? true : false,
                    showUserInactiveModal: this.state.errResponseCode === 403 ? true : false
                })
            })
        })
    }

    hideModal = e => {
            this.setState({ showUnauthorisedModal: false, showUserInactiveModal: false})
    }

    render() {
        
        return (
            <div className="LoginForm">
                {/* Modal for Unauthorised*/}
                <Modal show={this.state.showUnauthorisedModal}>
                    <Modal.Header onClick={this.hideModal} closeButton>
                        <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Warning: </h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="center" style={{ textAlign: "center"}}>Invalid Username or Password!</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary center" onClick={this.hideModal}>
                            OK
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showUserInactiveModal}>
                    <Modal.Header onClick={this.hideModal} closeButton>
                        <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Warning: </h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="center" style={{ textAlign: "center"}}>User is Inactive. Please contact the administrator to change the status.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary center" onClick={this.hideModal}>
                            OK
                        </button>
                    </Modal.Footer>
                </Modal>
                
                <form onSubmit={this.handleFormSubmit}>
                    <div className="login-container">
                        <h6 className="login-h3">Sign In</h6>
                        <FormGroup>
                            <label className="login-label">Email address</label>
                            <FormControl
                                className="login-label"
                                aria-label="Email"
                                type="text"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label className="login-label">Password</label>
                            <FormControl
                                className="login-label"
                                aria-label="Password"
                                type="password"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                        <button type="submit" className="btn btn-primary btn-block login-btn">Login</button>
                        <p className="forgot-password text-right mt-2"> Forgot <a href="#"><Link to="forgotpassword">password?</Link></a></p>
                    </div>
                </form>
            </div>
        );
    }
}

export default Login;