import React, { Component } from 'react';
import { request } from '../../constants/constants';
import ReactTable from 'react-table';
import Pagination from '../../components/Pagination/pagination';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormControl, FormLabel, Modal, Form } from 'react-bootstrap';
import UserContext from '../../contexts/User/Index';
import SearchField from "react-search-field";
import Loader from "react-loader-spinner";

class UsersTable extends Component {
  static contextType = UserContext;
    constructor(props) {
      super(props);
      this.state = {
          hospitals: [],
          showDeleteModal: false,
          toggleInactiveUsers: false,
          searchUser: '',
          isLoading: true
      };
      this.onToggleInactiveUsers = this.onToggleInactiveUsers.bind(this)
    }

    componentDidMount() {
      this.init();
    }

    init(){
      this.getUser().then(result => {
        this.setState({
          users: result.data.data,
          isLoading: false
        })
      })   
    }

    getUser(){
      return request('GET', '/users/', {}, true);
    }

    onToggleInactiveUsers = e => {
      console.log(e.target.checked)
      this.setState({
        toggleInactiveUsers: !this.state.toggleInactiveUsers,
        isLoading: true
      })

      if(e.target.checked){
        request('GET', '/users/all', {}, true).then(result => {
          this.setState({users: result.data.data, isLoading: false});
        })      
      }else{
        request('GET', '/users/', {}, true).then(result => {
          this.setState({users: result.data.data, isLoading: false});
        })   
      }
    }

    deleteHandler = e => {
      e.preventDefault();
      const data = {
        id: e.target.value
      } 
      request('DELETE', `/users/${e.target.value}`, data, true).then(res => {
        this.setState({
          isLoading: false
        })
        window.location.href = "/userstable";
      }).catch(err => {
        console.error(err.response);
        this.setState({
            isLoading: false,
            error: err.response.data
        })
      })
    }    

    handleDelete = (user_id) => {
      this.setState({ currentId: user_id, showDeleteModal: true})
    }

    handleTypeOfUser = e =>{
      e.preventDefault();
      this.setState({
        isLoading: true
      })

      if(e.target.value === "SelectAll"){
        request('GET', `/users/all`, {}, true).then(result => {
          this.setState({
            users: result.data.data, 
            isLoading: false
          })
        })
      }else {
        request('GET', `/users/getByPermission/${e.target.value}`, {}, true).then(result => {
          this.setState({
            users: result.data.data,
            isLoading: false
          })
        })
      }
    }

    /* Modal Functions */
    hideDeleteModal = e => {
      this.setState({ showDeleteModal: false })
    }

    handleSearchUser = (e) => {
      const searchUser = e;

      if(!searchUser){
        this.init();
      } else {
        request('GET', `/users/search/searchUsers?user=${searchUser}`, {}, true).then(result => {
          this.setState({
            users: result.data.data
          });
          // const users = result.data.data.map(obj => {
          //     return {
          //         id: obj.id,
          //         name: obj.title + '. ' + obj.first_name + ' ' + obj.last_name,
          //         email: obj.email,
          //         office_telephone: obj.office_telephone,
          //         mobile_number: obj.mobile_number,
          //         status: obj.status,
          //         permission: obj.permission
          //     }
          // });
          // this.setState({users});
        })
      }
    }

    getResizeValues = (newResized, event) => {
      if(newResized){
          console.log(localStorage.getItem('usersTableWidths'), newResized)
          if(localStorage.getItem('usersTableWidths')) {
              const widths = JSON.parse(localStorage.getItem('usersTableWidths'));
              newResized.forEach(element => {
                  const i = widths.findIndex(width => width.id == element.id);
                  if(i > -1) {
                      const width = widths[i];
                      width.value = element.value
                  } else {
                      widths.push(element);
                  }
              });
              localStorage.setItem("usersTableWidths", JSON.stringify(widths));

          } else {
              localStorage.setItem("usersTableWidths", JSON.stringify(newResized));
          }
      }
  }

  getColumnWidth(columnId, defaultSize) {
      if(localStorage.getItem('usersTableWidths')) {
          const widths =  JSON.parse(localStorage.getItem('usersTableWidths'));
          const i = widths.findIndex((element) => element.id == columnId);
          if(i > -1) {
              const columnWith = widths[i].value;
              return columnWith;
          }
          return defaultSize;
      }
      return defaultSize;
  }

    render() {
      const userPermission = this.context.user.user.permission;
        const columns = [{
            Header: 'Action',
            Cell: row => 
            <React.Fragment>
              {/* <Link to={`/userview/${row.original.id}`} ><Button className="btn btn-dark mr-1 center">View</Button></Link>
              <Button className="btn btn-danger center" value={`${row.original.id}`} onClick={this.handleDelete}>Delete</Button> */}
              <i className="far fa-trash-alt" style={{color: 'red'}} onClick={() => this.handleDelete(row.original.id)}/>
              <Link to={`/userview/${row.original.id}`} ><i class="far fa-eye ml-2" /></Link>
            </React.Fragment>,
            width: this.getColumnWidth('action_id', 160),
            id: "action_id",
            accessor: 'id',
          },
          {
            Header: 'Name',
            width: this.getColumnWidth('name', 250),
            id: "name",
            Cell: row => {
              return <React.Fragment>
                <span className="center"> { row.original && row.original.title + '. ' + row.original.first_name + ' ' + row.original.last_name }</span>
                </React.Fragment>
            },
            accessor: row => row.first_name,
          },
          {
            Header: 'Email',
            width: this.getColumnWidth('email', 250),
            id: "email",
            accessor: 'email',
            style: { 'whiteSpace': 'unset' },
            Cell: row => {
              return <React.Fragment>
                    <span className="center"> { row.original && row.original.email }</span>
                  </React.Fragment>
            }
          },
          // {
          //   Header: 'Office Telephone',
          //   accessor: 'office_telephone'
          // },
          // {
          //   Header: 'Mobile Number',
          //   accessor: 'mobile_number'
          // },
          {
            Header: 'User Type',
            Cell: row => {
              return <React.Fragment>
                    <span className="center"> { row.original && row.original.permission }</span>
                  </React.Fragment>
            },
            width: this.getColumnWidth('permission', 250),
            id: "permission",
            accessor: 'permission'
          },

          {
            Header: 'Status',
            Cell: row => 
            <React.Fragment>
              {row.original.status === 1 ? 'Active' : 'Inactive'}
            </React.Fragment>,
            width: this.getColumnWidth('status', 200),
            id: "status",
            accessor: 'status'
            
          }]

        return (
            <div class="container-fluid users-table">
                {/* <h1 class="h3 mb-2 text-gray-800">Tables</h1> */}
                {/* <p class="mb-4">DataTables is a third party plugin that is used to generate the demo table below. For more information about DataTables, please visit the official DataTables documentation.</p> */}
                <div className="card shadow mb-4">
                  <div className="card-header py-3">
                    <Row className="center">
                      <Col sm={3} md={6} lg={4}><h6 className="mb-2 font-weight-bold text-primary">List of Users </h6></Col>    
                      <Col sm={12} md={6} lg={4}>
                        <SearchField
                          placeholder="Search ..."
                          // onChange={this.handleSearchUser}
                          onSearchClick={this.handleSearchUser}
                          onEnter={this.handleSearchUser}
                          classNames="mb-2 center"
                          name="searchUser"
                          value={this.state.searchUser}
                        />       
                      </Col>           
                      <Col sm={12} md={12} lg={2}>
                        <Link to="/usersform"><button style={{width: '100%'}} type="submit" className="btn btn-primary center"><i class="fas fa-user-plus" /> Add User</button></Link>
                      </Col>
                      
                    </Row>
                    
                  </div>
                  <div className="card-body">
                     {/* Modal for Delete Booking  */}
                      <Modal show={this.state.showDeleteModal} backdrop="static">
                        <Modal.Header onClick={this.hideDeleteModal} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="center" style={{textAlign: "center"}}>Are you sure you want to delete this user?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="center" onClick={this.hideDeleteModal}>
                                Cancel
                            </Button>
                            <Button 
                                className="btn btn-danger center" 
                                value={this.state.currentId} 
                                onClick={this.deleteHandler}
                            >Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Row className="center">
                      <Col sm={3} md={3} lg={3}><FormLabel>Select type of user: {this.state.toggleInactiveUsers}</FormLabel></Col>
                      <Col sm={12} md={4} lg={5}>
                          <FormControl
                              as = "select"
                              type="text"
                              name="hospital_id"
                              className="mb-2 center"
                              onChange={this.handleTypeOfUser}
                          >   
                              <option value="SelectAll">Select All</option>
                              <option value="super_administrator" style={{display: userPermission === 'super_administrator' ? 'block' : 'none'}}>Super Administrator</option>
                              <option value="administrator">Administrator</option>
                              <option value="staff">Staff</option>
                              <option value="secretary">Secretary</option>                              
                          </FormControl>
                      </Col>
                      <Col sm={12} md={5} lg={4}>
                        <Form.Check 
                            type="switch"
                            id="custom-switch"
                            label="Show INACTIVE Users"
                            onClick={this.onToggleInactiveUsers}
                            checked={this.state.toggleInactiveUsers}
                            value={this.state.toggleInactiveUsers}
                            className="center"
                        />
                      </Col>
                    </Row><br/>
                    <div className="table-responsive center">
                      {
                        this.state.isLoading ? (
                          <Loader
                            type="TailSpin"
                            color="#2850C2"
                            height={80}
                            width={80}
                            timeout={10000} 
                          />
                        ) : (
                          <ReactTable
                            PaginationComponent={Pagination}
                            data={this.state.users}
                            columns={columns}
                            defaultExpanded={true}
                            minRows={0}
                            onResizedChange={this.getResizeValues} 
                          />
                        )
                      }
                    
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}

export default UsersTable;