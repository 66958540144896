import React, { Component } from 'react';
import { Row, Col, Container, Table, Modal} from 'react-bootstrap';
import moment from 'moment';
import { request } from '../../constants/constants';
import Loader from "react-loader-spinner";
import Pagination from '../../components/Pagination/pagination';
import ReactTable from 'react-table';
import UserContext from '../../contexts/User/Index';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import logo from '../../assets/images/alexus-logo.png';

class AlsReport extends Component {
    static contextType = UserContext;

    constructor(props){
        super(props);
        this.state = {
            id: this.props.match.params.id,
            invoicePeriod: '',
            als_reference: '',
            invoice_date: '',
            total_transactions: '',
            isLoading: true,
            invoices: [],
            procedure_summary: [],
            divided_net: 0,
            divided_gst: 0,
            divided_total_amount: 0,
            net: 0,
            gst: 0,
            total_amount: 0,
            showSuccessModal: false

        }
    }

    componentDidMount(){
        this.setState({
            current_user_email: this.context.user.user.email,
            current_name: this.context.user.user.title + '. ' + this.context.user.user.first_name + ' ' + this.context.user.user.last_name
        })

        request('GET', `/reconciliations/${this.state.id}`, {}, true).then(result => { 
            console.log("result.data.data", result.data)
            this.setState({
                als_reference: result.data.data.als_reference,
                invoice_date: moment(result.data.data.invoice_date).format('DD/MM/YYYY'),
                invoices: result.data.invoices,
                total_transactions: result.data.total_items,
                invoicePeriod: moment(result.data.data.from_date).format('DD/MM/YYYY')  + ' to ' + moment(result.data.data.to_date).format('DD/MM/YYYY'), 
                isLoading: false,
                net: result.data.data.net,
                gst: result.data.data.gst,
                total_amount: result.data.data.total_amount,
                divided_net: result.data.data.net * 0.5,
                divided_gst: result.data.data.gst * 0.5,
                divided_total_amount: result.data.data.total_amount * 0.5,
                procedure_summary: result.data.procedure_summary,
                from_date: moment(result.data.data.from_date).format('DD-MM-YYYY'),
                to_date: moment(result.data.data.to_date).format('DD-MM-YYYY')
            })     
        }).catch(err => { 
            console.log(err.response)
            this.setState({
                isLoading: false
            })
        });
    }

    getResizeValues = (newResized, event) => {
        if(newResized){
            // console.log(localStorage.getItem('invoicesTableWidths'), newResized)
            if(localStorage.getItem('alexusReportTableWidths')) {
                const widths = JSON.parse(localStorage.getItem('alexusReportTableWidths'));
                newResized.forEach(element => {
                    const i = widths.findIndex(width => width.id == element.id);
                    if(i > -1) {
                        const width = widths[i];
                        width.value = element.value
                    } else {
                        widths.push(element);
                    }
                });
                localStorage.setItem("alexusReportTableWidths", JSON.stringify(widths));

            } else {
                localStorage.setItem("alexusReportTableWidths", JSON.stringify(newResized));
            }
        }
    }

    getColumnWidth(columnId, defaultSize) {
        if(localStorage.getItem('alexusReportTableWidths')) {
            const widths =  JSON.parse(localStorage.getItem('alexusReportTableWidths'));
            const i = widths.findIndex((element) => element.id == columnId);
            if(i > -1) {
                const columnWith = widths[i].value;
                return columnWith;
            }
            return defaultSize;
        }
        return defaultSize;
    }

    handleSendEmail = () => {

        var invoice_table_body = [];
        var procedure_table_body = [];
        const ten_percent_gst = 0.1;
        const half_percentage = 0.5;
        var half_of_total_net = this.state.net / 2;
        var half_of_total_gst = this.state.gst / 2;
        var half_of_total_amount = this.state.total_amount / 2;

        const doc = new jsPDF();  
        /**
         * Set the font size 9 
        */
        doc.setFontSize(9);

        /**
         * Left side of the heading
        */ 
        doc.addImage(logo, 'PNG', 40, 11, 25, 25)
        doc.text("ABN: 41-541-228-897", 40, 40)
        doc.text("ACN: 541-228-897", 42, 45)
        
        /*
         * Right side of the heading
        */ 
        doc.text("ALEXUS SERVICES PTY LTD", 120, 20)
        doc.text("PO Box 2035", 130, 25)
        doc.text("Sunbury, VIC, Australia, 3429", 122, 30)
        doc.text("PH #: Alister: 0400-137-952", 124, 35)
        doc.text("Allan: 0438-341-130", 130, 40)
        doc.text("EMAIL: info@alexus.com.au", 123, 45)
        doc.line(14, 55, 196, 55);

        /*
         * Tax Invoice Ref Left
        */
        doc.text("Tax Invoice Ref: " + this.state.als_reference, 14, 63)
        doc.text("Invoice Date: " + moment(this.state.invoice_date).format('DD-MM-YYYY'), 14, 68)
        doc.text("Account Code: ALS", 14, 73)
        doc.text("Invoice Period: " + this.state.from_date + " to " + this.state.to_date, 14, 78)
        
        /*
         * Tax Invoice Ref Right 
        */
        doc.text("Australasian Lithotripsy Services Pty Ltd", 130, 63)

        /*
         * Procedure Table
        */
        var total_procedure_gst = 0;
        var total_procedure_total_amount = 0;
        var total_procedure_price = 0;

        this.state.procedure_summary.forEach(elem => {
            total_procedure_gst = (elem.total_price * ten_percent_gst) * half_percentage;
            total_procedure_price = elem.total_price * half_percentage;
            total_procedure_total_amount = total_procedure_price + total_procedure_gst;
            procedure_table_body.push([elem.type_of_procedure, elem.total_procedures, this.transformAmount(total_procedure_price), this.transformAmount(total_procedure_gst), this.transformAmount(total_procedure_total_amount)])
        });
        
        procedure_table_body.push(["Total", this.state.total_transactions, this.transformAmount(half_of_total_net), this.transformAmount(half_of_total_gst), this.transformAmount(half_of_total_amount)])

        doc.autoTable({ 
            styles: { 
                fillColor: [211,211,211] , 
                textColor: [0, 0, 0], 
                minCellWidth: 0, 
                minCellHeight: 0, 
                fontSize: 8, 
                cellPadding: 1

            },
            startY: 88,
            head: [['Procedure', 'No. of Transactions', 'Net Amount ($)', 'GST Amount ($)', 'Total Amount ($)']],
            body: procedure_table_body,
            didParseCell: function (data) {
                var rows = data.table.body;
                if (data.row.index === rows.length - 1) {
                    data.cell.styles.fontStyle = "bold";
                }
            }
        })

        var neto_invoice_space_difference = 0;
        if(procedure_table_body.length > 1) {
            neto_invoice_space_difference = procedure_table_body.length * 3;
        } 

        console.log("neto_invoice_space_difference", neto_invoice_space_difference, "length", procedure_table_body.length, )
        /*
         * Net Invoice part right side
        */
        doc.setFont(undefined, 'bold');
        doc.text("NET INVOICE: " + this.transformAmount(half_of_total_net), 150, 110 + neto_invoice_space_difference)
        doc.text("G.S.T: " + this.transformAmount(half_of_total_gst), 150, 115 + neto_invoice_space_difference)
        doc.text("INVOICE TOTAL: " + this.transformAmount(half_of_total_amount), 150, 120 + neto_invoice_space_difference)

        /*
        * Hospital Invoices part
        */
        let total_gst = 0;
        let total_amount = 0;

        this.state.invoices.forEach(elem => {
            total_gst = elem.total_amount * ten_percent_gst;
            total_amount = total_gst + elem.total_amount;
            invoice_table_body.push([
                                        elem.ID, 
                                        elem.hospital.name, 
                                        this.getDoctorName(elem.items),
                                        elem.total_items,
                                        this.transformAmount(elem.total_amount),
                                        this.transformAmount(total_gst),
                                        this.transformAmount(total_amount)
                                    ])
                                    
        });

        invoice_table_body.push(
            ['Total', null, null, this.state.total_transactions, this.transformAmount(this.state.net), this.transformAmount(this.state.gst), this.transformAmount(this.state.total_amount)]
        )

        doc.setFont("arial", 'bold');
        doc.text("HOSPITAL INVOICES INCLUDED IN MANAGEMENT FEE", 14, 130 + neto_invoice_space_difference)
        doc.autoTable({ 
            styles: { 
                fillColor: [211,211,211] , 
                textColor: [0, 0, 0], 
                minCellWidth: 0, 
                minCellHeight: 0, 
                fontSize: 8, 
                cellPadding: 1

            },
            startY: 132 + neto_invoice_space_difference,
            head: [['Invoice Number', 'Hospital', 'Doctor', 'Trans.', 'Net ($)', 'GST ($)', 'Total Amount ($)']],
            body: invoice_table_body, 
            didParseCell: function (data) {
                var rows = data.table.body;
                if (data.row.index === rows.length - 1) {
                    data.cell.styles.fontStyle = "bold";
                }
            }
        })
 
        /**
         * Create filename
         */
        var file_name = this.state.als_reference + '-' + this.state.from_date +  "-to-" + this.state.to_date;

        /*
            *Create a footer
        */
        const pageCount = doc.internal.getNumberOfPages();
 
        for(var i = 1; i <= pageCount; i++) {
            if(i == pageCount){
            
                doc.text('Thank you for your business!', 85, doc.internal.pageSize.height - 10);
                doc.text('www.alexus.com.au', 92, doc.internal.pageSize.height - 5);
            }
         }
 
        doc.save(file_name);





        // this.setState({
        //     isLoading: true
        // })

        // const data = {
        //     user_email: this.state.current_user_email,
        //     user_name: this.state.current_name
        // }

        // request('POST', `/reconciliations/als/print-reconciliation-report/${this.state.id}`, data, true).then(result => {
        //     console.log(result.data)
        //     this.setState({ 
        //         successMessage: result.data.message, 
        //         showSuccessModal: true,
        //         isLoading: false,
        //     })
        //     // const invoice_url = result.data.url;
        //     // this.setState({
        //     //     isLoading: false
        //     // })
        //     // if(invoice_url){
        //     //     const win = window.open(invoice_url, '_blank');
        //     //     if(win){
        //     //         win.focus();    
        //     //     }
        //     // } 
        // }).catch(err => { 
        //     console.log(err.response)
        //     this.setState({
        //         isLoading: false
        //     })
        // });
    }

    hideSuccessModal = () => {
        this.setState({ 
            showSuccessModal: false
        })
    }

    transformAmount = (amount) => {
        return "$" + amount.toLocaleString('en-US', {minimumFractionDigits: 2})
    }

    getDoctorName = (elem_items) => {
        var current_doctor_name = null;
        elem_items.forEach(val => {
            var booking_info = val.booking
            if(booking_info){
                var doctor_name = booking_info.doctor.title + '. ' + booking_info.doctor.first_name + ' ' + booking_info.doctor.last_name
                if(current_doctor_name != doctor_name){
                    current_doctor_name = doctor_name 
                }
            }
        })

        return current_doctor_name;
    }


    render() {

        const columns = [ 
            {  
                Header: 'Invoice No.',  
                id: 'invoice_number',
                width: this.getColumnWidth('invoice_number', 150),
                Cell: (row) => (<span>{`${row.original.ID}`}</span>),
                accessor: row => row.ID,                
            },

            {
                Header: 'Hospital',  
                id: 'hospital',
                width: this.getColumnWidth('hospital', 250),
                // Cell: (row) => (<span>{`${row.original.hospital.name}`}</span>),
                accessor: row => row.hospital && row.hospital.name,
                Cell: (row) => {
                    return (
                        <React.Fragment>
                          <div className="cell-text">
                            {
                                row.original.hospital && ( <span>{row.original.hospital.name}</span>)
                            }
                          </div>
                        </React.Fragment>
                      )
                }
            },

            {
                Header: 'Doctor',  
                id: 'doctor',
                width: this.getColumnWidth('doctor', 250),
                // Cell: (row) => (<span>{`${row.original.doctor.title}. ${row.original.doctor.first_name} ${row.original.doctor.last_name}`}</span>),
                // accessor: row => row.doctor && row.doctor.id,
                accessor: (row) => {
                    return  (
                        <React.Fragment>
                            {
                                row.items && row.items.map(obj => {
                                    return <React.Fragment> 
                                        {
                                            (obj.booking && obj.booking.doctors && obj.booking.doctors.last_name)
                                        }
                                        </React.Fragment>
                                    
                                })
                            }
                        </React.Fragment>
                    )
                },
                Cell: (row) => {
                    let current_doctor_name = null
                    return (
                        <React.Fragment>
                          <div className="cell-text">
                            {
                                row.original.items && row.original.items.map(obj => {
                                    if(obj.booking){
                                        let doctor_name = obj.booking && obj.booking.doctor && obj.booking.doctor.title + '. ' + obj.booking.doctor.first_name + ' ' + obj.booking.doctor.last_name
                                        if(current_doctor_name != doctor_name){
                                            current_doctor_name = doctor_name
                                            return <React.Fragment>
                                                {
                                                    <span>
                                                        {doctor_name}<br />                                               
                                                    </span>                                           
                                                }   
                                            </React.Fragment>
                                        }
                                    }
                                })
                            }
                          </div>
                        </React.Fragment>
                      )
                }
            },

            {
                Header: 'Trans.', 
                accessor: row => row && row.total_items,
                width: this.getColumnWidth('transactions', 100),
                id: "transactions",
                Cell: (row) => {
                    return (
                        <React.Fragment>
                          <div className="cell-text">
                            <span>{row.original.total_items}</span>
                          </div>
                        </React.Fragment>
                      )
                }
            },

            {
                Header: "Net ($)",
                id: 'total_amount',
                width: this.getColumnWidth('total_amount', 150),
                Cell: (row) => (<span>${`${(row.original.total_amount).toLocaleString('en-US', {maximumFractionDigits: 2})} `}</span>),
                accessor: row => row.total_amount
            },

            {
                Header: "GST ($)",
                id: 'total_amount',
                width: this.getColumnWidth('total_amount', 150),
                Cell: (row) => (<span>${`${(row.original.total_amount * 0.1).toLocaleString('en-US', {maximumFractionDigits: 2})} `}</span>),
                accessor: row => row.total_amount
            },

            {
                Header: "Total ($)",
                id: 'total_amount',
                width: this.getColumnWidth('total_amount', 150),
                Cell: (row) => (<span>${`${(row.original.total_amount + (row.original.total_amount * 0.1)).toLocaleString('en-US', {maximumFractionDigits: 2})} `}</span>),
                accessor: row => row.total_amount
            }

        ];

        return (
            <div className="container-fluid p-3 p-md-5">
                <div className="row justify-center mb-4">
                    <div className="col-sm-12 col-md-10">

                    </div>
                </div>   
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col sm={12} md={8} lg={10}>
                                <h5 className="mb-2 font-weight-bold text-primary center">Australasian Lithotripsy Services Pty Ltd</h5>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                {/* <button className="btn btn-primary center" onClick={this.handleSendEmail} style={{width: "100%" }} > Send Email </button> */}
                                <button className="btn btn-primary center" onClick={this.handleSendEmail} style={{width: "100%" }} > Download Report </button>

                            </Col>
                        </Row>
                    </div>             
                    <div className="card-body">
                        {/* Modal for Email Successfully Sent */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideSuccessModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center"  style={{textAlign: "center"}}>{this.state.successMessage ? this.state.successMessage : "Email Successfully Sent"}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideSuccessModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {
                            this.state.isLoading ? (
                                <Loader
                                    type="TailSpin"
                                    color="#2850C2"
                                    height={80}
                                    width={80}
                                    // timeout={15000} //3 secs
                                />
                            ) : (
                                <Container>
                                    <Row className="center font-weight-bold">
                                        <Col sm={12} md={4} lg={6}>
                                            <h6 className="font-weight-bold center">
                                                Alexus Services Pty Ltd<br />
                                                PO Box 2035<br />
                                                SUNBURY<br />
                                                VIC 3429
                                            </h6>
                                        </Col>
                                        <Col sm={12} md={4} lg={3}  className="text-lg-right">
                                            Tax Invoice Ref:<br />
                                            Invoice Date:<br />
                                            Account Code:<br />
                                            Invoice Period:
                                        </Col>
                                        <Col sm={12} md={4} lg={3} > 
                                            {this.state.als_reference}<br />
                                            {this.state.invoice_date}<br />
                                            ALS<br />
                                            {this.state.invoicePeriod}<br />
                                        </Col>
                                    </Row>
                                    <Row className="mt-lg-5 mt-md-3 mt-sm-5 center">
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Procedure</th>
                                                    <th>No. of Transactions</th>
                                                    <th>Net Amount ($)</th>
                                                    <th>GST Amount ($)</th>
                                                    <th>Total Amount ($)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.procedure_summary && this.state.procedure_summary.map(obj => {
                                                        
                                                        return <React.Fragment>
                                                                    <tr>
                                                                        <td>{obj.type_of_procedure}</td>
                                                                        <td>{obj.total_procedures}</td>
                                                                        <td>${(obj.total_price).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                                        <td>${(obj.total_price * 0.1).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                                        <td>${(obj.total_price + (obj.total_price * 0.1)).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                                    </tr>
                                                            </React.Fragment>
                                                    })
                                                }
                                                
                                            </tbody>
                                        </Table>
                                    </Row>
                                    <Row className="center font-weight-bold">
                                        <Col sm={12} md={6} lg={9} className="text-lg-right">
                                            NET INVOICE <br />
                                            G.S.T<br />
                                            INVOICE TOTAL
                                        </Col>
                                        <Col sm={12} md={6} lg={3} className="mt-lg-0 mt-sm-2">
                                            ${(this.state.divided_net).toLocaleString('en-US', {maximumFractionDigits: 2})}<br />
                                            ${(this.state.divided_gst).toLocaleString('en-US', {maximumFractionDigits: 2})}<br />
                                            ${(this.state.divided_total_amount).toLocaleString('en-US', {maximumFractionDigits: 2})}<br />
                                        </Col>
                                    </Row>
                                    <Row className="mt-lg-2 mt-md-3 mt-sm-2 center">
                                        <p className="font-weight-bold ">HOSPITAL INVOICES INCLUDED IN MANAGEMENT FEE</p>
                                    </Row>
                                    <ReactTable
                                        data={this.state.invoices}
                                        columns={columns}
                                        style={{ overflow: "wrap" }}
                                        PaginationComponent={Pagination}
                                        className="center"
                                        minRows={0}
                                        // getTrProps={this.getTrProps}
                                        onResizedChange={this.getResizeValues}
                                    />
                                    <Row className="mt-lg-5 mt-md-5 mt-sm-2 center">    
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <td>No. of Trans</td>
                                                    <td>Net ($)</td>
                                                    <td>GST ($)</td>
                                                    <td>Gross ($)</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Total</td>
                                                    <td>{this.state.total_transactions}</td>
                                                    <td>${(this.state.net).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                    <td>${(this.state.gst).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                    <td>${(this.state.total_amount).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Container>
                                
                                
                            )
                        }
                        
                        
                    </div>
                </div>
                
            </div>
        );
    }
}

export default AlsReport;