import React, { Component } from 'react';
import { Col, Container, FormGroup, Row } from 'reactstrap';
import { FormControl, FormLabel, Modal } from 'react-bootstrap';
import { request } from '../../constants/constants';
import { Multiselect } from 'multiselect-react-dropdown';
import Loader from "react-loader-spinner";

class BookingCycleUpdateForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            id: this.props.match.params.id,
            doctors: [],
            hospitals: [],
            hospitalNames: [],
            optionProcedures: [],
            showSuccessModal: false,
            showError: false,
            proceduresNames: [],
            currentProcedures: [],
            isLoading: true,
            start_date: ""
        }
    }

    componentDidMount() {

        request('GET', `/booking_cycles/getSingleBookingCycleWithTrashed/${this.state.id}`, {}, true).then(res => { 
            console.log('res.data.data', res.data.data)
            this.setState({ 
                hospital_id: res.data.data.hospital.id,
                doctor_id: res.data.data.doctor.id,
                procedure_id: res.data.data.procedures,
                slot: res.data.data.slot,
                week: res.data.data.week,
                day: res.data.data.day,
                start_date: res.data.data.start_date,
                status: res.data.data.status
            }, () => { 

                this.getDoctorsInformation(this.state.doctor_id);
                this.getHospitalProcedure(this.state.hospital_id);

                // this.state.procedure_id.map(procedure => {
                //     const procId = procedure.procedure_id;
                //     // request('GET', `/hospital_procedures/getBy/procedureIdAndHospitalId/${procId}/${this.state.hospital_id}`, {}, true).then(result => {

                //         console.log('procedureIdAndHospitalId', res)

                //         this.state.proceduresNames.push({name: result.data.data.procedure_type, id: procId})
                //         this.setState({
                //             proceduresNames: this.state.proceduresNames
                //         });
                //         const currentProcedures = this.state.proceduresNames.map(procedure => ({
                //             id: procedure.id,
                //             name: procedure.name
                //         }))
                //         this.setState({
                //             currentProcedures: currentProcedures,
                //             isLoading: false
                //         });
                //     }).catch(err => {
                //         console.error(err);
                //         this.setState({
                //             isLoading: false,
                //             error: err.response
                //         })
                //     })
                // })
                
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                // error: err.response.data
            })
        });

        request('GET', `/booking_cycle_procedures/getBy/bookingCycleId/${this.state.id}`, {}, true).then(result => {
            console.log('procedureIdAndHospitalId', result.data.data)

            const currentProcedures = result.data.data.map(procedure => ({
                id: procedure.procedure_id,
                name: procedure.procedure_name
            }))
            
            this.setState({
                currentProcedures: currentProcedures,
                isLoading: false
            });
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoading: false,
                error: err.response
            })
        })

    }

    getDoctorsInformation = (doctor_id) => {

        request('GET', '/doctors/allDoctorsByAdminOnlyActive', {}, true).then(result => { 
            // console.log(result)
            const doctors = result.data.data.map(obj => ({
                id: obj.ID,
                name: obj.title + '. ' + obj.first_name + ' ' + obj.last_name,
                hospitals: obj.hospitals
            }));
            // console.log("this.state.doctor_id", doctor_id)
            this.setState({ doctors }, () => {
                const index_doctors = this.state.doctors.findIndex(val => val.id == doctor_id);
                const update_doctor = this.state.doctors[index_doctors];
                // console.log("doctor", update_doctor)
                this.setState({
                    hospitalNames: update_doctor.hospitals ? update_doctor.hospitals : []
                })
                request('GET', `/hospital_procedures/${this.state.hospital_id}`, {}, true).then(result => {
                    const hospitalProc = result.data.data.map(procedure => ({
                        id: procedure.id,
                        name: procedure.procedure_type
                        
                    }));
                    this.setState({
                        procedures: hospitalProc
                    })
                }).catch(err => {
                    console.error(err);
                    this.setState({
                        isLoading: false,
                        error: err.response
                    })
                })
            }); 
        }).catch(err => { 
            this.setState({
                isLoading: false,
                error: err.response
            })
        }); 
    }

    onSelect = (selectedList, selectedItem) => {
        console.log('selectedList', selectedList)
        this.setState({
            selectedProcedures: selectedList
        })
    }

    onRemove = (selectedList, removedItem) => {
        console.log('selectedList', selectedList)
        this.setState({
            selectedProcedures: selectedList
        })
    }


    handleDoctor = e => {
        const id = e.target.value;
        if(id==="-Select-"){
            return false
        }
        const index = this.state.doctors.findIndex(doctor => doctor.id === id);
        const doctor = this.state.doctors[index];
        this.setState({
            hospitals: doctor.hospitals,
            doctor_id: id,
        })
    }

    handleHospital = e => {
        this.setState({
            currentProcedures: []
        })
        const hospitalId = e.target.value; 
        if(hospitalId==="-Select-"){
            return false
        }
        this.setState({
            hospital_id: hospitalId
        })

        this.getHospitalProcedure(hospitalId);
    }

    getHospitalProcedure = (hospitalId) => {
        request('GET', `/hospital_procedures/${hospitalId}`, {}, true).then(result => {
            console.log('hospital_procedures', result.data.data)

            const optionProcedures = result.data.data.map(obj => ({
                // id: obj.id,
                id: obj.procedure_id,
                name: obj.procedure_type
                
            }));
            this.setState({ optionProcedures })

            // this.setState({ optionProcedures }, () => {
            //     const currentProcedures = this.state.optionProcedures.map(procedure => ({
            //         id: procedure.id,
            //         name: procedure.name
            //     }))
            //     this.setState({
            //         currentProcedures: currentProcedures
            //     })
            // })
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoading: false,
                error: err.response
            })
        })
    }

    handleProcedure = e => {
        const procedureId = e.target.value;

        if(procedureId ==="-Select-"){
            return false
        }
        this.setState({
            procedure_id: procedureId
        })
    }

    handleChange = e => {
        const val = e.target.value;
        if(val === "-Select-"){
            return false;
        }
        this.setState({
            [e.target.name]: val
        })
    }

    handleStatus = e => {
        this.setState({
            status: e.target.value
        })
    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const data = { 
            hospital_id: this.state.hospital_id,
            procedure_id: this.state.selectedProcedures ? this.state.selectedProcedures : this.state.currentProcedures,
            doctor_id: this.state.doctor_id,
            slot: this.state.slot,
            week: this.state.week,
            day: this.state.day,
            status: this.state.status,
            start_date: this.state.start_date,
        }

        // console.log(data)
        request('PUT', `/booking_cycles/updateBookingCycle/${this.state.id}`, data, true).then(res => {
        // console.log(res)
            this.setState({
                isLoading: false,
                showSuccessModal: true
            })
        }).catch(err => {
                console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                errorData: err.response.data.errors,
                errCode: err.response.status
            })
            this.setState({
                showError: this.state.errCode === 422 ? true : false
            })
        }) 
    }

    /* Modal Function */
    hideSuccessModal = e =>{
        this.setState({
             showSuccessModal: false
        })
        window.location.href = "/bookingcyclepage";
    }

    hideModalError = () => {
        this.setState({ showError: false })
    }

    onChange = (e) =>{ 
        this.setState({ 
            start_date: e.target.value 
        }) 
    }

    render() {
        const mutiselectStyle = {
            option: { 
                color: "black"
            }
          };
        return (
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h5 className="m-0 font-weight-bold text-primary center">Booking Cycle Update Form</h5>
                    </div>
                    <div className="card-body">  
                        {/* Modal for SUCCESS Updating  */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideSuccessModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: 'center'}}>Booking Cycle Successfully Updated! </p>                                             
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" variant="primary" onClick={this.hideSuccessModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for ERROR  */}
                        <Modal show={this.state.showError}>
                            <Modal.Header onClick={this.hideModalError} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <p className="text-align" style={{textAlign: "center"}}>{this.state.errorMessage}</p>     
                            {
                                this.state.errorData &&  Object.keys(this.state.errorData).map(key => <p style={{textAlign: "center"}}>{this.state.errorData[key]}</p>)
                            }                                        
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={this.hideModalError}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <form onSubmit={this.handleFormSubmit}>
                            {
                                this.state.isLoading ? (
                                    <Loader
                                        type="TailSpin"
                                        color="#2850C2"
                                        height={80}
                                        width={80}
                                        // timeout={10000} 
                                    />
                                ) : (
                                    <Container>
                                        <FormGroup>
                                            <Row className="center">
                                                <Col sm={12} md={4} lg={2}><FormLabel>Doctor: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                <Col sm={12} md={8} lg={4}>
                                                    <FormControl
                                                        as="select"
                                                        type="text"
                                                        name="doctor"
                                                        value={this.state.doctor_id}
                                                        onChange={this.handleDoctor}
                                                        className="mb-md-2 center"
                                                    >
                                                        {this.state.doctors.map(doctor => ( 
                                                            <option value={doctor.id}> {doctor.name} </option>
                                                        ))}
                                                    </FormControl>
                                                </Col>
                                                <Col sm={12} md={4} lg={1}><FormLabel>Slot: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                <Col sm={12} md={8} lg={4}>
                                                    <FormControl
                                                        as="select"
                                                        type="text"
                                                        name="slot"
                                                        value={this.state.slot}
                                                        onChange={this.handleChange}
                                                        className="center"
                                                    >
                                                        <option value="-Select-">-Select-</option>
                                                        <option value="AM">AM</option>
                                                        <option value="PM">PM</option>                                           
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup>
                                            <Row className="center">
                                                <Col sm={12} md={4} lg={2}><FormLabel>Hospital: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                <Col sm={12} md={8} lg={4}>
                                                    <FormControl
                                                        as="select"
                                                        type="text"
                                                        name="hospital"
                                                        value={this.state.hospital_id}
                                                        onChange={this.handleHospital}
                                                        className="mb-md-2 center"
                                                    >   
                                                        {/* <option value="-Select-">-Select-</option> */}
                                                        {this.state.hospitalNames.map(hospital => ( 
                                                            (hospital.pivot.deleted_at ? "" : <option value={hospital.id}> {hospital.name} </option>)
                                                        ))}
                                                    </FormControl>
                                                </Col>
                                                <Col sm={12} md={4} lg={1}><FormLabel>Day: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                <Col sm={12} md={8} lg={4}>
                                                    <FormControl
                                                        as="select"
                                                        type="text"
                                                        name="day"
                                                        value={this.state.day}
                                                        onChange={this.handleChange}
                                                        className="center"
                                                    >
                                                        <option value="-Select-">-Select-</option>
                                                        <option value="Monday">Monday</option>
                                                        <option value="Tuesday">Tuesday</option>
                                                        <option value="Wednesday">Wednesday</option>
                                                        <option value="Thursday">Thursday</option>
                                                        <option value="Friday">Friday</option>
                                                        <option value="Saturday">Saturday</option>
                                                        <option value="Sunday">Sunday</option>
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup>
                                            <Row className="center">
                                                <Col sm={12} md={4} lg={2}><FormLabel>Procedure: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                <Col sm={12} md={8} lg={4}>
                                                    <Multiselect
                                                        options={this.state.optionProcedures} 
                                                        selectedValues={this.state.currentProcedures} 
                                                        onSelect={this.onSelect} 
                                                        onRemove={this.onRemove} 
                                                        displayValue="name"
                                                        className="center"
                                                        style={mutiselectStyle}
                                                        avoidHighlightFirstOption="true"
                                                    />
                                                </Col>
                                                <Col sm={12} md={4} lg={1}><FormLabel>Week: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                <Col sm={12} md={8} lg={4}>
                                                    <FormControl
                                                        as="select"
                                                        type="text"
                                                        name="week"
                                                        value={this.state.week}
                                                        onChange={this.handleChange}
                                                        className="mt-sm-2 mt-md-2 center"
                                                    >
                                                        <option value="-Select-">-Select-</option>
                                                        <option value="1">Week 1</option>
                                                        <option value="2">Week 2</option>
                                                        <option value="3">Week 3</option>
                                                        <option value="4">Week 4</option>
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col sm={12} md={4} lg={2}><FormLabel>Status: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={12} md={8} lg={4}>
                                                        <FormControl
                                                            as="select"
                                                            type="text"
                                                            name="status"
                                                            value={this.state.status}
                                                            onChange={this.handleStatus}
                                                            className="center"
                                                        >
                                                            <option value="-Select-">-Select-</option>
                                                            <option value="1">Active</option>
                                                            <option value="0">Inactive</option>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm={12} md={4} lg={1}><FormLabel>Start Date: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                    <Col sm={12} md={8} lg={4}>
                                                        <FormControl
                                                            type="date"
                                                            className="center"
                                                            name="date"
                                                            value={this.state.start_date}
                                                            onChange={this.onChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <Row className="center">
                                                <Col sm={12} md={12} lg={2}><button style={{width: '100%'}} type="submit" className="btn btn-primary mt-4 center" >Submit</button></Col>
                                            </Row>
                                    </Container>
                                )
                            }
                            
                        </form>   
                    </div>          
                </div>
            </div>
        );
    }
}

export default BookingCycleUpdateForm;