import React, { Component } from 'react';
import { request } from '../../constants/constants';
import { Container, FormGroup, Row, Col, Button } from 'reactstrap';
import { FormControl, FormLabel, Modal } from 'react-bootstrap';

class HospitalsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            office_telephone: '',
            office_telephone_2: '',
            office_email: '',
            office_facsmile: '',
            office_address: '',
            office_address_2: '',
            office_suburb: '',
            office_state: '',
            office_postcode: '',
            postal_address: '',
            postal_address_2: '',
            postal_suburb: '',
            postal_state: '',
            postal_postcode: '',
            contact_title: '',
            contact_first_name: '',
            contact_last_name: '',
            contact_mobile: '',
            contact_telephone: '',
            contact_facsmile: '',
            contact_email: '',
            website: '',
            num_rooms: '',
            reference_number: '',
            showError: false,
            showSuccessModal: false,
            chkbox: false
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleOfficeState = e => {
        if(e.target.value === "-Select-"){
            return false;
        } else {
            this.setState({
                office_state: e.target.value
            })
        }
    }

    handlePostalState = e => {
        if(e.target.value === "-Select-"){
            return false;
        } else {
            this.setState({
                postal_state: e.target.value
            })
        }
    }

    handleContactTitle = e =>{
        if(e.target.value === "-Select-"){
            return false;
        } else {
            this.setState({
                contact_title: e.target.value
            })
        }
    }

    handleClick = e => {
        console.log(e.target.checked);
        this.setState({
            chkbox: !this.state.chkbox
        })

        if(e.target.checked === true){
            this.setState({
                postal_address: this.state.office_address,
                postal_address_2: this.state.office_address_2,
                postal_suburb: this.state.office_suburb,
                postal_state: this.state.office_state,
                postal_postcode: this.state.office_postcode
            })
        } else {
            console.log(this.state.postal_address)
            this.setState({
                postal_address: '',
                postal_address_2: '',
                postal_suburb: '',
                postal_state: '',
                postal_postcode: ''
            })
        }
    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const data = {
            name: this.state.name,
            reference_number: this.state.reference_number,
            office_telephone: this.state.office_telephone,
            office_telephone_2: this.state.office_telephone_2,
            office_email: this.state.office_email,
            office_facsmile: this.state.office_facsmile,
            office_address: this.state.office_address,
            office_address_2: this.state.office_address_2,
            office_suburb: this.state.office_suburb,
            office_state: this.state.office_state,
            office_postcode: this.state.office_postcode,
            postal_address: this.state.postal_address,
            postal_address_2: this.state.postal_address_2,
            postal_suburb: this.state.postal_suburb,
            postal_state: this.state.postal_state,
            postal_postcode: this.state.postal_postcode,
            contact_title: this.state.contact_title,
            contact_first_name: this.state.contact_first_name,
            contact_last_name: this.state.contact_last_name,
            contact_mobile: this.state.contact_mobile,
            contact_telephone: this.state.contact_telephone,
            contact_facsmile: this.state.contact_facsmile,
            contact_email: this.state.contact_email,
            website: this.state.website,
            num_rooms: this.state.num_rooms
        }

        request('POST', '/hospitals', data, true).then(res => {
            this.setState({
                isLoading: false,
                showSuccessModal: true
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                errorData: err.response.data.errors,
                showError: true,
            })
        })
    }


    /* Modal Functions */
    hideModalError = () => {
        this.setState({ showError: false })
    }

    hideModal = e => {
        this.setState({ showSuccessModal: false })
        window.location.href = "/hospitalstable";

    }

    render() {
        return (
            <div class="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6>Hospital Details Form</h6>
                    </div>
                    <div className="card-body">
                        {/* Modal for ERROR  */}
                        <Modal show={this.state.showError} backdrop="static">
                            <Modal.Header onClick={this.hideModalError} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <p className="text-align" style={{textAlign: "center"}}>{this.state.errorMessage}</p>     
                            {
                                this.state.errorData &&  Object.keys(this.state.errorData).map(key => <p className="center" style={{textAlign: "center"}}>{this.state.errorData[key]}</p>)
                            }                                        
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={this.hideModalError}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Successful Added */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>Hospital Successfully Added!</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <div className="table-responsive">
                            <form onSubmit={this.handleFormSubmit}>
                                <Container>
                                    <FormGroup>
                                        <Row className="center">
                                            {/* <Col lg={2}><FormLabel>Code: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col sm={4}>
                                                <FormControl
                                                    type="text"
                                                    name="code"
                                                    value={this.state.code}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col> */}
                                            <Col lg={2} md={12}><FormLabel>Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="text"
                                                    name="name"
                                                    value={this.state.name}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                            <Col lg={2} md={12}><FormLabel mt-2 mt-lg-0>Reference Number: </FormLabel></Col>
                                            <Col lg={4} md={12}>
                                                <FormControl
                                                    type="text"
                                                    name="reference_number"
                                                    value={this.state.reference_number}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <hr/>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12} sm={12}><FormLabel>Hospital Telephone: </FormLabel></Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormControl
                                                    type="number"
                                                    name="office_telephone"
                                                    value={this.state.office_telephone}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                            <Col lg={2} md={12} sm={12}><FormLabel className="mt-2 mt-lg-0">Hospital Telephone 2: </FormLabel></Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormControl
                                                    type="number"
                                                    name="office_telephone_2"
                                                    value={this.state.office_telephone_2}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12} sm={12}><FormLabel>Hospital Email: </FormLabel></Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormControl
                                                    type="email"
                                                    name="office_email"
                                                    value={this.state.office_email}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                            <Col lg={2} md={12} sm={12}><FormLabel className="mt-2 mt-lg-0">Hospital Facsmile: </FormLabel></Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormControl
                                                    type="text"
                                                    name="office_facsmile"
                                                    value={this.state.office_facsmile}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <hr/>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12} sm={12}><FormLabel>Hospital Address: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormControl
                                                    type="text"
                                                    name="office_address"
                                                    value={this.state.office_address}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                            <Col lg={2} md={12} sm={12}><FormLabel className="mt-2 mt-lg-0">Hospital Address 2: </FormLabel></Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormControl
                                                    type="text"
                                                    name="office_address_2"
                                                    value={this.state.office_address_2}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12} sm={12}><FormLabel>Hospital Suburb: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormControl
                                                    type="text"
                                                    name="office_suburb"
                                                    value={this.state.office_suburb}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                            <Col lg={2} md={12} sm={12}><FormLabel className="mt-2 mt-lg-0">Hospital State: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={2} md={12} sm={12}>
                                                <FormControl
                                                    as="select"
                                                    type="text"
                                                    name="office_state"
                                                    value={this.state.office_state}
                                                    onChange={this.handleOfficeState}
                                                    className="center"
                                                >
                                                    <option value="-Select-">-Select-</option>
                                                    <option value="ACT">ACT</option>
                                                    <option value="NSW">NSW</option>
                                                    <option value="VIC">VIC</option>
                                                    <option value="TAS">TAS</option>
                                                    <option value="QLD">QLD</option>
                                                    <option value="SA">SA</option>
                                                    <option value="WA">WA</option>
                                                    <option value="NT">NT</option>
                                                </FormControl>
                                            </Col>                               
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12} sm={12}><FormLabel>Hospital Postcode: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormControl
                                                    type="text"
                                                    name="office_postcode"
                                                    value={this.state.office_postcode}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <input type="checkbox" ckecked={this.state.chkbox} onClick={this.handleClick} /> <span className="center" style={{ color: 'red'}}>Click this if you have the same Hospital and Postal Address</span>
                                    <hr/>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12} sm={12}><FormLabel>Postal Address: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormControl
                                                    type="text"
                                                    name="postal_address"
                                                    value={this.state.postal_address}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                            <Col lg={2} md={12} sm={12}><FormLabel className="mt-2 mt-lg-0">Postal Address 2: </FormLabel></Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormControl
                                                    type="text"
                                                    name="postal_address_2"
                                                    value={this.state.postal_address_2}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12} sm={12}><FormLabel>Postal Suburb: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormControl
                                                    type="text"
                                                    name="postal_suburb"
                                                    value={this.state.postal_suburb}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                            <Col lg={2} md={12} sm={12}><FormLabel className="mt-2 mt-lg-0">Postal State: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={2} md={12} sm={12}>
                                                <FormControl
                                                    as="select"
                                                    type="text"
                                                    name="postal_state"
                                                    value={this.state.postal_state}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                >
                                                    <option value="-Select-">-Select-</option>
                                                    <option value="ACT">ACT</option>
                                                    <option value="NSW">NSW</option>
                                                    <option value="VIC">VIC</option>
                                                    <option value="TAS">TAS</option>
                                                    <option value="QLD">QLD</option>
                                                    <option value="SA">SA</option>
                                                    <option value="WA">WA</option>
                                                    <option value="NT">NT</option>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12} sm={12}><FormLabel>Postal Postcode: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormControl
                                                    type="text"
                                                    name="postal_postcode"
                                                    value={this.state.postal_postcode}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <hr/>
                                    <h6>Contact Person</h6><br />
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12} sm={12}><FormLabel>Title: </FormLabel></Col>
                                            <Col lg={2} md={12} sm={12}>
                                                <FormControl
                                                    as="select"
                                                    type="text"
                                                    name="contact_title"
                                                    value={this.state.contact_title}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                >
                                                    <option value="-Select-">-Select-</option>
                                                    <option value="Dr">Dr</option>
                                                    <option value="Mrs">Mrs</option>
                                                    <option value="Ms">Ms</option>
                                                    <option value="Mr">Mr</option>
                                                </FormControl>
                                            </Col>
                                            <Col lg={2} md={12} sm={12}><FormLabel className="mt-2 mt-lg-0">First Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={2} md={12} sm={12}>
                                                <FormControl
                                                    type="text"
                                                    name="contact_first_name"
                                                    value={this.state.contact_first_name}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                            <Col lg={2} md={12} sm={12}><FormLabel className="mt-2 mt-lg-0">Last Name: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={2} md={12} sm={12}>
                                                <FormControl
                                                    type="text"
                                                    name="contact_last_name"
                                                    value={this.state.contact_last_name}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12} sm={12}><FormLabel>Mobile: </FormLabel></Col>
                                            <Col lg={2} md={12} sm={12}>
                                                <FormControl
                                                    type="number"
                                                    name="contact_mobile"
                                                    value={this.state.contact_mobile}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                            <Col lg={2} md={12} sm={12}><FormLabel className="mt-2 mt-lg-0">Telephone: </FormLabel></Col>
                                            <Col lg={2} md={12} sm={12}>
                                                <FormControl
                                                    type="number"
                                                    name="contact_telephone"
                                                    value={this.state.contact_telephone}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                            <Col lg={2} md={12} sm={12}><FormLabel className="mt-2 mt-lg-0">Email: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                            <Col lg={2} md={12} sm={12}>
                                                <FormControl
                                                    type="text"
                                                    name="contact_email"
                                                    value={this.state.contact_email}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12} sm={12}><FormLabel>Facsmile: </FormLabel></Col>
                                            <Col lg={2} md={12} sm={12}>
                                                <FormControl
                                                    type="text"
                                                    name="contact_facsmile"
                                                    value={this.state.contact_facsmile}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <hr/>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={2} md={12} sm={12}><FormLabel>Website: </FormLabel></Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormControl
                                                    type="text"
                                                    name="website"
                                                    value={this.state.website}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col>
                                            {/* <Col sm={2}><FormLabel>Number of Rooms: </FormLabel></Col>
                                            <Col sm={4}>
                                                <FormControl
                                                    type="number"
                                                    name="num_rooms"
                                                    value={this.state.num_rooms}
                                                    onChange={this.handleChange}
                                                    className="center"
                                                />
                                            </Col> */}
                                        </Row>
                                    </FormGroup>
                                    <Row className="center">
                                        <Col md={12} lg={3} sm={12}><button style={{width: '100%'}} type="submit" className="btn btn-primary mt-4 center">Submit</button></Col>
                                    </Row>
                                </Container>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HospitalsForm;