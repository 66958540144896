import React, { Component } from 'react';
import { request } from '../../constants/constants';
import moment from 'moment';
import { Row, Col, Container, Table,  Modal} from 'react-bootstrap';
import ReactTable from 'react-table';
import Pagination from '../../components/Pagination/pagination';
import Loader from "react-loader-spinner";
import UserContext from '../../contexts/User/Index';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import logo from '../../assets/images/alexus-logo.png';


class AlexusReport extends Component {
    static contextType = UserContext;

    constructor(props){
        super(props);
        this.state = {
            id: this.props.match.params.id,
            pageTitle: '',
            divided_net: 0,
            divided_gst: 0,
            divided_total_amount: 0,
            net: 0,
            gst: 0,
            total_amount: 0,
            total_transactions: 0,
            invoices: [],
            isLoading: true,
            from_date: "",
            to_date: "",
            
            current_user_email: '',
            current_name: '',
            showSuccessModal: false, 
            dontShowPdfHtml: 0

        }
    }

    componentDidMount(){
        request('GET', `/reconciliations/${this.state.id}`, {}, true).then(result => { 
            // console.log("result.data.data", result.data)
            this.setState({
                alexus_reference: result.data.data.alexus_reference,
                invoices: result.data.invoices,
                total_transactions: result.data.total_items,
                pageTitle: 'Alexus Reconciliation for ' + moment(result.data.data.from_date).format('DD/MM/YYYY')  + ' to ' + moment(result.data.data.to_date).format('DD/MM/YYYY'), 
                net: result.data.data.net,
                gst: result.data.data.gst,
                total_amount: result.data.data.total_amount,
                divided_net: result.data.data.net * 0.5,
                divided_gst: result.data.data.gst * 0.5,
                divided_total_amount: result.data.data.total_amount * 0.5,
                isLoading: false,
                from_date: moment(result.data.data.from_date).format('DD-MM-YYYY'),
                to_date: moment(result.data.data.to_date).format('DD-MM-YYYY')
            })     
        }).catch(err => { 
            console.log(err.response)
        });

        this.setState({
            current_user_email: this.context.user.user.email,
            current_name: this.context.user.user.title + '. ' + this.context.user.user.first_name + ' ' + this.context.user.user.last_name
        })
    }

    getResizeValues = (newResized, event) => {
        if(newResized){
            // console.log(localStorage.getItem('invoicesTableWidths'), newResized)
            if(localStorage.getItem('alexusReportTableWidths')) {
                const widths = JSON.parse(localStorage.getItem('alexusReportTableWidths'));
                newResized.forEach(element => {
                    const i = widths.findIndex(width => width.id == element.id);
                    if(i > -1) {
                        const width = widths[i];
                        width.value = element.value
                    } else {
                        widths.push(element);
                    }
                });
                localStorage.setItem("alexusReportTableWidths", JSON.stringify(widths));

            } else {
                localStorage.setItem("alexusReportTableWidths", JSON.stringify(newResized));
            }
        }
    }

    getColumnWidth(columnId, defaultSize) {
        if(localStorage.getItem('alexusReportTableWidths')) {
            const widths =  JSON.parse(localStorage.getItem('alexusReportTableWidths'));
            const i = widths.findIndex((element) => element.id == columnId);
            if(i > -1) {
                const columnWith = widths[i].value;
                return columnWith;
            }
            return defaultSize;
        }
        return defaultSize;
    }

    handleEmail = () => {

        var invoice_table_body = [];
        const ten_percent_gst = 0.1;

        const doc = new jsPDF();  
        /**
         * Set the font size 9 
        */
        doc.setFontSize(9);

        /**
         * Left side of the heading
        */ 
        doc.addImage(logo, 'PNG', 40, 11, 25, 25)
        doc.text("ABN: 41-541-228-897", 40, 40)
        doc.text("ACN: 541-228-897", 42, 45)
        
        /*
        * Right side of the heading
        */ 
        doc.text("ALEXUS SERVICES PTY LTD", 120, 20)
        doc.text("PO Box 2035", 130, 25)
        doc.text("Sunbury, VIC, Australia, 3429", 122, 30)
        doc.text("PH #: Alister: 0400-137-952", 124, 35)
        doc.text("Allan: 0438-341-130", 130, 40)
        doc.text("EMAIL: info@alexus.com.au", 123, 45)
        doc.line(14, 55, 196, 55);
        doc.text("Alexus Reconciliation for " + this.state.from_date +  " to " + this.state.to_date , 14, 63)

        /**
         * Start of Income part
        */
        doc.setFontSize(12)
        doc.setFont("arial", "bold")
        doc.text("Income", 14, 71)
        doc.autoTable({ 
            styles: { 
                fillColor: [211,211,211] , 
                textColor: [0, 0, 0], 
                minCellWidth: 0, 
                minCellHeight: 0, 
                fontSize: 8, 
                cellPadding: 1

            },
            startY: 73,
            head: [['Type', 'Net ($)', 'GST ($)', 'Gross ($)']],
            body: [
                ['Income', this.transformAmount(this.state.net), this.transformAmount(this.state.gst), this.transformAmount(this.state.total_amount)],
            ],
        })

        /**
         * Start of Disbursement Part
        */
        var half_of_total_net = this.state.net / 2;
        var half_of_total_gst = this.state.gst / 2;
        var half_of_total_amount = this.state.total_amount / 2;
        doc.setFont("arial", "bold")
        doc.text("Disbursement", 14, 93)
        doc.autoTable({ 
            styles: { 
                fillColor: [211,211,211] , 
                textColor: [0, 0, 0], 
                minCellWidth: 0, 
                minCellHeight: 0, 
                fontSize: 8, 
                cellPadding: 1

            },
            startY: 95,
            head: [['Type', 'Net ($)', 'GST ($)', 'Gross ($)']],
            body: [
                ['Exus Services Pty Ltd', this.transformAmount(half_of_total_net), this.transformAmount(half_of_total_gst), this.transformAmount(half_of_total_amount)],
                ['Australasian Lithotripsy Services Pty Ltd', this.transformAmount(half_of_total_net), this.transformAmount(half_of_total_gst), this.transformAmount(half_of_total_amount)],
                ['Total', this.transformAmount(this.state.net), this.transformAmount(this.state.gst), this.transformAmount(this.state.total_amount)]
            ],
        })

        /**
         * Start of Disbursement Breakdown Part
        */
        doc.setFont("arial", "bold")
        doc.text("Disbursement Breakdown", 14, 125)
        doc.autoTable({ 
            styles: { 
                fillColor: [211,211,211] , 
                textColor: [0, 0, 0], 
                minCellWidth: 0, 
                minCellHeight: 0, 
                fontSize: 8, 
                cellPadding: 1

            },
            startY: 127,
            head: [['Disbursed To', 'Gross ($)',]],
            body: [
                ['Exus Services Pty Ltd', this.transformAmount(half_of_total_amount)],
                ['Australasian Lithotripsy Services Pty Ltd', this.transformAmount(half_of_total_amount)],
                ['Total', this.transformAmount(this.state.total_amount)]
            ],
        })

        /*
        * Hospital Invoices part
        */
        let total_gst = 0;
        let total_amount = 0;

        this.state.invoices.forEach(elem => {
            total_gst = elem.total_amount * ten_percent_gst;
            total_amount = total_gst + elem.total_amount;
            invoice_table_body.push([
                                        elem.ID, 
                                        elem.hospital.name, 
                                        this.getDoctorName(elem.items),
                                        elem.total_items,
                                        this.transformAmount(elem.total_amount),
                                        this.transformAmount(total_gst),
                                        this.transformAmount(total_amount)
                                    ])
                                    
        });

        invoice_table_body.push(
            ['Total', null, null, this.state.total_transactions, this.transformAmount(this.state.net), this.transformAmount(this.state.gst), this.transformAmount(this.state.total_amount)]
        )

        /**
         * Set the font size 9 
        */
        var total_invoices = invoice_table_body.length
        var last_index = total_invoices - 1;
        console.log("last_index", last_index, "total_invoices", total_invoices)
        doc.setFontSize(9);
        doc.setFont("arial")
        doc.text("HOSPITAL INVOICES INCLUDED IN MANAGEMENT FEE", 14, 158)
        doc.autoTable({ 
            styles: { 
                fillColor: [211,211,211] , 
                textColor: [0, 0, 0], 
                minCellWidth: 0, 
                minCellHeight: 0, 
                fontSize: 8, 
                cellPadding: 1

            },
            startY: 160,
            head: [['Invoice Number', 'Hospital', 'Doctor', 'Trans.', 'Net ($)', 'GST ($)', 'Total Amount ($)']],
            body: invoice_table_body, 
            didParseCell: function (data) {
                var rows = data.table.body;
                if (data.row.index === rows.length - 1) {
                    data.cell.styles.fontStyle = "bold";
                }
            }
        })
            
        /**
         * Create filename
         */
        var file_name = this.state.alexus_reference + '-' + this.state.from_date +  "-to-" + this.state.to_date;

        /*
            *Create a footer
        */
        const pageCount = doc.internal.getNumberOfPages();
        // console.log("pageCount", pageCount)

        for(var i = 1; i <= pageCount; i++) {
            if(i == pageCount){
                doc.text('Thank you for your business!', 85, doc.internal.pageSize.height - 10);
                doc.text('www.alexus.com.au', 92, doc.internal.pageSize.height - 5);
            }
        }

        doc.save(file_name);
        
        // const data = {
        //     user_email: this.state.current_user_email,
        //     user_name: this.state.current_name
        // }

        // request('POST', `/reconciliations/print-reconciliation-report/${this.state.id}`, data, true).then(result => {
        //     console.log(result.data)
        //     this.setState({ 
        //         successMessage: result.data.message, 
        //         showSuccessModal: true,
        //         isLoading: false,
        //     })
        //     // const invoice_url = result.data.url;
        //     // this.setState({
        //     //     isLoading: false
        //     // })
        //     // if(invoice_url){
        //     //     const win = window.open(invoice_url, '_blank');
        //     //     if(win){
        //     //         win.focus();    
        //     //     }
        //     // } 
        // }).catch(err => { 
        //     console.log(err.response)
        //     this.setState({
        //         isLoading: false
        //     })
        // });

    }

    transformAmount = (amount) => {
        return "$" + amount.toLocaleString('en-US', {minimumFractionDigits: 2})
    }

    getDoctorName = (elem_items) => {
        var current_doctor_name = null;
        elem_items.forEach(val => {
            var booking_info = val.booking
            if(booking_info){
                var doctor_name = booking_info.doctor.title + '. ' + booking_info.doctor.first_name + ' ' + booking_info.doctor.last_name
                if(current_doctor_name != doctor_name){
                    current_doctor_name = doctor_name 
                }
            }
        })

        return current_doctor_name;
    }

    hideSuccessModal = () => {
        this.setState({ 
            showSuccessModal: false
        })

    }

    // getTrProps = (state, rowInfo, instance) => {
    //     if (rowInfo) {
    //         return {
    //             style: {
    //                 color:
    //                 rowInfo.original.payment_status == "PAID" 
    //                     ? "#009900"
    //                     : "#000000"
    //             }
    //         }
    //     }
    //     return {}
    // }

    render() {

        const columns = [ 
            {  
                Header: 'Invoice No.',  
                id: 'invoice_number',
                width: this.getColumnWidth('invoice_number', 150),
                Cell: (row) => (<span>{`${row.original.ID}`}</span>),
                accessor: row => row.ID,                
            },

            {
                Header: 'Hospital',  
                id: 'hospital',
                width: this.getColumnWidth('hospital', 250),
                // Cell: (row) => (<span>{`${row.original.hospital.name}`}</span>),
                accessor: row => row.hospital && row.hospital.name,
                Cell: (row) => {
                    return (
                        <React.Fragment>
                          <div className="cell-text">
                            {
                                row.original.hospital && ( <span>{row.original.hospital.name}</span>)
                            }
                          </div>
                        </React.Fragment>
                      )
                }
            },

            {
                Header: 'Doctor',  
                id: 'doctor',
                width: this.getColumnWidth('doctor', 250),
                // Cell: (row) => (<span>{`${row.original.doctor.title}. ${row.original.doctor.first_name} ${row.original.doctor.last_name}`}</span>),
                // accessor: row => row.doctor && row.doctor.id,
                accessor: (row) => {
                    return  (
                        <React.Fragment>
                            {
                                row.items && row.items.map(obj => {
                                    return <React.Fragment> 
                                        {
                                            (obj.booking && obj.booking.doctors && obj.booking.doctors.last_name)
                                        }
                                        </React.Fragment>
                                    
                                })
                            }
                        </React.Fragment>
                    )
                },
                Cell: (row) => {
                    let current_doctor_name = null
                    return (
                        <React.Fragment>
                          <div className="cell-text">
                            {
                                row.original.items && row.original.items.map(obj => {
                                    if(obj.booking){
                                        let doctor_name = obj.booking && obj.booking.doctor && obj.booking.doctor.title + '. ' + obj.booking.doctor.first_name + ' ' + obj.booking.doctor.last_name
                                        if(current_doctor_name != doctor_name){
                                            current_doctor_name = doctor_name
                                            return <React.Fragment>
                                                {
                                                    <span>
                                                        {doctor_name}<br />                                               
                                                    </span>                                           
                                                }   
                                            </React.Fragment>
                                        }
                                    }
                                })
                            }
                          </div>
                        </React.Fragment>
                      )
                }
            },

            {
                Header: 'Trans.', 
                accessor: row => row && row.total_items,
                width: this.getColumnWidth('transactions', 100),
                id: "transactions",
                Cell: (row) => {
                    return (
                        <React.Fragment>
                          <div className="cell-text">
                            <span>{row.original.total_items}</span>
                          </div>
                        </React.Fragment>
                      )
                }
            },

            {
                Header: "Net ($)",
                id: 'total_amount',
                width: this.getColumnWidth('total_amount', 150),
                Cell: (row) => (<span>${`${(row.original.total_amount).toLocaleString('en-US', {maximumFractionDigits: 2})} `}</span>),
                accessor: row => row.total_amount
            },

            {
                Header: "GST ($)",
                id: 'total_amount',
                width: this.getColumnWidth('total_amount', 150),
                Cell: (row) => (<span>${`${(row.original.total_amount * 0.1).toLocaleString('en-US', {maximumFractionDigits: 2})} `}</span>),
                accessor: row => row.total_amount
            },

            {
                Header: "Total ($)",
                id: 'total_amount',
                width: this.getColumnWidth('total_amount', 150),
                Cell: (row) => (<span>${`${(row.original.total_amount + (row.original.total_amount * 0.1)).toLocaleString('en-US', {maximumFractionDigits: 2})} `}</span>),
                accessor: row => row.total_amount
            }

        ];

        return (
            <div className="container-fluid p-3 p-md-5">
                <div className="row justify-center mb-4">
                    <div className="col-sm-12 col-md-10">
                   
                    </div>
                </div>   
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col sm={12} md={8} lg={10}>
                                <h5 className="mb-2 font-weight-bold text-primary center">{this.state.pageTitle}</h5>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                {
                                    this.state.isLoading ? "" : ( 
                                        <button className="btn btn-primary center" onClick={this.handleEmail} style={{width: "100%" }} > Dowload Report </button>
                                    )
                                }
                                {/* <Link to="/alexus-report-page"><button className="btn btn-primary center" style={{width: "100%" }} > Try </button></Link> */}
                            </Col>
                        </Row>
                    </div>             
                    <div className="card-body">

                        {/* Modal for Email Successfully Sent */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideSuccessModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center"  style={{textAlign: "center"}}>{this.state.successMessage ? this.state.successMessage : "Email Successfully Sent"}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideSuccessModal}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>


                        {
                            this.state.isLoading ? (
                                <Loader
                                    type="TailSpin"
                                    color="#2850C2"
                                    height={80}
                                    width={80}
                                    // timeout={20000} //20 secs
                                />
                            ) :(
                                <Container id="main-page-content">
                                    <Row className="center">
                                        <h6 className="font-weight-bold"><u>Income</u></h6>
                                    </Row>
                                    <Row className="m-0 center" id="income-part">
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Net ($)</th>
                                                    <th>GST ($)</th>
                                                    <th>Gross ($)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Income</td>
                                                    <td>${(this.state.net).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                    <td>${(this.state.gst).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                    <td>${(this.state.total_amount).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Row>
                                    <Row className="mt-lg-2 mt-md-2 mt-sm-0 center">
                                        <h6 className="font-weight-bold "><u>Disbursement</u></h6>
                                    </Row>
                                    <Row className="m-0 center">
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Net ($)</th>
                                                    <th>GST ($)</th>
                                                    <th>Gross ($)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Exus Services Pty Ltd</td>
                                                    <td>${(this.state.divided_net).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                    <td>${(this.state.divided_gst).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                    <td>${(this.state.divided_total_amount).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                </tr>
                                                <tr>
                                                    <td>Australasian Lithotripsy Services Pty Ltd</td>
                                                    <td>${(this.state.divided_net).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                    <td>${(this.state.divided_gst).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                    <td>${(this.state.divided_total_amount).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total</td>
                                                    <td>${(this.state.net).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                    <td>${(this.state.gst).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                    <td>${(this.state.total_amount).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Row>
                                    <Row className="mt-lg-2 mt-md-2 mt-sm-2 center">
                                        <h6 className="font-weight-bold"><u>Disbursement Breakdown</u></h6>
                                    </Row>
                                    <Row className="m-0 center">
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Disbursed To</th>
                                                    <th>Gross ($)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Exus Services Pty Ltd</td>
                                                    <td>${(this.state.divided_total_amount).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                </tr>
                                                <tr>
                                                    <td>Australasian Lithotripsy Services Pty Ltd</td>
                                                    <td>${(this.state.divided_total_amount).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total</td>
                                                    <td>${(this.state.divided_total_amount * 2).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Row>
        
                                    <Row className="mt-lg-2 mt-md-2 mt-sm-5 center">
                                        <p className="font-weight-bold ">HOSPITAL INVOICES INCLUDED IN MANAGEMENT FEE</p>
                                    </Row>
                                    <ReactTable
                                        data={this.state.invoices}
                                        columns={columns}
                                        style={{ overflow: "wrap" }}
                                        PaginationComponent={Pagination}
                                        className="center"
                                        minRows={0}
                                        // getTrProps={this.getTrProps}
                                        onResizedChange={this.getResizeValues}
                                    />
        
                                    <Row className="mt-lg-5 mt-md-5 mt-sm-2 center">    
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <td>No. of Trans</td>
                                                    <td>Net ($)</td>
                                                    <td>GST ($)</td>
                                                    <td>Gross ($)</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Total</td>
                                                    <td>{this.state.total_transactions}</td>
                                                    <td>${(this.state.net).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                    <td>${(this.state.gst).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                    <td>${(this.state.total_amount).toLocaleString('en-US', {maximumFractionDigits: 2})}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Container>
                            )
                        }
                    </div>
                </div>
            </div>
            
        );
    }
}

export default AlexusReport;