import React, { Component } from 'react';
import { FormGroup, FormControl, Button, Modal } from 'react-bootstrap';
import { request } from '../../constants/constants';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            showSuccessModal: false
        };
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = e => {

        e.preventDefault();

        this.setState({
            showErrorModal: false
        })

        const data = {
            email: this.state.email
        }
        request('POST', '/auth/forgotpassword/', data, false).then(res => {
            this.setState({
                showSuccessModal: true
            })
        }).catch(err => { 

            this.setState({
                showErrorModal: true,
                errorMessage: err.response.data.message
            })

        });
    }

    hideModal = () => {
        this.setState({
            showSuccessModal: false
        })
        window.location.href = "/forgotpassword";
    }

    hideModalError = () => {
        this.setState({
            showErrorModal: false
        })
    }



    render() {
        return (
            <div className="LoginForm">

                <Modal show={this.state.showErrorModal}>
                    <Modal.Header onClick={this.hideModalError} closeButton>
                        <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error: </h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="center" style={{ textAlign: "center"}}>{this.state.errorMessage}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary center" onClick={this.hideModalError}>
                            OK
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showSuccessModal}>
                    <Modal.Header onClick={this.hideModal} closeButton>
                        <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="center" style={{ textAlign: "center"}}>Please check your email now!</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary center" onClick={this.hideModal}>
                            OK
                        </button>
                    </Modal.Footer>
                </Modal>

                <form className="p-5" onSubmit={this.handleSubmit}> 
                    <div className="login-container">
                        <p>Please enter your email</p>
                        <div className="form-group">
                            <input name="email" value={this.state.value} onChange={this.handleChange} type="text" className="form-control" id="email" placeholder="Email" />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">Send</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default ForgotPassword;