import React, { Component } from 'react';
import { Col, Row } from "reactstrap";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Modal, FormLabel, FormGroup, FormControl, Table } from "react-bootstrap";
import moment from "moment";
import { request } from '../../constants/constants';
import UserContext from '../../contexts/User/Index';
// import './CalendarStyles.css';

class MainCalendar extends Component {
    
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            doctors: [],
            hospitals: [],
            showBookings: false,
            isLoading: false
        }
    }

    componentDidMount() {
        const userPermission = this.context.user.user.permission;
        const user_id = this.context.user.user.id;

        this.init(userPermission, user_id);

        if(userPermission === 'super_administrator' || userPermission === 'administrator' || userPermission === 'staff'){ 
            request("GET", "/doctors/allDoctorsByAdminOnlyActive", {}, true).then((result) => {
                const doctors = result.data.data.map((obj) => ({
                    id: obj.ID,
                    name: obj.title + ". " + obj.first_name + " " + obj.last_name,
                    hospitals: obj.hospitals,
                }));
                this.setState({ doctors });
            });
        }

        if(userPermission === 'secretary'){ 
            request("GET", "/doctors", {}, true).then((result) => {
                const doctors = result.data.data.map((obj) => ({
                    id: obj.ID,
                    name: obj.title + ". " + obj.first_name + " " + obj.last_name,
                    hospitals: obj.hospitals,
                }));
                this.setState({ doctors });
            });
        }

    }

    init(permission, user_id) {
        this.setState({
            isLoading: true
        })

        if(permission === 'super_administrator' || permission === 'administrator'){
            this.getBookingsAdmin().then((result) => {
                console.log("result", result)
                const bookingsDates = result.data.data.map(obj => new Date(obj));
                this.setState({
                    bookingsByDate: bookingsDates,
                    isLoading: false,
                });
            });
        }

        if(permission === 'staff'){
            this.getBookingsStaff(user_id).then((result) => {
                console.log("result", result)
                const bookingsDates = result.data.data.map(obj => new Date(obj));
                this.setState({
                    bookingsByDate: bookingsDates,
                    isLoading: false,
                })
            })
        }

        if(permission === 'secretary'){
            this.getBookingsSecretary().then((result) => {
                console.log("result", result)
                const bookingsDates = result.data.data.map(obj => new Date(obj));
                this.setState({
                    bookingsByDate: bookingsDates,
                    isLoading: false,
                });
            });
        }
        
    }

    getBookingsStaff(user_id) {
        return request('GET', `/bookings/dates/staff/getAllBookingDatesForStaff/${user_id}`, {}, true);
    }

    getBookingsAdmin() {
        return request('GET', '/bookings/dates/getAllBookingDatesForAdmin', {}, true);
    }

    getBookingsSecretary() {
        return request('GET', '/bookings/dates/secretary/getAllBookingDatesForSecretary', {}, true);
    }

    /* Function for Doctor Dropdown*/
    handleDoctor = (e) => {
        const id = e.target.value;
        if (id === "-Select-") {
            return false;
        }
        const index = this.state.doctors.findIndex((doctor) => doctor.id === id);
        const doctor = this.state.doctors[index];
        this.setState({
            hospitals: doctor.hospitals,
            currentDoctorId: id,
        });
    };

    /* Function for Hospital Dropdown*/
    handleHospital = (e) => {
        const id = e.target.value;
        this.setState({ currentHospitalId: id });
    };

    /* Function for Slot Dropdown*/
    handleSlot = (e) => {
        const slotValue = e.target.value;
        if (slotValue === "-Select-") {
            return false;
        }
        this.setState({
            currentSlot: slotValue,
        });
    };

    handleDayClick = (date) => {

        const newDate = moment(date).format("YYYY-MM-DD");
        window.location.href = `/booking-information/${newDate}`;
        
        // const data = {
        //     date: newDate,
        //     doctorId: this.state.currentDoctorId,
        //     hospitalId: this.state.currentHospitalId,
        //     slot: this.state.currentSlot
        // };

        // this.setState({
        //     showBookings: true,
        //     isLoading: true,
        // })

        // request('POST', '/bookings/groupByDate', data, true).then(result => {
        //     console.log(result)
        //     const booking_data = result.data.data.map(obj => ({
        //         doctor_name: obj.name,
        //         doctor_id: obj.id,
        //         bookings: obj.bookings.map(val => val)
        //     }))
        //     this.setState({
        //         booking_data: booking_data,
        //         isLoading: false,
        //     })
        // }).catch((err) => {
        //     console.error(err.response);
        //     this.setState({
        //         isLoading: false,
        //         errorCode: err.response.status,
        //         errorMessage: err.response.data.message,
        //     });
        // });
    }

    handleApplyFilter = () => {
        const data = {
          doctorId: this.state.currentDoctorId,
          hospitalId: this.state.currentHospitalId,
          slot: this.state.currentSlot,
        };
    
        this.setState({
            bookingsByDate: [],
            isLoading: true,
        });
    
        this.getBookingsFilter(data);
    
    }

    getBookingsFilter = (data) => {

        request("POST", `/bookings/search/apply-filter-bookings`, data, true).then((result) => {
            console.log('apply-filter-bookings', result)
            const bookingsDates = result.data.data.map(obj => new Date(obj));
            this.setState({
                bookingsByDate: bookingsDates,
                isLoading: false,
            });
         });
        
    }

    /* Modal Functions*/

    hideBookings = () => {
        this.setState({
            showBookings: false,
            errorCode: null,
            booking_data: null
        })
    }


    render() {
        const bookingStyle = `.DayPicker-Day--highlighted {
            background-color: #7489C2;
            color: black;
        }`;

        const modifiers = {
            highlighted: this.state.bookingsByDate
        };

        return (
            <div className="container-fluid p-3 p-md-5">
                <div className="row justify-center mb-4">
                    <div className="col-sm-12 col-md-10">
                        <FormGroup>
                            <Row className="center">
                                <Col sm={12} md={12} lg={3}>
                                    <FormLabel>Doctors: </FormLabel>
                                    <FormControl
                                        as="select"
                                        type="text"
                                        name="doctor_id"
                                        id="doctor_id"
                                        onChange={this.handleDoctor}
                                        className="center"
                                    >
                                        <option value="-Select-">-Select-</option>
                                        {this.state.doctors.map((doctor, i) => (
                                            <option key={i} value={doctor.id}>
                                                {" "}
                                                {doctor.name}{" "}
                                            </option>
                                        ))}
                                    </FormControl>
                                </Col>
                                <Col sm={12} md={12} lg={3}>
                                    <FormLabel>Hospitals: </FormLabel>
                                    <FormControl
                                        as="select"
                                        type="text"
                                        name="hospital_id"
                                        id="hospital_id"
                                        className="center"
                                        onChange={this.handleHospital}
                                    >
                                        <option value="-Select-">Select All</option>
                                        {this.state.hospitals.map((hospital, i) => (
                                            <option key={i} value={hospital.id}>
                                                {" "}
                                                {hospital.name}{" "}
                                            </option>
                                        ))}
                                    </FormControl>
                                </Col>

                                <Col sm={12} md={12} lg={3}>
                                    <FormLabel>Slots: </FormLabel>
                                    <FormControl
                                        as="select"
                                        name="slot"
                                        id="slot"
                                        className="center"
                                        onChange={this.handleSlot}
                                    >
                                        <option value="-Select-">-Select-</option>
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                    </FormControl>
                                </Col>
                                <Col sm={12} md={12} lg={3}>
                                    <FormLabel> </FormLabel>
                                    <button
                                        type="button"
                                        className="btn btn-primary center btn-block mt-2"
                                        onClick={this.handleApplyFilter}
                                    >
                                        Search
                                    </button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col>
                                <h6 className="mb-2 m-md-0 font-weight-bold text-primary">
                                    List Summary
                            </h6>
                            </Col>
                        </Row>
                    </div>
                    <div className="card-body table-responsive">
                        {/*Modal Show Bookings per Doctor by date */}
                        <Modal
                            show={this.state.showBookings}
                        >
                            <Modal.Header onClick={this.hideBookings} closeButton>
                                <Modal.Title>
                                    <h5 className="m-0 font-weight-bold text-danger">
                                        List of Bookings
                                    </h5>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    this.state.isLoading ? (<p>Loading ... </p>) : (
                                        this.state.booking_data && this.state.booking_data.map(obj => {
                                            return <React.Fragment>
                                                <p className="center"> <b>{obj.doctor_name} </b></p>
                                                {obj.bookings && obj.bookings.map(val => {
                                                    return <React.Fragment>
                                                        <ul>
                                                            <li className="center">{val.patient.title}. {val.patient.first_name} {val.patient.last_name}</li>
                                                        </ul>
                                                    </React.Fragment>

                                                })}
                                            </React.Fragment>
                                        })
                                    )
                                }
                                {
                                    this.state.errorCode ? (<p className="center" style={{ textAlign: 'center' }}>{this.state.errorMessage}</p>) : ""

                                }

                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-primary center"
                                    onClick={this.hideBookings}
                                >
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <Row>
                            <Col lg={12}>
                                <style>{bookingStyle}</style>
                                <DayPicker
                                    onDayClick={this.handleDayClick}
                                    numberOfMonths={6}
                                    className="center"
                                    modifiers={modifiers}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainCalendar;