import React, { Component } from 'react';
import { Container, Row, Col, FormGroup } from 'reactstrap';
import { FormControl, FormLabel, Modal} from 'react-bootstrap';
import { request } from '../../constants/constants';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import { Calendar } from 'react-calendar';
import UserContext from '../../contexts/User/Index';
import { Multiselect } from 'multiselect-react-dropdown';
import history from './../../history';
import './BookingCalendar.css';
import Loader from "react-loader-spinner";

class DoctorsBooking extends Component {
    static contextType = UserContext;

    constructor (props){
        super(props);
        this.state = {
            doctor_id: '',
            hospital_id: '',
            room_id: '',
            patient_id: '',
            slot: '',
            booking_status: 1,
            notes: '',
            date: '',
            doctors: [],
            currentHospitals: [],
            booking_dates: [],
            patient_procedure_booking_dates: [],
            booking: [],
            curBookingInfo: [],
            procedures: [],
            procedure: [],
            patients: [],
            doctorName: [],
            hospitalName: [],
            patientName: [],
            selectedPatients: [],
            cycleId: '',
            doctorId: '',
            currentProcedures: [],
            currentHospital: '',
            finalProcedure: [],
            procedureName: [],
            start_dates: [],
            end_dates: [],
            selectedValue: [],
            hospital_procedures: [],
            hospitals: [],
            offListDate: [],
            defaultDoctorId: '',
            multipleDoctor: 1,
            currentDoctorId: '',
            errorMessage :'',
            error: [],

            //for modals
            showSuccessModal: false,
            showError: false,
            show: false,
            isLoading: false,

            week_start_dates: [],
            week_end_dates: [],
            dayAddNumberCollection: [],
            slotOptions: []

        };
    }

    componentDidMount() {
        const userPermission = this.context.user.user.permission;
        this.init(userPermission);  

        if(this.props.location.state){
            this.setState({ 
                selectedValue: [{ id: this.props.location.state.newPatientId, name: this.props.location.state.newPatientName }],
                finalProcedure: [{ id: this.props.location.state.newPatientProcedureId, name: this.props.location.state.newPatientProcedureName }],
                currentDoctorId: this.props.location.state.newDoctorId
            }, () => {
                this.setState({
                    propsFinalProcedure: this.state.finalProcedure
                }) 
            })
        }
    } 

    init(userPermission){
        if(userPermission === 'administrator' || userPermission === 'super_administrator'){
            request('GET', '/doctors/admin/allDoctorsByBookingCycle', {}, true).then(result => {
                // console.log("doctors", result)
                const doctorName = result.data.data.map(obj => ({
                    cycleId: obj.id,
                    doctorId: obj.doctor_id,
                    procedures: obj.booking_cycle_procedures ? obj.booking_cycle_procedures : obj.booking_cycle_offlist_procedures,
                    name: obj.doctor ? obj.doctor.title + '. ' + obj.doctor.first_name + ' ' + obj.doctor.last_name : null ,
                    last_name: obj.doctor ? obj.doctor.last_name : null, 
                    hospitalName: obj.hospital.name,
                    hospitalId: obj.hospital_id,
                    slot: obj.slot,
                    week: obj.week,
                    day: obj.day,
                    date: obj.date,
                    column_start_date: obj.start_date,
                    column_end_date: obj.end_date
                }));
                doctorName.sort((a, b) => a.last_name > b.last_name ? 1 : -1);
                this.setState({ doctorName }, () => {
                    if(this.props.location.state){
                        if(this.props.location.state.newDoctorId){
                            this.handleDoctorId();
                        }
                    }
                })
            });
        } else if (userPermission === 'secretary'){
            request('GET', '/doctors/secretary/allDoctorsByBookingCycleSec', {}, true).then(result => {
                // console.log("allDoctorsByBookingCycleSec", result)
                const doctorName = result.data.data.map(obj => ({
                    cycleId: obj.id,
                    doctorId: obj.doctor_id,
                    procedures: obj.booking_cycle_procedures ? obj.booking_cycle_procedures : obj.booking_cycle_offlist_procedures,
                    name: obj.doctor ? obj.doctor.title + '. ' + obj.doctor.first_name + ' ' + obj.doctor.last_name : null,
                    last_name: obj.doctor ? obj.doctor.last_name : null,
                    hospitalName: obj.hospital.name,
                    hospitalId: obj.hospital_id,
                    slot: obj.slot,
                    week: obj.week,
                    day: obj.day,
                    date: obj.date,
                    column_start_date: obj.start_date,
                    column_end_date: obj.end_date
                }));
                doctorName.sort((a, b) => a.last_name > b.last_name ? 1 : -1);
                this.setState({ doctorName }, () => {

                    if(this.state.doctorName.length === 1){
                        
                        const index = this.state.doctorName.findIndex(info => info.doctorId);
                        const doctor = this.state.doctorName[index];

                        const currentProcedures = doctor.procedures.map((procedure, i) => ({ 
                            name: procedure.procedure_name, 
                            id: procedure.procedure_id
                        }));

                        this.setState({
                            currentDoctorId: this.state.doctorName.map(obj => obj.doctorId).toString(),
                            doctor_id: doctor.doctorId
                        });

                        this.getBookingCyclesInformation(doctor.doctorId, doctor.week);
                    }

                    if(this.props.location.state){
                        if(this.props.location.state.newDoctorId){
                            this.handleDoctorId();
                        }
                    }
                })
            });
        } 

    }

    bookingsForCalendar = (doctorId, hospitalId) => {
        request('GET', `/bookings/appointment/${hospitalId}/${doctorId}`, {}, true).then(result => { 
            // console.log('bookings', result)
            const cal_bookings = result.data.data.map(booking => {
                this.setState({
                    booking_dates: [...this.state.booking_dates, booking.date]
                })
            }); 
        })
    }

    handleDoctorId = (e) => {

        const docId = this.props.location.state ? (this.props.location.state.newDoctorId && this.props.location.state.newDoctorId) : e.target.value;

        if(docId === "-Select-"){
            return false;
        }

        this.setState({
            doctor_id: docId,
            currentDoctorId: docId,
            start_dates: [],
            end_dates: [],
            offListDate: [],
            patient_procedure_booking_dates: [],
            slotOptions: []
        })

        document.getElementById('hospital_id').value = 'Select All';

        this.getPatients(docId);

        const index = this.state.doctorName.findIndex(info => info.doctorId === docId);

        const doctor = this.state.doctorName[index];
        if(doctor){
            console.log('doctor', docId)
            this.getBookingCyclesInformation(docId, doctor.week, doctor.column_start_date, doctor.column_end_date)
        }
    }

    getPatients = (docId) => {
        // console.log("doctorId", doctorId);

        request('GET', `/patients/getBy/doctor/${docId}`, {}, true).then(result => {
            // console.log('result', result)
            const patients = result.data.data.patients.map(obj => ({
                id: obj.id,
                name: obj.title + '. ' + obj.first_name + ' ' + (obj.second_name ? obj.second_name + ' ' : '') + obj.last_name,
                last_name: obj.last_name,
                patient_procedure_id: obj.procedure
            }));
            patients.sort((a, b) => a.last_name > b.last_name ? 1 : -1);
            this.setState({ patients }); 
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response
            })
        })    

    }
    
    getBookingCyclesInformation = (docId, doctor_week, doctor_column_start_date, doctor_column_end_date) => {
        this.getPatients(docId);

        this.initBookingCyclesInfo(docId).then((result, i) => {

            // console.log("length", result.data.data.length);

            if(result.data.data.length === 1){
                
                this.initSingleData(docId, doctor_week, doctor_column_start_date, doctor_column_end_date);
                
            } else {

                this.setState({
                    multipleDoctor: 1,
                    finalProcedure: this.props.location.state ? this.state.propsFinalProcedure : [],
                    slot: ""
                })

                request('GET', `/doctor_hospitals/byDoctorIdWithBookingCycle/${docId}`, {}, true).then((result, i) => { 
                    // console.log('doctor_hospitalsResults', result.data.data.length)

                    if(result.data.data.length === 1){

                        this.setState({
                            multipleDoctor: 0,
                            finalProcedure: [],
                            slot: ""
                        })
                
                        const second_index = this.state.doctorName.findIndex(info => info.doctorId === this.state.doctor_id);
                        const second_doctor = this.state.doctorName[second_index];
                           
                        this.setState({
                            currentHospital: second_doctor.hospitalName,
                            currentHospitalId: second_doctor.hospitalId,
                        });
                    
                        this.getBookingsByHospitalDoctor(this.state.currentHospitalId, docId);
        
                    } else {

                        const hospitals = result.data.data.map(obj => ({
                            hospitalName: obj.name,
                            hospitalId: obj.id,
                            hospitalStatus: obj.status
                        }));

                        this.setState({ hospitals });

                    }
                })

            }                       
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response
            })
        })

    }

    initBookingCyclesInfo = (docId) => {
        return request('GET', `/booking_cycles/getBydoctorId/getBookingCycleBydoctorId/${docId}`, {}, true);
    }

    initSingleData = (docId, doctor_week, doctor_column_start_date, doctor_column_end_date) => {
       this.setState({
           multipleDoctor: 0,
           finalProcedure: [],
           slot: ""
       })

       const second_index = this.state.doctorName.findIndex(info => info.doctorId === this.state.doctor_id);
       const second_doctor = this.state.doctorName[second_index];
       const currentProceduresSingleData = second_doctor.procedures.map((procedure, i) => ({ 
           name: procedure.procedure_name, 
           id: procedure.procedure_id
       })); 
          
       this.setState({
           currentDoctorId: second_doctor.doctorId,
           currentHospital: second_doctor.hospitalName,
           currentHospitalId: second_doctor.hospitalId,
           finalProcedure: this.props.location.state ? this.state.propsFinalProcedure : currentProceduresSingleData,
           slot: second_doctor.slot,
           currentDay: second_doctor.day
       }, async () => {
           
           const hospId = second_doctor.hospitalId;
           const dayForDate = second_doctor.day;

           //Check bookings is existing
           this.bookingsForCalendar(docId, hospId);
           const weekNumberToString = doctor_week;

           const weekNumber = weekNumberToString  === "4" || weekNumberToString  === 4 ? 0 : weekNumberToString;
            // console.log("details: ", dayForDate, weekNumber)
            if(second_doctor.date){
        
                this.state.offListDate.push(second_doctor.date)

            } else { 

                if(dayForDate === "Sunday"){
                    let dayAddNumber = 6;
                    await this.getWeekNumber(weekNumber, "Sunday", doctor_column_start_date, doctor_column_end_date);    
                }
                if(dayForDate === "Monday"){
                        let dayAddNumber = 0;
                        await this.getWeekNumber(weekNumber, "Monday", doctor_column_start_date, doctor_column_end_date);
                }
                if(dayForDate === "Tuesday"){
                        let dayAddNumber = 1;
                        await this.getWeekNumber(weekNumber, "Tuesday", doctor_column_start_date, doctor_column_end_date);
                }
                if(dayForDate === "Wednesday"){
                        let dayAddNumber = 2;
                        await this.getWeekNumber(weekNumber, "Wednesday", doctor_column_start_date, doctor_column_end_date);
                }
                if(dayForDate === "Thursday"){
                        let dayAddNumber = 3;
                        await this.getWeekNumber(weekNumber, "Thursday", doctor_column_start_date, doctor_column_end_date);
                }
                if(dayForDate === "Friday"){
                        let dayAddNumber = 4;
                        await this.getWeekNumber(weekNumber, "Friday", doctor_column_start_date, doctor_column_end_date);
                }
                if(dayForDate === "Saturday"){
                        let dayAddNumber = 5;
                        await this.getWeekNumber(weekNumber, "Saturday", doctor_column_start_date, doctor_column_end_date);
                } 
                
            } 
           
           this.setState({
               // procedureName: this.props.location.state ? this.state.propsFinalProcedure.map(obj => obj.name) : this.state.finalProcedure.map(obj => obj.name)
               procedureName: this.props.location.state ? this.state.propsFinalProcedure : this.state.finalProcedure

           })                            
       });
    }

    handleHospitalId = e => {
        const hospitalId = e.target.value;
        
        this.setState({
            hospitalId: hospitalId,
            currentHospitalId: hospitalId,
            week_start_dates: [],
            week_end_dates: [],
            start_dates: [],
            end_dates: [],
            dayAddNumberCollection: [],
            offListDate: [],
            patient_procedure_booking_dates: [],
            slotOptions: []
        })

        this.getBookingsByHospitalDoctor(hospitalId, this.state.doctor_id);
        this.bookingsForCalendar(this.state.doctor_id, hospitalId);

    }   

    getBookingsByHospitalDoctor = (hospital_id, doctor_id) => {
        let dateCounter = 0;
        request('GET', `/booking_cycles/getByHospAndDoc/${hospital_id}/${doctor_id}`, {}, true).then(result => {
            // console.log("getByHospAndDoc", result)
            const booking_cycles = result.data.data.map(obj => ({
                cycleId: obj.id,
                procedures: obj.booking_cycle_procedures ? obj.booking_cycle_procedures.map(obj => ({ id: obj.procedure_id, name: obj.procedure_name })) :  obj.booking_cycle_offlist_procedures.map(obj => ({ id: obj.procedure_id, name: obj.procedure_name })),
                slot: obj.slot,
                week: obj.week,
                day: obj.day,
                date: obj.date,
                column_start_date: obj.start_date,
                column_end_date: obj.end_date
            }));

            this.setState({
                booking_cycles           
            }, async () => {
                for (let index = 0; index < this.state.booking_cycles.length; index++) {
                    dateCounter++;
                   let dayAddNumber = 0;
                   let indexed_booking_cycle = this.state.booking_cycles[index];
                //    console.log("details: ", indexed_booking_cycle.day, indexed_booking_cycle.week)
                    if(booking_cycles[index].date){
                        // console.log(booking_cycles[index].date)
                        this.state.offListDate.push(this.state.booking_cycles[index].date)
                    } else {
                        
                        if(indexed_booking_cycle.day === "Sunday"){
                            let dayAddNumber = 6;
                            await this.getWeekNumber(indexed_booking_cycle.week, "Sunday", indexed_booking_cycle.column_start_date, indexed_booking_cycle.column_end_date);                        
                        }
                        if(indexed_booking_cycle.day === "Monday"){
                            let dayAddNumber = 0;
                            await this.getWeekNumber(indexed_booking_cycle.week, "Monday", indexed_booking_cycle.column_start_date, indexed_booking_cycle.column_end_date);                        

                        }
                        if(indexed_booking_cycle.day === "Tuesday"){
                            let dayAddNumber = 1;
                            await this.getWeekNumber(indexed_booking_cycle.week, "Tuesday", indexed_booking_cycle.column_start_date, indexed_booking_cycle.column_end_date);                        

                        }
                        if(indexed_booking_cycle.day === "Wednesday"){
                            let dayAddNumber = 2;
                            await this.getWeekNumber(indexed_booking_cycle.week, "Wednesday", indexed_booking_cycle.column_start_date, indexed_booking_cycle.column_end_date);                        

                        }
                        if(indexed_booking_cycle.day === "Thursday"){
                            let dayAddNumber = 3;
                            await this.getWeekNumber(indexed_booking_cycle.week, "Thursday", indexed_booking_cycle.column_start_date, indexed_booking_cycle.column_end_date);                        

                        }
                        if(indexed_booking_cycle.day === "Friday"){
                            let dayAddNumber = 4;
                            await this.getWeekNumber(indexed_booking_cycle.week, "Friday", indexed_booking_cycle.column_start_date, indexed_booking_cycle.column_end_date);                        

                        }
                        if(indexed_booking_cycle.day === "Saturday"){
                            let dayAddNumber = 5;
                            await this.getWeekNumber(indexed_booking_cycle.week, "Saturday", indexed_booking_cycle.column_start_date, indexed_booking_cycle.column_end_date);                        

                        } 


                    }
                }
                this.setState({
                    offListDate: this.state.offListDate
                })
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response
            })
        })
    }


    // getWeekNumber(currentWeekNumber, dayAddNumber, column_start_date, column_end_date){
    getWeekNumber(currentWeekNumber, day, column_start_date, column_end_date){

        return new Promise((resolve, reject) => {

            const date_data = {
                start_date: column_start_date,
                end_date: column_end_date,
                day: day
            }

            const weekNumber = currentWeekNumber === 4 ? 0 : currentWeekNumber;
            // this.state.dayAddNumberCollection.push(dayAddNumber);
            request('POST', `/booking_cycles/getDatesByWeekNumber/${weekNumber}`, date_data, true).then((result) => { 
                // console.log("getDatesByWeekNumber", result)
                this.setState({
                    start_dates: [ ...this.state.start_dates, result.data.week_start_dates],
                })
                resolve(true);
            }).catch(err => {
                console.error(err.response);
                this.setState({
                    isLoading: false,
                    error: err.response
                })
                reject(err)
            })
        })
    }

    handlePatient = e => {
        const patient_id = e.target.value;
        this.setState({
            patient_id: patient_id,
            // showAddPatientModal: e.target.value=="AddPatient" ? true : false
        })
    }  

    //Multiselect of Patients
    onSelect = (selectedList, selectedItem) => {
        this.setState({
            selectedPatients: selectedList
        })
        const procedure = selectedList.map(obj => obj.patient_procedure_id);
        this.setState({
            patient_procedure: procedure
        }, () => {
            this.state.patient_procedure.map((obj, i) => {
                const procedureId = obj;
                const for_booking_dates_data = {
                    hospital_id: this.state.hospitalId,
                    doctor_id: this.state.currentDoctorId,
                    procedure_id: procedureId
                }

                request('POST', `/booking_cycle_procedures/procedureDates`, for_booking_dates_data, true).then(result => { 
                    // console.log('procedureDates', result)
                    this.setState({
                        patient_procedure_booking_dates: result.data.day 
                    });
                }).catch(err => {
                    console.error(err.response);
                    this.setState({
                        isLoading: false,
                        error: err.response
                    })
                })

            })
        });
    }

    onRemove = (selectedList, removedItem) => {
        // console.log(selectedList, removedItem)
        this.setState({
            selectedPatients: selectedList,
            patient_procedure_booking_dates: []
        })

    }   

    //Multiselect of Treatment/Procedure
    onSelectTreatment = (selectedList, selectedItem) => {
        // console.log(selectedList)
        this.setState({
            // selectedTreatment: selectedList.map(obj => obj.name)
            selectedTreatment: selectedList
        })
    }
    onRemoveTreatment = (selectedList, selectedItem) => {
        this.setState({
            // selectedTreatment: selectedList.map(obj => obj.name)
            selectedTreatment: selectedList

        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleCalendar = (date) => {
        const event_date = moment(date).format("YYYY-MM-DD");
        const current_doctor_id = this.state.doctor_id;
        const current_hospital_id = this.state.currentHospitalId;

        return request('GET', `/booking_cycles/getBookingCycleByDate/${event_date}/${current_hospital_id}/${current_doctor_id}`, {}, true).then(result => {
            // console.log('Clickdates', result.data.data)

            const dateInformation = result.data.data.map(obj => ({
                slot: obj.slot,
                procedures: obj.booking_cycle_offlist_procedures ? obj.booking_cycle_offlist_procedures : obj.booking_cycle_procedures
            }))

            this.setState({ dateInformation })

            if(result.data.data.length > 1){

                result.data.data.map(value => {
                    if(!this.state.slotOptions.includes(value.slot)){
                        this.state.slotOptions.push(value.slot)
                    }
                })

            } else {
                const one_data = result.data.data[0];
                if(one_data){

                    const procedures = one_data.booking_cycle_procedures ? one_data.booking_cycle_procedures : one_data.booking_cycle_offlist_procedures
                    const currentProcedures = procedures.map((obj, i) => ({
                                                name: obj.procedure_name,
                                                id: obj.procedure_id
                                            }));

                    this.setState({
                        slot: one_data.slot,
                        finalProcedure: this.props.location.state ? this.state.propsFinalProcedure : currentProcedures
                    }, () => {
                        this.setState({
                            // procedureName: this.state.finalProcedure.map(obj => obj.name)
                            procedureName: this.state.finalProcedure
                        })
                    })
                }
            }

            
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response
            })
        })
    }

    onChange = date =>{ 
        this.setState({ 
            date 
        }) 
    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const data = {
            doctor_id: this.state.doctor_id ? this.state.doctor_id : this.state.currentDoctorId,
            hospital_id: this.state.hospitalId ? this.state.hospitalId : this.state.currentHospitalId,
            // procedure: this.state.selectedTreatment ? this.state.selectedTreatment.toString() : this.state.procedureName.toString(),
            procedure: this.state.selectedTreatment ? this.state.selectedTreatment : this.state.procedureName,
            patient_id: this.state.selectedValue.length > 0 ? this.state.selectedValue : this.state.selectedPatients,
            slot: this.state.slot,
            booking_status: this.state.booking_status,
            treated: 0,
            notes: this.state.notes,
            date: moment(this.state.date).format('YYYY-MM-DD'),
        }

        request('POST', '/bookings/', data, true).then(res => {
            this.setState({
                isLoading: false,
                showSuccessModal: true,
                successMessage: "Booking Successfully Added!"
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                responseCode: err.response.status,
                errorData: err.response.data.errors,
                errorMessage: err.response.data.errorMessage
            }, () => {
                this.setState({
                    showError: this.state.responseCode === 400 || 422 ? true : false,
                    // errorMessage: this.state.responseCode == 400 ? "Slot of the day already taken." : ""
                })
            })
            
        })
    }

    /*Modal Functions */
    hideModal = () => {
        this.setState({ show: false });
      }; 

    hideModalError = () => {
        this.setState({ showError: false })
    }

    hideModalSuccess = () => {
        this.setState({ 
            showSuccessModal: false, 
        })
        history.replace({ pathname: "/doctorsbooking", state: "" })
        window.location.href = "/doctorsbooking";
    }

    handleSlot = (e) => {
        const current_slot = e.target.value

        const slot_index = this.state.dateInformation.findIndex(info => info.slot == current_slot)
        const slot_info = this.state.dateInformation[slot_index];
        const slot_procedures = slot_info.procedures;

        const currentProcedures = slot_procedures.map((obj, i) => ({
            name: obj.procedure_name,
            id: obj.procedure_id
        }));

        this.setState({
            finalProcedure: this.props.location.state ? this.state.propsFinalProcedure : currentProcedures,
            slot: current_slot            
        }, () => {
            this.setState({
                procedureName: this.state.finalProcedure
            })
        }); 


        //  this.setState({
        //         slot: result.data.data.slot,
        //         procedures: result.data.data.booking_cycle_procedures ? result.data.data.booking_cycle_procedures: result.data.data.booking_cycle_offlist_procedures
        //     }, () => {

        //         /* For showing of procedures */
        //         // let procedures = this.state.procedures.map(obj => obj);

        //         const currentProcedures = this.state.procedures.map((obj, i) => ({
        //             name: obj.procedure_name,
        //             id: obj.procedure_id
        //         }));

        //         this.setState({
        //             finalProcedure: this.props.location.state ? this.state.propsFinalProcedure : currentProcedures,
        //             slot: this.state.slot            
        //         }, () => {
        //             this.setState({
        //                 // procedureName: this.state.finalProcedure.map(obj => obj.name)
        //                 procedureName: this.state.finalProcedure
        //             })
        //         });          
        //     })

    }

    render() {
        const mutiselectStyle = {
            option: { 
                color: "black",
            },
        };

        // console.log(this.context)
        return (
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col><h6 className="m-0 font-weight-bold text-primary">New Booking</h6></Col>
                        </Row>
                    </div>
                    <div className="card-body center">
                        {/* Modal for ERROR  */} 
                        <Modal show={this.state.showError}>
                            <Modal.Header onClick={this.hideModalError} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <p className="text-align" style={{textAlign: "center"}}>{this.state.errorMessage}</p>     
                            {
                                this.state.errorData &&  Object.keys(this.state.errorData).map(key => <p style={{textAlign: "center"}}>{this.state.errorData[key]}</p>)
                            }                                        
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={this.hideModalError}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>
                    
                        {/* Modal for Patient Successfully Added  */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideModalSuccess} closeButton>
                            <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation!</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: "center"}}>{this.state.successMessage}</p>     
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideModalSuccess}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Start of Form for Booking */}
                        <form onSubmit={this.handleFormSubmit}>
                            {
                                this.state.isLoading ? (
                                    <Loader
                                        type="TailSpin"
                                        color="#2850C2"
                                        height={80}
                                        width={80}
                                        timeout={10000} //3 secs
                                    />
                                ) : (
                                    <Container>
                                        <FormGroup>
                                            <Row className="center">
                                                <Col sm={2} md={3} lg={2}><FormLabel>Doctor: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                <Col sm={4} md={9} lg={4}>
                                                    <FormControl
                                                        as="select"
                                                        type="text"
                                                        name="doctor_id"
                                                        onChange={this.handleDoctorId}
                                                        className="center"
                                                        value={this.state.currentDoctorId}
                                                    >
                                                        <option value="-Select-">-Select-</option>
                                                        {this.state.doctorName.map(doctor => ( 
                                                            <option value={doctor.doctorId}> {doctor.name} </option>
                                                        ))}
                                                        
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup>
                                            <Row className="center">
                                                <Col sm={2} md={3} lg={2}><FormLabel>Hospital: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                <Col sm={4} md={9} lg={4}>
                                                        {this.state.multipleDoctor === 0 ? 
                                                            (<FormControl
                                                                type="text"
                                                                name="hospital_id"
                                                                id="hospital_id"
                                                                className="center"
                                                                value={this.state.currentHospital}   
                                                                disabled="true"                                        
                                                            />) : 
                                                            (
                                                                <FormControl
                                                                    as = "select"
                                                                    type="text"
                                                                    name="hospital_id"
                                                                    id="hospital_id"
                                                                    className="center"
                                                                    onChange={this.handleHospitalId}
                                                                >   
                                                                    <option value="Select All">Select All</option>
                                                                    {this.state.hospitals.map(obj => (
                                                                        ( obj.hospitalStatus ? <option value={obj.hospitalId}> {obj.hospitalName} </option> : '' )                                                                                  
                                                                    ))} 
                                                                </FormControl> 
                                                            ) 
                                                        }                                        
                                                </Col>
                                            </Row>
                                        </FormGroup> 
                                        <FormGroup>
                                            <Row className="center">
                                                <Col sm={2} md={3} lg={2}><FormLabel>Patient: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                <Col sm={4} md={9} lg={4}>
                                                <Multiselect
                                                    options={this.state.patients} 
                                                    selectedValues={this.state.selectedValue} 
                                                    onSelect={this.onSelect}
                                                    onRemove={this.onRemove} 
                                                    displayValue="name"
                                                    className="center"
                                                    style={mutiselectStyle}
                                                    avoidHighlightFirstOption="true"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup>
                                            <Row className="center">
                                                <Col sm={2} md={3} lg={2}><FormLabel>Date: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                <Col sm={4} md={9} lg={9}>
                                                    <Calendar
                                                        onChange={this.onChange}
                                                        onClickDay={this.handleCalendar}
                                                        tileClassName={({ date }) => { 
                                                            //console.log("this.state.booking_dates", this.state.booking_dates, moment(date).format("YYYY-MM-DD"))
                                                            if(this.state.booking_dates.find(x => x == moment(date).format("YYYY-MM-DD"))){
                                                                // console.log('date', moment(date).format("YYYY-MM-DD"))
                                                                return 'react-calendar__tile--active'
                                                            }                                   
                                                        }}

                                                        tileDisabled={({ date, i }) => {
                                                            let isBetween = true;
                                                            const dateFormatted = new Date(moment(date).format('YYYY-MM-DD')).getTime();
                                                            const timeStamp = 24*60*60*1000;

                                                            // /* For when choosing patients*/
                                                            // if(this.state.patient_procedure_booking_dates.find(x => x == moment(date).format("YYYY-MM-DD"))){
                                                            //     // console.log('date', moment(date).format("YYYY-MM-DD"))
                                                            //     return isBetween = false;
                                                            // }  
                                                            
                                                            /* For an offlist */
                                                            if(this.state.offListDate.length > 0){
                                                                // console.log('offListDates', this.state.offListDate)

                                                                this.state.offListDate.forEach(element => {
                                                                    const offListDate = new Date(element).getTime();
                                                                    if(offListDate === dateFormatted){
                                                                        isBetween = false;                                                        
                                                                    } 
                                                                });
                                                                                                                
                                                            } 
                                                            
                                                            /* For multiple booking cycle */
                                                            let dayAddNumber_ctr = 0;
                                                            
                                                            // this.state.dayAddNumberCollection.forEach(element => {
                                                            //     // console.log(dayAddNumber_ctr, this.state.start_dates[dayAddNumber_ctr], typeof this.state.start_dates[dayAddNumber_ctr]);
                                                            //     if(this.state.start_dates[dayAddNumber_ctr]){
                                                            //         this.state.start_dates[dayAddNumber_ctr].forEach(element_2 => {
                                                            //             let startDate = new Date(element_2).getTime();
                                                            //             let officialdate = startDate + element*timeStamp;
                                                            //             if(dateFormatted === officialdate) {
                                                            //                 isBetween = false;
                                                            //             } 
                                                            //         });      
                                                            //     }
                                                            //     dayAddNumber_ctr++;     
                                                            // });
                                                            
                                                                
                                                            if(this.state.start_dates.length > 0){

                                                                for (let index = 0; index < this.state.start_dates.length; index++) {
                                                                    this.state.start_dates[index].forEach(element_2 => {
                                                                        let startDate = new Date(element_2).getTime();
                                                                        let officialdate = startDate;
                                                                        if(dateFormatted === officialdate) {
                                                                            isBetween = false;
                                                                        } 
                                                                    });   
                                                                    
                                                                }
                                                                
                                                            }
                                                            return isBetween;
                                                        
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup>
                                            <Row className="center">    
                                                <Col sm={2} md={3} lg={2}><FormLabel>Slot: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                <Col sm={4} md={9} lg={4}>
                                                    {
                                                        this.state.slotOptions.length > 1 ? (
                                                            <FormControl
                                                                as="select"
                                                                name="slot"
                                                                className="center"
                                                                value={this.state.slot}
                                                                onChange={this.handleSlot}
                                                            >
                                                                
                                                                <option value="-Select-">-Select-</option>
                                                                {this.state.slotOptions.map((obj, i) => (
                                                                    <option key={i} value={obj}>
                                                                        {obj}
                                                                    </option>
                                                                ))}
                                                            </FormControl> 
                                                        ) : (
                                                            <FormControl
                                                                type="text"
                                                                name="slot"
                                                                className="center"
                                                                value={this.state.slotOptions[0] ? this.state.slotOptions[0] : this.state.slot}
                                                                onChange={this.handleChange}
                                                                disabled="true"
                                                            /> 
                                                        )
                                                    }
                                                        
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup>
                                            <Row className="center">
                                                <Col sm={2} md={3} lg={2}><FormLabel>Procedure: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                                <Col sm={4} md={9} lg={4}>
                                                    <Multiselect
                                                        options={this.state.finalProcedure} 
                                                        selectedValues={this.state.propsFinalProcedure} 
                                                        onSelect={this.onSelectTreatment} 
                                                        onRemove={this.onRemoveTreatment}
                                                        displayValue="name"
                                                        className="center"
                                                        style={mutiselectStyle}
                                                        avoidHighlightFirstOption="true"
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup>
                                            <Row className="center">
                                                <Col sm={2} md={3} lg={2}><FormLabel>Notes: </FormLabel></Col>
                                                <Col sm={4} md={9} lg={4}>
                                                    <FormControl 
                                                        as="textarea"
                                                        name="notes"
                                                        className="center"
                                                        value={this.state.notes}
                                                        onChange={this.handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <Row className="center">
                                            <Col sm={4} md={12} lg={2}><button style={{width: '100%'}}className="btn btn-primary center">Submit</button></Col>
                                        </Row>
                                    </Container>
                                )
                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default DoctorsBooking;