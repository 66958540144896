import React from 'react';
import {  Switch, Route } from 'react-router-dom';
import Home from '../pages/Home/Home';
import UserRoute from '../components/protected-routes/UserRoute';
import AdminRoute from '../components/protected-routes/AdminRoute';
import DoctorsTable from '../pages/Table/DoctorsTable';
import HospitalsTable from '../pages/Table/HospitalsTable';
import UsersTable from '../pages/Table/UsersTable';
import DoctorsForm from '../pages/Table/DoctorsForm';
import HospitalsForm from '../pages/Table/HospitalsForm';
import UsersForm from '../pages/Table/UsersForm';
import DoctorsBooking from '../pages/Bookings/DoctorsBooking';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import Login from '../pages/LogIn/Login';
import DoctorView from '../pages/Table/DoctorView';
import HospitalView from '../pages/Table/HospitalView';
import UserView from '../pages/Table/UserView';
import DoctorEdit from '../pages/Table/DoctorEdit';
import HospitalEdit from '../pages/Table/HospitalEdit';
import UserEdit from '../pages/Table/UserEdit';
import PatientsBookingTable from '../pages/Table/PatientsBookingTable';
import PatientEdit from '../pages/Table/PatientEdit';
import PatientsBookingEdit from '../pages/Table/PatientsBookingEdit';
import CancelledBookings from '../pages/Table/CancelledBookings';
import PatientBookingView from '../pages/Table/PatientBookingView';
import BookingsCalendar from '../pages/Calendar/BookingsCalendar';
import Invoice from '../pages/Invoices/Invoice';
import Invoices from '../pages/Invoices/Invoices';
import AdminBookingPage from '../pages/Bookings/AdminBookingPage';
import SecDoctorList from '../pages/Lists/SecDoctorList';
import HospitalDoctorList from '../pages/Lists/HospitalDoctorList';
import TreatmentProcedure from '../pages/Table/TreatmentProcedure';
import TreatmentProcedureForm from '../pages/Table/TreatmentProcedureForm';
import TreatmentProcedureEdit from '../pages/Table/TreatmentProcedureEdit';
import TreatmentPerHospital from '../pages/Table/TreatmentPerHospital';
import EditProfile from '../pages/Profile/EditProfile';
import SecretaryPage from '../pages/Calendar/SecretaryPage';
import BookingCyclePage from '../pages/Bookings/BookingCyclePage';
import BookingCycleForm from '../pages/Bookings/BookingCycleForm';
import BookingCycleUpdateForm from '../pages/Bookings/BookingCycleUpdateForm';
import PoAndInvoices from '../pages/Invoices/PoAndInvoices';
import ProbesTable from '../pages/Table/ProbesTable';
import BookingCycleOffList from '../pages/Bookings/BookingCycleOffList';
import BookingCycleOffListForm from '../pages/Bookings/BookingCycleOffListForm';
import SecretaryCancelledBookingsTable from '../pages/Table/SecretaryCancelledBookingsTable';
import MainCalendar from '../pages/Calendar/MainCalendar';
import DateBookingInformation from '../pages/Calendar/DateBookingInformation';
import PatientBookingsReport from '../pages/Reports/PatientBookingsReport';
import InvoicesReport from '../pages/Reports/InvoicesReport';
import BookingsByCategory from '../pages/Reports/BookingsByCategory';
import Reconciliation from '../pages/Reports/Reconciliation';
import AlexusReport from '../pages/Reports/AlexusReport';
import ExusReport from '../pages/Reports/ExusReport';
import AlsReport from '../pages/Reports/AlsReport';
import SecretaryRoute from '../components/protected-routes/SecretaryRoute';
import StaffAndAdminRoute from '../components/protected-routes/StaffAndAdminRoute';
import SecAndAdminRoute from '../components/protected-routes/SecAndAdminRoute';
import MainMenu from '../mainmenu/MainMenu';

function Routes() {   

        return (
            <Switch>

                {/* <UserRoute path="/" exact component={Home} /> */}
                <Route path="/" exact component={MainMenu}/>

                {/* All Access Route */}
                    <UserRoute path="/main-calendar" exact component={MainCalendar} />
                    <UserRoute path="/booking-information/:date" exact component={DateBookingInformation} />
                    <UserRoute path="/editprofile/:id" exact component={EditProfile} />

                {/* User Login - Register - Forgot Pass  */}
                    <Route path="/login" exact component={Login} />
                    <Route path="/forgotpassword" exact component={ForgotPassword} />
                    <Route path="/resetpassword" exact component={ResetPassword} />

                {/* Admin Routes */}
                    <AdminRoute path="/doctorstable" exact component={DoctorsTable} />
                    <AdminRoute path="/hospitalstable" exact component={HospitalsTable} />
                    <AdminRoute path="/userstable" exact component={UsersTable} />
                    <AdminRoute path="/doctorsform" exact component={DoctorsForm} />
                    <AdminRoute path="/hospitalsform" exact component={HospitalsForm} />
                    <AdminRoute path="/usersform" exact component={UsersForm} />
                    <AdminRoute path="/invoice" exact component={Invoice} />
                    <AdminRoute path="/invoices" exact component={Invoices} />
                    <AdminRoute path="/generalBookings" exact component={AdminBookingPage} />
                    <AdminRoute path="/doctorview/:id" exact component={DoctorView} />
                    <AdminRoute path="/hospitalview/:id" exact component={HospitalView} />
                    <AdminRoute path="/userview/:id" exact component={UserView} />
                    <AdminRoute path="/doctoredit/:id" exact component={DoctorEdit} />
                    <AdminRoute path="/hospitaledit/:id" exact component={HospitalEdit} />
                    <AdminRoute path="/useredit/:id" exact component={UserEdit} />
                    <AdminRoute path="/treatmentprocedure" exact component={TreatmentProcedure} />
                    <AdminRoute path="/procedureform" exact component={TreatmentProcedureForm} />
                    <AdminRoute path="/procedureeditform/:id" exact component={TreatmentProcedureEdit} />
                    <AdminRoute path="/treatmentperhospital" exact component={TreatmentPerHospital} />
                    <AdminRoute path="/bookingcyclepage" exact component={BookingCyclePage} />
                    <AdminRoute path="/bookingcycleform" exact component={BookingCycleForm} />
                    <AdminRoute path="/bookingcycleupdateform/:id" exact component={BookingCycleUpdateForm} />
                    <AdminRoute path="/probestable" exact component={ProbesTable} />
                    <AdminRoute path="/bookingcycleofflist" exact component={BookingCycleOffList} />
                    <AdminRoute path="/bookingcycleofflistform" exact component={BookingCycleOffListForm} />

                    {/* Route for reports for admin */}
                    <AdminRoute path="/patient-bookings-report" exact component={PatientBookingsReport} />
                    <AdminRoute path="/invoices-report" exact component={InvoicesReport} />
                    <AdminRoute path="/bookings-by-category" exact component={BookingsByCategory} />
                    <AdminRoute path="/reconciliation-report" exact component={Reconciliation} />
                    <AdminRoute path="/alexus-report/:id" exact component={AlexusReport} />
                    <AdminRoute path="/exus-report/:id" exact component={ExusReport} />
                    <AdminRoute path="/als-report/:id" exact component={AlsReport} />
                    <AdminRoute path="/poandinvoices/:id/:invNo" exact component={PoAndInvoices} />

                {/* Secretary Routes */}
                    <SecretaryRoute path="/patientsbookingtable" exact component={PatientsBookingTable} />
                    <SecretaryRoute path="/cancelledbookingstable" exact component={CancelledBookings} />
                    <SecretaryRoute path="/secretarypage" exact component={SecretaryPage} />
                    <SecretaryRoute path="/secCancelledbookingstable" exact component={SecretaryCancelledBookingsTable} />

                {/* Mixed Permissions Routes */}
                    {/* Staff and Admin Route */}
                    {/* <StaffAndAdminRoute path="/" exact component={BookingsCalendar} /> */}
                    <StaffAndAdminRoute path="/bookingscalendar" exact component={BookingsCalendar} />

                    {/* Sec and Admin Route */}
                    <SecAndAdminRoute path="/doctorsbooking" exact component={DoctorsBooking} />
                    <SecAndAdminRoute path="/patientsbookingview/:id" exact component={PatientBookingView} />                
                    <SecAndAdminRoute path="/patientsbookingedit/:id/:hospId" exact component={PatientsBookingEdit} />
                   
                {/* Unused Routes */}
                    <UserRoute path="/patientedit/:id" exact component={PatientEdit} />
                    <UserRoute path="/hospdoctorlist" exact component={HospitalDoctorList} />
                    <UserRoute path="/secdoctorlist" exact component={SecDoctorList} />

            </Switch>
        );
}

export default Routes;