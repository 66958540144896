import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Sidebar.css';
import UserContext from '../../contexts/User/Index';
import GeneralSidebar from './GeneralSidebar';
import SecretarySidebar from './SecretarySidebar';
import logo from '../../assets/images/alexus-logo.png';
import $ from 'jquery';
import { request } from '../../constants/constants';


class Sidebar extends Component {
    static contextType = UserContext;
    constructor(props){
        super(props);
        this.state = {
            logo: '',
            isLogoLoading: true
        }
    }
    

    componentDidMount() {
        request('GET', `/users/logo/getLogo`, {}, true).then((res) => {
            this.setState({
                logo: res.data.url,
                isLogoLoading: false
            })
        })

        $("#sidebarToggle, #sidebarToggleTop").on('click', function(e) {
            $("body").toggleClass("sidebar-toggled");
            $(".sidebar").toggleClass("toggled");
            if ($(".sidebar").hasClass("toggled")) {
              $('.sidebar .collapse').collapse('hide');
            };
        });

        $(window).resize(function() {
            if ($(window).width() < 768) {
              $('.sidebar .collapse').collapse('hide');
            };
            
            // Toggle the side navigation when window is resized below 480px
            if ($(window).width() < 480 && !$(".sidebar").hasClass("toggled")) {
              $("body").addClass("sidebar-toggled");
              $(".sidebar").addClass("toggled");
              $('.sidebar .collapse').collapse('hide');
            };
        });
        
          // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
          $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function(e) {
            if ($(window).width() >= 768) {
              var e0 = e.originalEvent,
                delta = e0.wheelDelta || -e0.detail;
              this.scrollTop += (delta < 0 ? 1 : -1) * 30;
              e.preventDefault();
            }
          });
    }

    render() {
        const userPermission = this.context.user.user.permission;
        return (
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                {
                        this.context.user && (userPermission === 'secretary' ? 

                        <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/secretarypage">
                            <div className="sidebar-brand-icon">
                                {/* <i className="fas fa-laugh-wink" /> */}
                                {
                                    this.state.isLogoLoading ? '' :  <img src={this.state.logo} className="logo" alt="Logo" />
                                }
                            </div>
                        </a> 
                         : 
                        <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/bookingscalendar">
                            <div className="sidebar-brand-icon">
                                {/* <i className="fas fa-laugh-wink" /> */}
                                {/* <img src={logo} className="logo" alt="Logo" /> */}
                                {
                                    this.state.isLogoLoading ? '' :  <img src={this.state.logo} className="logo" alt="Logo" />
                                }

                            </div>
                        </a>
                        ) 
                }
                {
                    this.context.user && (userPermission === 'secretary' ? 
                        <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/secretarypage">
                            <div className="sidebar-brand-text">ALEXUS SERVICES<span style={{ fontSize: '7px' }}>&nbsp;PTY LTD</span></div>
                        </a> : 
                        <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/bookingscalendar">
                            <div className="sidebar-brand-text">ALEXUS SERVICES<span style={{ fontSize: '7px' }}>&nbsp;PTY LTD</span></div>
                        </a> 
                    )
                }   
                
                
                <hr className="sidebar-divider my-0" />
                    <li className="nav-item active">
                    {
                        this.context.user && (userPermission === 'secretary' ? 
                            ("") : (<Link className="nav-link" to="/bookingscalendar">
                                <i className="fas fa-fw fa-tachometer-alt" />
                                    <span>Dashboard</span>
                                </Link>))
                    }
                        
                    </li>
                    <hr className="sidebar-divider" />
                    <div className="sidebar-heading">
                        Pages
                    </div>
                    {userPermission === 'secretary' ? <SecretarySidebar /> : <GeneralSidebar />}
                    
                <hr className="sidebar-divider d-none d-md-block" />
            </ul>          
        );
    }
}

export default Sidebar;